// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fileTree .arco-tree-node-indent-block {
  width: 4px !important;
}
.fileTree .arco-tree-node-title {
  display: flex;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fileTree .arco-tree-node-title-text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/components/FileTree/index.scss"],"names":[],"mappings":"AACE;EACE,qBAAA;AAAJ;AAMI;EAAA,aAAA;EAAA,WAAA;EAAA,gBAAA;EAAA,uBAAA;EAAA;AAAA;AAGA;EAAA,WAAA;EAAA,gBAAA;EAAA,uBAAA;EAAA;AAAA","sourcesContent":[".fileTree {\n  .arco-tree-node-indent-block {\n    width: 4px !important;\n  }\n  // .arco-tree-node-switcher {\n  //   margin-right: 2px !important;\n  // }\n  .arco-tree-node-title {\n    @apply flex w-full overflow-hidden overflow-ellipsis whitespace-nowrap;\n  }\n  .arco-tree-node-title-text {\n    @apply w-full overflow-hidden overflow-ellipsis whitespace-nowrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
