// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.SearchBox .arco-input-inner-wrapper{
  background-color: var(--color-fill-1)
}
.SearchBox .noDrag {
  -webkit-app-region: no-drag; /* 按钮部分不允许拖动 */
}

.FocusSearchBox .arco-input-inner-wrapper{
  border-width: 1px;
  border-style: solid;
  border-color: rgb(var(--primary-5))
}`, "",{"version":3,"sources":["webpack://./src/components/GlobalSearchBox/index.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAGR;EAAA;AAAA;AAEJ;EACF,2BAAA,EAAA,cAAA;AAAF;;AAMI;EAAA,iBAAA;EAAA,mBAAA;EAAA;AAAA","sourcesContent":["\n.SearchBox {\n    .arco-input-inner-wrapper {\n        @apply bg-fill-1\n    }\n    .noDrag {\n  -webkit-app-region: no-drag; /* 按钮部分不允许拖动 */\n}\n}\n\n.FocusSearchBox {\n.arco-input-inner-wrapper {\n    @apply border border-solid border-primary-5\n}\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
