// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading__collapse .skeleton-header,
.loading__collapse .skeleton-content {
  animation: waveLoading 2.4s infinite linear;
  background: linear-gradient(90deg, #e5e6eb 0%, #f7f8fa 50%, #e5e6eb 100%);
  background-size: 200% 100%;
}
@keyframes waveLoading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.loading__collapse .arco-collapse-item-active > .arco-collapse-item-header {
  background-color: var(--color-fill-1);
  padding-left: 1rem;
}
.loading__collapse .arco-collapse-item-content-box {
  padding-left: 1rem;
}
.loading__collapse .arco-collapse {
  border-style: none;
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Workspace/CBWorkspaceCenter/Center/CenterContent/CollapseComponents/LoadingCollapse/index.scss"],"names":[],"mappings":"AACE;;EAEE,2CAAA;EACA,yEAAA;EACA,0BAAA;AAAJ;AAEE;EACE;IACE,2BAAA;EAAJ;EAEE;IACE,4BAAA;EAAJ;AACF;AAIM;EAAA,qCAAA;EAAA;AAAA;AAIF;EAAA;AAAA;AAGA;EAAA;AAAA","sourcesContent":[".loading__collapse {\n  .skeleton-header,\n  .skeleton-content {\n    animation: waveLoading 2.4s infinite linear;\n    background: linear-gradient(90deg, #e5e6eb 0%, #f7f8fa 50%, #e5e6eb 100%);\n    background-size: 200% 100%;\n  }\n  @keyframes waveLoading {\n    0% {\n      background-position: 200% 0;\n    }\n    100% {\n      background-position: -200% 0;\n    }\n  }\n  .arco-collapse-item-active {\n    > .arco-collapse-item-header {\n      @apply bg-fill-1 pl-4;\n    }\n  }\n  .arco-collapse-item-content-box {\n    @apply pl-4;\n  }\n  .arco-collapse {\n    @apply border-none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
