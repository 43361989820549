"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.noReadmeAtom = exports.userApiKeysAtom = exports.atlasKeysAtom = exports.loginModalVisibleAtom = exports.headerListAtom = exports.tabActiveAtom = exports.themesAtom = exports.currentCodebaseAtom = exports.currentFilePathAtom = exports.selectedKeysAtom = exports.isLoadingAtom = exports.isFoldAtom = exports.FileAtom = void 0;
const jotai_1 = require("jotai");
const headerList_1 = require("@src/components/EHeader/headerList");
const FileAtom = __importStar(require("./fileAtom"));
exports.FileAtom = FileAtom;
//YorgMenu Folder control
exports.isFoldAtom = (0, jotai_1.atom)(true);
exports.isLoadingAtom = (0, jotai_1.atom)(false);
exports.selectedKeysAtom = (0, jotai_1.atom)(["1001"]);
exports.currentFilePathAtom = (0, jotai_1.atom)("");
exports.currentCodebaseAtom = (0, jotai_1.atom)({
    id: "",
    name: "",
    save_path: "",
    source_type: 0,
    is_git: false,
    last_access_time: 0,
    last_fetch_time: 0,
    default_project_uuid: "",
    git: {
        current_branch: "",
    },
    project_num: 0,
    currentBranch: "",
});
exports.themesAtom = (0, jotai_1.atom)(localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "/yorg_main.css");
exports.tabActiveAtom = (0, jotai_1.atom)([
    { title: "home", active: true, pageName: "home", id: 0 },
]);
// 就是触发页面重新获取view用的
exports.headerListAtom = (0, jotai_1.atom)(headerList_1.headerTabListDefault);
exports.loginModalVisibleAtom = (0, jotai_1.atom)(false);
exports.atlasKeysAtom = (0, jotai_1.atom)(null);
exports.userApiKeysAtom = (0, jotai_1.atom)(null);
exports.noReadmeAtom = (0, jotai_1.atom)(false);
