"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRegeDraft = void 0;
const web_react_1 = require("@arco-design/web-react");
const jotai_1 = require("jotai");
const react_1 = require("react");
const api_1 = require("@src/api");
const softwareEngineer_1 = require("@src/api/codebaseApi/softwareEngineer");
const workspaceAtom_1 = require("@src/store/workspaceAtom");
const CBWorkSpaceContext_1 = require("../CBWorkSpaceContext");
const useVerifyAction_1 = require("./useVerifyAction");
const { postFileContentByProId } = api_1.api;
const { postRegeDraft } = softwareEngineer_1.softwareEngineerApi;
const useRegeDraft = () => {
    const { setDiffList, languageList, setDiffPageLoading, setShowTextArea, regeList, setRegePrepare, diffListCache, setRegeList, } = (0, react_1.useContext)(CBWorkSpaceContext_1.WorkSpaceContext);
    const [curTaskId] = (0, jotai_1.useAtom)(workspaceAtom_1.curTaskIdAtom);
    const { verifyActions } = (0, useVerifyAction_1.useVerifyAction)();
    const [, setWpIsDoingRege] = (0, jotai_1.useAtom)(workspaceAtom_1.wpIsDoingRegeAtom);
    const [, setIsWpGeneWorking] = (0, jotai_1.useAtom)(workspaceAtom_1.isWpGeneWorkingAtom);
    const [, setVerifyThought] = (0, jotai_1.useAtom)(workspaceAtom_1.verifyThoughtAtom);
    const [curProId] = (0, jotai_1.useAtom)(workspaceAtom_1.curProIdAtom);
    const regeDraft = async (advices) => {
        if (Object.keys(regeList).length === 0) {
            web_react_1.Message.warning("Please Select one file.");
            return;
        }
        try {
            setWpIsDoingRege(true);
            setIsWpGeneWorking(true);
            setDiffPageLoading(true);
            setRegePrepare(false);
            const res = (await postRegeDraft({
                task_uuid: curTaskId,
                feedback: advices,
                actions: regeList,
            }));
            if (res.status === 0) {
                const actions = res.data.actions;
                const thoughts = res.data.thoughts;
                let result = "";
                Object.entries(thoughts).forEach(([filePath, entries]) => {
                    result += `File: ${filePath}\n`;
                    Object.entries(entries).forEach(([key, value]) => {
                        const lineRange = key.replace(/[()]/g, "").replace(", ", "-");
                        // 在每个句号后添加换行符
                        const formattedValue = value.replace(/\. /g, ".\n");
                        result += `  (line ${lineRange}) ${formattedValue}\n`;
                    });
                    result += "\n"; // 在每个文件的条目后添加额外的换行以分隔文件
                });
                const diffItemsPromises = Object.entries(actions).map(async ([rege_path]) => {
                    var _a;
                    const res_file_content = (await postFileContentByProId({
                        project_uuid: (_a = localStorage.getItem("curProId")) !== null && _a !== void 0 ? _a : curProId,
                        paths: [rege_path],
                    }));
                    if (res_file_content.status === 0) {
                        return Object.entries(actions[rege_path]).map(([rege_range, rege_content]) => ({
                            breadcrumbData: rege_path.split("/"),
                            oldCode: res_file_content.data.file_contents[0].content,
                            newCode: diffListCache[rege_path][0].newCode, //如果是rege的话，得从cache里面取
                            SSECode: rege_content,
                            path: rege_path,
                            range: rege_range,
                            sessionId: curTaskId,
                            language: languageList[rege_path],
                            regeCode: res.data.file_contents[rege_path],
                        }));
                    }
                    else {
                        return []; // 如果请求失败，返回空数组
                    }
                });
                // 等待所有异步操作完成，并展平数组
                const diffItemsArray = await Promise.all(diffItemsPromises);
                const diffItems = diffItemsArray.flat();
                // 更新状态，过滤掉 undefined 的值
                setDiffList(diffItems);
                //console.log(diffItems, "展平后数组");
                // 所有异步操作完成后执行
                setWpIsDoingRege(false);
                setDiffPageLoading(false);
                setRegeList({});
                setShowTextArea(false);
                setIsWpGeneWorking(false);
                setVerifyThought(result);
                // verifyActions({ languageList: languageList, regenerate: true }); 暂时先不verify
            }
        }
        catch (e) {
            setIsWpGeneWorking(false);
        }
    };
    return { regeDraft };
};
exports.useRegeDraft = useRegeDraft;
