"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const web_react_1 = require("@arco-design/web-react");
const icon_1 = require("@arco-design/web-react/icon");
const i18next_1 = require("i18next");
const react_1 = __importStar(require("react"));
const Button_1 = require("@src/components/Button");
const BreadcrumbItem = web_react_1.Breadcrumb.Item;
const CreateFileItem = ({ filePath, onEdit, onDelete, }) => {
    const [isEditMode, setIsEditMode] = (0, react_1.useState)(false);
    const [newFilePath, setNewFilePath] = (0, react_1.useState)(filePath);
    return (react_1.default.createElement("div", { className: `flex h-11 items-center rounded-xl bg-fill-1 p-1 shadow ${isEditMode ? "gap-3" : "gap-[6px]"}` },
        react_1.default.createElement(icon_1.IconFile, { className: "ml-3 h-4 w-4 shrink-0" }),
        isEditMode ? (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(web_react_1.Input, { className: "bg-bg-1", placeholder: (0, i18next_1.t)("workspace.center.focusFiles.createFilePlaceholder"), value: newFilePath, onChange: (e) => setNewFilePath(e) }),
            react_1.default.createElement("div", { className: "mr-1 flex gap-1" },
                react_1.default.createElement(Button_1.Button, { shape: "round", onClick: () => {
                        setIsEditMode(false);
                        onEdit(newFilePath);
                    } }, (0, i18next_1.t)("workspace.center.focusFiles.save")),
                react_1.default.createElement(Button_1.Button, { shape: "round", type: "white", onClick: () => {
                        setIsEditMode(false);
                        setNewFilePath(filePath);
                        //console.log(newFilePath);
                    } }, (0, i18next_1.t)("workspace.center.focusFiles.cancel"))))) : (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(web_react_1.Breadcrumb, { className: "px-1 [&_svg]:h-3 [&_svg]:w-3" }, newFilePath.split("/").map((item, idx) => (react_1.default.createElement(BreadcrumbItem, { key: idx, className: "p-0 text-sm" }, item)))),
            react_1.default.createElement(web_react_1.Tag, { color: "purple", className: "shrink-0 rounded-lg border border-solid [&.arco-tag]:border-border-2" }, (0, i18next_1.t)("workspace.center.focusFiles.new")),
            react_1.default.createElement(Button_1.Button, { className: "shrink-0", type: "text", icon: react_1.default.createElement(icon_1.IconCopy, { className: "h-4 w-4" }), onClick: () => navigator.clipboard.writeText(newFilePath) }),
            react_1.default.createElement(Button_1.Button, { className: "ml-auto", type: "white", shape: "round", onClick: () => setIsEditMode(true) }, (0, i18next_1.t)("workspace.center.focusFiles.editFile")),
            react_1.default.createElement(Button_1.Button, { className: "-ml-[2px] shrink-0", type: "text", icon: react_1.default.createElement(icon_1.IconDelete, { className: "h-4 w-4" }), onClick: onDelete })))));
};
exports.default = CreateFileItem;
