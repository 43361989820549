"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeHighlightsBasedOnStartOrEnd = mergeHighlightsBasedOnStartOrEnd;
function mergeHighlightsBasedOnStartOrEnd(highlights) {
    // 首先按 start 排序
    const sortedHighlights = highlights.sort((a, b) => a.start - b.start);
    const mergedHighlights = [];
    sortedHighlights.forEach((highlight) => {
        if (mergedHighlights.length === 0) {
            mergedHighlights.push({ ...highlight });
        }
        else {
            const last = mergedHighlights[mergedHighlights.length - 1];
            if (highlight.start <= last.end) {
                // 如果当前高亮的 start 在上一个范围内，合并它们
                last.end = Math.max(last.end, highlight.end);
            }
            else {
                // 否则，开始一个新的范围
                mergedHighlights.push({ ...highlight });
            }
        }
    });
    return mergedHighlights;
}
