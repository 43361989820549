// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copilot__ask__tab .my__model .arco-collapse-item-active {
  border-bottom: 1px solid var(--color-border-1) !important;
}
.copilot__ask__tab .my__model .arco-collapse-item-active .arco-collapse-item-header {
  border-bottom-color: transparent;
}
.copilot__ask__tab .my__model .arco-collapse-item-header {
  border-bottom-color: var(--color-border-1);
  background-color: var(--color-bg-1);
  font-size: 16px;
}
.copilot__ask__tab .my__model .arco-collapse-item-content {
  background-color: var(--color-bg-1);
}
.copilot__ask__tab .my__model .arco-collapse-item {
  border-bottom-width: 0px;
}
.copilot__ask__tab .my__model .arco-collapse-item .arco-collapse-item-icon-hover {
  left: 0px;
}
.copilot__ask__tab .my__model .arco-collapse-item-header-left {
  padding-left: 28px;
  padding-right: 0px;
}
.copilot__ask__tab .my__model .arco-collapse-item-content-box {
  padding: 0px;
  padding-bottom: 0.75rem;
  padding-top: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Copilot/ConfigTab/AskTab/index.scss"],"names":[],"mappings":"AAEI;EACE,yDAAA;AADN;AAGQ;EAAA;AAAA;AAIF;EAAA,0CAAA;EAAA,mCAAA;EAAA;AAAA;AAGA;EAAA;AAAA;AAGA;EAAA;AAAA;AAEE;EAAA;AAAA;AAIF;EAAA,kBAAA;EAAA;AAAA;AAGA;EAAA,YAAA;EAAA,uBAAA;EAAA;AAAA","sourcesContent":[".copilot__ask__tab {\n  .my__model {\n    .arco-collapse-item-active {\n      border-bottom: 1px solid var(--color-border-1) !important;\n      .arco-collapse-item-header {\n        @apply border-b-transparent;\n      }\n    }\n    .arco-collapse-item-header {\n      @apply border-b-border-1 bg-bg-1 text-[16px];\n    }\n    .arco-collapse-item-content {\n      @apply bg-bg-1;\n    }\n    .arco-collapse-item {\n      @apply border-b-0;\n      .arco-collapse-item-icon-hover {\n        @apply left-0;\n      }\n    }\n    .arco-collapse-item-header-left {\n      @apply pl-[28px] pr-0;\n    }\n    .arco-collapse-item-content-box {\n      @apply p-0 pb-3 pt-4;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
