// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.workspace_sessions_list .arco-spin-children {
  height: 100%;
}
.workspace_sessions_list .sesson__list::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Workspace/CBWorkSpaceSessions/index.scss"],"names":[],"mappings":"AAEI;EAAA;AAAA;AAGA;EACE,aAAA;AADN","sourcesContent":[".workspace_sessions_list {\n  .arco-spin-children {\n    @apply h-full;\n  }\n  .sesson__list {\n    &::-webkit-scrollbar {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
