"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getReadmeContent = exports.postFileContent = exports.getSingleFolderList = exports.getFileList = exports.getFile = exports.getAllFileList = void 0;
const apiConfigV2_1 = require("../apiConfigV2");
const urlV2 = "/api/v2/workspace";
const urlV2Project = "/api/v2/project";
// const getAllFileList = (id: string) => {
//   return getApiV2<{ file_nodes: TreeNode[] }>(urlV2 + "/get_file_tree", {
//     workspace_uuid: id,
//   });
// };
const getAllFileList = (id) => {
    return (0, apiConfigV2_1.getApi)(urlV2Project + "/get_file_tree", {
        project_uuid: id,
    }, true);
};
exports.getAllFileList = getAllFileList;
const getSingleFolderList = (id, key, path) => {
    return (0, apiConfigV2_1.getApi)(urlV2Project + "/get_file_tree_single_layer", {
        project_uuid: id,
        key,
        path,
    }, true);
};
exports.getSingleFolderList = getSingleFolderList;
const getFile = (path, workspace_uuid, onError) => {
    return (0, apiConfigV2_1.getApi)(urlV2 + "/get_file_content", { path, workspace_uuid }, true, onError);
};
exports.getFile = getFile;
const postFileContent = (paths, project_uuid, encoding) => {
    return (0, apiConfigV2_1.postApi)(urlV2Project + "/get_file_content", { paths, project_uuid, encoding }, true);
};
exports.postFileContent = postFileContent;
const getFileList = (path, workspace_uuid) => {
    return (0, apiConfigV2_1.getApi)(urlV2 + "/get_current_file_list", {
        workspace_uuid,
        path,
    }, true);
};
exports.getFileList = getFileList;
const getReadmeContent = (params) => {
    return (0, apiConfigV2_1.getApi)(urlV2 + "/get_readme_content", params, true);
};
exports.getReadmeContent = getReadmeContent;
