"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userAtom = void 0;
const jotai_1 = require("jotai");
exports.userAtom = (0, jotai_1.atom)({
    uuid: "",
    name: "",
    authing_id: "",
    profile: "",
    picture: "",
    openai_api_key: "",
    deepseek_api_key: "",
    git_user_name: "",
    git_user_email: "",
    github_infos: [],
    gitlab_infos: [],
});
