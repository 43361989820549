"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.APIKeys = void 0;
const web_react_1 = require("@arco-design/web-react");
const i18next_1 = require("i18next");
const jotai_1 = require("jotai");
const react_1 = __importStar(require("react"));
const userApi_1 = require("@src/api/userApi");
const userAtom_1 = require("@src/store/userAtom");
const Button_1 = require("../Button");
const APIKeys = ({ setInvisible }) => {
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [currentAPIKeys, setCurrentAPIKeys] = (0, react_1.useState)({
        user_id: "",
        openai_api_key: "",
        deepseek_api_key: "",
    });
    const [user, setUser] = (0, jotai_1.useAtom)(userAtom_1.userAtom);
    const updateConfig = () => {
        setIsLoading(true);
        (0, userApi_1.setUserAPIKey)(currentAPIKeys)
            .then((res) => {
            if ((res === null || res === void 0 ? void 0 : res.status) === 0) {
                web_react_1.Message.success((0, i18next_1.t)("settings.api.success"));
                setInvisible();
                (0, userApi_1.getUserInfoByAuthingId)({ authing_id: user.authing_id }).then((res) => {
                    setUser(res.data);
                    localStorage.setItem("openai_api_key", (res === null || res === void 0 ? void 0 : res.data.openai_api_key) || "");
                    localStorage.setItem("deepseek_api_key", (res === null || res === void 0 ? void 0 : res.data.deepseek_api_key) || "");
                });
            }
            else {
                throw new Error();
            }
        })
            .catch(() => {
            web_react_1.Message.error((0, i18next_1.t)("settings.api.error"));
        })
            .finally(() => {
            setIsLoading(false);
        });
    };
    (0, react_1.useEffect)(() => {
        setCurrentAPIKeys({
            user_id: user.uuid,
            openai_api_key: user.openai_api_key,
            deepseek_api_key: user.deepseek_api_key,
        });
    }, []);
    return (react_1.default.createElement("div", { className: "flex flex-1 flex-col" },
        react_1.default.createElement("div", { className: "flex flex-grow flex-col gap-4 px-3 py-4" },
            react_1.default.createElement("label", { className: "flex flex-col gap-2 text-text-2" },
                (0, i18next_1.t)("settings.api.openai"),
                react_1.default.createElement(web_react_1.Input, { className: "rounded-lg border border-solid border-border-2 bg-fill-2", value: currentAPIKeys.openai_api_key, allowClear: true, placeholder: (0, i18next_1.t)("settings.api.openaiPlaceholder"), onChange: (openai_api_key) => {
                        setCurrentAPIKeys((prev) => ({
                            ...prev,
                            openai_api_key: openai_api_key.replace(/\s/g, ""),
                        }));
                    } })),
            react_1.default.createElement("label", { className: "flex flex-col gap-2 text-text-2" },
                (0, i18next_1.t)("settings.api.deepseek"),
                react_1.default.createElement(web_react_1.Input, { className: "rounded-lg border border-solid border-border-2 bg-fill-2", value: currentAPIKeys.deepseek_api_key, allowClear: true, placeholder: (0, i18next_1.t)("settings.api.deepseekPlaceholder"), onChange: (deepseek_api_key) => {
                        setCurrentAPIKeys((prev) => ({
                            ...prev,
                            deepseek_api_key: deepseek_api_key.replace(/\s/g, ""),
                        }));
                    } }))),
        react_1.default.createElement("div", { className: "flex justify-end gap-2 border-t border-solid border-border-2 p-3" },
            react_1.default.createElement(Button_1.Button, { type: "gray", onClick: setInvisible }, (0, i18next_1.t)("settings.shared.cancel")),
            react_1.default.createElement(Button_1.Button, { loading: isLoading, onClick: updateConfig }, (0, i18next_1.t)("settings.shared.save")))));
};
exports.APIKeys = APIKeys;
