"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.globalSearchMode = exports.selectedTabAtom = exports.fileListAtom = exports.cbtreeAtom = exports.isShowChatAtom = void 0;
const jotai_1 = require("jotai");
exports.isShowChatAtom = (0, jotai_1.atom)(false);
exports.cbtreeAtom = (0, jotai_1.atom)([]);
// record file list
exports.fileListAtom = (0, jotai_1.atom)([]);
exports.selectedTabAtom = (0, jotai_1.atom)("allFiles");
exports.globalSearchMode = (0, jotai_1.atom)(false);
