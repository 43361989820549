// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fileCheckBox_wrapper {
    width: 100%
}
.fileCheckBox_wrapper .arco-checkbox-group-direction-vertical .arco-checkbox {
    display: flex;
    height: 2.25rem;
    align-items: center;
    border-bottom-width: 1px;
    border-style: solid;
    border-color: var(--color-border-2);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 0.25rem;
    padding-right: 0.5rem
}
.fileCheckBox_wrapper .arco-checkbox-group {
    width: 100%
}
.fileCheckBox_wrapper .arco-checkbox-text {
    width: 100%
}
.fileCheckBox_wrapper .arco-checkbox-mask-wrapper {
    top: 0px
}
.fileCheckBox_wrapper .arco-checkbox-checked .arco-checkbox-mask {
    background-color: rgb(var(--primary-5))
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Workspace/CBCommon/FileCheckBox/index.scss"],"names":[],"mappings":"AACE;IAAA;AAAA;AAEE;IAAA,aAAA;IAAA,eAAA;IAAA,mBAAA;IAAA,wBAAA;IAAA,mBAAA;IAAA,mCAAA;IAAA,qBAAA;IAAA,wBAAA;IAAA,qBAAA;IAAA;AAAA;AAGA;IAAA;AAAA;AAGA;IAAA;AAAA;AAGA;IAAA;AAAA;AAIE;IAAA;AAAA","sourcesContent":[".fileCheckBox_wrapper {\n  @apply w-full;\n  .arco-checkbox-group-direction-vertical .arco-checkbox {\n    @apply flex h-9 items-center border-b border-solid border-border-2 py-1.5 pl-1 pr-2;\n  }\n  .arco-checkbox-group {\n    @apply w-full;\n  }\n  .arco-checkbox-text {\n    @apply w-full;\n  }\n  .arco-checkbox-mask-wrapper {\n    @apply top-0;\n  }\n  .arco-checkbox-checked {\n    .arco-checkbox-mask {\n      @apply bg-primary-5;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
