// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.docs__collapse .arco-collapse-item-content-box {
    padding: 0px
}
.docs__collapse .arco-btn-text:not(.arco-btn-disabled) {
    color: var(--color-text-2)
}
.docs__collapse .arco-collapse-item-header {
    height: 2.75rem;
    background-color: var(--color-fill-1);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 1rem;
    padding-right: 0.75rem
}
.docs__collapse .arco-collapse-item-header-icon-down {
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.docs__collapse .arco-icon-hover {
    left: 1rem
}
.docs__collapse .edit__button .arco-btn-secondary:not(.arco-btn-disabled) {
    height: 30px;
    width: 100px;
    border-radius: 9999px;
    border-color: var(--color-border-2);
    background-color: var(--color-bg-2);
    color: var(--color-text-2)
}
.docs__collapse .edit__button .arco-btn-secondary:not(.arco-btn-disabled):hover {
    background-color: transparent
}
.docs__collapse .arco-breadcrumb {
    font-size: 14px
}
.docs__collapse .button_group .arco-btn-secondary {
    height: 2rem;
    width: 77px;
    border-radius: 0.5rem;
    border-color: var(--color-border-2);
    font-size: 14px;
    color: var(--color-text-2)
}
.docs__collapse .button_group .arco-btn-primary {
    height: 2rem;
    width: 79px;
    border-radius: 0.5rem;
    font-size: 14px
}
.docs__collapse .arco-collapse-item {
    background-color: var(--color-bg-8)
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Workspace/CBWorkspaceCenter/Center/CenterContent/CollapseComponents/FloderCollapse/index.scss"],"names":[],"mappings":"AAEI;IAAA;AAAA;AAGA;IAAA;AAAA;AAGA;IAAA,eAAA;IAAA,qCAAA;IAAA,oBAAA;IAAA,uBAAA;IAAA,kBAAA;IAAA;AAAA;AAGA;IAAA,kBAAA;IAAA;AAAA;AAIA;IAAA;AAAA;AAIE;IAAA,YAAA;IAAA,YAAA;IAAA,qBAAA;IAAA,mCAAA;IAAA,mCAAA;IAAA;AAAA;AAAA;IAAA;AAAA;AAKF;IAAA;AAAA;AAIE;IAAA,YAAA;IAAA,WAAA;IAAA,qBAAA;IAAA,mCAAA;IAAA,eAAA;IAAA;AAAA;AAGA;IAAA,YAAA;IAAA,WAAA;IAAA,qBAAA;IAAA;AAAA;AAIF;IAAA;AAAA","sourcesContent":[".docs__collapse {\n  .arco-collapse-item-content-box {\n    @apply p-0;\n  }\n  .arco-btn-text:not(.arco-btn-disabled) {\n    @apply text-text-2;\n  }\n  .arco-collapse-item-header {\n    @apply h-11 bg-fill-1  py-1 pl-4 pr-3;\n  }\n  .arco-collapse-item-header-icon-down {\n    @apply rotate-90;\n  }\n\n  .arco-icon-hover {\n    @apply left-4;\n  }\n  .edit__button {\n    .arco-btn-secondary:not(.arco-btn-disabled) {\n      @apply h-[30px] w-[100px] rounded-full border-border-2 bg-bg-2 text-text-2 hover:bg-transparent;\n    }\n  }\n\n  .arco-breadcrumb {\n    @apply text-[14px];\n  }\n  .button_group {\n    .arco-btn-secondary {\n      @apply h-8 w-[77px] rounded-lg border-border-2 text-[14px] text-text-2;\n    }\n    .arco-btn-primary {\n      @apply h-8 w-[79px] rounded-lg text-[14px];\n    }\n  }\n  .arco-collapse-item {\n    @apply bg-bg-8;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
