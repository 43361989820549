// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-bar .input-search .arco-input-group .arco-input-inner-wrapper {
    width: 200px;
    border-radius: 0.5rem;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-border-2);
    background-color: var(--color-fill-2)
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Copilot/TopBar/index.scss"],"names":[],"mappings":"AAEI;IAAA,YAAA;IAAA,qBAAA;IAAA,iBAAA;IAAA,mBAAA;IAAA,mCAAA;IAAA;AAAA","sourcesContent":[".top-bar {\n  .input-search .arco-input-group .arco-input-inner-wrapper {\n    @apply w-[200px] rounded-lg border border-solid border-border-2 bg-fill-2;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
