"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const findNodeByKey = (tree, key) => {
    if (!tree || !tree.key) {
        return null; // 如果树为空或节点无效，则返回null
    }
    if (tree.key === key) {
        return tree; // 如果找到匹配的节点，则返回该节点
    }
    if (tree.children && tree.children.length > 0) {
        // 如果有子节点，递归搜索每个子节点
        for (const child of tree.children) {
            const result = findNodeByKey(child, key);
            if (result) {
                return result; // 如果在子节点中找到匹配的节点，返回该节点
            }
        }
    }
    return null; // 如果没有找到匹配的节点，返回null
};
exports.default = findNodeByKey;
