"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const icon_1 = require("@arco-design/web-react/icon");
const react_1 = __importStar(require("react"));
const Button_1 = require("@src/components/Button");
require("./index.scss");
const ErrorRollbackChatItem = ({ children, handleYes = () => { }, handleNo = () => { }, itemIndex, ask, }) => {
    const [isEditing] = (0, react_1.useState)(false);
    const [editedText] = (0, react_1.useState)(children || "");
    const [isShow] = (0, react_1.useState)(true); // 在父组件中定义状态
    const [displayLength, setDisplayLength] = (0, react_1.useState)(0);
    const ref = (0, react_1.useRef)(null);
    // 创建一个隐藏的文本输入框
    (0, react_1.useEffect)(() => {
        if (ref.current !== null) {
            ref.current.style.height = "0px";
            ref.current.style.height = ref.current.scrollHeight + "px";
        }
    }, [ref, editedText, isEditing]);
    (0, react_1.useEffect)(() => {
        const timer = setTimeout(() => {
            if (displayLength < children.length) {
                setDisplayLength(displayLength + 1);
            }
        }, 5); // 这里的100是每个字符显示的间隔时间，可以根据需要调整
        return () => clearTimeout(timer);
    }, [displayLength, children]);
    return (react_1.default.createElement("div", { className: ` ${itemIndex !== 0 ? "mt-2  rounded-[8px] border" : ""} box-border flex w-full flex-col items-start self-stretch border-solid ${ask ? "rounded-xl border border-border-2 shadow-sm" : "border-border-2"} pt-3` },
        react_1.default.createElement("div", { className: "flex flex-col items-start gap-4 self-stretch rounded-lg pb-2 pl-3 pr-[6px]" },
            react_1.default.createElement("div", { className: "flex items-center justify-between self-stretch" },
                react_1.default.createElement("div", { className: "flex items-center gap-2 self-stretch text-text-1" },
                    react_1.default.createElement("div", { className: "flex h-[12px] w-[12px] items-center justify-center gap-[10px] rounded-full bg-[#F76560] p-[2px]" }),
                    react_1.default.createElement("p", { className: "text-[#F76560]" }, "An error occured")))),
        isShow && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement("div", { className: "mt-[-5px] flex flex-col items-start gap-3 self-stretch rounded-bl-lg rounded-br-lg p-3 text-text-1" },
                react_1.default.createElement("div", { className: "w-full overflow-auto break-words" }, editedText.slice(0, displayLength)),
                react_1.default.createElement("div", { className: "confirm_button flex items-center justify-end gap-2 self-stretch" },
                    react_1.default.createElement(Button_1.Button, { type: "gray", className: "cursor-pointer rounded-lg text-[14px] text-text-1", onClick: handleYes },
                        react_1.default.createElement(icon_1.IconRefresh, null),
                        react_1.default.createElement("span", null, "Retry"))))))));
};
exports.default = ErrorRollbackChatItem;
