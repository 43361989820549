// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.local-repo .arco-input-inner-wrapper-focus {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px
}
.local-repo .input-button {
    border-width: 1px;
    border-left-width: 0px;
    border-style: solid;
    border-color: var(--color-border-2)
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Home/TopBar/AddRepoModal/LocalRepo/index.scss"],"names":[],"mappings":"AAEI;IAAA,4BAAA;IAAA;AAAA;AAGA;IAAA,iBAAA;IAAA,sBAAA;IAAA,mBAAA;IAAA;AAAA","sourcesContent":[".local-repo {\n  .arco-input-inner-wrapper-focus {\n    @apply rounded-r-none;\n  }\n  .input-button {\n    @apply border border-l-0 border-solid border-border-2;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
