// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wp__state__card__comp .arco-card-body {
    height: 100%;
    width: 100%;
    padding: 0px
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Workspace/CBCommon/StateCardComp/index.scss"],"names":[],"mappings":"AAEI;IAAA,YAAA;IAAA,WAAA;IAAA;AAAA","sourcesContent":[".wp__state__card__comp {\n  .arco-card-body {\n    @apply h-full w-full p-0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
