"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class WorkStatusManager {
    constructor() {
        this.isWorking = false;
    }
    setWorking(status) {
        this.isWorking = status;
    }
    isWorkingStatus() {
        return this.isWorking;
    }
}
const workStatusManager = new WorkStatusManager();
exports.default = workStatusManager;
