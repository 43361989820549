"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldShowTab = exports.isElectron = void 0;
// check if the app is running in Electron environment
const isElectron = () => {
    // renderer process
    if (typeof window !== "undefined" &&
        typeof window.process === "object" &&
        window.process.type === "renderer") {
        return true;
    }
    // main process
    if (typeof process !== "undefined" &&
        typeof process.versions === "object" &&
        !!process.versions.electron) {
        return true;
    }
    // detect the Electron preload script
    if (typeof navigator === "object" &&
        typeof navigator.userAgent === "string" &&
        navigator.userAgent.toLowerCase().includes("electron")) {
        return true;
    }
    return false;
};
exports.isElectron = isElectron;
const shouldShowTab = (tab, currentPath) => {
    return currentPath.startsWith(tab.url);
};
exports.shouldShowTab = shouldShowTab;
