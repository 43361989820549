"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initTheme = void 0;
const initTheme = () => {
    var _a;
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = (_a = localStorage.getItem("theme")) !== null && _a !== void 0 ? _a : "/yorg_main.css";
    const head = document.querySelector("head");
    head.insertBefore(link, head.firstChild);
};
exports.initTheme = initTheme;
