"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const en = {
    workspaceList: {
        topBar: {
            searchPlaceholder: "Search Project",
            category: "Category",
            myRepositories: "My Repositories",
            teamRepositories: "Team Repositories",
            jiraTickets: "Jira Tickets",
            groupBy: "Group By",
            repoLocation: "Repo Location",
            commitStatus: "Commit Status",
            accessTime: "Access Time",
        },
        content: {
            title: "With Atlas, Deliver PR Faster",
        },
        group: {
            public: "Public Repo",
            private: "Private Repo",
            local: "Local",
            today: "Today",
            thisWeek: "This Week",
            thisMonth: "This Month",
            moreThanMonth: "More than a month",
        },
        newProject: {
            title: "New Project",
            repo: "Select a Repo:",
            repoPlaceholder: "Please select a repo",
            repoMissing: "Please select a repo",
            branch: "Select a Branch:",
            branchPlaceholder: "Please select a branch",
            branchMissing: "Please select a branch",
            name: "Name of the new project:",
            namePlaceholder: "Please enter project name",
            nameMissing: "Please enter project name",
            success: "Add Project Success!",
            button: "Create Project",
        },
        list: {
            repoName: "Repo Name",
            branch: "Based on",
            projectName: "Project Name",
            sessions: "Session Info",
            sessionCount_1: "1 session",
            sessionCount_other: "{{count}} sessions",
            lastModified: "Last Modified",
            action: "Actions",
            view: "View",
            remove: "Remove",
            noData: "No data",
        },
    },
};
const zh = {
    workspaceList: {
        topBar: {
            searchPlaceholder: "搜索项目",
            category: "分类",
            myRepositories: "我的仓库",
            teamRepositories: "团队仓库",
            jiraTickets: "Jira 任务",
            groupBy: "分组",
            repoLocation: "仓库位置",
            commitStatus: "提交状态",
            accessTime: "访问时间",
        },
        content: {
            title: "使用 Atlas，更快地交付 PR",
        },
        group: {
            public: "公共仓库",
            private: "私有仓库",
            local: "本地",
            today: "今天",
            thisWeek: "本周",
            thisMonth: "本月",
            moreThanMonth: "超过一个月",
        },
        newProject: {
            title: "新建项目",
            repo: "选择一个仓库：",
            repoPlaceholder: "请选择一个仓库",
            repoMissing: "请选择一个仓库",
            branch: "选择一个分支：",
            branchPlaceholder: "请选择一个分支",
            branchMissing: "请选择一个分支",
            name: "新项目的名称：",
            namePlaceholder: "请输入项目名称",
            nameMissing: "请输入项目名称",
            success: "添加项目成功！",
            button: "创建项目",
        },
        list: {
            repoName: "仓库名称",
            branch: "基于",
            projectName: "项目名称",
            sessions: "会话信息",
            sessionCount_1: "1 个会话",
            sessionCount_other: "{{count}} 个会话",
            lastModified: "上次修改",
            action: "操作",
            view: "查看",
            remove: "移除",
            noData: "无数据",
        },
    },
};
exports.default = { en, zh };
