// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wp__res__import__container .arco-collapse-item-content-box {
    max-height: 1000px;
    overflow-y: scroll;
    padding: 0.75rem
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Workspace/CBWorkspaceCenter/Center/CenterContent/CenterResImport/LocalContext/index.scss"],"names":[],"mappings":"AAEI;IAAA,kBAAA;IAAA,kBAAA;IAAA;AAAA","sourcesContent":[".wp__res__import__container {\n  .arco-collapse-item-content-box {\n    @apply max-h-[1000px] overflow-y-scroll p-3;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
