// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.research-entry .editable-content:empty::before {
  content: "Enter your question here.                                                                    ";
  color: gray;
  pointer-events: none;
}
.research-entry .editable-content-CN:empty::before {
  content: "在此处输入您的消息。                                                               ";
  color: gray;
  pointer-events: none;
}
.research-entry .typebtn .arco-btn-secondary:not(.arco-btn-disabled){
  background-color: rgb(var(--primary-6));
}
.research-entry .typebtn .arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):hover{
  background-color: rgb(var(--primary-6));
}
.research-entry .textareaBtn .arco-btn-text:not(.arco-btn-disabled){
  font-size: 12px;
  color: var(--color-text-2);
}
.research-entry .textareaBtn .rightBtn.arco-btn-text:not(.arco-btn-disabled){
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-border-2);
}
.research-entry .textareaBtn .arco-btn-secondary:not(.arco-btn-disabled){
  background-color: var(--color-fill-3);
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Research/ResearchEntry/index.scss"],"names":[],"mappings":"AAAA,gBAAgB;AACd;EACA,wGAAA;EACA,WAAA;EACA,oBAAA;AACF;AACA;EACE,oFAAA;EACA,WAAA;EACA,oBAAA;AACF;AAGI;EAAA;AAAA;AAGD;EAAA;AAAA;AAKC;EAAA,eAAA;EAAA;AAAA;AAIA;EAAA,kBAAA;EAAA,iBAAA;EAAA,mBAAA;EAAA;AAAA;AAIA;EAAA;AAAA","sourcesContent":[".research-entry {\n  .editable-content:empty::before {\n  content: \"Enter your question here.                                                                    \";\n  color: gray;\n  pointer-events: none;\n}\n.editable-content-CN:empty::before {\n  content: \"在此处输入您的消息。                                                               \";\n  color: gray;\n  pointer-events: none;\n}\n.typebtn {\n  .arco-btn-secondary:not(.arco-btn-disabled) {\n    @apply bg-primary-6;\n  }\n  .arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):hover {\n   @apply bg-primary-6;\n}\n}\n.textareaBtn {\n  .arco-btn-text:not(.arco-btn-disabled) {\n    @apply text-[12px] text-text-2;\n  }\n\n  .rightBtn.arco-btn-text:not(.arco-btn-disabled) {\n    @apply rounded-[4px] border border-solid border-border-2;\n  }\n\n  .arco-btn-secondary:not(.arco-btn-disabled) {\n    @apply bg-fill-3;\n  }\n}\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
