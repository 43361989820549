"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const web_react_1 = require("@arco-design/web-react");
const icon_1 = require("@arco-design/web-react/icon");
const i18next_1 = require("i18next");
const jotai_1 = require("jotai");
const react_1 = __importStar(require("react"));
const codebaseHomeApi_1 = require("@src/api/codebaseApi/codebaseHomeApi");
const Button_1 = require("@src/components/Button");
const userAtom_1 = require("@src/store/userAtom");
require("./index.scss");
const MenuItem = web_react_1.Menu.Item;
const MyRepo = ({ source, setCloneInfo, openSettingsModal, }) => {
    const [filter, setFilter] = (0, react_1.useState)("");
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [userNameInfo, setUserNameInfo] = (0, react_1.useState)([]);
    const [repoList, setRepoList] = (0, react_1.useState)([]);
    const [data, setData] = (0, react_1.useState)({});
    const user = (0, jotai_1.useAtomValue)(userAtom_1.userAtom);
    const getRepoList = () => {
        setIsLoading(true);
        (source === "GitHub" ? codebaseHomeApi_1.getGithubRepoList : codebaseHomeApi_1.getGitlabRepoList)({
            uuid: user.uuid,
        })
            .then((res) => {
            if (res && (res === null || res === void 0 ? void 0 : res.status) !== 403) {
                setRepoList(res.data.remote_infos);
            }
        })
            .finally(() => {
            setIsLoading(false);
        });
    };
    (0, react_1.useEffect)(() => {
        if (source === "GitHub") {
            setUserNameInfo(user.github_infos.map((info) => info.user_name));
        }
        else {
            setUserNameInfo(user.gitlab_infos.map((info) => info.user_name));
        }
    }, [source, user]);
    (0, react_1.useEffect)(() => {
        if (userNameInfo.length) {
            getRepoList();
        }
    }, [userNameInfo]);
    (0, react_1.useEffect)(() => {
        const groupByOwner = (data) => data
            .filter(
        // filter out repos that don't match the filter
        (repo) => repo.name.toLowerCase().includes(filter.toLowerCase()) ||
            repo.owner.toLowerCase().includes(filter.toLowerCase()))
            .reduce(
        // group repos by owner
        (acc, cur) => {
            if (!acc[cur.owner]) {
                acc[cur.owner] = [];
            }
            acc[cur.owner].push(cur);
            return acc;
        }, userNameInfo.reduce((acc, cur) => {
            acc[cur] = [];
            return acc;
        }, {}));
        const groupedData = groupByOwner(repoList);
        setData(groupedData);
    }, [userNameInfo, repoList, filter]);
    return (react_1.default.createElement("div", { className: "private-repo flex-grow overflow-hidden" }, !userNameInfo.length ? (react_1.default.createElement("div", { className: "flex h-full flex-col items-center justify-center" },
        react_1.default.createElement(icon_1.IconInfoCircleFill, { className: "h-12 w-12 text-primary-6" }),
        react_1.default.createElement("span", { className: "mt-4 font-medium" }, (0, i18next_1.t)("home.add.myRepo.noConnection", { source })),
        react_1.default.createElement(Button_1.Button, { className: "mt-6", onClick: () => {
                openSettingsModal(source);
            } }, (0, i18next_1.t)("home.add.myRepo.link")))) : (react_1.default.createElement(react_1.Fragment, null,
        react_1.default.createElement("div", { className: "flex flex-col gap-2 px-3 py-4" },
            react_1.default.createElement("label", { className: "text-text-2", htmlFor: "repo-filter" }, (0, i18next_1.t)("home.add.myRepo.label", { source })),
            react_1.default.createElement("div", { className: "flex gap-2" },
                react_1.default.createElement(web_react_1.Input, { className: "rounded-lg border border-solid border-border-2", id: "repo-filter", name: "repo-filter", allowClear: true, placeholder: (0, i18next_1.t)("home.add.myRepo.placeholder"), onChange: setFilter }),
                react_1.default.createElement(Button_1.Button, { type: "white", className: "flex-shrink-0", icon: react_1.default.createElement(icon_1.IconLoop, null), onClick: getRepoList, disabled: isLoading || !userNameInfo.length }))),
        react_1.default.createElement(web_react_1.Spin, { loading: isLoading, className: "block h-[350px]" },
            userNameInfo.length && !repoList.length && !isLoading && (react_1.default.createElement(web_react_1.Empty, { className: "flex h-[350px] [&_.arco-empty-wrapper]:my-auto", description: (0, i18next_1.t)("home.add.myRepo.noData") })),
            userNameInfo.length && !!repoList.length && (react_1.default.createElement("div", { className: "repo__list__menu h-[350px] overflow-y-scroll" },
                react_1.default.createElement(web_react_1.Menu, null, Object.entries(data).map(([owner, repos], index) => repos.length !== 0 && (react_1.default.createElement("div", { key: index },
                    react_1.default.createElement("span", { className: "px-4 text-sm font-medium leading-7 text-text-1" }, owner),
                    repos.map((repo) => {
                        return (react_1.default.createElement(MenuItem, { key: repo.url, onClick: () => {
                                setCloneInfo(repo);
                            } },
                            repo.visible_type === 0 ? (react_1.default.createElement(icon_1.IconLock, null)) : (react_1.default.createElement(icon_1.IconBook, null)),
                            react_1.default.createElement("div", { className: "truncate" },
                                repo.owner,
                                "/",
                                repo.name)));
                    }))))))))))));
};
exports.default = MyRepo;
