// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-repo-menu .arco-menu {

    width: 180px
}

.add-repo-modal-left .arco-menu-item:focus-visible {

    --tw-shadow: 0 0 #0000;

    --tw-shadow-colored: 0 0 #0000;

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.add-repo-modal-left .arco-menu-item.arco-menu-selected {

    background-color: inherit
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Home/TopBar/AddRepoModal/index.scss"],"names":[],"mappings":"AAEI;;IAAA;AAAA;;AAKA;;IAAA,sBAAA;;IAAA,8BAAA;;IAAA;AAAA;AAGE;;IAAA;AAAA","sourcesContent":[".add-repo-menu {\n  .arco-menu {\n    @apply w-[180px];\n  }\n}\n.add-repo-modal-left {\n  .arco-menu-item {\n    @apply focus-visible:shadow-none;\n\n    &.arco-menu-selected {\n      @apply bg-inherit;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
