"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CodebaseRemoteVisibleType = exports.CodebaseSourceType = exports.CodebaseQuerySourceType = void 0;
var CodebaseQuerySourceType;
(function (CodebaseQuerySourceType) {
    CodebaseQuerySourceType[CodebaseQuerySourceType["PRIVATE"] = 0] = "PRIVATE";
    CodebaseQuerySourceType[CodebaseQuerySourceType["PUBLIC"] = 1] = "PUBLIC";
    CodebaseQuerySourceType[CodebaseQuerySourceType["LOCAL"] = 2] = "LOCAL";
})(CodebaseQuerySourceType || (exports.CodebaseQuerySourceType = CodebaseQuerySourceType = {}));
var CodebaseSourceType;
(function (CodebaseSourceType) {
    CodebaseSourceType[CodebaseSourceType["LOCAL"] = 0] = "LOCAL";
    CodebaseSourceType[CodebaseSourceType["REMOTE"] = 1] = "REMOTE";
})(CodebaseSourceType || (exports.CodebaseSourceType = CodebaseSourceType = {}));
var CodebaseRemoteVisibleType;
(function (CodebaseRemoteVisibleType) {
    CodebaseRemoteVisibleType[CodebaseRemoteVisibleType["PRIVATE"] = 0] = "PRIVATE";
    CodebaseRemoteVisibleType[CodebaseRemoteVisibleType["PUBLIC"] = 1] = "PUBLIC";
})(CodebaseRemoteVisibleType || (exports.CodebaseRemoteVisibleType = CodebaseRemoteVisibleType = {}));
