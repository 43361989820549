"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSearchLoadingAtom = exports.curSearchContentAtom = exports.searchResultHistoryAtom = exports.hasSearchResultAtom = exports.searchTypeAtom = exports.SearchType = exports.isShowPreviewAtom = exports.currentPreviewFilepathAtom = exports.currentPreviewContentAtom = exports.selectedFileTypesAtom = exports.curNextPageSearchResultsAtom = exports.curSearchResultAtom = void 0;
const jotai_1 = require("jotai");
exports.curSearchResultAtom = (0, jotai_1.atom)([]);
exports.curNextPageSearchResultsAtom = (0, jotai_1.atom)([]);
exports.selectedFileTypesAtom = (0, jotai_1.atom)([]);
exports.currentPreviewContentAtom = (0, jotai_1.atom)("");
exports.currentPreviewFilepathAtom = (0, jotai_1.atom)("");
exports.isShowPreviewAtom = (0, jotai_1.atom)(false);
var SearchType;
(function (SearchType) {
    SearchType["KEYWORD"] = "keyword";
    SearchType["SEMANTIC"] = "nlp";
})(SearchType || (exports.SearchType = SearchType = {}));
exports.searchTypeAtom = (0, jotai_1.atom)(SearchType.KEYWORD);
exports.hasSearchResultAtom = (0, jotai_1.atom)(false);
exports.searchResultHistoryAtom = (0, jotai_1.atom)([]);
exports.curSearchContentAtom = (0, jotai_1.atom)("");
exports.isSearchLoadingAtom = (0, jotai_1.atom)(false);
