// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CodebaseFile .line-numbers {
  text-align: center !important;
  left: 5px !important;
  z-index: 100;
  font-family: "PingFang SC";
  font-size: 12px;
  font-weight: 550;
  color: var(--color-text-2);
}
.CodebaseFile .view-line span {
  font-family: "JetBrains Mono";
}
.CodebaseFile .arco-spin-children {
  height: 100%;
}
.CodebaseFile .arco-spin {
  display: block;
}
.CodebaseFile .monaco-editor::-webkit-scrollbar {
  display: none;
}
.CodebaseFile .monaco-editor .margin {
  width: 22px !important;
  contain: layout !important;
}
.CodebaseFile .monaco-editor .slider {
  width: 0 !important;
}
.CodebaseFile .margin-view-overlays {
  width: 30px !important;
  left: 8px;
}
.CodebaseFile .monaco-scrollable-element {
  left: 52px !important;
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/File/index.scss"],"names":[],"mappings":"AACE;EACE,6BAAA;EACA,oBAAA;EACA,YAAA;EACA,0BAAA;EACA,eAAA;EACA,gBAAA;EACA,0BAAA;AAAJ;AAGE;EACE,6BAAA;AADJ;AAGE;EACE,YAAA;AADJ;AAGE;EACE,cAAA;AADJ;AAGE;EACE,aAAA;AADJ;AAII;EACE,sBAAA;EACA,0BAAA;AAFN;AAII;EACE,mBAAA;AAFN;AAOE;EACE,sBAAA;EACA,SAAA;AALJ;AAOE;EACE,qBAAA;AALJ","sourcesContent":[".CodebaseFile {\n  .line-numbers {\n    text-align: center !important;\n    left: 5px !important;\n    z-index: 100;\n    font-family: \"PingFang SC\";\n    font-size: 12px;\n    font-weight: 550;\n    @apply text-text-2;\n  }\n\n  .view-line span {\n    font-family: \"JetBrains Mono\";\n  }\n  .arco-spin-children {\n    height: 100%;\n  }\n  .arco-spin {\n    display: block;\n  }\n  .monaco-editor::-webkit-scrollbar {\n    display: none;\n  }\n  .monaco-editor {\n    .margin {\n      width: 22px !important;\n      contain: layout !important;\n    }\n    .slider {\n      width: 0 !important;\n    }\n    \n  }\n\n  .margin-view-overlays {\n    width: 30px !important;\n    left: 8px;\n  }\n  .monaco-scrollable-element {\n    left: 52px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
