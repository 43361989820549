"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.postUpdateOllamaConfigs = exports.getOllamaConfigs = exports.postUpdateEmbAutoConfig = exports.getDefaultModelConfigs = exports.postUpdateProviderConfig = exports.postUpdateInSubscription = exports.postModelSubscription = exports.postModelConfigs = void 0;
const apiConfigV2_1 = require("../apiConfigV2");
const url = "/api/v2/copilot";
const postModelConfigs = () => {
    return (0, apiConfigV2_1.postApi)(url + "/model_configs", {}, true);
};
exports.postModelConfigs = postModelConfigs;
const postModelSubscription = () => {
    return (0, apiConfigV2_1.postApi)(url + "/model_configs_subscription", {}, true);
};
exports.postModelSubscription = postModelSubscription;
const postUpdateInSubscription = (params) => {
    return (0, apiConfigV2_1.postApi)(url + "/update_model_configs", params, true);
};
exports.postUpdateInSubscription = postUpdateInSubscription;
const postUpdateProviderConfig = (params) => {
    return (0, apiConfigV2_1.postApi)(url + "/update_provider_config", params, true);
};
exports.postUpdateProviderConfig = postUpdateProviderConfig;
const getDefaultModelConfigs = (params) => {
    return (0, apiConfigV2_1.getApi)(url + "/get_default_model_configs", params, true);
};
exports.getDefaultModelConfigs = getDefaultModelConfigs;
const postUpdateEmbAutoConfig = (data) => {
    return (0, apiConfigV2_1.postApi)(url + "/update_default_model", data, true);
};
exports.postUpdateEmbAutoConfig = postUpdateEmbAutoConfig;
const getOllamaConfigs = () => {
    return (0, apiConfigV2_1.getApi)(url + "/get_ollama_config", {}, true);
};
exports.getOllamaConfigs = getOllamaConfigs;
const postUpdateOllamaConfigs = (data) => {
    return (0, apiConfigV2_1.postApi)(url + "/update_ollama_config", data, true);
};
exports.postUpdateOllamaConfigs = postUpdateOllamaConfigs;
