"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHighlight = void 0;
const react_1 = require("react");
const useHighlight = ({ lineHeight, resultStartLineNumber, resultEndLineNumber, }) => {
    const [selectedLines, setSelectedLines] = (0, react_1.useState)(null);
    const [scrollPosition, setScrollPosition] = (0, react_1.useState)(0);
    const editorRef = (0, react_1.useRef)(null);
    // 计算外部高亮元素的位置和大小，考虑滚动位置(Calculates the position and size of the outer highlighted elements, taking into account the scroll position)
    const highlightTop = selectedLines
        ? (selectedLines.start - 1) * lineHeight - scrollPosition
        : 0;
    const highlightHeight = selectedLines
        ? (selectedLines.end - selectedLines.start + 1) * lineHeight
        : 0;
    (0, react_1.useEffect)(() => {
        // 如果传入了resultStartLineNumber和resultEndLineNumber，则初始化selectedLines
        if (resultStartLineNumber && resultEndLineNumber) {
            setSelectedLines({
                start: resultStartLineNumber,
                end: resultEndLineNumber,
            });
        }
    }, [resultStartLineNumber, resultEndLineNumber]);
    return {
        highlightTop,
        highlightHeight,
        setSelectedLines,
        setScrollPosition,
        editorRef,
    };
};
exports.useHighlight = useHighlight;
