// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.waiting-list-modal .arco-modal-header {
    border-style: none
}
.waiting-list-modal .arco-modal {
    border-radius: 12px
}
.waiting-list-modal .arco-modal-content {
    padding-top: 10px
}`, "",{"version":3,"sources":["webpack://./src/components/WaitingList/index.scss"],"names":[],"mappings":"AAEI;IAAA;AAAA;AAGA;IAAA;AAAA;AAGA;IAAA;AAAA","sourcesContent":[".waiting-list-modal {\n.arco-modal-header {\n    @apply border-none\n}\n.arco-modal {\n    @apply rounded-[12px]\n}\n.arco-modal-content {\n    @apply pt-[10px]\n}\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
