// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copilot__update__modal .arco-modal-footer {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 11px;
    padding-bottom: 11px
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Copilot/ConfigTab/UpdateModal/index.scss"],"names":[],"mappings":"AAEI;IAAA,qBAAA;IAAA,sBAAA;IAAA,iBAAA;IAAA;AAAA","sourcesContent":[".copilot__update__modal {\n  .arco-modal-footer {\n    @apply px-3 py-[11px];\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
