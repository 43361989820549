"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handlePasteClean = void 0;
/**
 * Cleans and inserts pasted content into a contentEditable element.
 * @param event The clipboard event from a contentEditable element.
 */
const handlePasteClean = (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData;
    const pastedData = clipboardData.getData("text/html") || clipboardData.getData("text/plain");
    // 创建一个临时元素来解析HTML
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = pastedData;
    // 移除所有样式
    Array.from(tempDiv.querySelectorAll("*")).forEach((el) => {
        el.removeAttribute("style");
    });
    // 插入清理后的HTML到contentEditable区域
    document.execCommand("insertHTML", false, tempDiv.innerHTML);
};
exports.handlePasteClean = handlePasteClean;
