"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BranchSelect = void 0;
const web_react_1 = require("@arco-design/web-react");
const icon_1 = require("@arco-design/web-react/icon");
const jotai_1 = require("jotai");
const react_1 = __importStar(require("react"));
const react_i18next_1 = require("react-i18next");
const codebaseDetailTopApi_1 = require("@src/api/codebaseApi/codebaseDetailTopApi");
const allAtom_1 = require("@src/store/allAtom");
const cbDetailAtom_1 = require("@src/store/cbDetailAtom");
const isElectron_1 = require("@src/util/isElectron");
const BranchDropdown_1 = require("./BranchDropdown");
const BranchSelect = ({ type }) => {
    const [currentCodebase, setCurrentCodebase] = (0, jotai_1.useAtom)(allAtom_1.currentCodebaseAtom);
    const [, setDropStatus] = (0, jotai_1.useAtom)(cbDetailAtom_1.dropStatusAtom);
    const [isShowReadme] = (0, jotai_1.useAtom)(cbDetailAtom_1.isShowReadmeAtom);
    const [branchInfo, setBranchInfo] = (0, react_1.useState)();
    const [branchLists, setBranchLists] = (0, react_1.useState)([]);
    const [popupVisible, setPopupVisible] = (0, react_1.useState)(false);
    const { t } = (0, react_i18next_1.useTranslation)();
    const outItemWidth = isShowReadme ? "222px" : "280px";
    const topItemWidth = isShowReadme ? "142px" : "200px";
    const topItemTextMaxWidth = isShowReadme ? "149px" : "216px";
    const fetchBranches = (0, react_1.useCallback)(async () => {
        if (!currentCodebase.id)
            return;
        const res = await (0, codebaseDetailTopApi_1.getBranchList)(currentCodebase.id);
        if (res) {
            const branches = res.data.branches.map((branchName) => ({
                icon: react_1.default.createElement(icon_1.IconBranch, null),
                branchName,
            }));
            setBranchLists([
                { title: t("detail.topBar.currentBranch.yourBranch"), items: branches },
            ]);
        }
    }, [currentCodebase.id]);
    const handleBranchSelect = (0, react_1.useCallback)(async (branch) => {
        if (!(0, isElectron_1.isElectron)()) {
            web_react_1.Message.info(t("detail.topBar.desktopOnly"));
            return;
        }
        setBranchInfo(branch);
        setPopupVisible(false);
        setCurrentCodebase((old) => ({
            ...old,
            currentBranch: branch.branchName,
            git: {
                ...old.git,
                current_branch: branch.branchName,
            },
        }));
        const workspace_uuid = currentCodebase.id;
        await (0, codebaseDetailTopApi_1.switchBranch)({ workspace_uuid, new_branch: branch.branchName });
    }, [setBranchInfo, setCurrentCodebase, currentCodebase.id]);
    (0, react_1.useEffect)(() => {
        fetchBranches();
    }, [fetchBranches]);
    return (react_1.default.createElement(web_react_1.Dropdown, { droplist: react_1.default.createElement(BranchDropdown_1.BranchDropdown, { branchListWidth: outItemWidth, branchLists: branchLists, closeDropdown: () => {
                setPopupVisible(false);
                setDropStatus(false);
            }, onSelect: handleBranchSelect, type: type }), position: "bl", trigger: "click", defaultPopupVisible: false, popupVisible: popupVisible, onVisibleChange: (visible) => setPopupVisible(visible) }, type === "detail" ? (react_1.default.createElement("div", { className: `flex w-[${outItemWidth}] cursor-pointer items-center justify-between border-r border-solid border-border-2 px-[12px] py-[7px] ${popupVisible ? "border-b-neutral-1 bg-fill-1" : ""}` },
        react_1.default.createElement("div", { className: "flex items-center gap-[12px] text-primary-6 " },
            react_1.default.createElement(icon_1.IconBranch, { className: "h-[18px] w-[18px] text-sm text-primary-6" }),
            react_1.default.createElement("div", { className: "flex flex-1" },
                react_1.default.createElement("div", { className: `flex w-[${topItemWidth}] flex-col items-start`, style: { width: topItemWidth } },
                    react_1.default.createElement("div", { className: "max-w-[209px] truncate text-xs font-normal leading-[20px]" }, t("detail.topBar.currentBranch.title")),
                    react_1.default.createElement(web_react_1.Tooltip, { position: "bottom", trigger: "hover", content: currentCodebase.git.current_branch },
                        react_1.default.createElement("div", { className: `w-[${topItemTextMaxWidth}] truncate text-sm font-medium leading-[22px] text-text-1`, style: { width: topItemTextMaxWidth } }, currentCodebase.git.current_branch)))),
            react_1.default.createElement(icon_1.IconDown, { className: "flex h-[14px] w-[14px] flex-col items-center justify-center text-text-1" })))) : (react_1.default.createElement("div", { className: `flex h-[45px] items-center justify-between rounded-tl-[12px] px-[12px] py-[7px] ${popupVisible ? "bg-fill-1" : ""}` },
        react_1.default.createElement(icon_1.IconBranch, { className: "mr-2 h-[18px] w-[18px] text-text-2" }),
        react_1.default.createElement(web_react_1.Tooltip, { position: "bottom", trigger: "hover", content: branchInfo === null || branchInfo === void 0 ? void 0 : branchInfo.branchName },
            react_1.default.createElement("div", { className: `w-[${topItemTextMaxWidth}] truncate text-sm font-medium leading-[22px] text-text-1`, style: { width: topItemTextMaxWidth } }, currentCodebase.git.current_branch)),
        react_1.default.createElement(icon_1.IconDown, { className: "flex h-[14px] w-[14px] flex-col items-center justify-center text-text-1" })))));
};
exports.BranchSelect = BranchSelect;
