"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublicRepo = void 0;
const web_react_1 = require("@arco-design/web-react");
const icon_1 = require("@arco-design/web-react/icon");
const i18next_1 = require("i18next");
const react_1 = __importStar(require("react"));
const githubApi_1 = require("@src/api/githubApi");
const gitlabApi_1 = require("@src/api/gitlabApi");
const Button_1 = require("@src/components/Button");
const codebaseHomeTypes_1 = require("@src/types/codebaseHomeTypes");
const PublicRepo = ({ source, setCloneInfo, }) => {
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [currentClonePath, setCurrentClonePath] = (0, react_1.useState)("");
    const [result, setResult] = (0, react_1.useState)();
    return (react_1.default.createElement("div", { className: "flex-grow px-3 py-4" },
        react_1.default.createElement("label", { className: "flex flex-col gap-2 text-text-2" },
            (0, i18next_1.t)("home.add.publicRepo.label", { source }),
            react_1.default.createElement("div", { className: "flex gap-2" },
                react_1.default.createElement(web_react_1.Input, { className: "rounded-lg border border-solid border-border-2", allowClear: true, placeholder: (0, i18next_1.t)("home.add.publicRepo.placeholder", { source }), onChange: (value) => {
                        setResult(undefined);
                        setCurrentClonePath(value);
                    } }),
                react_1.default.createElement(Button_1.Button, { type: "white", className: "flex-shrink-0", icon: react_1.default.createElement(icon_1.IconSearch, null), disabled: !currentClonePath, loading: isLoading, onClick: () => {
                        setIsLoading(true);
                        (source === "GitHub"
                            ? githubApi_1.verifyGithubPublicRepo
                            : gitlabApi_1.verifyGitlabPublicRepo)({ repo_url: currentClonePath })
                            .then((res) => {
                            setResult(res);
                            if (res.status === 0) {
                                setCloneInfo({
                                    ...res.data,
                                    visible_type: codebaseHomeTypes_1.CodebaseRemoteVisibleType.PUBLIC,
                                });
                            }
                        })
                            .catch((e) => {
                            console.error(e);
                        })
                            .finally(() => {
                            setIsLoading(false);
                        });
                    } }))),
        result && (react_1.default.createElement("div", { className: "flex flex-col" },
            react_1.default.createElement("span", { className: "mb-1 mt-3 font-medium" }, (0, i18next_1.t)("home.add.publicRepo.result")),
            result.status === 0 && (react_1.default.createElement("span", { className: "flex items-center gap-2" },
                react_1.default.createElement(icon_1.IconPublic, { className: "h-4 w-4 text-text-2" }),
                react_1.default.createElement("span", null, result.data.owner),
                react_1.default.createElement("span", null, "/"),
                react_1.default.createElement("span", { className: "font-semibold" }, result.data.name))),
            result.status === 1 && (react_1.default.createElement("span", { className: "flex items-center gap-2 text-danger-5" },
                react_1.default.createElement(icon_1.IconCloseCircleFill, { className: "h-4 w-4" }),
                react_1.default.createElement("span", null, (0, i18next_1.t)("home.add.publicRepo.notRepo"))))))));
};
exports.PublicRepo = PublicRepo;
exports.default = exports.PublicRepo;
