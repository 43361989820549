// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled-modal {
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 0.5rem;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-border-2);
    --tw-shadow: 0px 1px 3px 0px rgba(25, 33, 61, 0.10);
    --tw-shadow-colored: 0px 1px 3px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.styled-modal .arco-modal-header {
    border-bottom-color: var(--color-border-2);
    padding-left: 1rem
}
.styled-modal .arco-modal-close-icon {
    right: 1rem
}
.styled-modal .arco-modal-content {
    padding: 0px
}
.styled-modal .arco-modal-footer {
    border-bottom-color: var(--color-border-2);
    padding: 0.5rem
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/index.scss"],"names":[],"mappings":"AACE;IAAA,uBAAA;IAAA,kBAAA;IAAA,qBAAA;IAAA,iBAAA;IAAA,mBAAA;IAAA,mCAAA;IAAA,mDAAA;IAAA,2DAAA;IAAA;AAAA;AAEE;IAAA,0CAAA;IAAA;AAAA;AAGA;IAAA;AAAA;AAGA;IAAA;AAAA;AAGA;IAAA,0CAAA;IAAA;AAAA","sourcesContent":[".styled-modal {\n  @apply w-fit rounded-lg border border-solid border-border-2 shadow;\n  .arco-modal-header {\n    @apply border-b-border-2 pl-4;\n  }\n  .arco-modal-close-icon {\n    @apply right-4;\n  }\n  .arco-modal-content {\n    @apply p-0;\n  }\n  .arco-modal-footer {\n    @apply border-b-border-2 p-2;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
