// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card .arco-card-body {
    display: flex;
    flex-direction: column;
    gap: 0.75rem
}`, "",{"version":3,"sources":["webpack://./src/page/Database/Home/Content/CardView/CardItem/index.scss"],"names":[],"mappings":"AACE;IAAA,aAAA;IAAA,sBAAA;IAAA;AAAA","sourcesContent":[".card .arco-card-body {\n  @apply flex flex-col gap-3 ;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
