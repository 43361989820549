// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.globalSearchStyle .arco-checkbox-group-direction-vertical .arco-checkbox {
    display: flex;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 6px
}
.globalSearchStyle .arco-checkbox-mask-wrapper {
    top: 0px
}
.globalSearchStyle .arco-btn-secondary:not(.arco-btn-disabled) {
    border-radius: 100px;
    background-color: var(--color-bg-1)
}
.globalSearchStyle .preview .arco-btn-size-small {
    padding-right: 0.5rem
}
.globalSearchStyle .arco-collapse-item-content-box {
    padding: 0px
}
.globalSearchStyle .searchpreview .arco-icon-hover {
    display: none
}
.globalSearchStyle .searchpreview .arco-collapse-item-header {
    border-style: none
}
.globalSearchStyle .searchpreview .arco-collapse-item-header-left {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px
}
.globalSearchStyle .searchpreview .arco-collapse {
    border-radius: 12px
}

.NotFound .arco-icon {
    height: 48px;
    width: 48px
}

.searchBox .arco-modal-footer {
    display: none
}
.searchBox .arco-modal-content {
    padding: 0px
}

.InputSearchBox .arco-btn-outline:not(.arco-btn-disabled) {
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-border-2);
    background-color: var(--color-fill-2);
    color: var(--color-text-2)
}
.InputSearchBox .arco-btn-outline:not(.arco-btn-disabled):not(.arco-btn-loading):active {
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-border-2);
    background-color: var(--color-fill-2);
    color: var(--color-text-2)
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/GlobalSearch/index.scss"],"names":[],"mappings":"AAEI;IAAA,aAAA;IAAA,mBAAA;IAAA,gBAAA;IAAA;AAAA;AAGA;IAAA;AAAA;AAIA;IAAA,oBAAA;IAAA;AAAA;AAGA;IAAA;AAAA;AAIA;IAAA;AAAA;AAIA;IAAA;AAAA;AAII;IAAA;AAAA;AAGA;IAAA,iBAAA;IAAA,oBAAA;IAAA;AAAA;AAGA;IAAA;AAAA;;AAOA;IAAA,YAAA;IAAA;AAAA;;AAKJ;IAAA;AAAA;AAGA;IAAA;AAAA;;AAKA;IAAA,iBAAA;IAAA,mBAAA;IAAA,mCAAA;IAAA,qCAAA;IAAA;AAAA;AAKA;IAAA,iBAAA;IAAA,mBAAA;IAAA,mCAAA;IAAA,qCAAA;IAAA;AAAA","sourcesContent":[".globalSearchStyle {\n.arco-checkbox-group-direction-vertical .arco-checkbox {\n    @apply flex items-center py-[6px]\n}\n.arco-checkbox-mask-wrapper {\n    @apply top-0\n}\n\n.arco-btn-secondary:not(.arco-btn-disabled) {\n    @apply bg-bg-1 rounded-[100px]\n}\n.preview .arco-btn-size-small {\n    @apply pr-2\n    \n}\n.arco-collapse-item-content-box {\n    @apply p-0\n}\n.searchpreview {\n .arco-icon-hover {\n    @apply hidden\n   \n}\n .arco-collapse-item-header {\n        @apply border-none\n    }\n    .arco-collapse-item-header-left{\n        @apply pl-[8px] py-[12px]\n    }\n    .arco-collapse {\n        @apply rounded-[12px]\n    }\n}\n\n}\n.NotFound {\n    .arco-icon {\n        @apply w-[48px] h-[48px]\n    }\n}\n.searchBox {\n   .arco-modal-footer {\n    @apply hidden;\n  }\n  .arco-modal-content {\n    @apply p-0\n  }\n}\n.InputSearchBox {\n    .arco-btn-outline:not(.arco-btn-disabled) {\n    @apply bg-fill-2 text-text-2 border-border-2 border-solid border\n    \n\n}\n .arco-btn-outline:not(.arco-btn-disabled):not(.arco-btn-loading):active {\n    @apply bg-fill-2 text-text-2 border-border-2 border-solid border\n }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
