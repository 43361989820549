"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useVerifyAction = void 0;
const jotai_1 = require("jotai");
const react_1 = require("react");
const api_1 = require("@src/api");
const codebaseTaskApi_1 = require("@src/api/codebaseApi/codebaseTaskApi");
const workspaceAtom_1 = require("@src/store/workspaceAtom");
const CBWorkSpaceContext_1 = require("../CBWorkSpaceContext");
const { postFileContentByProId } = api_1.api;
const useVerifyAction = () => {
    const { setDiffList, sessionRoundList, setCommitHistoryList, setSessRoundActiveKeys, diffListCache, setRegeList, } = (0, react_1.useContext)(CBWorkSpaceContext_1.WorkSpaceContext);
    const [curTaskId] = (0, jotai_1.useAtom)(workspaceAtom_1.curTaskIdAtom);
    const [wpIsDoingRege, setWpIsDoingRege] = (0, jotai_1.useAtom)(workspaceAtom_1.wpIsDoingRegeAtom);
    const [, setIsWpGeneWorking] = (0, jotai_1.useAtom)(workspaceAtom_1.isWpGeneWorkingAtom);
    const [curProId] = (0, jotai_1.useAtom)(workspaceAtom_1.curProIdAtom);
    const [, setShowVerifyModal] = (0, jotai_1.useAtom)(workspaceAtom_1.showVerifyModalAtom);
    const [status, setWaitingStatus] = (0, jotai_1.useAtom)(workspaceAtom_1.waitingStatusAtom);
    const [, setNewDraftList] = (0, jotai_1.useAtom)(workspaceAtom_1.newDraftListAtom);
    const [, setVerifyThought] = (0, jotai_1.useAtom)(workspaceAtom_1.verifyThoughtAtom);
    const [, setIsWaiting] = (0, jotai_1.useAtom)(workspaceAtom_1.isWaitingAtom);
    const getRoundNumber = () => {
        return sessionRoundList.filter((v) => v.type === "round").length.toString();
    };
    const verifyActions = async ({ languageList, regenerate, }) => {
        try {
            setIsWaiting(true);
            setWaitingStatus("waitReviewing");
            const res = (await codebaseTaskApi_1.taskApi.postVerifyActions({
                task_uuid: curTaskId,
            }));
            const new_commit = {
                sessionId: curTaskId,
                results: [],
                title: "Round " + getRoundNumber(),
            };
            if (res.status === 0) {
                setNewDraftList([]);
                setIsWaiting(true);
                setWaitingStatus("isNoWaitingThinking");
                //console.log("setNewDraftList([])", status);
                const actions = res.data.actions;
                const file_contents = res.data.file_contents;
                const thoughts = res.data.thoughts;
                let result = "";
                Object.entries(thoughts).forEach(([filePath, entries]) => {
                    result += `File: ${filePath}\n`;
                    Object.entries(entries).forEach(([key, value]) => {
                        const lineRange = key.replace(/[()]/g, "").replace(", ", "-");
                        // 在每个句号后添加换行符
                        const formattedValue = value.replace(/\. /g, ".\n");
                        result += `  (line ${lineRange}) ${formattedValue}\n`;
                    });
                    result += "\n"; // 在每个文件的条目后添加额外的换行以分隔文件
                });
                const diffItemsPromises = Object.entries(actions).map(async ([verify_path]) => {
                    var _a;
                    const res_file_content = (await postFileContentByProId({
                        project_uuid: (_a = localStorage.getItem("curProId")) !== null && _a !== void 0 ? _a : curProId,
                        paths: [verify_path],
                    }));
                    new_commit.results.push(verify_path);
                    if (res_file_content.status === 0) {
                        return Object.entries(actions[verify_path]).map(([verify_range, verify_content]) => {
                            var _a;
                            return ({
                                breadcrumbData: verify_path.split("/"),
                                oldCode: res_file_content.data.file_contents[0].content,
                                newCode: regenerate
                                    ? (_a = diffListCache[verify_path][0]) === null || _a === void 0 ? void 0 : _a.newCode //如果是rege的话，得从cache里面取
                                    : file_contents[verify_path],
                                SSECode: verify_content,
                                path: verify_path,
                                range: verify_range,
                                sessionId: curTaskId,
                                language: languageList[verify_path],
                                ...(regenerate
                                    ? { regeCode: file_contents[verify_path] }
                                    : {}),
                            });
                        });
                    }
                    else {
                        return []; // 如果请求失败，返回空数组
                    }
                });
                // 等待所有异步操作完成，并展平数组
                const diffItemsArray = await Promise.all(diffItemsPromises);
                const diffItems = diffItemsArray.flat();
                // 更新状态，过滤掉 undefined 的值
                setDiffList(diffItems);
                //console.log(diffItems, "展平后数组");
                if (!regenerate) {
                    setCommitHistoryList((prev) => [...prev, new_commit]);
                    setSessRoundActiveKeys((prev) => [...prev, "commit"]);
                }
                // 所有异步操作完成后执行
                setWpIsDoingRege(false);
                setIsWpGeneWorking(false); //承接
                setRegeList({});
                setVerifyThought(result);
            }
        }
        catch (e) {
            setShowVerifyModal(false);
        }
    };
    return { verifyActions };
};
exports.useVerifyAction = useVerifyAction;
