"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.completeLogin = exports.getLoginUrl = void 0;
const apiConfigV2_1 = require("./apiConfigV2");
const url = "/api/v2/login";
const getLoginUrl = () => {
    return (0, apiConfigV2_1.getApi)(url + "/url");
};
exports.getLoginUrl = getLoginUrl;
const completeLogin = (params) => {
    return (0, apiConfigV2_1.postApi)(url + "/complete", params);
};
exports.completeLogin = completeLogin;
