// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.codebase-detail-loading {
    height: 100%
}
.codebase-detail-loading .arco-spin-children {
    display: flex;
    height: 100%;
    flex-direction: column
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Detail/index.scss"],"names":[],"mappings":"AACE;IAAA;AAAA;AAGE;IAAA,aAAA;IAAA,YAAA;IAAA;AAAA","sourcesContent":[".codebase-detail-loading {\n  @apply h-full;\n\n  .arco-spin-children {\n    @apply flex h-full flex-col;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
