"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.verifyGithubPublicRepo = exports.createGitHubAccount = void 0;
const apiConfigV2_1 = require("./apiConfigV2");
const url = "/api/v2/github";
const createGitHubAccount = (params) => {
    return (0, apiConfigV2_1.getApi)(url + "/fetch_token", params, true);
};
exports.createGitHubAccount = createGitHubAccount;
const verifyGithubPublicRepo = (params) => {
    return (0, apiConfigV2_1.getApi)(url + "/get_public_repo_info", params, true);
};
exports.verifyGithubPublicRepo = verifyGithubPublicRepo;
