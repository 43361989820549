// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserChatItem .textarea {
    margin: 0px;
    height: 296px;
    width: 400px;
    resize: none;
    overflow: hidden;
    border-style: none;
    background-color: transparent;
    padding: 0px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500
}
.UserChatItem .textarea:focus {
    border-style: none;
    outline: 2px solid transparent;
    outline-offset: 2px
}
.UserChatItem .textarea::-moz-placeholder {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    line-height: 20px;
    --tw-text-opacity: 1;
    color: rgb(247 248 250 / var(--tw-text-opacity))
}
.UserChatItem .textarea::placeholder {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    line-height: 20px;
    --tw-text-opacity: 1;
    color: rgb(247 248 250 / var(--tw-text-opacity))
}
.UserChatItem .textarea:active, .UserChatItem .textarea:focus {
    border-style: none;
    outline: 2px solid transparent;
    outline-offset: 2px
}`, "",{"version":3,"sources":["webpack://./src/components/chatBox/UserChatItem.tsx/UserChatItem.scss"],"names":[],"mappings":"AAEI;IAAA,WAAA;IAAA,aAAA;IAAA,YAAA;IAAA,YAAA;IAAA,gBAAA;IAAA,kBAAA;IAAA,6BAAA;IAAA,YAAA;IAAA,eAAA;IAAA,mBAAA;IAAA;AAAA;AAAA;IAAA,kBAAA;IAAA,8BAAA;IAAA;AAAA;AAGE;IAAA,eAAA;IAAA,mBAAA;IAAA,gBAAA;IAAA,iBAAA;IAAA,oBAAA;IAAA;AAAA;AAAA;IAAA,eAAA;IAAA,mBAAA;IAAA,gBAAA;IAAA,iBAAA;IAAA,oBAAA;IAAA;AAAA;AAKA;IAAA,kBAAA;IAAA,8BAAA;IAAA;AAAA","sourcesContent":[".UserChatItem {\n  .textarea {\n    @apply m-0 h-[296px] w-[400px] resize-none overflow-hidden border-none bg-transparent p-0 text-base font-medium focus:border-none focus:outline-none;\n\n    &::placeholder {\n      @apply text-base font-medium leading-[20px] text-gray-100;\n    }\n\n    &:active,\n    &:focus {\n      @apply border-none outline-none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
