"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const en = {
    home: {
        add: {
            title: "Add Repo",
            myRepo: {
                title: "My Repo",
                noConnection: "No {{source}} Connection",
                link: "Link Account",
                placeholder: "Filter",
                label: "Add My {{source}} Repo",
                noData: "No data. Please try refreshing.",
            },
            publicRepo: {
                title: "Public Repo",
                label: "Add a Public {{source}} Repo",
                placeholder: "Enter {{source}} URL",
                result: "Result",
                notRepo: "This is not a public Repo",
            },
            local: {
                title: "Local",
                label: "Add a Repo by providing local path",
                placeholder: "Select Local Path",
                failed: "Failed to select directory",
                scan: "or, scan a folder (.git folder) :",
                clickOrDrag: "Click or drag your folder here",
                constraint: "Only directories with a .git folder can be uploaded.",
            },
            manageAccount: "Manage Account",
            cancel: "Cancel",
            clone: "Clone",
        },
        topBar: {
            searchPlaceholder: "Search Repo",
            category: {
                title: "Category",
                myRepos: "My Repositories",
                trending: "Community Trending",
                teamRepos: "Team Repositories",
            },
            group: {
                title: "Group By",
                repoLocation: "Repo Location",
                workspaceProject: "Workspace Project",
                accessTime: "Access Time",
            },
        },
        myRepos: {
            public: "Public Repo",
            private: "Private Repo",
            local: "Local",
            noProject: "No Workspace Project",
            haveProject: "Have Workspace Projects",
            today: "Today",
            thisWeek: "This Week",
            thisMonth: "This Month",
            moreThanMonth: "More than a Month",
        },
        sidebar: {
            dashboard: "Dashboard",
            repos: "Repos",
            codebase: "Codebase",
            repo2: "Repo 2",
            research: "Research",
            copilot: "Copilot",
            prStudio: "PR Studio",
            settings: "Settings",
            aboutAtlas: "About AtlasXP",
        },
        trending: {
            editor: "Editor's Choice",
            aiRelated: "AI Related",
            allCategory: "All Repositories",
            notFoundTitle: "No Result Found",
            contributeRepo: "Contribute a public repo",
            importRepo: "Import a private repo",
            title: "With Atlas, Explore the World of Open Source",
            loadMore: "Load More",
        },
        waitlist: {
            title: "With Atlas, Chat and Build",
            downLoad: "Download Desktop Client",
            learnMore: "Learn More",
        },
        card: {
            project_one: "{{count}} Project",
            project_other: "{{count}} Projects",
        },
        list: {
            name: "Repo Name",
            des: "Repo Description",
            category: "Category",
            project: "Num. of Projects",
            action: "Actions",
            view: "View",
            retry: "Retry",
            stars: "GitHub Stars",
        },
        shared: {
            failed: "Failed to add",
            noData: "No data",
            noDes: "No description provided",
            public: "Public",
            private: "Private",
            local: "Local",
            remove: "Remove",
            defaultUser: "default_user",
        },
        clone: {
            title: "Clone Repo",
            content: "Cloning a repo will create a new copy of the repo. All your existing projects will also be cloned.",
            inProgress: "Cloning in progress...",
            success: "Successfully cloned",
            error: "Clone failed, please check the url",
            inClone: "Only one repository can be cloned at a time.",
        },
        delete: {
            title: "Remove Repo",
            content: "Removing a repo is an irreversible process. All your existing projects will also be deleted.",
            success: "Successfully deleted",
            error: "Failed to delete: server error",
        },
        duplicate: {
            title: "Repo Duplication",
            content: "There is an existing repo called <0>{{repoName}}</0>. It seems to be a duplicate. <0>The cloning process will be terminated now.</0>",
            button: "Terminate",
        },
        topSearch: {
            search: "Search Repo",
        },
        dashboard: {
            welcome: "Welcome, I am Atlas",
            learn: "Learn",
            build: "Build",
            repoExplorer: {
                title: "Repo Explorer",
                description: "Explore repositories with Atlas.",
            },
            researcher: {
                title: "Researcher",
                description: "Conduct research with Atlas.",
            },
            copilot: {
                title: "Copilot",
                description: "Use Atlas in your VS Code.",
                askAtlas: "Ask Atlas",
                embedding: "Embedding",
                autocomplete: "Autocomplete",
                prompts: "Prompts",
                inSubscription: "In Subscription",
                myKeys: "Use My Keys",
                left: "Left",
                cancel: "Cancel",
                update: "Update",
                viewDoc: "View Doc",
                preferredModel: "Preferred Model",
                preferredToUse: "Preferred to use",
                myOpenAIKey: "My OpenAI Key",
                apiKey: "API Key",
                apiBase: "API Base",
                enterAPIKey: "Please enter your API key",
                enterAPIBase: "Please enter your API Base",
                obtainKey: "Obtain Key",
                remove: "Remove",
                reset: "Reset",
                useSub: "Use Subscription",
                useKey: "Use My Key",
                rateLimit: "Rate Limit",
                unlimited: "Unlimited",
                updated: "To update configuration in your AtlasXP Copilot Extension, please click the “Sync Configuration” button in the extension.",
                updatedTitle: "Configuration updated",
                understand: "Understand",
                selectOne: "Please Select One Model",
                instruction: "Instruction",
                permDownLoad: "Download Ollama and lanch the application",
                permRun1: "Open a terminal and run ollama run ",
                permRun2: "Please make sure that the model being used is consistent with what you provides below.",
                unAva: "Unavailable",
                unLock: "UnLocked",
                useOll: "Use Ollama (Autodetected Models)",
            },
            prStudio: {
                title: "PR Studio",
                description: "Deliver high-quality PR with AtlasXP.\nJoin Waitlist.",
            },
            footer: {
                info: "Info",
                terms: "Terms",
                changeLog: "ChangeLog",
                discord: "Discord",
            },
        },
    },
};
const zh = {
    home: {
        add: {
            title: "添加仓库",
            myRepo: {
                title: "我的仓库",
                noConnection: "无 {{source}} 连接",
                link: "连接账户",
                placeholder: "过滤",
                label: "添加我的 {{source}} 仓库",
                noData: "无数据，请尝试刷新",
            },
            publicRepo: {
                title: "公共仓库",
                label: "添加公共 {{source}} 仓库",
                placeholder: "输入 {{source}} URL",
                result: "结果",
                notRepo: "这不是一个公共仓库",
            },
            local: {
                title: "本地",
                label: "通过提供本地路径添加仓库",
                placeholder: "选择本地路径",
                failed: "选择目录失败",
                scan: "或者，扫描一个文件夹（.git 文件夹）：",
                clickOrDrag: "点击或拖拽文件夹到这里",
                constraint: "只能上传包含 .git 文件夹的目录",
            },
            manageAccount: "管理账户",
            cancel: "取消",
            clone: "克隆",
        },
        topBar: {
            searchPlaceholder: "搜索仓库",
            category: {
                title: "分类",
                myRepos: "我的仓库",
                trending: "社区热门",
                teamRepos: "团队仓库",
            },
            group: {
                title: "分组",
                repoLocation: "仓库位置",
                workspaceProject: "工作区项目",
                accessTime: "访问时间",
            },
        },
        myRepos: {
            public: "公共仓库",
            private: "私有仓库",
            local: "本地",
            noProject: "无工作区项目",
            haveProject: "有工作区项目",
            today: "今天",
            thisWeek: "本周",
            thisMonth: "本月",
            moreThanMonth: "超过一个月",
        },
        trending: {
            editor: "编辑推荐",
            aiRelated: "AI 相关",
            allCategory: "所有仓库",
            notFoundTitle: "没有找到仓库",
            contributeRepo: "贡献一个公共仓库",
            importRepo: "导入私有仓库",
            title: "和Atlas一起探索开源项目",
            loadMore: "加载更多",
        },
        waitlist: {
            title: "和Atlas一起探索、构建",
            downLoad: "下载桌面端",
            learnMore: "了解更多",
        },
        card: {
            project_one: "{{count}} 个项目",
            project_other: "{{count}} 个项目",
        },
        list: {
            name: "仓库名称",
            des: "仓库描述",
            category: "分类",
            project: "项目数量",
            action: "操作",
            view: "查看",
            retry: "重试",
            stars: "GitHub 星数",
        },
        shared: {
            failed: "添加失败",
            noData: "无数据",
            noDes: "未提供描述",
            public: "公共",
            private: "私有",
            local: "本地",
            remove: "移除",
            defaultUser: "默认用户",
        },
        clone: {
            title: "克隆仓库",
            content: "克隆仓库将创建仓库的新副本。您现有的所有项目也将被克隆。",
            inProgress: "克隆进行中...",
            success: "克隆成功",
            error: "克隆失败，请检查 URL",
            inClone: "请等待当前进程完成后再启动另一个克隆。",
        },
        delete: {
            title: "移除仓库",
            content: "移除仓库是一个不可逆的过程。您现有的所有项目也将被删除。",
            success: "删除成功",
            error: "删除失败：服务器错误",
        },
        duplicate: {
            title: "仓库重复",
            content: "存在一个名为 <0>{{repoName}}</0> 的重复仓库。<0>克隆过程将被终止。</0>",
            button: "终止",
        },
        sidebar: {
            dashboard: "导航",
            repos: "仓库",
            codebase: "代码库",
            repo2: "仓库 2",
            research: "搜索引擎",
            copilot: "Copilot助手",
            prStudio: "PR 工作室",
            settings: "设置",
            aboutAtlas: "关于 AtlasXP",
        },
        topSearch: {
            search: "搜索仓库",
        },
        dashboard: {
            welcome: "欢迎，我是 Atlas",
            learn: "学习",
            build: "构建",
            repoExplorer: {
                title: "仓库浏览器",
                description: "使用 Atlas 探索仓库。",
            },
            researcher: {
                title: "研究员",
                description: "使用 Atlas 进行研究。",
            },
            copilot: {
                title: "Copilot",
                description: "在您的 VS Code 中使用 Atlas。",
                askAtlas: "询问 Atlas",
                embedding: "嵌入",
                autocomplete: "自动完成",
                prompts: "提示",
                inSubscription: "订阅中",
                myKeys: "我的秘钥",
                left: "剩余",
                cancel: "取消",
                update: "更新",
                viewDoc: "查看文档",
                preferredModel: "首选模型",
                preferredToUse: "首选使用",
                myOpenAIKey: "我的 OpenAI 密钥",
                apiKey: "API 密钥",
                apiBase: "API 基础地址",
                enterAPIKey: "请输入您的 API 密钥",
                enterAPIBase: "请输入您的 API 基础地址",
                obtainKey: "获取密钥",
                remove: "移除",
                reset: "重置",
                useSub: "使用订阅",
                useKey: "使用我的秘钥",
                rateLimit: "次数限制",
                unlimited: "无限次数",
                updated: "要更新您的 AtlasXP Copilot 扩展的配置，请点击扩展中的“同步配置”按钮.",
                updatedTitle: "配置已更新",
                understand: "知道了",
                selectOne: "请选择一个模型",
                permDownLoad: "下载 Ollama 并启动应用程序。",
                instruction: "向导",
                permRun1: "打开终端并运行 ",
                permRun2: "请确保使用的模型与下方提供的一致。",
                unAva: "不可用",
                unLock: "已解锁",
                useOll: "使用Ollama (自动检测模型)",
            },
            prStudio: {
                title: "PR 工作室",
                description: "使用 AtlasXP 交付高质量 PR。加入等待列表。",
            },
            footer: {
                info: "信息",
                terms: "条款",
                changeLog: "更新日志",
                discord: "Discord",
            },
        },
    },
};
exports.default = {
    en,
    zh,
};
