"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.router = void 0;
const web_react_1 = require("@arco-design/web-react");
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const CBWorkSpaceContext_1 = require("@src/page/Codebase/Workspace/CBWorkSpaceContext");
// 懒加载组件
const Login = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require("@page/Login"))));
const SignIn = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require("@page/Login/SignIn"))));
const SignUp = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require("@page/Login/SignUp"))));
const SetProfile = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require("@page/Login/SetProfile"))));
const LinkGithub = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require("@page/Login/LinkGithub"))));
const SyncRepo = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require("@page/Login/SyncRepo"))));
const Codebase = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require("@page/Codebase"))));
const CodebaseHome = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require("@page/Codebase/Home"))));
const CodebaseDetail = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require("@page/Codebase/Detail"))));
const CodebaseFile = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require("@page/Codebase/File"))));
const CodebaseResearchResult = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require("@page/Codebase/Research/ResearchResult"))));
const CodebaseWorkspaceList = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require("@page/Codebase/WorkspaceList"))));
const CodebaseWorkspace = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require("@page/Codebase/Workspace"))));
const CodebaseResearch = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require("@src/page/Codebase/Research"))));
const GlobalSearch = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require("@src/page/Codebase/GlobalSearch"))));
const Copilot = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require("@src/page/Codebase/Copilot"))));
const Database = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require("@page/Database"))));
const DatabaseHome = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require("@page/Database/Home"))));
const ErrorPage = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require("@page/Error"))));
const Prices = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require("@page/Payment/Prices"))));
const Subscribe = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require("@page/Payment/Subscribe"))));
const PaymentSuccess = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require("@page/Payment/Success"))));
const DashBoard = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require("@page/Codebase/Home/DashBoard"))));
const Loading = () => (react_1.default.createElement(web_react_1.Spin, { className: "flex h-full w-full items-center justify-center", loading: true }));
const router = (0, react_router_dom_1.createBrowserRouter)([
    {
        path: "/",
        errorElement: react_1.default.createElement(ErrorPage, null),
        element: (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(Loading, null) },
            react_1.default.createElement(Login, null))),
        children: [
            {
                path: "",
                element: react_1.default.createElement(react_router_dom_1.Navigate, { to: "/codebase/dashboard", replace: true }),
            },
            {
                path: "login",
                element: (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(Loading, null) },
                    react_1.default.createElement(SignIn, null))),
            },
            {
                path: "signup",
                element: (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(Loading, null) },
                    react_1.default.createElement(SignUp, null))),
            },
            {
                path: "set-profile",
                element: (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(Loading, null) },
                    react_1.default.createElement(SetProfile, null))),
            },
            {
                path: "link-github",
                element: (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(Loading, null) },
                    react_1.default.createElement(LinkGithub, null))),
            },
            {
                path: "sync-repo",
                element: (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(Loading, null) },
                    react_1.default.createElement(SyncRepo, null))),
            },
        ],
    },
    {
        path: "/codebase",
        element: (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(Loading, null) },
            react_1.default.createElement(Codebase, null))),
        children: [
            {
                path: "",
                element: react_1.default.createElement(react_router_dom_1.Navigate, { to: "/codebase/dashboard", replace: true }),
            },
            {
                path: "home",
                element: (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(Loading, null) },
                    react_1.default.createElement(CodebaseHome, null))),
            },
            {
                path: "launchpad",
                element: (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(Loading, null) },
                    react_1.default.createElement(CodebaseDetail, null))),
            },
            {
                path: "explorer",
                element: (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(Loading, null) },
                    react_1.default.createElement(CodebaseFile, null))),
            },
            {
                path: "workspace",
                element: (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(Loading, null) },
                    react_1.default.createElement(CBWorkSpaceContext_1.WorkSpaceProvider, null,
                        react_1.default.createElement(CodebaseWorkspace, null)))),
            },
            {
                path: "workspaceList",
                element: (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(Loading, null) },
                    react_1.default.createElement(CodebaseWorkspaceList, null))),
            },
            {
                path: "research",
                element: (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(Loading, null) },
                    react_1.default.createElement(CodebaseResearch, null))),
            },
            {
                path: "dashboard",
                element: (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(Loading, null) },
                    react_1.default.createElement(DashBoard, null))),
            },
            {
                path: "researchResult",
                element: (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(Loading, null) },
                    react_1.default.createElement(CodebaseResearchResult, null))),
            },
            {
                path: "globalSearch",
                element: (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(Loading, null) },
                    react_1.default.createElement(GlobalSearch, null))),
            },
            {
                path: "copilot",
                element: (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(Loading, null) },
                    react_1.default.createElement(Copilot, null))),
            },
        ],
    },
    {
        path: "/database",
        element: (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(Loading, null) },
            react_1.default.createElement(Database, null))),
        children: [
            {
                path: "",
                element: react_1.default.createElement(react_router_dom_1.Navigate, { to: "/database/home", replace: true }),
            },
            {
                path: "home",
                element: (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(Loading, null) },
                    react_1.default.createElement(DatabaseHome, null))),
            },
        ],
    },
    {
        path: "/payment",
        element: (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(Loading, null) },
            react_1.default.createElement(Login, null))),
        children: [
            {
                path: "",
                element: react_1.default.createElement(Prices, null),
            },
            {
                path: "prices",
                element: (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(Loading, null) },
                    react_1.default.createElement(Prices, null))),
            },
            {
                path: "subscribe",
                element: (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(Loading, null) },
                    react_1.default.createElement(Subscribe, null))),
            },
            {
                path: "success",
                element: (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(Loading, null) },
                    react_1.default.createElement(PaymentSuccess, null))),
            },
        ],
    },
    {
        path: "*",
        element: react_1.default.createElement(ErrorPage, null),
    },
]);
exports.router = router;
