"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const web_react_1 = require("@arco-design/web-react");
const icon_1 = require("@arco-design/web-react/icon");
const i18next_1 = require("i18next");
const jotai_1 = require("jotai");
const react_1 = __importStar(require("react"));
const codebaseCopilot_1 = require("@src/api/codebaseApi/codebaseCopilot");
const codebaseHomeAtom_1 = require("@src/store/codebaseHomeAtom");
const defaultSubConfig = {
    pro: [],
    basic: [],
};
const InSub = ({ openUpdate }) => {
    const [subReset, setSubReset] = (0, react_1.useState)(false);
    const [inSubConfigs, setInSubConfigs] = (0, react_1.useState)(defaultSubConfig);
    const [userPaymentInfo] = (0, jotai_1.useAtom)(codebaseHomeAtom_1.userPaymentInfoAtom);
    const CollapseItem = web_react_1.Collapse.Item;
    const handleChangeInSub = (e, type, title) => {
        setInSubConfigs((prev) => {
            const newSub = { ...prev };
            newSub[type] = newSub[type].map((v) => {
                if (title === v.title) {
                    return { ...v, active: e };
                }
                return v;
            });
            return newSub;
        });
    };
    const handleUpdateInSub = async (type) => {
        try {
            const configs = inSubConfigs[type].map((v) => ({
                title: v.title,
                active: v.active,
                params: v.params,
                type: v.type,
                provider: v.provider,
                model: v.model,
                system_message: v.system_message,
                api_base: v.api_base,
                use_subscription: v.use_subscription,
            }));
            const response = await (0, codebaseCopilot_1.postUpdateInSubscription)({ models: configs });
            if ((response === null || response === void 0 ? void 0 : response.status) === 0) {
                // Message.success("Subscription updated successfully");
                openUpdate();
            }
        }
        catch (error) {
            console.error("Error updating subscription:", error);
        }
    };
    (0, react_1.useEffect)(() => {
        const fetchInSubscribeDate = async () => {
            try {
                const res = (await (0, codebaseCopilot_1.postModelSubscription)());
                if (res.status === 0) {
                    setInSubConfigs(res.data);
                }
            }
            catch (e) {
                console.log(e);
            }
        };
        fetchInSubscribeDate();
    }, [subReset]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", { className: "my__model mx-auto border-t border-solid border-border-1" },
            react_1.default.createElement(web_react_1.Collapse, { bordered: false, style: { width: 550 }, expandIcon: react_1.default.createElement(icon_1.IconRight, { className: "text-[18px] text-text-2" }), defaultActiveKey: ["Basic", "Pro"] },
                react_1.default.createElement(CollapseItem, { header: "Basic", name: "Basic", extra: react_1.default.createElement("div", { className: "text-primary-6" },
                        react_1.default.createElement(icon_1.IconCheck, { className: "mr-1 text-[12px]" }),
                        react_1.default.createElement("span", { className: "text-[14px]" }, (0, i18next_1.t)("home.dashboard.copilot.unLock"))) },
                    react_1.default.createElement("div", { className: "mb-4 flex max-w-[550px] flex-wrap justify-between gap-4" }, inSubConfigs === null || inSubConfigs === void 0 ? void 0 : inSubConfigs.basic.map(({ title, active, context_length, max_output_length }) => (react_1.default.createElement("div", { className: "flex w-[251px] items-center justify-between text-text-1" },
                        react_1.default.createElement("div", { className: "flex items-center" },
                            react_1.default.createElement("span", { className: "mr-[11px] text-[14px]" }, title),
                            " ",
                            react_1.default.createElement(web_react_1.Tooltip, { content: react_1.default.createElement("div", { className: "flex flex-col" },
                                    react_1.default.createElement("p", null,
                                        "Context window: ",
                                        react_1.default.createElement("span", null, context_length)),
                                    react_1.default.createElement("p", null,
                                        "Max output: ",
                                        react_1.default.createElement("span", null, max_output_length))) },
                                react_1.default.createElement(icon_1.IconInfoCircle, { className: "cursor-pointer text-[12px]" }))),
                        react_1.default.createElement(web_react_1.Switch, { size: "small", onChange: (e) => handleChangeInSub(e, "basic", title), checked: active }))))),
                    react_1.default.createElement("div", { className: "mx-auto flex w-[550px] items-center justify-end text-center" },
                        react_1.default.createElement(web_react_1.Button, { type: "secondary", className: "mr-2 h-8 w-[77px]", onClick: () => setSubReset((prev) => !prev) }, (0, i18next_1.t)("home.dashboard.copilot.cancel")),
                        react_1.default.createElement(web_react_1.Button, { type: "primary", className: "h-8 w-[79px]", onClick: () => handleUpdateInSub("basic") }, (0, i18next_1.t)("home.dashboard.copilot.update")))),
                react_1.default.createElement(CollapseItem, { header: "Pro", name: "Pro", extra: userPaymentInfo.active ? (react_1.default.createElement("div", { className: "text-primary-6" },
                        react_1.default.createElement(icon_1.IconCheck, { className: "mr-1 text-[12px]" }),
                        react_1.default.createElement("span", { className: "text-[14px]" }, (0, i18next_1.t)("home.dashboard.copilot.unLock")))) : (react_1.default.createElement("div", { className: "text-[#FF9626]" },
                        react_1.default.createElement(icon_1.IconClose, { className: "mr-1 text-[12px]" }),
                        react_1.default.createElement("span", { className: "text-[14px]" }, (0, i18next_1.t)("home.dashboard.copilot.unAva")))) },
                    react_1.default.createElement("div", { className: "mb-4 flex max-w-[550px] flex-wrap justify-between gap-4" }, inSubConfigs === null || inSubConfigs === void 0 ? void 0 : inSubConfigs.pro.map(({ title, active, context_length, max_output_length }) => (react_1.default.createElement("div", { className: "flex w-[251px] items-center justify-between text-text-1" },
                        react_1.default.createElement("div", { className: "flex items-center" },
                            react_1.default.createElement("span", { className: "mr-[11px] text-[14px]" }, title),
                            " ",
                            react_1.default.createElement(web_react_1.Tooltip, { content: react_1.default.createElement("div", { className: "flex flex-col" },
                                    react_1.default.createElement("p", null,
                                        "Context window: ",
                                        react_1.default.createElement("span", null, context_length)),
                                    react_1.default.createElement("p", null,
                                        "Max output: ",
                                        react_1.default.createElement("span", null, max_output_length))) },
                                react_1.default.createElement(icon_1.IconInfoCircle, { className: "cursor-pointer text-[12px]" }))),
                        react_1.default.createElement(web_react_1.Switch, { size: "small", onChange: (e) => handleChangeInSub(e, "pro", title), checked: active, disabled: !userPaymentInfo.active }))))),
                    react_1.default.createElement("div", { className: "mx-auto flex w-[550px] items-center justify-end text-center" },
                        react_1.default.createElement(web_react_1.Button, { type: "secondary", className: "mr-2 h-8 w-[77px]", disabled: !userPaymentInfo.active, onClick: () => setSubReset((prev) => !prev) }, (0, i18next_1.t)("home.dashboard.copilot.cancel")),
                        react_1.default.createElement(web_react_1.Button, { type: "primary", className: "h-8 w-[79px]", disabled: !userPaymentInfo.active, onClick: () => handleUpdateInSub("pro") }, (0, i18next_1.t)("home.dashboard.copilot.update"))))))));
};
exports.default = InSub;
