"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileTreeMulti = void 0;
const web_react_1 = require("@arco-design/web-react");
const react_1 = __importStar(require("react"));
require("./index.scss");
const FileTreeMulti = ({ currentTreeData, getNewTree, onCheck, fieldNames, isEditAble, defaultKeys, expandFirst, }) => {
    const [treeData, setTreeData] = (0, react_1.useState)([]);
    const [expandedKeys, setExpandedKeys] = (0, react_1.useState)([]);
    const [treeCheckedKeys, setTreeCheckedKeys] = (0, react_1.useState)(defaultKeys !== null && defaultKeys !== void 0 ? defaultKeys : []);
    const handleSelect = (selectedKeys, info) => {
        //console.log("select", selectedKeys);
        getNewTree(selectedKeys[0], treeData, info);
    };
    const handleExpand = (checkedKeys, info) => {
        setExpandedKeys(checkedKeys);
        const { expanded, expandedNodes, node } = info;
        getNewTree(checkedKeys[0], treeData, {
            selected: expanded,
            selectedNodes: expandedNodes,
            node: node,
        });
    };
    const loadMore = (treeNode) => {
        //console.log("loading");
        return new Promise((resolve) => {
            if (treeNode.props.dataRef) {
                const { key, type, children } = treeNode.props.dataRef;
                if (type === "directory" && (children === null || children === void 0 ? void 0 : children.length) === 0) {
                    getNewTree(key !== null && key !== void 0 ? key : "0", treeData);
                }
            }
            resolve();
        });
    };
    (0, react_1.useEffect)(() => {
        setTreeData(currentTreeData);
    }, [currentTreeData]);
    (0, react_1.useEffect)(() => {
        if (expandFirst)
            setExpandedKeys(["0"]);
    }, []);
    return (react_1.default.createElement("div", { className: `flex flex-1 flex-col overflow-auto border-t border-border-2` },
        react_1.default.createElement(web_react_1.Tree, { treeData: treeData, className: "fileTree__multi flex-1 overflow-auto px-[12px]", onSelect: handleSelect, expandedKeys: expandedKeys, onExpand: handleExpand, defaultExpandedKeys: defaultKeys, defaultCheckedKeys: defaultKeys, loadMore: loadMore, blockNode: true, onCheck: (checkedKeys) => {
                if (isEditAble) {
                    setTreeCheckedKeys(checkedKeys);
                }
                onCheck(checkedKeys, treeData);
            }, fieldNames: fieldNames, multiple: true, checkable: true, checkedKeys: treeCheckedKeys })));
};
exports.FileTreeMulti = FileTreeMulti;
