// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arco-btn.arco-btn-secondary.login-button {
    height: 46px;
    width: 100%;
    border-radius: 0.5rem;
    background-color: rgb(var(--primary-6));
    padding: 0.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
.arco-btn.arco-btn-secondary.login-button:hover {
    background-color: rgb(var(--primary-5));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}`, "",{"version":3,"sources":["webpack://./src/page/Login/LoginButton/index.scss"],"names":[],"mappings":"AACE;IAAA,YAAA;IAAA,WAAA;IAAA,qBAAA;IAAA,uCAAA;IAAA,eAAA;IAAA,eAAA;IAAA,mBAAA;IAAA,gBAAA;IAAA,oBAAA;IAAA;AAAA;AAAA;IAAA,uCAAA;IAAA,oBAAA;IAAA;AAAA","sourcesContent":[".arco-btn.arco-btn-secondary.login-button {\n  @apply h-[46px] w-full rounded-lg bg-primary-6 p-2 text-base font-medium text-white-100 hover:bg-primary-5 hover:text-white-100;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
