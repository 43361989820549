"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const web_react_1 = require("@arco-design/web-react");
const icon_1 = require("@arco-design/web-react/icon");
const i18next_1 = require("i18next");
const react_1 = __importStar(require("react"));
const debounce_1 = require("@src/util/debounce");
const IsDetailedResBtn = ({ isFollowup, isExplaining, isBtnLoading, isRufusingDetailed, handleBriefNo, handleCreateNewTask, getDetailedAnswer, setIsAnswerBrief, setIsFollowup, handleFollowup, isFollowupBrief, }) => {
    const [isClicked, setIsClicked] = (0, react_1.useState)(false);
    const debouncedClick = (0, react_1.useCallback)((0, debounce_1.debounce)(() => {
        if (!isFollowup) {
            getDetailedAnswer();
        }
        else {
            handleFollowup();
        }
    }, 300, { immediate: true }), [isFollowup, getDetailedAnswer, handleFollowup]);
    const handleClickYes = (0, react_1.useCallback)(() => {
        if (!isClicked) {
            setIsClicked(true);
            debouncedClick();
        }
    }, [isClicked, debouncedClick]);
    const handleClickNo = (0, react_1.useCallback)(() => {
        if (!isClicked) {
            setIsClicked(true);
            if (!isFollowup) {
                handleBriefNo();
                setIsClicked(false);
            }
            else {
                handleCreateNewTask(true);
                setIsAnswerBrief(false);
                setIsFollowup(false);
            }
        }
    }, [
        isClicked,
        isFollowup,
        handleBriefNo,
        handleCreateNewTask,
        setIsAnswerBrief,
        setIsFollowup,
    ]);
    return (react_1.default.createElement(web_react_1.Spin, { loading: isRufusingDetailed || isFollowupBrief, className: "w-full" },
        react_1.default.createElement("div", { className: "Atlas_Status_Animate flex w-full flex-col items-center gap-4 self-stretch rounded-[8px] bg-bg-1 p-3" },
            react_1.default.createElement("div", { className: "text-text-2" }, !isFollowup
                ? (0, i18next_1.t)("chatModel.messageInput.generateDetailedAnswer")
                : (0, i18next_1.t)("chatModel.messageInput.forNextStep")),
            react_1.default.createElement("div", { className: "isDetailedResBtn bottom_buttons flex w-full items-center justify-between gap-2" },
                react_1.default.createElement(web_react_1.Button, { type: "secondary", size: "large", onClick: handleClickNo, className: "w-1/2 min-w-fit rounded-lg", disabled: isExplaining || isBtnLoading || isClicked },
                    isFollowup ? react_1.default.createElement(icon_1.IconPlus, null) : react_1.default.createElement(icon_1.IconClose, null),
                    isFollowup ? "New Chat" : "No"),
                react_1.default.createElement(web_react_1.Button, { type: "primary", size: "large", className: "w-1/2 rounded-lg", onClick: handleClickYes, loading: isExplaining || isBtnLoading, disabled: isExplaining || isBtnLoading || isClicked },
                    !isExplaining && !isBtnLoading && (react_1.default.createElement(react_1.default.Fragment, null,
                        isFollowup ? react_1.default.createElement(icon_1.IconMessage, null) : react_1.default.createElement(icon_1.IconCheck, null),
                        " ")),
                    isFollowup ? "Ask Follow-up" : "Yes")))));
};
exports.default = IsDetailedResBtn;
