// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.result__tag .arco-btn-secondary:not(.arco-btn-disabled) {

    margin-right: 0.75rem;

    height: 1.75rem;

    width: 5.25rem;

    border-radius: 1rem;

    border-width: 1px;

    border-style: solid;

    border-color: var(--color-border-2);

    background-color: var(--color-bg-2)
}

.resultBgc .arco-btn-secondary:not(.arco-btn-disabled) {

    border-width: 1px;

    border-style: solid;

    border-color: rgb(var(--primary-5));

    background-color: rgb(var(--primary-1));

    color: rgb(var(--primary-5))
}

.confirm_button .arco-btn-text > span {

    color: var(--color-text-1)
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Workspace/CBWorkspaceRight/ChatItems/index.scss"],"names":[],"mappings":"AAEI;;IAAA,qBAAA;;IAAA,eAAA;;IAAA,cAAA;;IAAA,mBAAA;;IAAA,iBAAA;;IAAA,mBAAA;;IAAA,mCAAA;;IAAA;AAAA;;AAMA;;IAAA,iBAAA;;IAAA,mBAAA;;IAAA,mCAAA;;IAAA,uCAAA;;IAAA;AAAA;;AAOE;;IAAA;AAAA","sourcesContent":[".result__tag {\n  .arco-btn-secondary:not(.arco-btn-disabled) {\n    @apply mr-3 h-7  w-[5.25rem] rounded-2xl border border-solid border-border-2 bg-bg-2;\n  }\n\n}\n  .resultBgc {\n     .arco-btn-secondary:not(.arco-btn-disabled) {\n    @apply bg-primary-1 border border-solid border-primary-5 text-primary-5\n  }\n   \n  }\n.confirm_button {\n  .arco-btn-text {\n    > span {\n      @apply text-text-1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
