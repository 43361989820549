"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const en = {
    detail: {
        nav: {
            manageAccount: "Manage Accounts",
        },
        topBar: {
            currentRepo: { title: "Current Repo", placeholder: "Filter" },
            currentBranch: {
                title: "Current Branch",
                placeholder: "Filter",
                yourBranch: "Your Branch",
            },
            fetch: "Pull Origin",
            lastFetchedNow: "Last fetched now",
            lastFetchedSeconds: "Last fetched {{seconds}} seconds ago",
            lastFetchedMinutes: "Last fetched {{minutes}} mins ago",
            lastFetchedHours: "Last fetched {{hours}} hours ago",
            lastFetchedDays: "Last fetched {{days}} days ago",
            lastFetchedWeeks: "Last fetched {{weeks}} weeks ago",
            lastFetchedMonths: "Last fetched {{months}} months ago",
            lastFetchedYears: "Last fetched {{years}} years ago",
            searchProjectPlaceholder: "Search Project",
            desktopOnly: "This feature is only available on the desktop version.",
        },
        shared: {
            defaultUser: "default_user",
        },
        explore: {
            title: "Explore Repo",
            chat: {
                title: "Explore the repo with AI",
                button: "Ask Atlas",
            },
            file: {
                title: "View repo files",
                button: "Open Codebase",
            },
        },
        workspace: {
            title: "AI Workspace",
            createProject: {
                title: "Create a new project",
                button: "Create Project",
            },
            viewProject: {
                title: "View existing projects",
                subTitle: "",
                button: "Open Workspace",
            },
        },
        locate: {
            title: "Locate Repo",
            edit: {
                title: "View in IDE",
                subTitle: "Use your <0>preferred external editor</0>",
                button: "Open IDE",
            },
            finder: {
                title: "View in Finder",
                button: "Show in Finder",
            },
            github: {
                title: "View on GitHub",
                subTitle: "Use your Browser",
                button: "View on GitHub",
                noreadme: "This repository has no readme",
            },
        },
        project: {
            title: "Workspace Projects",
            expand: "Expand all",
            collapse: "Collapse all",
            branch: "<0>Based on</0> <1>{{branch}}</1> <2>{{current}}</2>",
            count_one: "1 project",
            count_other: "{{count}} projects",
            lastModifyTime_zero: "Today",
            lastModifyTime_one: "1 day ago",
            lastModifyTime_other: "{{count}} days ago",
            fileCount_one: "1 file",
            fileCount_other: "{{count}} files",
            view: "View",
            session: "session",
            sessions: "sessions",
            create: {
                title: "New Project",
                label: "Name of the new project:",
                placeholder: "Please enter project name",
                button: "Create",
                error: "Create project error!",
            },
            remove: {
                title: "Remove Project",
                content: "Removing a project is an irreversible process.",
            },
            rename: {
                title: "Rename Project",
                label: "New project name:",
                placeholder: "Please enter project name",
                button: "Rename",
            },
            noProject: "No project",
        },
    },
};
const zh = {
    detail: {
        nav: {
            manageAccount: "管理账户",
        },
        topBar: {
            currentRepo: { title: "当前仓库", placeholder: "筛选" },
            currentBranch: {
                title: "当前分支",
                placeholder: "筛选",
                yourBranch: "你的分支",
            },
            fetch: "获取远程",
            lastFetchedNow: "刚刚获取",
            lastFetchedSeconds: "上次获取于 {{seconds}} 秒前",
            lastFetchedMinutes: "上次获取于 {{minutes}} 分钟前",
            lastFetchedHours: "上次获取于 {{hours}} 小时前",
            lastFetchedDays: "上次获取于 {{days}} 天前",
            lastFetchedWeeks: "上次获取于 {{weeks}} 周前",
            lastFetchedMonths: "上次获取于 {{months}} 月前",
            lastFetchedYears: "上次获取于 {{years}} 年前",
            searchProjectPlaceholder: "搜索项目",
            desktopOnly: "此功能仅在桌面版本中可用。",
        },
        shared: {
            defaultUser: "默认用户",
        },
        explore: {
            title: "探索存储库",
            chat: {
                title: "使用 AI 探索存储库",
                button: "询问 Atlas",
            },
            file: {
                title: "查看存储库文件",
                button: "打开代码库",
            },
        },
        workspace: {
            title: "AI 工作区",
            createProject: {
                title: "新建项目",
                button: "创建项目",
            },
            viewProject: {
                title: "查看现有项目",
                subTitle: "",
                button: "打开工作区",
            },
        },
        locate: {
            title: "定位",
            edit: {
                title: "在外部编辑器中编辑存储库",
                subTitle: "使用您的<0>首选外部编辑器</0>",
                button: "在外部编辑器中打开",
            },
            finder: {
                title: "在 Finder 中查看存储库文件",
                button: "在 Finder 中显示",
            },
            github: {
                title: "在 GitHub 上打开存储库页面",
                subTitle: "使用浏览器",
                button: "在 GitHub 上查看",
                noreadme: "此仓库不含readme文件",
            },
        },
        project: {
            title: "工作区项目",
            expand: "展开全部",
            collapse: "折叠全部",
            branch: "<0>基于</0> <1>{{branch}}</1> <2>（当前）</2>",
            count_one: "1 个项目",
            count_other: "{{count}} 个项目",
            lastModifyTime_zero: "今天",
            lastModifyTime_one: "1 天前",
            lastModifyTime_other: "{{count}} 天前",
            fileCount_one: "1 个文件",
            fileCount_other: "{{count}} 个文件",
            view: "查看",
            session: "个会话",
            sessions: "个会话",
            create: {
                title: "新建项目",
                label: "新项目名称：",
                placeholder: "请输入项目名称",
                button: "创建项目",
                error: "创建项目错误！",
            },
            remove: {
                title: "删除项目",
                content: "删除项目是一个不可逆的过程。",
            },
            rename: {
                title: "重命名项目",
                label: "新项目名称：",
                placeholder: "请输入项目名称",
                button: "重命名",
            },
            noProject: "无项目",
        },
    },
};
exports.default = { en, zh };
