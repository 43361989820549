"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHandleProjectClick = void 0;
const jotai_1 = require("jotai");
const react_router_dom_1 = require("react-router-dom");
const allAtom_1 = require("@src/store/allAtom");
const workspaceAtom_1 = require("@src/store/workspaceAtom");
const useHandleProjectClick = () => {
    const [, setCurProId] = (0, jotai_1.useAtom)(workspaceAtom_1.curProIdAtom);
    const [currentCodebase] = (0, jotai_1.useAtom)(allAtom_1.currentCodebaseAtom);
    const navigate = (0, react_router_dom_1.useNavigate)();
    // 返回一个函数，用于处理点击事件
    return (id, repoName = "") => {
        setCurProId(id);
        localStorage.setItem("curProId", id);
        const { remote } = currentCodebase;
        currentCodebase.title = (remote === null || remote === void 0 ? void 0 : remote.owner) + "/" + repoName;
        currentCodebase.active = true;
        navigate("/codebase/workspace");
    };
};
exports.useHandleProjectClick = useHandleProjectClick;
