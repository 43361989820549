"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperatingSystem = void 0;
const react_1 = require("react");
var OperatingSystem;
(function (OperatingSystem) {
    OperatingSystem["Windows"] = "windows";
    OperatingSystem["MacOS"] = "macos";
    OperatingSystem["Linux"] = "linux";
    OperatingSystem["Unknown"] = "unknown";
    OperatingSystem["Ubuntu"] = "ubuntu";
})(OperatingSystem || (exports.OperatingSystem = OperatingSystem = {}));
const useOperatingSystem = () => {
    const [os, setOs] = (0, react_1.useState)(OperatingSystem.Unknown);
    (0, react_1.useEffect)(() => {
        var _a;
        let platform = (_a = navigator.userAgent) === null || _a === void 0 ? void 0 : _a.toLowerCase();
        if (!platform) {
            platform = navigator.platform.toLowerCase();
        }
        if (platform) {
            if (platform.includes("win")) {
                setOs(OperatingSystem.Windows);
            }
            else if (platform.includes("mac")) {
                setOs(OperatingSystem.MacOS);
            }
            else if (platform.includes("linux")) {
                setOs(OperatingSystem.Linux);
            }
            else if (platform.includes("ubuntu")) {
                setOs(OperatingSystem.Ubuntu);
            }
        }
    }, []);
    return os;
};
exports.default = useOperatingSystem;
