// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.repo__search__reults .load__more .arco-btn-secondary {
    border-style: none
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Home/Trending/SearchResultNull/index.scss"],"names":[],"mappings":"AAGM;IAAA;AAAA","sourcesContent":[".repo__search__reults {\n  .load__more {\n    .arco-btn-secondary {\n      @apply border-none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
