"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const web_react_1 = require("@arco-design/web-react");
const web_react_2 = require("@arco-design/web-react");
const i18next_1 = require("i18next");
const react_1 = __importStar(require("react"));
const codebaseCopilot_1 = require("@src/api/codebaseApi/codebaseCopilot");
const RadioGroup = web_react_2.Radio.Group;
const defaultEmbeddingModel = {
    active: false,
    require_api_base: false,
    require_api_key: false,
    subscription: false,
    api_base: "",
    api_key: "",
    model: "",
    params: {},
    provider: "",
    system_message: "",
    title: "",
    type: "embedding",
    is_ollama: false,
    isDefault: true,
};
const Option = web_react_1.Select.Option;
const Embedding = ({ openUpdate }) => {
    const [curType, setCurType] = (0, react_1.useState)("sub");
    const [curModel, setCurModel] = (0, react_1.useState)(defaultEmbeddingModel);
    const [originSubList, setOriginSubList] = (0, react_1.useState)([]);
    const [subList, setSubList] = (0, react_1.useState)([]);
    const fetchOriginData = async () => {
        try {
            const res = (await (0, codebaseCopilot_1.getDefaultModelConfigs)({
                type: "embedding",
            }));
            if ((res === null || res === void 0 ? void 0 : res.status) === 0) {
                const { default_model } = res.data;
                setOriginSubList(res.data.options);
                setCurModel(default_model);
                //设置type
                if (default_model.subscription && !default_model.api_key) {
                    setCurType("sub");
                }
                else if (default_model.require_api_key) {
                    setCurType("myKey");
                }
                else if (default_model.is_ollama) {
                    setCurType("oll");
                }
            }
        }
        catch (e) {
            console.error(e);
        }
    };
    const handleUpdate = async () => {
        try {
            if (curModel.isDefault || curModel.provider === "free_trial") {
                web_react_1.Message.warning((0, i18next_1.t)("home.dashboard.copilot.selectOne"));
                return;
            }
            if (curModel.require_api_key &&
                curType === "myKey" &&
                curModel.api_key.length <= 0) {
                web_react_1.Message.warning("API Key required");
                return;
            }
            if (curModel.require_api_base && !curModel.api_base) {
                web_react_1.Message.warning("API Base required");
                return;
            }
            const updateEmb = {
                provider: curModel.provider,
                title: curModel.title,
                type: curModel.type,
                api_key: curType === "sub" ? "" : curModel.api_key,
                api_base: curModel.api_base,
                params: curModel.params,
            };
            const res = await (0, codebaseCopilot_1.postUpdateEmbAutoConfig)(updateEmb);
            if ((res === null || res === void 0 ? void 0 : res.status) === 0) {
                // Message.success("Embedding Model updated successfully");
                openUpdate();
                fetchOriginData();
            }
            else {
                web_react_1.Message.error("Embedding Model updated failed");
            }
        }
        catch (e) {
            web_react_1.Message.error("Embedding Model updated failed");
            console.log(e);
        }
    };
    (0, react_1.useEffect)(() => {
        fetchOriginData();
    }, []);
    (0, react_1.useEffect)(() => {
        if (curType === "sub") {
            setSubList(originSubList.filter((v) => v.subscription));
        }
        else if (curType === "myKey") {
            setSubList(originSubList.filter((v) => v.require_api_key));
        }
        else if (curType === "oll") {
            setSubList(originSubList.filter((v) => v.is_ollama));
        }
    }, [originSubList, curType]);
    return (react_1.default.createElement("div", { className: "mx-auto mt-2 max-w-[550px]" },
        react_1.default.createElement("div", { className: "flex flex-col gap-6" },
            react_1.default.createElement("div", { className: "flex items-center justify-between gap-[114px]" },
                react_1.default.createElement("span", { className: "text-[14px] text-text-1" }, (0, i18next_1.t)("home.dashboard.copilot.preferredToUse")),
                react_1.default.createElement(RadioGroup, { className: "flex justify-end", value: curType, onChange: (e) => {
                        setCurType(e);
                        setCurModel(defaultEmbeddingModel);
                    } },
                    react_1.default.createElement(web_react_2.Radio, { value: "sub" }, "Subscribtion"),
                    react_1.default.createElement(web_react_2.Radio, { value: "myKey" }, "My Key"),
                    react_1.default.createElement(web_react_2.Radio, { value: "oll", disabled: true }, "Ollama"))),
            react_1.default.createElement("div", { className: "flex items-center justify-between gap-[114px]" },
                react_1.default.createElement("span", { className: "text-[14px] text-text-1" }, (0, i18next_1.t)("home.dashboard.copilot.preferredModel")),
                react_1.default.createElement(web_react_1.Select, { style: { width: 330, borderRadius: 8 }, className: "rounded-lg", value: curModel.title.length > 0
                        ? curModel.title
                        : "Please select your Model", onChange: (e) => {
                        const find = subList.find((v) => v.title === e);
                        if (!find) {
                            web_react_1.Message.error("No Model Found");
                            return;
                        }
                        setCurModel(find);
                    } }, subList === null || subList === void 0 ? void 0 : subList.map((model) => (react_1.default.createElement(Option, { key: model.title, value: model.title }, model.title))))),
            curType === "myKey" && (react_1.default.createElement("div", { className: "flex items-center justify-between gap-[114px]" },
                react_1.default.createElement("span", { className: "text-[14px] text-text-1" }, (0, i18next_1.t)("home.dashboard.copilot.apiKey")),
                react_1.default.createElement(web_react_1.Input, { placeholder: (0, i18next_1.t)("home.dashboard.copilot.enterAPIKey"), style: { width: 330, background: "var(--color-fill-2)" }, value: curModel.api_key, onChange: (e) => setCurModel((prev) => ({ ...prev, api_key: e })) }))),
            curModel.require_api_base && (react_1.default.createElement("div", { className: "flex items-center justify-between gap-[114px]" },
                react_1.default.createElement("span", { className: "text-[14px] text-text-1" }, (0, i18next_1.t)("home.dashboard.copilot.apiBase")),
                react_1.default.createElement(web_react_1.Input, { placeholder: (0, i18next_1.t)("home.dashboard.copilot.enterAPIBase"), style: { width: 330, background: "var(--color-fill-2)" }, value: curModel.api_base, onChange: (e) => setCurModel((prev) => ({ ...prev, api_base: e })) }))),
            react_1.default.createElement("div", { className: "mt-4 flex justify-end" },
                react_1.default.createElement(web_react_1.Button, { type: "secondary", className: "mr-2 h-8 w-[77px]", onClick: () => {
                        setCurModel(defaultEmbeddingModel);
                    } }, (0, i18next_1.t)("home.dashboard.copilot.cancel")),
                react_1.default.createElement(web_react_1.Button, { type: "primary", className: "h-8 w-[79px]", onClick: handleUpdate }, (0, i18next_1.t)("home.dashboard.copilot.update"))))));
};
exports.default = Embedding;
