"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.relatedData = exports.shortenPath = void 0;
//文件路径处理
const shortenPath = (path) => {
    const parts = path.split("/");
    if (parts.length <= 3) {
        return path;
    }
    return `${parts[0]}/.../${parts.slice(-2).join("/")}`;
};
exports.shortenPath = shortenPath;
exports.relatedData = [
    {
        file_path: "main/nodes/node/claude.py",
        related_code: "using System;\nusing System.Collections.Generic;\nusing System.Linq;\nusing System.Threading.Tasks;\n\nnamespace Storyteller.Models\n{\n    public class Writer\n    {\n        /// <summary>\n        /// The name of the writer.\n        /// </summary>\n        public string Name { get; set; }\n\n        /// <summary>\n        /// The writer's score so far.\n        /// </summary>\n        public int Score { get; set; }\n\n        /// <summary>\n        /// A guid is needed to uniquely identify the user.\n        /// </summary>\n        public string Identifier { get; set; }\n\n        public PlayerColor Color { get; set; }\n    }\n}\n",
        description: '\nThe comment "# Program make a simple calculator" describes the overall purpose of the file "project.py", which is to create a simple calculator program. This context helps to understand that the following functions for addition and subtraction are part of this calculator\'s functionality.\n',
    },
    {
        file_path: "main/nodes/node/openai.py",
        related_code: "using System;\nusing System.Collections.Generic;\nusing System.Linq;\nusing System.Threading.Tasks;\n\nnamespace Storyteller.Models\n{\n    public class Writer\n    {\n        /// <summary>\n        /// The name of the writer.\n        /// </summary>\n        public string Name { get; set; }\n\n        /// <summary>\n        /// The writer's score so far.\n        /// </summary>\n        public int Score { get; set; }\n\n        /// <summary>\n        /// A guid is needed to uniquely identify the user.\n        /// </summary>\n        public string Identifier { get; set; }\n\n        public PlayerColor Color { get; set; }\n    }\n}\n",
        description: "This part of the code uses the API to do ....This part of the code uses the API to do ....This part of the code uses the API to do ....This part of the code uses the API to do ....",
    },
];
