"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jotai_1 = require("jotai");
const react_1 = require("react");
const cbDetailAtom_1 = require("@src/store/cbDetailAtom");
function useMediaQuery() {
    const [isShowReadme] = (0, jotai_1.useAtom)(cbDetailAtom_1.isShowReadmeAtom);
    const [widths, setWidths] = (0, react_1.useState)({
        detailBoardWidth: "100%",
        readmeViewerWidth: "0px", // 默认不显示
        readmeViewerOpacity: 0, // 完全透明
    });
    (0, react_1.useEffect)(() => {
        function handleResize() {
            const windowWidth = window.innerWidth;
            if (isShowReadme) {
                const detailWidth = Math.max(0, 520);
                const readmeWidth = Math.max(windowWidth - 510, 908);
                setWidths({
                    detailBoardWidth: `${detailWidth}px`,
                    readmeViewerWidth: `${readmeWidth}px`,
                    readmeViewerOpacity: 1,
                });
            }
            else {
                setWidths({
                    detailBoardWidth: "100%",
                    readmeViewerWidth: "0px",
                    readmeViewerOpacity: 0,
                });
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [isShowReadme]);
    return widths;
}
exports.default = useMediaQuery;
