"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.searchForKeyword = void 0;
const codebaseProjectApi_1 = require("@src/api/codebaseApi/codebaseProjectApi");
const debounce_1 = require("../debounce");
const { getSearchFile, getSearchFolder } = codebaseProjectApi_1.projectApi;
const searchForKeywordImpl = async (keyword) => {
    var _a, _b;
    let fileResults, folderResults;
    let showFullPath = true;
    if (keyword) {
        [fileResults, folderResults] = await Promise.all([
            getSearchFile({
                project_uuid: localStorage.getItem("curchatProjectId"),
                keyword: keyword,
                max_count: 10,
            }),
            getSearchFolder({
                project_uuid: localStorage.getItem("curchatProjectId"),
                keyword: keyword,
                max_count: 10,
            }),
        ]);
    }
    else {
        fileResults = { data: { paths: ["File"] } };
        folderResults = { data: { paths: ["Folder"] } };
        showFullPath = false;
    }
    const files = (_a = fileResults === null || fileResults === void 0 ? void 0 : fileResults.data.paths.map((path) => ({
        type: "file",
        path,
    }))) !== null && _a !== void 0 ? _a : [];
    const folders = (_b = folderResults === null || folderResults === void 0 ? void 0 : folderResults.data.paths.map((path) => ({
        type: "folder",
        path,
    }))) !== null && _b !== void 0 ? _b : [];
    return { files, folders, showFullPath };
};
exports.searchForKeyword = (0, debounce_1.debounce)(searchForKeywordImpl, 300);
