"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setUserAPIKey = exports.addGitLabClassicToken = exports.addGitHubClassicToken = exports.deleteGitLabAccount = exports.deleteGitHubAccount = exports.getUserInfoByAuthingId = void 0;
const apiConfigV2_1 = require("./apiConfigV2");
const url = "/api/v2/user";
const getUserInfoByAuthingId = (params) => {
    return (0, apiConfigV2_1.getApi)(url + "/get_user_info_by_authing_id", params, true);
};
exports.getUserInfoByAuthingId = getUserInfoByAuthingId;
const deleteGitHubAccount = (params) => {
    return (0, apiConfigV2_1.postApi)(url + "/delete_github_info", params, true);
};
exports.deleteGitHubAccount = deleteGitHubAccount;
const deleteGitLabAccount = (params) => {
    return (0, apiConfigV2_1.postApi)(url + "/delete_gitlab_info", params, true);
};
exports.deleteGitLabAccount = deleteGitLabAccount;
const addGitHubClassicToken = (params) => {
    return (0, apiConfigV2_1.postApi)(url + "/add_classic_token", params, true);
};
exports.addGitHubClassicToken = addGitHubClassicToken;
const addGitLabClassicToken = (params) => {
    return (0, apiConfigV2_1.postApi)(url + "/add_classic_gitlab_token", params, true);
};
exports.addGitLabClassicToken = addGitLabClassicToken;
const setUserAPIKey = (params) => {
    return (0, apiConfigV2_1.postApi)(url + "/set_user_config", params, true);
};
exports.setUserAPIKey = setUserAPIKey;
