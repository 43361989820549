// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sync-repo-box .sync-button {
    flex-shrink: 0;
    border-radius: 0.5rem;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-border-2);
    background-color: var(--color-bg-1)
}
.sync-repo-box .sync-button:hover {
    background-color: var(--color-fill-2)
}
.sync-repo-box .arco-checkbox-mask-wrapper {
    top: auto
}
.sync-repo-box .arco-checkbox-text {
    display: flex;
    min-width: 0px;
    flex-grow: 1;
    align-items: center;
    gap: 0.5rem
}`, "",{"version":3,"sources":["webpack://./src/page/Login/SyncRepo/index.scss"],"names":[],"mappings":"AAEI;IAAA,cAAA;IAAA,qBAAA;IAAA,iBAAA;IAAA,mBAAA;IAAA,mCAAA;IAAA;AAAA;AAAA;IAAA;AAAA;AAIA;IAAA;AAAA;AAIA;IAAA,aAAA;IAAA,cAAA;IAAA,YAAA;IAAA,mBAAA;IAAA;AAAA","sourcesContent":[".sync-repo-box {\n  .sync-button {\n    @apply flex-shrink-0 rounded-lg border border-solid border-border-2 bg-bg-1 hover:bg-fill-2;\n  }\n\n  .arco-checkbox-mask-wrapper {\n    @apply top-auto;\n  }\n\n  .arco-checkbox-text {\n    @apply flex min-w-0 flex-grow items-center gap-2;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
