"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatNumber = exports.ParseRange = void 0;
const ParseRange = (range) => {
    const match = range.match(/\((\d+),\s*(\d+)\)/);
    if (match && match[1] && match[2]) {
        return [Number(match[1]), Number(match[2])];
    }
    return [-1, -1];
};
exports.ParseRange = ParseRange;
const formatNumber = (num) => {
    if (num >= 1000 && num < 1000000) {
        return (num / 1000).toFixed(1) + "k";
    }
    else if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + "M";
    }
    else {
        return num.toString();
    }
};
exports.formatNumber = formatNumber;
