"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const i18next_1 = require("i18next");
const jotai_1 = require("jotai");
const react_1 = require("react");
const workspaceAtom_1 = require("@src/store/workspaceAtom");
const CBWorkSpaceContext_1 = require("../CBWorkSpaceContext");
const useNewSessionEnv = () => {
    const [, setIsWpGeneWorking] = (0, jotai_1.useAtom)(workspaceAtom_1.isWpGeneWorkingAtom);
    const [, setCurTaskId] = (0, jotai_1.useAtom)(workspaceAtom_1.curTaskIdAtom);
    const [, setWpCentertabActive] = (0, jotai_1.useAtom)(workspaceAtom_1.wpCentertabActiveAtom);
    const [, setWpShowChat] = (0, jotai_1.useAtom)(workspaceAtom_1.wpShowChatAtom);
    const [, setSelectHighlights] = (0, jotai_1.useAtom)(workspaceAtom_1.selectHighlightsAtom);
    const [, setHighlights] = (0, jotai_1.useAtom)(workspaceAtom_1.highlightsAtom);
    const [, setChatIconShow] = (0, jotai_1.useAtom)(workspaceAtom_1.chatIconShowAtom);
    const [, setLastDisplayedLog] = (0, jotai_1.useAtom)(workspaceAtom_1.lastDisplayedLogAtom);
    const [, setNewDraftList] = (0, jotai_1.useAtom)(workspaceAtom_1.newDraftListAtom);
    const [, setWpIsDoingRege] = (0, jotai_1.useAtom)(workspaceAtom_1.wpIsDoingRegeAtom);
    const { setSessionRoundList, setGeneratePlan, setDiffList, setDiffListCache, setSessionDoing, setResultList, setCurCheckStatus, setWpTaskStatus, setCurTaskName, setDiffShow, setChatHistory, setShowTextArea, setShowInteractArea, setWorkStatusList, setAddFilesNum, setWorkLog, setFocusFileSnippet, setFocusFileContent, setFinalActions, setLocalContextList, setCurCheckTaskId, setCommitHistoryList, setSessRoundActiveKeys, setRegePrepare, setRegeList, } = (0, react_1.useContext)(CBWorkSpaceContext_1.WorkSpaceContext);
    const newSessionEnv = (taskInfo) => {
        const { curTaskName = "", taskId = "" } = taskInfo !== null && taskInfo !== void 0 ? taskInfo : {};
        setRegePrepare(false);
        setShowTextArea(true);
        setGeneratePlan({});
        setWpCentertabActive("1");
        setDiffShow(false);
        setDiffList([]);
        setResultList([]);
        setSessionDoing(false);
        setFocusFileContent([]);
        setWpTaskStatus("none");
        setShowInteractArea(true);
        setCurCheckStatus("config");
        setSelectHighlights([]);
        setHighlights([]);
        setDiffListCache({});
        setWpIsDoingRege(false);
        setFocusFileSnippet({});
        setAddFilesNum(0);
        setWpShowChat(true);
        setChatIconShow(true);
        setIsWpGeneWorking(false);
        setWorkLog([]);
        setFinalActions({});
        localStorage.setItem("curTaskId", taskInfo ? taskId : "");
        setCurTaskId(taskInfo ? taskId : "");
        setCurCheckTaskId(taskInfo ? taskId : "");
        setCurTaskName(taskInfo ? curTaskName : "");
        setLocalContextList([]);
        setCommitHistoryList([]);
        setSessRoundActiveKeys(taskInfo ? [taskId] : []);
        setChatHistory([]);
        setLastDisplayedLog(null);
        setNewDraftList([]);
        setRegeList({});
        if (taskInfo) {
            const config = {
                title: (0, i18next_1.t)("workspace.shared.reference"),
                status: "config",
                finish: false,
                handleClickTitle: () => {
                    setDiffShow(false);
                    setCurCheckStatus("config");
                },
                sessionId: taskId,
            };
            setWorkStatusList([config]);
            const sessionRound = {
                sessionId: taskId,
                type: "round",
                title: "Round 1",
            };
            const sessionCommit = {
                sessionId: taskId,
                type: "commit",
                title: "Commit History",
            };
            setSessionRoundList([sessionRound, sessionCommit]);
        }
        else {
            setSessionRoundList([]);
            setWorkStatusList([]);
        }
    };
    return {
        newSessionEnv,
    };
};
exports.default = useNewSessionEnv;
