// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copilot__tabs .arco-tabs-header-scroll {
  padding-left: 134px;
  padding-right: 134px;
}
.copilot__tabs .arco-tabs-header-scroll .arco-tabs-header-ink {
  background-color: rgb(var(--primary-6));
}
.copilot__tabs .arco-select-view {
  border-radius: 0.5rem;
}
.copilot__tabs::-webkit-scrollbar {
  display: none;
}
.copilot__tabs .arco-radio-group .arco-radio {
  margin-left: 1.25rem;
  margin-right: 0px;
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Copilot/ConfigTab/index.scss"],"names":[],"mappings":"AAEI;EAAA,mBAAA;EAAA;AAAA;AAEE;EAAA;AAAA;AAIF;EAAA;AAAA;AAEF;EACE,aAAA;AAAJ;AAIM;EAAA,oBAAA;EAAA;AAAA","sourcesContent":[".copilot__tabs {\n  .arco-tabs-header-scroll {\n    @apply px-[134px];\n    .arco-tabs-header-ink {\n      @apply bg-primary-6;\n    }\n  }\n  .arco-select-view {\n    @apply rounded-lg;\n  }\n  &::-webkit-scrollbar {\n    display: none;\n  }\n  .arco-radio-group {\n    .arco-radio {\n      @apply ml-5 mr-0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
