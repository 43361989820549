"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const web_react_1 = require("@arco-design/web-react");
const api_1 = require("@src/api");
const { postFileContentByProId } = api_1.api;
const useFileContent = ({ curProId }) => {
    const getFileContentByFileList = async (params) => {
        const { files_list, handleError, setFileList, sessionId } = params;
        try {
            const resContent = (await postFileContentByProId({
                project_uuid: curProId,
                paths: files_list,
                // encoding: "gbk",
            }));
            if (resContent.status === 0) {
                resContent.data.file_contents.forEach((item) => {
                    if (sessionId) {
                        const newItem = { ...item, sessionId };
                        setFileList((prev) => [...prev, newItem]);
                    }
                    else {
                        setFileList((prev) => [...prev, item]);
                    }
                });
            }
        }
        catch (e) {
            if (handleError)
                handleError();
            web_react_1.Message.error("Get files contents error!");
        }
    };
    return {
        getFileContentByFileList,
    };
};
exports.default = useFileContent;
