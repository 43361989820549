// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CBdetailTop-arco-styles .arco-input-inner-wrapper {
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-border-2);
    background-color: var(--color-fill-1)
}
.CBdetailTop-arco-styles .arco-input-inner-wrapper.arco-input-inner-wrapper-focus {
    border-color: rgb(var(--primary-6))
}
.CBdetailTop-arco-styles .arco-spin-children {
    height: 100%
}

.hover-truncate-off:hover {
    overflow: visible;
    text-overflow: ellipsis;
    white-space: normal
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Detail/DetailTop/index.scss"],"names":[],"mappings":"AAEI;IAAA,iBAAA;IAAA,mBAAA;IAAA,mCAAA;IAAA;AAAA;AAGE;IAAA;AAAA;AAKF;IAAA;AAAA;;AAKF;IAAA,iBAAA;IAAA,uBAAA;IAAA;AAAA","sourcesContent":[".CBdetailTop-arco-styles {\n  .arco-input-inner-wrapper {\n    @apply border border-solid border-border-2 bg-fill-1;\n\n    &.arco-input-inner-wrapper-focus {\n      @apply border-primary-6;\n    }\n  }\n\n  .arco-spin-children {\n    @apply h-full;\n  }\n}\n\n.hover-truncate-off:hover {\n  @apply overflow-visible overflow-ellipsis whitespace-normal;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
