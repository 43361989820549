"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jotai_1 = require("jotai");
const react_1 = require("react");
const codebaseChatModeApi_1 = require("@src/api/codebaseApi/codebaseChatModeApi");
const workspaceAtom_1 = require("@src/store/workspaceAtom");
const CBWorkSpaceContext_1 = require("../../CBWorkSpaceContext");
const useWpWorkLog = () => {
    const { getChatTaskContext } = codebaseChatModeApi_1.useCbChatModeApi;
    const { setContextExamFiles, setWorkLog, setIsExploering } = (0, react_1.useContext)(CBWorkSpaceContext_1.WorkSpaceContext);
    const clearIntervalRef = (0, react_1.useRef)(() => { });
    const [curTaskId] = (0, jotai_1.useAtom)(workspaceAtom_1.curTaskIdAtom);
    const [isThinking, setIsThinking] = (0, jotai_1.useAtom)(workspaceAtom_1.isThinkingAtom);
    const intervalIdRef = (0, react_1.useRef)(null);
    const [isPickingFile, setIsPickingFile] = (0, react_1.useState)(false);
    const [isWaiting, setIsWaiting] = (0, react_1.useState)(false);
    //停止context的轮询
    const handleStopInterval = (clearIntervalFn) => {
        clearIntervalRef.current = clearIntervalFn;
    };
    const clearIntervalId = () => {
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current); //clear interval
            intervalIdRef.current = null;
        }
    };
    (0, react_1.useEffect)(() => {
        handleStopInterval(clearIntervalId); //绑定clearInterval回调函数
    }, [handleStopInterval]);
    const fetchAndProcessToolCallPaths = async (taskUuid) => {
        //console.log("fetchAndProcessToolCallPaths");
        setContextExamFiles([]);
        setIsExploering(true);
        intervalIdRef.current = setInterval(async () => {
            const res = await getChatTaskContext({ task_uuid: taskUuid });
            setIsThinking(true);
            const { tool_call_list } = res.data;
            if (tool_call_list && tool_call_list.length > 0) {
                let allPaths = [];
                let logEntries = new Set();
                for (const tool of tool_call_list) {
                    allPaths = allPaths.concat(tool.tool_call_path);
                    const logEntry = {
                        tool_call_path: tool.tool_call_path,
                        tool_name: tool.tool_name,
                        content: tool.content,
                    };
                    logEntries.add(JSON.stringify(logEntry));
                }
                const uniquePaths = [...new Set(allPaths)];
                setContextExamFiles((prev) => [...new Set([...prev, ...uniquePaths])]);
                const uniqueLogEntries = Array.from(logEntries).map((entry) => JSON.parse(entry));
                setWorkLog(() => uniqueLogEntries);
            }
        }, 2000);
    };
    const closeWorkLogInterval = () => {
        if (clearIntervalRef.current) {
            clearIntervalRef.current();
            setIsPickingFile(false);
        }
    };
    (0, react_1.useEffect)(() => {
        if (isPickingFile) {
            fetchAndProcessToolCallPaths(curTaskId == ""
                ? localStorage.getItem("curTaskId")
                : curTaskId);
        }
    }, [isPickingFile]);
    return {
        isPickingFile,
        setIsPickingFile,
        closeWorkLogInterval,
    };
};
exports.default = useWpWorkLog;
