"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const web_react_1 = require("@arco-design/web-react");
const icon_1 = require("@arco-design/web-react/icon");
const react_1 = __importStar(require("react"));
const CBWorkSpaceContext_1 = require("../../CBWorkSpaceContext");
require("./index.scss");
const StateCardComp = ({ title, handleClick, finish, check, sessionId, }) => {
    const { curCheckStatus, curCheckTaskId, setCurCheckTaskId } = (0, react_1.useContext)(CBWorkSpaceContext_1.WorkSpaceContext);
    return (react_1.default.createElement(web_react_1.Card, { className: "wp__state__card__comp mb-[6px] h-[38px] bg-transparent", bordered: false, key: title },
        react_1.default.createElement("div", { className: `flex h-full  cursor-pointer items-center rounded-lg bg-fill-1 px-3  py-2 shadow ${curCheckStatus === check &&
                (sessionId ? sessionId === curCheckTaskId : true)
                ? "border border-solid border-primary-4"
                : "border-border-2 "}`, onClick: () => {
                setCurCheckTaskId(sessionId !== null && sessionId !== void 0 ? sessionId : "");
                handleClick();
            } },
            finish ? (react_1.default.createElement("div", { className: "flex h-[14px] w-[14px] items-center justify-center gap-[10px] rounded-full bg-primary-5 p-[2px] text-[white]" },
                react_1.default.createElement(icon_1.IconCheck, { className: "text-[14px] " }))) : (react_1.default.createElement("div", { className: "text-text-2" },
                react_1.default.createElement(icon_1.IconClockCircle, { className: `text-[16px] ${curCheckStatus === check && "text-primary-5"} ` }))),
            react_1.default.createElement(web_react_1.Typography.Text, { className: "verflow-hidden ml-[6px] text-ellipsis whitespace-nowrap text-[14px] font-semibold" }, title))));
};
exports.default = StateCardComp;
