// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wp_right_chat {
  font-size: 14px;
}
.wp_right_chat .feedback_button .arco-btn-text > span {
  color: var(--color-text-1);
}
.wp_right_chat .feedback_button .arco-btn-secondary {
  border-radius: 0.125rem;
  border-style: solid;
  border-color: var(--color-border-2);
}
.wp_right_chat .header-right .arco-btn-secondary {
  border-style: none;
  border-color: var(--color-border-2);
  background-color: var(--color-bg-9);
}
.wp_right_chat .feedback_textarea .arco-textarea {
  height: 18rem;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-width: 1px;
  border-style: solid;
  border-color: var(--color-border-2);
  background-color: var(--color-fill-2);
}
.wp_right_chat .next__bottom___buttons .arco-btn {
  height: 2.25rem;
  width: 198px;
}
.wp_right_chat .next__bottom___buttons .arco-btn-secondary {
  border-color: var(--color-border-2);
}
.wp_right_chat .round__bottom___buttons .arco-btn {
  height: 2.25rem;
  width: 100%;
}
.wp_right_chat .round__bottom___buttons .arco-btn-secondary {
  border-color: var(--color-border-2);
  background-color: var(--color-bg-1);
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Workspace/CBWorkspaceRight/index.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AAGQ;EAAA;AAAA;AAIF;EAAA,uBAAA;EAAA,mBAAA;EAAA;AAAA;AAMA;EAAA,kBAAA;EAAA,mCAAA;EAAA;AAAA;AAKA;EAAA,aAAA;EAAA,+BAAA;EAAA,8BAAA;EAAA,qBAAA;EAAA,mBAAA;EAAA,mCAAA;EAAA;AAAA;AAKA;EAAA,eAAA;EAAA;AAAA;AAGA;EAAA;AAAA;AAKA;EAAA,eAAA;EAAA;AAAA;AAGA;EAAA,mCAAA;EAAA;AAAA","sourcesContent":[".wp_right_chat {\n  font-size: 14px;\n  .feedback_button {\n    .arco-btn-text {\n      > span {\n        @apply text-text-1;\n      }\n    }\n    .arco-btn-secondary {\n      @apply rounded-sm border-solid border-border-2;\n    }\n  }\n\n  .header-right {\n    .arco-btn-secondary {\n      @apply border-none border-border-2 bg-bg-9;\n    }\n  }\n  .feedback_textarea {\n    .arco-textarea {\n      @apply h-[18rem] rounded-b-none border-t border-solid border-border-2 bg-fill-2;\n    }\n  }\n  .next__bottom___buttons {\n    .arco-btn {\n      @apply h-9 w-[198px];\n    }\n    .arco-btn-secondary {\n      @apply border-border-2;\n    }\n  }\n  .round__bottom___buttons {\n    .arco-btn {\n      @apply h-9 w-full;\n    }\n    .arco-btn-secondary {\n      @apply border-border-2 bg-bg-1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
