// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.markdown-body {
  box-sizing: border-box;
  max-width: 980px;
  height: calc(100vh - 140px);
  margin: 0 auto;
  padding: 45px;
  overflow: auto;
  background: var(--color-bg-1);
  color: var(--color-text-1);
}

@media (max-width: 767px) {
  .markdown-body {
    padding: 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ReadmeViewer/index.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EAEA,gBAAA;EACA,2BAAA;EACA,cAAA;EACA,aAAA;EACA,cAAA;EACA,6BAAA;EACA,0BAAA;AAAF;;AAGA;EACE;IACE,aAAA;EAAF;AACF","sourcesContent":[".markdown-body {\n  box-sizing: border-box;\n  // min-width: 200px;\n  max-width: 980px;\n  height: calc(100vh - 140px);\n  margin: 0 auto;\n  padding: 45px;\n  overflow: auto;\n  background: var(--color-bg-1);\n  color: var(--color-text-1);\n}\n\n@media (max-width: 767px) {\n  .markdown-body {\n    padding: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
