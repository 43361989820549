"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const web_react_1 = require("@arco-design/web-react");
const icon_1 = require("@arco-design/web-react/icon");
const react_1 = __importStar(require("react"));
const ResultLineBgColorClasses_1 = require("@src/components/Editor/ResultLineBgColorClasses");
const LeftResultItem = ({ filepath, analysis, isCollapsed: parentIsCollapsed, resultLineBgColor, isLoading, refUrl, isHoverPopup = false, indexItem, isShowRefBg = true, }) => {
    const [isCollapsed, setIsCollapsed] = (0, react_1.useState)(parentIsCollapsed);
    // const handleClick = () => {
    //   setIsCollapsed(!isCollapsed);
    // };
    const bgColorClass = resultLineBgColor &&
        ResultLineBgColorClasses_1.ResultLinebgColorClasses[resultLineBgColor];
    (0, react_1.useEffect)(() => {
        setIsCollapsed(parentIsCollapsed);
    }, [parentIsCollapsed]);
    return (react_1.default.createElement("div", { className: "relative flex cursor-pointer items-start rounded-lg border border-solid border-border-2 bg-fill-1 p-[12px] text-text-1 ", onClick: () => {
            window.open(refUrl, "_blank");
        } },
        !isHoverPopup && isShowRefBg && (react_1.default.createElement("div", { className: `mr-4 flex w-[4px] items-start self-stretch ${bgColorClass} rounded-[2px] p-0` })),
        react_1.default.createElement("div", { className: " flex  flex-col " },
            react_1.default.createElement("div", { className: ` flex  flex-grow ${!isHoverPopup ? "max-w-[195px]" : "max-w-[134px]"} items-center justify-between overflow-hidden text-ellipsis whitespace-nowrap text-[14px] text-text-1` },
                isLoading ? (react_1.default.createElement("div", { className: "skeleton-header ml-[2px] h-[24px] w-[125px] bg-gradient-to-r from-[#E5E6EB] to-[#F7F8FA]" })) : (react_1.default.createElement(web_react_1.Tooltip, { content: filepath },
                    react_1.default.createElement("div", { className: " overflow-hidden text-ellipsis whitespace-nowrap" },
                        indexItem,
                        react_1.default.createElement("span", { className: "mx-[2px]" }, "/"),
                        filepath))),
                react_1.default.createElement("div", { className: `absolute right-[8px] ml-[30px]  flex h-[24px]  w-[24px] cursor-pointer items-center justify-center gap-[10px] p-[4px] ${isLoading ? "hidden" : ""}` },
                    react_1.default.createElement(web_react_1.Tooltip, { content: refUrl },
                        react_1.default.createElement(icon_1.IconArrowRight, { className: "h-[14px] w-[14px] rotate-[-45deg] text-text-3" })))),
            react_1.default.createElement("div", { className: `flex max-w-[272px] items-start gap-[16px] self-stretch ${isCollapsed
                    ? "max-h-0 overflow-hidden transition-[max-height_0.3s_ease-out]"
                    : "mt-[10px] max-h-[1000px] transition-[max-height_0.3s_ease-in] "}` }, isLoading ? (react_1.default.createElement("div", { className: "flex h-[165px]" },
                react_1.default.createElement("div", { className: "mr-[12px] w-[4px] rounded-[2px]  bg-text-4" }),
                react_1.default.createElement("div", { className: "skeleton-content skeleton w-[216px] bg-gradient-to-r from-[#E5E6EB] to-[#F7F8FA]" }))) : (react_1.default.createElement(web_react_1.Tooltip, { content: analysis },
                react_1.default.createElement("div", { className: "line-clamp-2 flex flex-1 flex-col items-start gap-[4px] overflow-x-auto text-[12px]" }, analysis)))))));
};
exports.default = LeftResultItem;
