"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatDateTime = void 0;
exports.fromNow = fromNow;
exports.paymentFormatDate = paymentFormatDate;
const formatDateTime = (timestamp) => {
    const date = new Date(timestamp * 1000); // 转换为毫秒
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    const month = months[date.getMonth()];
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${month} ${day}, ${year}, ${hours}:${minutes}`;
};
exports.formatDateTime = formatDateTime;
function fromNow(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    const intervals = [
        { label: "year", seconds: 31536000 },
        { label: "month", seconds: 2592000 },
        { label: "day", seconds: 86400 },
        { label: "hour", seconds: 3600 },
        { label: "minute", seconds: 60 },
        { label: "second", seconds: 1 },
    ];
    for (const interval of intervals) {
        const count = Math.floor(diffInSeconds / interval.seconds);
        if (count >= 1) {
            return count === 1
                ? `1 ${interval.label} ago`
                : `${count} ${interval.label}s ago`;
        }
    }
    return "just now";
}
function paymentFormatDate(dateString) {
    if (!dateString) {
        return "";
    }
    const parts = dateString.split("-");
    if (parts.length !== 3) {
        return "";
    }
    const [month, day, year] = parts;
    const date = new Date(`${year}-${month}-${day}`);
    if (isNaN(date.getTime())) {
        return "";
    }
    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
}
