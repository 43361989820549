"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const CBWorkSpaceContext_1 = require("../CBWorkSpaceContext");
const useRegeList = () => {
    const { setRegeList, setDiffListCache, diffList } = (0, react_1.useContext)(CBWorkSpaceContext_1.WorkSpaceContext);
    const updateDiffListCache = () => {
        const cache = diffList.reduce((acc, item) => {
            const { path } = item;
            if (!acc[path]) {
                acc[path] = [];
            }
            acc[path].push(item);
            return acc;
        }, {});
        //console.log(cache, "cache!");
        setDiffListCache(cache);
    };
    const addToRegeList = (key, value) => {
        setRegeList((prevRegeList) => {
            const updatedRegeList = { ...prevRegeList };
            if (updatedRegeList[key]) {
                // 如果key已经存在，往数组里添加新数据
                updatedRegeList[key] = [...updatedRegeList[key], value];
            }
            else {
                // 如果key不存在，创建一个新的数组
                updatedRegeList[key] = [value];
            }
            return updatedRegeList;
        });
    };
    const removeFromRegeList = (key, value) => {
        setRegeList((prevRegeList) => {
            const updatedRegeList = { ...prevRegeList };
            if (updatedRegeList[key]) {
                // 如果key存在，移除指定的value
                updatedRegeList[key] = updatedRegeList[key].filter((item) => item !== value);
                // 如果数组为空，删除key
                if (updatedRegeList[key].length === 0) {
                    delete updatedRegeList[key];
                }
            }
            return updatedRegeList;
        });
    };
    return {
        addToRegeList,
        removeFromRegeList,
        updateDiffListCache,
    };
};
exports.default = useRegeList;
