"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useElementHeight = (ref) => {
    const [height, setHeight] = (0, react_1.useState)(0);
    (0, react_1.useEffect)(() => {
        const handleResize = () => {
            var _a;
            if (ref.current instanceof HTMLElement) {
                setHeight(ref.current.clientHeight);
            }
            else if (((_a = ref.current) === null || _a === void 0 ? void 0 : _a.current) instanceof HTMLElement) {
                setHeight(ref.current.current.clientHeight);
            }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, [ref]); // 依赖于 ref，当 ref 变化时重新执行
    return height;
};
exports.default = useElementHeight;
