"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.debounce = void 0;
const debounce = (func, delay, options = {}) => {
    let timeoutId = null;
    let isImmediate = options.immediate;
    return (...args) => {
        return new Promise((resolve, reject) => {
            const later = () => {
                timeoutId = null;
                if (!isImmediate) {
                    try {
                        const result = func(...args);
                        resolve(result);
                    }
                    catch (error) {
                        reject(error);
                    }
                }
            };
            const callNow = isImmediate && !timeoutId;
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(later, delay);
            if (callNow) {
                try {
                    const result = func(...args);
                    resolve(result);
                }
                catch (error) {
                    reject(error);
                }
            }
        });
    };
};
exports.debounce = debounce;
