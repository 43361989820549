"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BranchDropdown = exports.pickWidthNum = void 0;
const web_react_1 = require("@arco-design/web-react");
const icon_1 = require("@arco-design/web-react/icon");
const i18next_1 = require("i18next");
const jotai_1 = require("jotai");
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const allAtom_1 = require("@src/store/allAtom");
const isElectron_1 = require("@src/util/isElectron");
require("./index.scss");
const pickWidthNum = (pxstr) => {
    var _a;
    return parseInt(((_a = pxstr.match(/\d+/)) === null || _a === void 0 ? void 0 : _a[0]) || "0", 10);
};
exports.pickWidthNum = pickWidthNum;
const BranchDropdown = ({ branchLists, closeDropdown, onSelect, branchListWidth, type }) => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const branchListWidthNum = (0, exports.pickWidthNum)(branchListWidth);
    const branchTextWidth = branchListWidthNum - 64;
    const [searchTerm, setSearchTerm] = (0, react_1.useState)("");
    const [currentCodebase] = (0, jotai_1.useAtom)(allAtom_1.currentCodebaseAtom);
    const handleClick = (branch) => {
        onSelect(branch);
        closeDropdown();
    };
    const filteredBranchLists = branchLists.map((list) => ({
        ...list,
        items: list.items.filter((item) => item.branchName.toLowerCase().includes(searchTerm.toLowerCase())),
    }));
    return (react_1.default.createElement("div", { className: `CBdetailTop-arco-styles relative right-[1px] flex -translate-y-1 flex-col items-start
      gap-4 self-stretch border-l border-r border-solid border-border-2 bg-fill-1
      ${(0, isElectron_1.isElectron)() ? "h-[calc(100vh_-_105px)]" : type === "detail" ? "h-[calc(100vh_-_116px)]" : "h-[calc(100vh_-_105px)]"}`, style: { width: branchListWidthNum + 1 } },
        react_1.default.createElement("div", { className: "flex items-start justify-center gap-[12px] self-stretch px-[12px] pb-[0px] pt-[12px]" },
            react_1.default.createElement(web_react_1.Input, { className: "rounded-lg border border-solid border-border-2", allowClear: true, placeholder: (0, i18next_1.t)("detail.topBar.currentBranch.placeholder"), onChange: (value) => setSearchTerm(value) })),
        react_1.default.createElement("div", { className: "relative flex flex-col gap-[3px] self-stretch overflow-y-auto overflow-x-hidden pl-1" }, filteredBranchLists.map((list, idx) => (react_1.default.createElement(react_1.Fragment, { key: idx },
            react_1.default.createElement("div", { className: "mb-[3px] px-[12px] text-[14px] font-bold text-text-1" }, (0, i18next_1.t)("detail.topBar.currentBranch.title")),
            react_1.default.createElement("div", { className: "relative flex cursor-pointer items-center justify-between px-[12px] py-[3px] pr-[8px] text-[14px] text-text-1 hover:bg-primary-1" },
                react_1.default.createElement("div", { className: "flex items-center gap-2" },
                    react_1.default.createElement(icon_1.IconBranch, null),
                    react_1.default.createElement("span", { className: `hover-truncate-off truncate`, style: { width: branchTextWidth } }, currentCodebase.git.current_branch))),
            react_1.default.createElement("div", { className: "mb-[3px] mt-[12px] px-[12px] text-[14px] font-bold text-text-1" }, list.title),
            list.items.map((item, id) => (react_1.default.createElement("div", { className: "relative flex cursor-pointer items-center justify-between px-[12px] py-[3px] pr-[8px] text-[14px] text-text-1 hover:bg-primary-1", key: id, onClick: () => handleClick(item) },
                react_1.default.createElement("div", { className: "flex items-center gap-2" },
                    react_1.default.createElement("div", null, item.icon),
                    react_1.default.createElement("span", { className: `hover-truncate-off truncate`, style: { width: branchTextWidth } }, item.branchName)))))))))));
};
exports.BranchDropdown = BranchDropdown;
