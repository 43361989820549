"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.postGlobalSearch = void 0;
const codebaseProjectApi_1 = require("@src/api/codebaseApi/codebaseProjectApi");
const { postCodeSearch } = codebaseProjectApi_1.projectApi;
const postGlobalSearch = async (searchQuery, searchType, currentCodebase, page = 1) => {
    try {
        const res = await postCodeSearch({
            project_uuid: currentCodebase.default_project_uuid,
            search_content: searchQuery,
            search_method: searchType === "keyword" ? "full_text_search" : "semantic_code_search",
            start_index: 0,
            end_index: 10,
            page: page,
        });
        const currentResults = res === null || res === void 0 ? void 0 : res.data.code_search_results;
        // Preload next page results
        const nextRes = await postCodeSearch({
            project_uuid: currentCodebase.default_project_uuid,
            search_content: searchQuery,
            search_method: searchType === "keyword" ? "full_text_search" : "semantic_code_search",
            start_index: 0,
            end_index: 10,
            page: page + 1,
        });
        const nextPageResults = nextRes === null || nextRes === void 0 ? void 0 : nextRes.data.code_search_results;
        return {
            currentResults,
            nextPageResults,
        };
    }
    catch (error) {
        console.error("Error during search:", error);
        throw error;
    }
};
exports.postGlobalSearch = postGlobalSearch;
