"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jotai_1 = require("jotai");
const cbDetailAtom_1 = require("@src/store/cbDetailAtom");
// 自定义 Hook 返回 detailHomeWidth
function useDetailHomeWidth() {
    const [isShowReadme] = (0, jotai_1.useAtom)(cbDetailAtom_1.isShowReadmeAtom);
    const detailHomeWidth = isShowReadme ? "404px" : "613px";
    return detailHomeWidth;
}
exports.default = useDetailHomeWidth;
