"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const web_react_1 = require("@arco-design/web-react");
const icon_1 = require("@arco-design/web-react/icon");
const i18next_1 = require("i18next");
const jotai_1 = require("jotai");
const react_1 = __importStar(require("react"));
const useCreateResearchResItem_1 = require("@src/hooks/useCreateResearchResItem");
const useExplainSSE_1 = require("@src/hooks/useExplainSSE");
const codebaseResearchAtom_1 = require("@src/store/codebaseResearchAtom");
const workspaceAtom_1 = require("@src/store/workspaceAtom");
const MainAskItem = ({ title, type, outerWidth, outerHeight, }) => {
    const createResearchResItem = (0, useCreateResearchResItem_1.useCreateResearchResItem)();
    const { getResearchResultSSE } = (0, useExplainSSE_1.useChatSSE)(() => { });
    const [curInputValue, setInputValue] = (0, react_1.useState)("");
    const [researchInputValue, setResearchInputValue] = (0, jotai_1.useAtom)(codebaseResearchAtom_1.researchInputValueAtom);
    const [, setResearchResItems] = (0, jotai_1.useAtom)(codebaseResearchAtom_1.researchResultHistoryAtom);
    const [, setWaitingStatus] = (0, jotai_1.useAtom)(workspaceAtom_1.waitingStatusAtom);
    const [isResearchSubmitLoading, setIsResearchSubmitLoading] = (0, jotai_1.useAtom)(codebaseResearchAtom_1.isResearchSubmitLoadingAtom);
    const [controller, setController] = (0, react_1.useState)();
    const handleInputChange = (value) => {
        setInputValue(value);
    };
    const isLoading = type === "input" && isResearchSubmitLoading;
    //console.log("researchInputValue", isLoading, type, isResearchSubmitLoading);
    const handleSend = async () => {
        let answer = "";
        let inputValue = type === "input" ? curInputValue : title;
        if (inputValue.trim() === "") {
            web_react_1.Message.warning("Please enter a question.");
            return;
        }
        setIsResearchSubmitLoading(true);
        setInputValue("");
        setResearchResItems((prev) => [
            ...prev,
            createResearchResItem(inputValue, true, { "1": { title: "", url: "", snippet: "" } }, []),
        ]);
        setResearchResItems((prevResearchResItems) => [
            ...prevResearchResItems,
            createResearchResItem(answer || researchInputValue, false, { "1": { title: "", url: "", snippet: "" } }, []),
        ]);
        setWaitingStatus("waitResearching");
        const controller = getResearchResultSSE(inputValue || researchInputValue);
        setController(controller);
    };
    (0, react_1.useEffect)(() => {
        return () => {
            controller === null || controller === void 0 ? void 0 : controller.abort();
        };
    }, []);
    return (react_1.default.createElement("div", { className: `flex items-center justify-between self-stretch rounded-[8px] border border-solid border-border-2 bg-fill-1 p-3 w-[${outerWidth}px] `, style: {
            height: outerHeight,
        } },
        type === "input" && (react_1.default.createElement(web_react_1.Input, { disabled: isLoading, className: "w-[calc(100%-68px)]", placeholder: (0, i18next_1.t)("research.content.askAFollowUpQuestion"), value: curInputValue, onChange: handleInputChange })),
        type === "ask" && (react_1.default.createElement(web_react_1.Tooltip, { content: title },
            react_1.default.createElement("div", { className: "overflow-hidden text-ellipsis whitespace-nowrap text-[14px] font-medium text-text-1" }, title))),
        isLoading ? (react_1.default.createElement("div", { className: "mt-[3px] flex h-[16px] w-[16px] items-center justify-center" },
            react_1.default.createElement(web_react_1.Spin, { className: "text-text-2" }))) : (react_1.default.createElement(icon_1.IconSend, { className: "h-[16px] w-[16px] cursor-pointer text-text-2", onClick: handleSend }))));
};
exports.default = MainAskItem;
