"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useChatControl = void 0;
const jotai_1 = require("jotai");
const fileAtom_1 = require("@src/store/fileAtom");
const useChatControl = () => {
    const [isShowChat, setIsShowChat] = (0, jotai_1.useAtom)(fileAtom_1.isShowChatAtom);
    const setChatMode = async (visible) => {
        // const apiKey = user.openai_api_key;
        // if (!apiKey && selectedkeyType !== "AtlasKey") {
        //   Message.error(t("chatModel.errorMsg.APIkey"));
        //   return;
        // }
        setIsShowChat(visible);
    };
    return { setChatMode, isShowChat };
};
exports.useChatControl = useChatControl;
