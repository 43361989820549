// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.codebase-home-collapse .arco-collapse-item-header {
    margin-bottom: 1rem;
    justify-content: normal;
    gap: 0.75rem;
    border-style: none;
    background-color: transparent;
    padding: 0px
}
.codebase-home-collapse .arco-collapse-item-header .arco-icon-hover {
    position: relative;
    left: 0px;
    top: 0px;
    transform: none
}
.codebase-home-collapse .arco-collapse-item-header .arco-icon-hover.arco-collapse-item-icon-hover:hover::before {
    background-color: transparent
}
.codebase-home-collapse .arco-collapse-item-header .arco-collapse-item-header-title {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5rem
}
.codebase-home-collapse .arco-collapse-item-active > .arco-collapse-item-header {
    background-color: transparent
}
.codebase-home-collapse .arco-collapse-item-content {
    background-color: transparent
}
.codebase-home-collapse .arco-collapse-item-content .arco-collapse-item-content-box {
    padding: 0px
}

.card {
    width: calc((100% - 1.5rem) / 2);
    flex: 0 0 calc((100% - 1.5rem) / 2);
    overflow: hidden;
    border-radius: 0.25rem;
    background-color: var(--color-fill-1)
}

.card:hover {
    background-color: var(--color-fill-3)
}

@media (min-width: 960px) {
    .card {
        width: calc((100% - 3rem) / 3);
        flex: 0 0 calc((100% - 3rem) / 3)
    }
}

@media (min-width: 1280px) {
    .card {
        width: calc((100% - 4.5rem) / 4);
        flex: 0 0 calc((100% - 4.5rem) / 4)
    }
}

@media (min-width: 1800px) {
    .card {
        width: calc((100% - 6rem) / 5);
        flex: 0 0 calc((100% - 6rem) / 5)
    }
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Home/index.scss"],"names":[],"mappings":"AAEI;IAAA,mBAAA;IAAA,uBAAA;IAAA,YAAA;IAAA,kBAAA;IAAA,6BAAA;IAAA;AAAA;AAGE;IAAA,kBAAA;IAAA,SAAA;IAAA,QAAA;IAAA;AAAA;AAGE;IAAA;AAAA;AAKF;IAAA,eAAA;IAAA,gBAAA;IAAA;AAAA;AAKA;IAAA;AAAA;AAIF;IAAA;AAAA;AAGE;IAAA;AAAA;;AAOJ;IAAA,gCAAA;IAAA,mCAAA;IAAA,gBAAA;IAAA,sBAAA;IAAA;AAAA;;AAAA;IAAA;AAAA;;AAAA;IAAA;QAAA,8BAAA;QAAA;IAAA;AAAA;;AAAA;IAAA;QAAA,gCAAA;QAAA;IAAA;AAAA;;AAAA;IAAA;QAAA,8BAAA;QAAA;IAAA;AAAA","sourcesContent":[".codebase-home-collapse {\n  .arco-collapse-item-header {\n    @apply mb-4 justify-normal gap-3 border-none bg-transparent p-0;\n\n    .arco-icon-hover {\n      @apply relative left-0 top-0 transform-none;\n\n      &.arco-collapse-item-icon-hover:hover::before {\n        @apply bg-transparent;\n      }\n    }\n\n    .arco-collapse-item-header-title {\n      @apply text-[18px] font-normal leading-6;\n    }\n  }\n  .arco-collapse-item-active {\n    > .arco-collapse-item-header {\n      @apply bg-transparent;\n    }\n  }\n  .arco-collapse-item-content {\n    @apply bg-transparent;\n\n    .arco-collapse-item-content-box {\n      @apply p-0;\n    }\n  }\n\n}\n\n.card {\n  @apply w-[calc((100%-1.5rem)/2)] flex-[0_0_calc((100%-1.5rem)/2)]\n  overflow-hidden rounded bg-fill-1 hover:bg-fill-3\n  lg:w-[calc((100%-3rem)/3)] lg:flex-[0_0_calc((100%-3rem)/3)]\n  xl:w-[calc((100%-4.5rem)/4)] xl:flex-[0_0_calc((100%-4.5rem)/4)]\n  min-[1800px]:w-[calc((100%-6rem)/5)] min-[1800px]:flex-[0_0_calc((100%-6rem)/5)];\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
