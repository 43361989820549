// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.worklog-collapse .arco-collapse-item-content-box {
    padding: 0px
}
.worklog-collapse .arco-collapse-item-header {
    height: 2.75rem;
    background-color: var(--color-fill-1)
}
.worklog-collapse .arco-collapse-item-header-icon-down {
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.worklog-collapse .arco-btn-text:not(.arco-btn-disabled) {
    color: var(--color-text-2)
}
.worklog-collapse .arco-icon-hover {
    left: 1rem
}
.worklog-collapse .button-white {
    height: 2rem;
    border-radius: 9999px;
    border-color: var(--color-border-2);
    background-color: var(--color-bg-2);
    color: var(--color-text-2)
}
.worklog-collapse .arco-breadcrumb {
    font-size: 14px
}
.worklog-collapse .footer_confirm .arco-btn {
    border-radius: 0.5rem
}
.worklog-collapse .footer_confirm .arco-btn-text:not(.arco-btn-disabled) {
    border-radius: 0.5rem;
    border-color: var(--color-border-2)
}
.worklog-collapse .footer .arco-btn-secondary {
    border-radius: 9999px;
    border-color: var(--color-border-2);
    background-color: transparent;
    color: var(--color-text-2)
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Workspace/CBWorkspaceCenter/Center/CenterContent/CollapseComponents/WorkLogCollapse/index.scss"],"names":[],"mappings":"AAEI;IAAA;AAAA;AAGA;IAAA,eAAA;IAAA;AAAA;AAGA;IAAA,kBAAA;IAAA;AAAA;AAGA;IAAA;AAAA;AAGA;IAAA;AAAA;AAGA;IAAA,YAAA;IAAA,qBAAA;IAAA,mCAAA;IAAA,mCAAA;IAAA;AAAA;AAGA;IAAA;AAAA;AAIE;IAAA;AAAA;AAGA;IAAA,qBAAA;IAAA;AAAA;AAKA;IAAA,qBAAA;IAAA,mCAAA;IAAA,6BAAA;IAAA;AAAA","sourcesContent":[".worklog-collapse {\n  .arco-collapse-item-content-box {\n    @apply p-0;\n  }\n  .arco-collapse-item-header {\n    @apply h-11 bg-fill-1;\n  }\n  .arco-collapse-item-header-icon-down {\n    @apply rotate-90;\n  }\n  .arco-btn-text:not(.arco-btn-disabled) {\n    @apply text-text-2;\n  }\n  .arco-icon-hover {\n    @apply left-4;\n  }\n  .button-white {\n    @apply h-8 rounded-full border-border-2 bg-bg-2 text-text-2;\n  }\n  .arco-breadcrumb {\n    @apply text-[14px];\n  }\n  .footer_confirm {\n    .arco-btn {\n      @apply rounded-lg;\n    }\n    .arco-btn-text:not(.arco-btn-disabled) {\n      @apply rounded-lg border-border-2;\n    }\n  }\n  .footer {\n    .arco-btn-secondary {\n      @apply rounded-full border-border-2 bg-transparent text-text-2;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
