// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project__white__list__modal .arco-modal-content {
    max-height: 536px;
    overflow-y: scroll
}
.project__white__list__modal .arco-modal-footer {
    height: 54px;
    padding: 0.75rem
}
.project__white__list__modal .arco-modal-title .arco-icon {
    cursor: pointer;
    font-size: 16px
}`, "",{"version":3,"sources":["webpack://./src/components/ProjectWhiteListModal/index.scss"],"names":[],"mappings":"AAEI;IAAA,iBAAA;IAAA;AAAA;AAGA;IAAA,YAAA;IAAA;AAAA;AAIE;IAAA,eAAA;IAAA;AAAA","sourcesContent":[".project__white__list__modal {\n  .arco-modal-content {\n    @apply max-h-[536px] overflow-y-scroll;\n  }\n  .arco-modal-footer {\n    @apply h-[54px] p-3;\n  }\n  .arco-modal-title {\n    .arco-icon {\n      @apply cursor-pointer text-[16px];\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
