"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isShowCommunityRepoOverviewAtom = exports.projectListLoadingAtom = exports.projectListAtom = exports.isShowReadmeAtom = exports.isShowProjectListAtom = exports.dropTopAtom = exports.dropStatusAtom = void 0;
const jotai_1 = require("jotai");
exports.dropStatusAtom = (0, jotai_1.atom)(false);
exports.dropTopAtom = (0, jotai_1.atom)(-1);
exports.isShowProjectListAtom = (0, jotai_1.atom)(false);
exports.isShowReadmeAtom = (0, jotai_1.atom)(false);
exports.projectListAtom = (0, jotai_1.atom)([]);
exports.projectListLoadingAtom = (0, jotai_1.atom)(false);
exports.isShowCommunityRepoOverviewAtom = (0, jotai_1.atom)(false);
