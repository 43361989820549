"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getKeyTypeFromSelectedParentItem = exports.getLLMTypeFromSelectedItem = void 0;
exports.getGlobalSSEHeaders = getGlobalSSEHeaders;
exports.getGlobalCommonHeaders = getGlobalCommonHeaders;
var LLMType;
(function (LLMType) {
    LLMType["DEEPSEEK"] = "deepseek";
    LLMType["OPENAI"] = "openai";
    // Claude = "Claude-3.5",
    // Llama3 = "Llama3",
})(LLMType || (LLMType = {}));
var KeyType;
(function (KeyType) {
    KeyType["atlas"] = "atlas";
    KeyType["on_prem"] = "on_prem";
})(KeyType || (KeyType = {}));
const getLLMTypeFromSelectedItem = () => {
    const selectedItem = localStorage.getItem("selectedParentItem");
    switch (selectedItem) {
        case "GPT4o":
            return LLMType.OPENAI;
        case "Claude-3.5":
            return LLMType.OPENAI;
        case "Llama3":
            return LLMType.OPENAI;
        default:
            return LLMType.DEEPSEEK;
    }
};
exports.getLLMTypeFromSelectedItem = getLLMTypeFromSelectedItem;
const getKeyTypeFromSelectedParentItem = () => {
    const selectedParentItem = localStorage.getItem("selectedItem");
    const openaiApiKey = localStorage.getItem("openai_api_key");
    const deepseekApiKey = localStorage.getItem("deepseek_api_key");
    // if (!openaiApiKey && !deepseekApiKey) {
    //   return KeyType.atlas;
    // }
    return selectedParentItem === "AtlasKey" ? KeyType.atlas : KeyType.on_prem;
};
exports.getKeyTypeFromSelectedParentItem = getKeyTypeFromSelectedParentItem;
function getGlobalSSEHeaders() {
    const selectedItem = localStorage.getItem("selectedParentItem") || LLMType.DEEPSEEK;
    return {
        "Content-Type": "application/json",
        ...(localStorage.getItem("id_token") && {
            Authorization: `Bearer ${localStorage.getItem("id_token")}`,
        }),
        "X-key-type": (0, exports.getKeyTypeFromSelectedParentItem)(),
        "X-llm-type": (0, exports.getLLMTypeFromSelectedItem)(),
    };
}
function getGlobalCommonHeaders() {
    const selectedItem = localStorage.getItem("selectedParentItem") || LLMType.DEEPSEEK;
    return {
        ...(localStorage.getItem("id_token") && {
            Authorization: `Bearer ${localStorage.getItem("id_token")}`,
        }),
        "X-key-type": (0, exports.getKeyTypeFromSelectedParentItem)(),
        "X-llM-type": (0, exports.getLLMTypeFromSelectedItem)(),
    };
}
