"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const web_react_1 = require("@arco-design/web-react");
const react_1 = __importStar(require("react"));
const isElectron_1 = require("@src/util/isElectron");
const CheckboxGroup = web_react_1.Checkbox.Group;
const FilterList = ({ list, selected, setSelected, rounded = false, }) => {
    const [initialized, setInitialized] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        if (!initialized && list.length > 0) {
            setSelected(list.map((item) => item.key));
            setInitialized(true);
        }
    }, [list, initialized, setSelected]);
    return (react_1.default.createElement("div", { className: `flex 
      border-b border-l border-r border-solid border-border-2 bg-bg-1 px-2  
      ${rounded ? "rounded-bl-[11px]" : ""}
      ${(0, isElectron_1.isElectron)() ? "h-[calc(100vh_-_170px)]" : "h-[calc(100vh_-_170px)]"}` },
        react_1.default.createElement(CheckboxGroup, { value: selected, onChange: (values) => setSelected(values), direction: "vertical", className: "w-full [&_label.arco-radio]:leading-9" }, list.map((item, i) => (react_1.default.createElement(web_react_1.Checkbox, { key: i, className: `flex items-center border-b border-solid border-border-2 relative${i === list.length - 1 ? "border-b-0" : ""}`, value: item.key, disabled: item.disabled },
            react_1.default.createElement("div", { className: "flex items-center gap-20" },
                react_1.default.createElement("div", { className: "ml-2" }, item.title),
                react_1.default.createElement("div", { className: "absolute right-4 text-text-1" }, item.count))))))));
};
exports.default = FilterList;
