"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const en = {
    overview: {
        open: "Open",
        review: "Review",
        exploreWithAtlas: "Explore repo with Atlas",
        takeATour: "Take a guided tour",
        takeATourButton: "Take tour",
        about: "About",
        discussion: "Discussion",
        reference: "Reference",
        summary: "Summary",
        language: "Language",
        viewOnGithub: "View on GitHub",
        commentExceed: "Comment cannot exceed 255 characters.",
        whatDoyouThink: "What do you think?",
    },
};
const zh = {
    overview: {
        open: "打开",
        review: "讨论",
        exploreWithAtlas: "用Atlas探索仓库",
        takeATour: "上手指南",
        takeATourButton: "上手指南",
        about: "仓库概况",
        discussion: "讨论",
        reference: "相关链接",
        summary: "简介",
        language: "语言",
        viewOnGithub: "GitHub页面",
        commentExceed: "评论不能超过255个字符。",
        whatDoyouThink: "说出你的想法",
    },
};
exports.default = {
    en,
    zh,
};
