// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arco-btn.arco-btn-secondary.i-18-button {
    position: absolute;
    bottom: 1.5rem;
    border-radius: 0.375rem;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-border-2)
}`, "",{"version":3,"sources":["webpack://./src/page/Login/SelectLanguage/index.scss"],"names":[],"mappings":"AACE;IAAA,kBAAA;IAAA,cAAA;IAAA,uBAAA;IAAA,iBAAA;IAAA,mBAAA;IAAA;AAAA","sourcesContent":[".arco-btn.arco-btn-secondary.i-18-button {\n  @apply absolute bottom-6 rounded-md border border-solid border-border-2;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
