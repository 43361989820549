"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldExecuteFinalAtom = exports.curShowQueryCodeWorklogAtom = exports.hasSelectedFilesAtom = exports.chatWhitelistAtom = exports.isChineseModeAtom = exports.triggerResultsClickAtom = exports.isSelectedTaskAtom = exports.isbtnExplainingAtom = exports.workFocusFilesAtom = exports.isExplainModeAtom = exports.isExamingFocusFileAtom = exports.isExplainingAtom = exports.alignmentStateAtom = exports.isErrorRollbackAtom = exports.isEditingUserChatItemAtom = exports.submitEditTriggerAtom = exports.editedTextAtom = exports.isInputDisabledAtom = exports.isbtnloadingAtom = exports.retryResponseAtom = exports.retryUuidAtom = exports.retryTriggerAtom = exports.acceptTriggerAtom = exports.AcceptFocusFilesResponseAtom = exports.segmentsArrayAtom = exports.AISuggestFocusFilesAtom = exports.selectedFilesAtom = exports.curChatResultIndexAtom = exports.curChatResultUuIdAtom = exports.curChatResultBtnIdAtom = exports.curChatResultAtom = exports.chatLeftResultAtom = exports.ChatResultsAtom = exports.curUnderstandTaskAtom = exports.isChatHistoryAtom = exports.chatHistoryAtom = exports.isAiSuggestionAtom = exports.isSubmitEditFocusFilesAtom = exports.isEditFocusFilesAtom = exports.isChatResultAtom = exports.isSelectFilesBoxVisibleAtom = exports.curSelectFileNameAtom = exports.isChatModelExpandAtom = exports.selectedCiteRangeAtom = exports.selectedCiteTextAtom = exports.relatedDataAtom = exports.explainAtom = exports.codebaseChatTaskAtom = exports.cbnewtaskIdAtom = exports.cbChatUploadFilesAtom = void 0;
exports.isCiteCodeAtom = exports.isAskKeySettingVisibleAtom = exports.isAskKeyModalVisibleAtom = exports.isChatSearchModeAtom = void 0;
const jotai_1 = require("jotai");
//cbchattask 上传的文件
exports.cbChatUploadFilesAtom = (0, jotai_1.atom)([]);
//cbchatNewtask
exports.cbnewtaskIdAtom = (0, jotai_1.atom)("");
//所有cbchattask
exports.codebaseChatTaskAtom = (0, jotai_1.atom)([]);
//是否直接explain代码
exports.explainAtom = (0, jotai_1.atom)(false);
//chatResult
exports.relatedDataAtom = (0, jotai_1.atom)(null);
//选中的code行数和内容
exports.selectedCiteTextAtom = (0, jotai_1.atom)("");
exports.selectedCiteRangeAtom = (0, jotai_1.atom)(null);
// 是否展开Chat Model
exports.isChatModelExpandAtom = (0, jotai_1.atom)(false);
//当前选中文件
exports.curSelectFileNameAtom = (0, jotai_1.atom)("");
//是否展开select focus files
exports.isSelectFilesBoxVisibleAtom = (0, jotai_1.atom)(false);
//isChatResult
exports.isChatResultAtom = (0, jotai_1.atom)(false);
//isShowEditFocusFiles
exports.isEditFocusFilesAtom = (0, jotai_1.atom)(false);
//isSubmitEditFocusFiles
exports.isSubmitEditFocusFilesAtom = (0, jotai_1.atom)(false);
//isAISuggestion
exports.isAiSuggestionAtom = (0, jotai_1.atom)(false);
//chatHistory
exports.chatHistoryAtom = (0, jotai_1.atom)([]);
//isChatHistory
exports.isChatHistoryAtom = (0, jotai_1.atom)(false);
//curUnderstandTask
exports.curUnderstandTaskAtom = (0, jotai_1.atom)("");
//curUnderstandTask_ChatResult
exports.ChatResultsAtom = (0, jotai_1.atom)([]);
// leftresumt
exports.chatLeftResultAtom = (0, jotai_1.atom)({});
//curresult
exports.curChatResultAtom = (0, jotai_1.atom)([]);
//curresultbtn
exports.curChatResultBtnIdAtom = (0, jotai_1.atom)(null);
//curresultuuid
exports.curChatResultUuIdAtom = (0, jotai_1.atom)("");
//curresultindex
exports.curChatResultIndexAtom = (0, jotai_1.atom)(0);
//focusFiles
exports.selectedFilesAtom = (0, jotai_1.atom)([]);
//AISuggestFocusFiles
exports.AISuggestFocusFilesAtom = (0, jotai_1.atom)([]);
//Segmentchat
exports.segmentsArrayAtom = (0, jotai_1.atom)([]);
//AcceptFocusFilesResponse
exports.AcceptFocusFilesResponseAtom = (0, jotai_1.atom)({
    chat: {},
    result_uuid: "",
    title: "",
    foucs_files: [],
});
//isacceptfocusfile
exports.acceptTriggerAtom = (0, jotai_1.atom)(false);
//retryTrigger
exports.retryTriggerAtom = (0, jotai_1.atom)(false);
//retryUuid
exports.retryUuidAtom = (0, jotai_1.atom)("");
//retryRes
exports.retryResponseAtom = (0, jotai_1.atom)({
    chat: {},
    result_uuid: "",
    title: "",
    foucs_files: [],
});
//ischatbtnloading
exports.isbtnloadingAtom = (0, jotai_1.atom)(false);
//isBanInput
exports.isInputDisabledAtom = (0, jotai_1.atom)(false);
// store the edited text
exports.editedTextAtom = (0, jotai_1.atom)("");
//trigger the after edit submit action
exports.submitEditTriggerAtom = (0, jotai_1.atom)(0);
exports.isEditingUserChatItemAtom = (0, jotai_1.atom)(false);
exports.isErrorRollbackAtom = (0, jotai_1.atom)(false);
//alignmentstate
exports.alignmentStateAtom = (0, jotai_1.atom)(false);
exports.isExplainingAtom = (0, jotai_1.atom)(false);
exports.isExamingFocusFileAtom = (0, jotai_1.atom)(false);
exports.isExplainModeAtom = (0, jotai_1.atom)(false);
exports.workFocusFilesAtom = (0, jotai_1.atom)([]);
exports.isbtnExplainingAtom = (0, jotai_1.atom)(false);
exports.isSelectedTaskAtom = (0, jotai_1.atom)(false);
exports.triggerResultsClickAtom = (0, jotai_1.atom)(false);
//中文模式
exports.isChineseModeAtom = (0, jotai_1.atom)(false);
exports.chatWhitelistAtom = (0, jotai_1.atom)([]);
exports.hasSelectedFilesAtom = (0, jotai_1.atom)(false);
//querycode worklog
exports.curShowQueryCodeWorklogAtom = (0, jotai_1.atom)([]);
//
exports.shouldExecuteFinalAtom = (0, jotai_1.atom)(false);
//search mode
exports.isChatSearchModeAtom = (0, jotai_1.atom)(false);
exports.isAskKeyModalVisibleAtom = (0, jotai_1.atom)(false);
exports.isAskKeySettingVisibleAtom = (0, jotai_1.atom)(false);
//iscitecode
exports.isCiteCodeAtom = (0, jotai_1.atom)(false);
