"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.openExternalLink = exports.maxWindow = exports.minWindow = exports.closeWindow = exports.openInExternalEditor = exports.viewOnGitHub = exports.showInFinder = void 0;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const electronAPI = window.electronAPI;
const showInFinder = (filePath) => {
    electronAPI.showInFinder(filePath);
};
exports.showInFinder = showInFinder;
const viewOnGitHub = (repoUrl) => {
    electronAPI.viewOnGitHub(repoUrl);
};
exports.viewOnGitHub = viewOnGitHub;
const openInExternalEditor = (filePath) => {
    electronAPI.openInExternalEditor(filePath);
};
exports.openInExternalEditor = openInExternalEditor;
const closeWindow = () => {
    electronAPI.closeWindow();
};
exports.closeWindow = closeWindow;
const minWindow = () => {
    electronAPI.minWindow();
};
exports.minWindow = minWindow;
const maxWindow = () => {
    electronAPI.maxWindow();
};
exports.maxWindow = maxWindow;
const openExternalLink = (url) => {
    electronAPI.openExternalLink(url);
};
exports.openExternalLink = openExternalLink;
