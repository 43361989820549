// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.res__side__bar::-webkit-scrollbar {
  display: none;
}
.res__side__bar .card_item_box .arco-card-body {
  height: 100%;
  width: 100%;
  padding: 0px;
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Workspace/CBWorkspaceLeft/ResSideBar/index.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ;AAIM;EAAA,YAAA;EAAA,WAAA;EAAA;AAAA","sourcesContent":[".res__side__bar {\n  &::-webkit-scrollbar {\n    display: none;\n  }\n  .card_item_box {\n    .arco-card-body {\n      @apply h-full w-full p-0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
