// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login__modal .arco-modal-content {
    height: 500px
}`, "",{"version":3,"sources":["webpack://./src/components/LoginModal/index.scss"],"names":[],"mappings":"AAEI;IAAA;AAAA","sourcesContent":[".login__modal {\n  .arco-modal-content {\n    @apply h-[500px];\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
