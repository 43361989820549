"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RepoDropdown = void 0;
const web_react_1 = require("@arco-design/web-react");
const i18next_1 = require("i18next");
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const handleCodeBaseClick_1 = require("@src/util/LinkDifferentSections/handleCodeBaseClick");
const isElectron_1 = require("@src/util/isElectron");
const BranchDropdown_1 = require("./BranchDropdown");
require("./index.scss");
const RepoDropdown = ({ listWidth, repoLists, closeModal, getBrachList, handleSearchClear, handleSearchChange, }) => {
    const handleCodeBaseClick = (0, handleCodeBaseClick_1.useHandleCodeBaseClick)();
    const repoListWidthNum = (0, BranchDropdown_1.pickWidthNum)(listWidth);
    const repoTextWidth = repoListWidthNum - 64;
    const navigate = (0, react_router_dom_1.useNavigate)();
    const handleClick = async (codebaseInfo) => {
        const updatedCodebaseInfo = await handleCodeBaseClick(codebaseInfo, "trending");
        await getBrachList(codebaseInfo.id);
        navigate("/codebase/launchpad");
        closeModal();
    };
    return (react_1.default.createElement("div", { className: `CBdetailTop-arco-styles flex
       w-[${listWidth}] -translate-y-1
      flex-col items-start gap-[12px] self-stretch
      rounded-bl-[11px] border-r
      border-solid
      border-border-2 bg-fill-1 pb-0
      ${(0, isElectron_1.isElectron)() ? "h-[calc(100vh_-_116px)]" : "h-[calc(100vh_-_116px)]"}`, style: { width: repoListWidthNum } },
        react_1.default.createElement("div", { className: "flex items-start justify-center gap-[12px] self-stretch px-[12px] pb-[0px] pt-[12px]" },
            react_1.default.createElement(web_react_1.Input, { className: "rounded-lg border border-solid border-border-2", allowClear: true, placeholder: (0, i18next_1.t)("detail.topBar.currentRepo.placeholder"), onClear: handleSearchClear, onChange: handleSearchChange })),
        react_1.default.createElement("div", { className: "relative flex flex-col gap-[3px] self-stretch overflow-y-auto overflow-x-hidden" }, repoLists.map((list, idx) => (react_1.default.createElement(react_1.Fragment, { key: idx },
            react_1.default.createElement("div", { className: "mb-[3px] mt-[10px] px-[12px] text-[14px] font-bold text-text-1" }, list.title),
            list.items.map((item, id) => (react_1.default.createElement("div", { className: "relative flex cursor-pointer items-center justify-between px-[12px] py-[3px] pr-[8px] text-[14px] text-text-1 hover:bg-primary-1", key: id, onClick: () => handleClick(item.codebaseInfo) },
                react_1.default.createElement("div", { className: "flex items-center gap-2" },
                    react_1.default.createElement("div", null, item.icon),
                    react_1.default.createElement("span", { className: `hover-truncate-off truncate`, style: { width: repoTextWidth } }, item.codebaseInfo.name)))))))))));
};
exports.RepoDropdown = RepoDropdown;
