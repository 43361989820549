"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isShowWaitingListModalAtom = exports.curSuggestionResearchAtom = exports.researchTriggerAtom = exports.isEntrySearchAtom = exports.researchInputValueAtom = exports.researchGroupAtom = exports.researchCategoryAtom = exports.isResearchSubmitLoadingAtom = exports.researchResultHistoryAtom = exports.researchResultAtom = void 0;
const jotai_1 = require("jotai");
exports.researchResultAtom = (0, jotai_1.atom)(null);
exports.researchResultHistoryAtom = (0, jotai_1.atom)([]);
exports.isResearchSubmitLoadingAtom = (0, jotai_1.atom)(false);
exports.researchCategoryAtom = (0, jotai_1.atom)("generalInquiry");
exports.researchGroupAtom = (0, jotai_1.atom)("global");
exports.researchInputValueAtom = (0, jotai_1.atom)("");
exports.isEntrySearchAtom = (0, jotai_1.atom)(false);
exports.researchTriggerAtom = (0, jotai_1.atom)(0);
exports.curSuggestionResearchAtom = (0, jotai_1.atom)("");
exports.isShowWaitingListModalAtom = (0, jotai_1.atom)(false);
