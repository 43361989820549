"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCurrentTheme = void 0;
const jotai_1 = require("jotai");
const allAtom_1 = require("@src/store/allAtom");
const useCurrentTheme = () => {
    const [theme] = (0, jotai_1.useAtom)(allAtom_1.themesAtom);
    let themeName;
    let isDark;
    switch (theme) {
        case "/yorg_dark.css":
            themeName = "dark";
            isDark = true;
            break;
        case "/yorg_violet.css":
            themeName = "violet";
            isDark = false;
            break;
        default:
            themeName = "main";
            isDark = false;
            break;
    }
    return { theme: themeName, isDark };
};
exports.useCurrentTheme = useCurrentTheme;
