// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home__top__search .arco-input-inner-wrapper {
  flex-grow: 1;
  border-style: none;
  background-color: transparent !important
}
.home__top__search .arco-input-inner-wrapper:focus {
  background-color: transparent
}
.home__top__search .arco-input-inner-wrapper:hover {
  border-style: none;
  background-color: transparent
}
.home__top__search .arco-input-group-addafter .arco-btn-primary {
  background-color: transparent;
  padding: 0px;
  color: var(--color-text-2)
}
.home__top__search .arco-input-group-addafter .arco-btn-primary:hover {
  background-color: transparent;
  color: var(--color-text-2)
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Home/Trending/TopSearch/index.scss"],"names":[],"mappings":"AAEI;EAAA,YAAA;EAAA,kBAAA;EACA;AADA;AAGE;EAAA;AAAA;AAGA;EAAA,kBAAA;EAAA;AAAA;AAKA;EAAA,6BAAA;EAAA,YAAA;EAAA;AAAA;AAGE;EAAA,6BAAA;EAAA;AAAA","sourcesContent":[".home__top__search {\n  .arco-input-inner-wrapper {\n    @apply flex-grow  border-none bg-transparent;\n    background-color: transparent !important;\n    &:focus {\n      @apply bg-transparent;\n    }\n    &:hover {\n      @apply border-none bg-transparent;\n    }\n  }\n  .arco-input-group-addafter {\n    .arco-btn-primary {\n      @apply bg-transparent p-0 text-text-2;\n\n      &:hover {\n        @apply bg-transparent text-text-2;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
