"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.verifyGitlabPublicRepo = exports.createGitLabAccount = void 0;
const apiConfigV2_1 = require("./apiConfigV2");
const url = "/api/v2/gitlab";
const createGitLabAccount = (params) => {
    return (0, apiConfigV2_1.getApi)(url + "/fetch_token", params, true);
};
exports.createGitLabAccount = createGitLabAccount;
const verifyGitlabPublicRepo = (params) => {
    return (0, apiConfigV2_1.getApi)(url + "/get_public_repo_info", params, true);
};
exports.verifyGitlabPublicRepo = verifyGitlabPublicRepo;
