"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const web_react_1 = require("@arco-design/web-react");
const icon_1 = require("@arco-design/web-react/icon");
const i18next_1 = require("i18next");
const jotai_1 = require("jotai");
const react_1 = __importStar(require("react"));
const allAtom_1 = require("@src/store/allAtom");
const codebaseSearchAtom_1 = require("@src/store/codebaseSearchAtom");
const postGlobalSearch_1 = require("@src/util/services/postGlobalSearch");
const searchType = "Key word Matching";
const SearchResultHeader = ({ isAllCollapsed, onCollapseAll, }) => {
    const [curSearchResult, setCurSearchResult] = (0, jotai_1.useAtom)(codebaseSearchAtom_1.curSearchResultAtom);
    const [currentCodebase] = (0, jotai_1.useAtom)(allAtom_1.currentCodebaseAtom);
    const [searchType] = (0, jotai_1.useAtom)(codebaseSearchAtom_1.searchTypeAtom);
    const [curSearchContent] = (0, jotai_1.useAtom)(codebaseSearchAtom_1.curSearchContentAtom);
    const [, setHasSearchResult] = (0, jotai_1.useAtom)(codebaseSearchAtom_1.hasSearchResultAtom);
    const [curNextPageSearchResults, setCurNextPageSearchResults] = (0, jotai_1.useAtom)(codebaseSearchAtom_1.curNextPageSearchResultsAtom);
    const searchTypeText = (0, react_1.useMemo)(() => {
        return searchType === "keyword"
            ? "Key word Matching"
            : "Natural Language Query";
    }, [searchType]);
    const [page, setPage] = (0, react_1.useState)(1);
    const [, setIsSearchLoading] = (0, jotai_1.useAtom)(codebaseSearchAtom_1.isSearchLoadingAtom);
    const fetchResults = async (newPage) => {
        setIsSearchLoading(true);
        try {
            const { currentResults, nextPageResults } = await (0, postGlobalSearch_1.postGlobalSearch)(curSearchContent, searchType, currentCodebase, newPage);
            setCurSearchResult(currentResults !== null && currentResults !== void 0 ? currentResults : []);
            setHasSearchResult((currentResults === null || currentResults === void 0 ? void 0 : currentResults.length) === 0);
            setPage(newPage);
            setCurNextPageSearchResults(nextPageResults !== null && nextPageResults !== void 0 ? nextPageResults : []);
        }
        catch (error) {
            console.error("Error fetching search results:", error);
        }
        finally {
            setIsSearchLoading(false);
        }
    };
    (0, react_1.useEffect)(() => {
        // Preload the second page when the component mounts
        fetchResults(1);
    }, []);
    return (react_1.default.createElement("div", { className: "flex h-[46px] items-center justify-between border border-solid border-border-2 bg-bg-1 p-[12px] pl-4" },
        react_1.default.createElement("div", { className: "flex items-center text-[14px] font-semibold text-text-1" },
            `Showing 1-20 matches, ${new Set(curSearchResult.map((item) => item.file_path)).size} files `,
            " ",
            react_1.default.createElement("div", { className: "ml-2 mt-[2px] flex items-center gap-2" },
                react_1.default.createElement(icon_1.IconArrowLeft, { onClick: () => fetchResults(page - 1), className: page === 1
                        ? "h-[16px] w-[16px] cursor-not-allowed  text-text-3"
                        : "h-[16px] w-[16px] cursor-pointer", style: { pointerEvents: page === 1 ? "none" : "auto" } }),
                react_1.default.createElement(icon_1.IconArrowRight, { onClick: () => fetchResults(page + 1), className: curNextPageSearchResults.length === 0
                        ? "h-[16px] w-[16px] cursor-not-allowed  text-text-3"
                        : "h-[16px] w-[16px] cursor-pointer", style: {
                        pointerEvents: curNextPageSearchResults.length === 0 ? "none" : "auto",
                    } }))),
        react_1.default.createElement(web_react_1.Button, { type: "secondary", className: " mt-[-1px] rounded-[100px] px-[12px] py-[2px] text-[12px]", onClick: onCollapseAll, size: "small" }, isAllCollapsed
            ? (0, i18next_1.t)("chatModel.searchBox.expandAll")
            : (0, i18next_1.t)("chatModel.searchBox.collapseAll"))));
};
exports.default = SearchResultHeader;
