// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fileTree__multi .arco-tree-node-indent-block {
  width: 4px !important;
}
.fileTree__multi .arco-tree-node-switcher {
  margin-right: 2px !important;
}
.fileTree__multi .arco-tree-node-title {
  display: flex;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fileTree__multi .arco-tree-node-title-text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fileTree__multi .arco-tree-node-selected .arco-tree-node-title {
  color: var(--color-text-1);
}`, "",{"version":3,"sources":["webpack://./src/components/FileTreeMulti/index.scss"],"names":[],"mappings":"AACE;EACE,qBAAA;AAAJ;AAEE;EACE,4BAAA;AAAJ;AAGI;EAAA,aAAA;EAAA,WAAA;EAAA,gBAAA;EAAA,uBAAA;EAAA;AAAA;AAGA;EAAA,WAAA;EAAA,gBAAA;EAAA,uBAAA;EAAA;AAAA;AAIE;EAAA;AAAA","sourcesContent":[".fileTree__multi {\n  .arco-tree-node-indent-block {\n    width: 4px !important;\n  }\n  .arco-tree-node-switcher {\n    margin-right: 2px !important;\n  }\n  .arco-tree-node-title {\n    @apply flex w-full overflow-hidden overflow-ellipsis whitespace-nowrap;\n  }\n  .arco-tree-node-title-text {\n    @apply w-full overflow-hidden overflow-ellipsis whitespace-nowrap;\n  }\n  .arco-tree-node-selected {\n    .arco-tree-node-title {\n      @apply text-text-1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
