// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.duplicate-modal-footer .button-primary {
    border-radius: 0.5rem;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(var(--primary-1));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Home/DuplicateModal/index.scss"],"names":[],"mappings":"AAEI;IAAA,qBAAA;IAAA,iBAAA;IAAA,mBAAA;IAAA,mCAAA;IAAA,oBAAA;IAAA;AAAA","sourcesContent":[".duplicate-modal-footer {\n  .button-primary {\n    @apply rounded-lg border border-solid border-primary-1 text-white-100;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
