"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalRepo = void 0;
const web_react_1 = require("@arco-design/web-react");
const icon_1 = require("@arco-design/web-react/icon");
const i18next_1 = require("i18next");
const react_1 = __importStar(require("react"));
require("./index.scss");
const LocalRepo = ({ setCloneInfo, }) => {
    const [value, setValue] = (0, react_1.useState)();
    const onChange = (dirPath) => {
        const clonePathSplit = dirPath.split("/");
        const name = clonePathSplit[clonePathSplit.length - 1];
        setCloneInfo({
            url: dirPath,
            name,
            visible_type: 0,
            owner: "",
        });
    };
    return (react_1.default.createElement("div", { className: "local-repo flex flex-grow flex-col gap-4 px-3 py-4" },
        react_1.default.createElement("label", { className: "flex flex-col gap-2 text-text-2" },
            (0, i18next_1.t)("home.add.local.label"),
            react_1.default.createElement(web_react_1.Input.Group, { className: "flex" },
                react_1.default.createElement(web_react_1.Input, { className: "rounded-lg border border-solid border-border-2 border-r-transparent", allowClear: true, value: value, placeholder: (0, i18next_1.t)("home.add.local.placeholder"), onChange: (dirPath) => {
                        onChange(dirPath);
                        setValue(dirPath);
                    } }),
                react_1.default.createElement(web_react_1.Button, { className: "input-button rounded-l-none", icon: react_1.default.createElement(icon_1.IconRight, null), onClick: async () => {
                        try {
                            const dirPath = await window.electronAPI.selectDirectory();
                            onChange(dirPath);
                            setValue(dirPath);
                        }
                        catch (error) {
                            web_react_1.Message.error((0, i18next_1.t)("home.add.local.failed"));
                        }
                    } }))),
        react_1.default.createElement("div", { className: "flex flex-col gap-2 text-text-2" },
            (0, i18next_1.t)("home.add.local.scan"),
            react_1.default.createElement(web_react_1.Upload, { drag: true, directory: true, showUploadList: false, onChange: (fileList) => {
                    if (fileList.length > 0) {
                        const file = fileList[0].originFile;
                        if (file.path) {
                            const dirPath = file.path.substring(0, file.path.lastIndexOf("/" + file.name));
                            onChange(dirPath);
                            setValue(dirPath);
                        }
                    }
                } },
                react_1.default.createElement("div", { className: "flex flex-col items-center rounded-lg border border-dashed border-gray-300 bg-fill-2 py-8 hover:border-blue-500" },
                    react_1.default.createElement(icon_1.IconPlus, { className: "mb-6" }),
                    react_1.default.createElement("div", { className: "text-text-1" }, (0, i18next_1.t)("home.add.local.clickOrDrag")),
                    react_1.default.createElement("div", { className: "text-xs text-text-3" }, (0, i18next_1.t)("home.add.local.constraint")))))));
};
exports.LocalRepo = LocalRepo;
exports.default = exports.LocalRepo;
