// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.community-repo-overview {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.community-repo-overview .arco-tabs-content {
  padding-top: 0.75rem;
}
.community-repo-overview .arco-btn-size-default {
  border-radius: 6px;
}
.community-repo-overview .arco-comment:not(:first-of-type), .community-repo-overview .arco-comment-inner-comment {
  margin-top: 0px;
}
.community-repo-overview .arco-btn-secondary:not(.arco-btn-disabled) {
  background-color: var(--color-fill-3);
}
.community-repo-overview .arco-textarea:focus, .community-repo-overview .arco-textarea.arco-textarea-focus {
  background-color: transparent;
}
.community-repo-overview .arco-comment-author {
  font-weight: 700;
}
.community-repo-overview .arco-comment-title-align-right {
  align-items: center;
  justify-content: normal;
}
.community-repo-overview .arco-textarea-word-limit {
  top: -6px;
  right: 0px;
}
.community-repo-overview textarea {
  min-height: 90px;
}
.community-repo-overview .arco-comment-datetime {
  margin-top: 2px;
  margin-left: 2px;
}
.community-repo-overview ::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}`, "",{"version":3,"sources":["webpack://./src/components/CommunityRepo/index.scss"],"names":[],"mappings":"AAAA;EAkCE,wBAAA,EAAA,0BAAA;EACA,qBAAA,EAAA,YAAA;AAhCF;AADI;EAAA;AAAA;AAGA;EAAA;AAAA;AAEH;EACG,eAAA;AAIJ;AAFA;EACI,qCAAA;AAIJ;AAAG;EAAA;AAAA;AAGA;EAAA;AAAA;AAGA;EAAA,mBAAA;EAAA;AAAA;AAGH;EACI,SAAA;EACA,UAAA;AAEJ;AACG;EAAA;AAAA;AAGG;EAAA,eAAA;EAAA;AAAA;AAMN;EACE,aAAA,EAAA,sBAAA;AAFF","sourcesContent":[".community-repo-overview {\n .arco-tabs-content {\n    @apply pt-3\n }\n .arco-btn-size-default {\n    @apply rounded-[6px]\n }\n .arco-comment:not(:first-of-type), .arco-comment-inner-comment {\n    margin-top: 0px;\n}\n.arco-btn-secondary:not(.arco-btn-disabled) {\n    background-color: var(--color-fill-3);\n}\n\n.arco-textarea:focus, .arco-textarea.arco-textarea-focus {\n   @apply bg-transparent\n}\n.arco-comment-author {\n   @apply font-bold\n}\n.arco-comment-title-align-right {\n   @apply justify-normal items-center\n\n}\n.arco-textarea-word-limit {\n    top: -6px;\n    right: 0px;\n}\ntextarea {\n   @apply min-h-[90px]\n}\n   .arco-comment-datetime {\n      @apply mt-[2px] ml-[2px]\n   }\n  -ms-overflow-style: none;  /* Internet Explorer 10+ */\n  scrollbar-width: none;  /* Firefox */\n\n\n::-webkit-scrollbar {\n  display: none;  /* Safari and Chrome */\n}\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
