"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const icon_1 = require("@arco-design/web-react/icon");
const i18next_1 = require("i18next");
const jotai_1 = require("jotai");
const react_1 = __importStar(require("react"));
const codebaseResearchAtom_1 = require("@src/store/codebaseResearchAtom");
const Suggestions = () => {
    const containerRef = (0, react_1.useRef)(null);
    const itemRefs = (0, react_1.useRef)([]);
    const [currentIndex, setCurrentIndex] = (0, react_1.useState)(0);
    const [, setResearchTrigger] = (0, jotai_1.useAtom)(codebaseResearchAtom_1.researchTriggerAtom);
    const [, setCurSuggestionResearch] = (0, jotai_1.useAtom)(codebaseResearchAtom_1.curSuggestionResearchAtom);
    const suggestions = (0, i18next_1.t)("research.suggestions.suggestions", {
        returnObjects: true,
    });
    const scrollLeft = () => {
        var _a, _b;
        if (currentIndex > 0) {
            const newIndex = currentIndex - 1;
            setCurrentIndex(newIndex);
            const itemWidth = ((_a = itemRefs.current[newIndex]) === null || _a === void 0 ? void 0 : _a.offsetWidth) || 0;
            (_b = containerRef.current) === null || _b === void 0 ? void 0 : _b.scrollBy({ left: -itemWidth, behavior: "smooth" });
        }
    };
    const scrollRight = () => {
        var _a, _b;
        if (currentIndex < suggestions.length - 1) {
            const newIndex = currentIndex + 1;
            setCurrentIndex(newIndex);
            const itemWidth = ((_a = itemRefs.current[newIndex]) === null || _a === void 0 ? void 0 : _a.offsetWidth) || 0;
            (_b = containerRef.current) === null || _b === void 0 ? void 0 : _b.scrollBy({ left: itemWidth, behavior: "smooth" });
        }
    };
    return (react_1.default.createElement("div", { className: "flex items-center justify-center " },
        react_1.default.createElement(icon_1.IconLeft, { onClick: scrollLeft, className: "mr-6 cursor-pointer text-text-2" }),
        react_1.default.createElement("div", { className: "relative w-full max-w-4xl overflow-hidden text-[14px]", ref: containerRef },
            react_1.default.createElement("div", { className: "flex space-x-6" }, suggestions.map((suggestion, index) => (react_1.default.createElement("div", { key: index, ref: (el) => (itemRefs.current[index] = el), className: "flex flex-shrink-0 items-center space-x-2 whitespace-nowrap rounded-lg border border-solid border-border-2 bg-fill-1 p-2 px-4 text-text-1", onClick: () => {
                    setCurSuggestionResearch(suggestion);
                    setResearchTrigger((pre) => pre + 1);
                } },
                react_1.default.createElement(icon_1.IconSearch, null),
                react_1.default.createElement("div", null, suggestion)))))),
        react_1.default.createElement(icon_1.IconRight, { onClick: scrollRight, className: "ml-6 cursor-pointer text-text-2" })));
};
exports.default = Suggestions;
