"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const en = {
    chatModel: {
        messageInput: {
            entireRepo: "Entire Repo",
            askMode: "Ask",
            ask: "Ask",
            searchMode: "Semantic Search",
            selectedFile: "Selected Files",
            internetSources: "Internet Sources",
            repo: "Repo",
            branch: "Branch",
            generateDetailedAnswer: "Generate detailed answer?",
            forNextStep: "For next step?",
        },
        chatContent: {
            searchType: "Natural Language Query",
            chatType: "Detailed Answer",
            chatResult: "My answers to your question are displayed on the left side of the screen (result page).",
            searchResult: "Search results are displayed on the left side of the screen.",
            generateDetailedAnswer: "I could generate a more detailed line-by-line explanation for you. Do you want me to generate it for you now?",
            prologue: "Hi, I am Atlas! In <1>Query_Code</1> mode you can choose files from your codebase and ask questions about it.",
            yesPlease: "Yes, please",
            noThanks: "No, thanks",
            explainCodeBlock: "Explain this code block for me.",
        },
        searchReady: {
            title: "Enter keyword to search",
        },
        searchBox: {
            reset: "Reset",
            collapseAll: "Collapse all",
            expandAll: "Expand all",
            keywordMatching: "Keyword Matching",
            semanticSearch: "Natural Language Query",
        },
        searchResultItem: {
            preview: "Preview",
        },
        errorMsg: {
            creditNotEnough: "Your credit is not enough to perform such action",
            recharge: "Please recharge",
            APIkey: "API key is not set or No atlaskey selected. Cannot toggle chat visibility.",
        },
        loadingMsg: {
            banOther: "Please wait until Atlas finishes",
        },
        keyNotice: {
            InsufficientCredits: "Insufficient Credits",
            insufficientCreditsTip: "To continue using, you would have to upgrade your subscription or enter your API keys.",
            AddKey: "Add Key",
            Subscribe: "Subscribe",
        },
        alignmentMessages: [
            "OK! I'll explore the repository to find the answer to your question. If I need any further clarification, I'll make sure to ask. Let's ensure we're aligned on this.",
            "I'll review the repository to find the information you need. If I require any additional information, I'll get in touch to make sure our understanding is aligned.",
            "I'll dive into the repository to find the answer to your question. If I need more details, I'll reach out to ensure we're on the same page.",
        ],
    },
};
const zh = {
    chatModel: {
        messageInput: {
            entireRepo: "整个仓库",
            askMode: "问答",
            ask: "提问",
            searchMode: "搜索",
            selectedFile: "部分文件",
            internetSources: "互联网资源",
            repo: "仓库",
            branch: "分支",
            generateDetailedAnswer: "生成详细答案?",
            forNextStep: "是否下一步?",
        },
        chatContent: {
            searchType: "自然语言查询",
            chatType: "详细回答",
            chatResult: "我的回答已在屏幕左侧的结果页面显示。",
            searchResult: "搜索结果已在屏幕左侧显示。",
            generateDetailedAnswer: "我可以为您生成更详细的逐行解释。您是否希望我为您生成它？",
            prologue: "你好，我是 Atlas！在 <1>Query_Code</1> 模式下，您可以从代码库中选择文件并询问相关问题。",
            yesPlease: "是的，请帮我",
            noThanks: "不了，谢谢",
            explainCodeBlock: "为我解释这段代码块",
        },
        searchReady: {
            title: "请输入关键词搜索",
        },
        searchBox: {
            reset: "重置",
            collapseAll: "折叠全部",
            expandAll: "展开全部",
            keywordMatching: "关键词匹配",
            semanticSearch: "自然语言搜索",
        },
        searchResultItem: {
            preview: "预览",
        },
        errorMsg: {
            creditNotEnough: "您的信用不足，无法执行此操作",
            recharge: "请充值",
            APIkey: "未设置 API 密钥或未选择 atlaskey。无法唤起聊天窗口",
        },
        loadingMsg: {
            banOther: "请等待 Atlas 完成。",
        },
        keyNotice: {
            InsufficientCredits: "信用不足",
            insufficientCreditsTip: "要继续使用，您需要升级订阅或输入您的 API 密钥。",
            AddKey: "添加密钥",
            Subscribe: "订阅",
        },
        alignmentMessages: [
            "好的！我会探索仓库以找到您问题的答案。如果需要进一步澄清，我会确保询问。让我们确保在这一点上达成一致。",
            "我将查看仓库以找到您需要的信息。如果需要任何额外信息，我会联系您以确保我们的理解一致。",
            "我会深入仓库寻找您问题的答案。如果需要更多细节，我会联系您以确保我们在同一页面上。",
        ],
    },
};
exports.default = { en, zh };
