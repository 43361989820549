"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const en = {
    payment: {
        prices: {
            title: "Subscription",
            currentSubscription: "Current Subscription",
            iWantTo: "I want to",
            payMonth: "Subscribe (monthly)",
            payOnce: "Pay Once",
            start: "Start",
            pro: "Pro",
            repoExplore: "Repo Explore",
            researcher: "Researcher",
            copilotXp: "Copilot XP",
            prStudio: "PR Studio",
            price: "Price",
            useOurModel: "Use our models",
            useYourApi: "Use your API keys",
            communityRepo: "Community Repo",
            addPrivateRepo: "Add Private Repo",
            addPublicRepo: "Add Public Repo",
            requests: "Requests",
            supported: "Supported",
            subscribe: "Subscribe",
            enterYourKey: "Enter your key",
            midAugest: "Mid Augest",
            LateAugest: "Late Augest",
            joinWaitlist: "Join Waitlist",
            mon: "mo",
            modelTip: "We use a combination of GPT4o, Claude3, and DeepSeek, based on the specific use case.",
            repoTip: "Connect to GitHub and GitLab via OAuth and/or ClassicToken.",
            oneMonth: "One Month",
        },
        setting: {
            subscribeTire: "Subscription Tier",
            free: "Free (No Active Subscription)",
            exploreUsage: "Explore Usage",
            researchUsage: "Research Usage",
            payMonth: "Monthly Subscription (With Stripe)",
            nextPay: "Next Payment date",
            cancelSub: "Cancel Subscription",
            useAtlasKey: "Use Atlas’s keys",
            useYourKey: "Use your API keys",
            refillDate: "Next Reset / Refill date",
            remains: "remains",
            refresh: "Refresh",
            manage: "Manage",
            config: "Configure",
            addKey: "Add Key",
            cancelSubInfo: "Sorry to hear that. Are you sure that you want to cancel subscription and lose access to Atlas’s pro features?",
        },
        userInfo: {
            upgradeNow: "Upgrade Now",
            InviteAndEarn: "Invite and Earn",
        },
        success: {
            type: "Subscription Type",
            nextBill: "Next billing day",
            billToday: "Billed Today",
            userName: "User Name",
            userEmail: "User Email",
            thanksSub: "Thank you for subscribing",
            payProcess: "Your payment was successfully processed",
            continue: "Continue",
        },
    },
};
const zh = {
    payment: {
        prices: {
            title: "订阅",
            currentSubscription: "当前订阅",
            iWantTo: "我想要",
            payMonth: "每月订阅",
            payOnce: "当月支付",
            start: "基础版",
            pro: "专业版",
            repoExplore: "仓库探索",
            researcher: "研究探索",
            copilotXp: "Copilot XP",
            prStudio: "PR工作室",
            price: "价格",
            useOurModel: "使用我们的模型",
            useYourApi: "使用您的API密钥",
            communityRepo: "社区仓库",
            addPrivateRepo: "添加私有仓库",
            addPublicRepo: "添加公共仓库",
            requests: "请求",
            supported: "支持",
            subscribe: "订阅",
            enterYourKey: "输入您的密钥",
            midAugest: "8月中",
            LateAugest: "8月下旬",
            joinWaitlist: "加入等候名单",
            mon: "月",
            modelTip: "我们根据具体应用场景使用 GPT-4、Claude 3 和 DeepSeek 的组合。",
            repoTip: "通过 OAuth 和/或 ClassicToken 连接到 GitHub 和 GitLab。",
            oneMonth: "当月试用",
        },
        setting: {
            subscribeTire: "订阅等级",
            free: "免费（无有效订阅）",
            exploreUsage: "Explore使用量",
            researchUsage: "Research使用量",
            payMonth: "每月订阅（通过Stripe支付）",
            nextPay: "下次付款日期",
            cancelSub: "取消订阅",
            useAtlasKey: "使用Atlas的密钥",
            useYourKey: "使用您的API密钥",
            refillDate: "下一次重置日期",
            remains: "剩余",
            refresh: "刷新",
            manage: "管理订阅",
            config: "配置",
            addKey: "添加秘钥",
            cancelSubInfo: "很抱歉听到这个消息。您确定要取消订阅并失去访问 Atlas 的专业功能吗",
        },
        userInfo: {
            upgradeNow: "立即升级",
            InviteAndEarn: "邀请获取佣金",
        },
        success: {
            type: "订阅类型",
            nextBill: "下一次账单日期",
            billToday: "今日账单",
            userName: "用户名",
            userEmail: "用户邮箱",
            thanksSub: "感谢您的订阅",
            payProcess: "您的支付已成功处理",
            continue: "回到首页",
        },
    },
};
exports.default = { en, zh };
