"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const web_react_1 = require("@arco-design/web-react");
const react_stripe_js_1 = require("@stripe/react-stripe-js");
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const PaymentBox = ({ subscriptionId }) => {
    const [messages, _setMessages] = (0, react_1.useState)("");
    const navigate = (0, react_router_dom_1.useNavigate)();
    const [isProcessing, setIsProcessing] = (0, react_1.useState)(false);
    // helper for displaying status messages.
    const setMessage = (message) => {
        _setMessages(`${messages}\n\n${message}`);
    };
    // Initialize an instance of stripe.
    const stripe = (0, react_stripe_js_1.useStripe)();
    const elements = (0, react_stripe_js_1.useElements)();
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        setIsProcessing(true);
        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: "https://www.baidu.com",
            },
        });
        setIsProcessing(false);
        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            //console.log(result.error.message);
        }
        else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };
    (0, react_1.useEffect)(() => {
        if (!subscriptionId) {
            navigate("/prices");
        }
    }, [subscriptionId]);
    return (react_1.default.createElement("div", { className: "flex h-full w-full items-center justify-center" }, elements && stripe && (react_1.default.createElement("form", { onSubmit: handleSubmit },
        react_1.default.createElement(react_stripe_js_1.PaymentElement, null),
        react_1.default.createElement(web_react_1.Button, { type: "primary", disabled: !stripe || isProcessing, className: "mt-3" }, "Subscribe"),
        react_1.default.createElement("div", null, messages)))));
};
exports.default = PaymentBox;
