// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.CBdetailBottom-arco-styles .arco-card-body{
  border-radius: 0.125rem;
}
.CBdetailBottom-arco-styles .arco-tabs-header-nav::before{
  background-color: transparent;
}
.CBdetailBottom-arco-styles .arco-table-cell .arco-btn-size-default{
  background-color: transparent;
}

.overlay-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(var(--color-fill-4));
}

.overlay-mask::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  backdrop-filter: blur(3px); /* 高斯模糊 */
  -webkit-backdrop-filter: blur(3px); /* WebKit 兼容性 */
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Detail/DetailBottom/index.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAEZ;EAAA;AAAA;AAGA;EAAA;AAAA;AAIE;EAAA;AAAA;;AAKN;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,0CAAA;AAFF;;AAMA;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,0BAAA,EAAA,SAAA;EACA,kCAAA,EAAA,eAAA;AAHF","sourcesContent":[".CBdetailBottom-arco-styles {\n  .arco-card-body {\n    @apply rounded-sm;\n  }\n  .arco-tabs-header-nav::before {\n    @apply bg-transparent;\n  }\n  .arco-table-cell {\n    .arco-btn-size-default {\n      @apply bg-transparent;\n    }\n  }\n}\n\n.overlay-mask {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgb(var(--color-fill-4));\n  // z-index: 3; /* 设置合适的 z-index 值，确保遮罩层在上面 */\n}\n\n.overlay-mask::before {\n  content: \"\";\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 100;\n  backdrop-filter: blur(3px); /* 高斯模糊 */\n  -webkit-backdrop-filter: blur(3px); /* WebKit 兼容性 */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
