"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jotai_1 = require("jotai");
const codebaseChatModeAtom_1 = require("@src/store/codebaseChatModeAtom");
const useCreateChatItem_1 = require("./useCreateChatItem");
const useResetChatState = () => {
    const [, setChatHistory] = (0, jotai_1.useAtom)(codebaseChatModeAtom_1.chatHistoryAtom);
    const [, setCurChatResult] = (0, jotai_1.useAtom)(codebaseChatModeAtom_1.curChatResultAtom);
    const [, setSelectedCiteRange] = (0, jotai_1.useAtom)(codebaseChatModeAtom_1.selectedCiteRangeAtom);
    const [, setSelectedFiles] = (0, jotai_1.useAtom)(codebaseChatModeAtom_1.selectedFilesAtom);
    const [, setIsErrorRollback] = (0, jotai_1.useAtom)(codebaseChatModeAtom_1.isErrorRollbackAtom);
    const [, setChatLeftResults] = (0, jotai_1.useAtom)(codebaseChatModeAtom_1.chatLeftResultAtom);
    const [, setIsExplainMode] = (0, jotai_1.useAtom)(codebaseChatModeAtom_1.isExplainModeAtom);
    const [, setHasSelectedFiles] = (0, jotai_1.useAtom)(codebaseChatModeAtom_1.hasSelectedFilesAtom);
    const [workFocusFiles, setWorkFocusFiles] = (0, jotai_1.useAtom)(codebaseChatModeAtom_1.workFocusFilesAtom);
    const createChatItem = (0, useCreateChatItem_1.useCreateChatItem)();
    const resetChatState = (isClearContext = false, isCiteCode = false) => {
        if (!isClearContext) {
            setChatHistory([]);
            setChatHistory((prevChatHistory) => [
                ...prevChatHistory,
                createChatItem("search", false, false, false, false, true),
            ]);
        }
        if (!isCiteCode) {
            setSelectedCiteRange(null);
            setHasSelectedFiles(false);
        }
        setCurChatResult([]);
        setSelectedFiles([]);
        setIsErrorRollback(false);
        setChatLeftResults({});
        setIsExplainMode(false);
        setWorkFocusFiles([]);
    };
    return {
        resetChatState,
    };
};
exports.default = useResetChatState;
