"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jotai_1 = require("jotai");
const allAtom_1 = require("@src/store/allAtom");
const userAtom_1 = require("@src/store/userAtom");
const useLogin = () => {
    const [, setLoginModalVisible] = (0, jotai_1.useAtom)(allAtom_1.loginModalVisibleAtom);
    const [user] = (0, jotai_1.useAtom)(userAtom_1.userAtom);
    const isLogin = () => {
        return !!(localStorage.getItem("id_token") ||
            (user.authing_id && user.uuid));
    };
    const LoginCheck = () => {
        if (!isLogin()) {
            setLoginModalVisible(true);
            return false;
        }
        return true;
    };
    return {
        isLogin,
        LoginCheck,
    };
};
exports.default = useLogin;
