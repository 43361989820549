"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const en = {
    settings: {
        title: "Settings",
        shared: {
            save: "Save",
            cancel: "Cancel",
            account: "Account {{index}}",
            oauth: "OAuth",
            linking: "Linking: ",
            tokenTooltip: "Click to view the tutorial",
            usernamePlaceholder: "Please enter your user name",
            auth: {
                button: "Authenticate",
                successShort: "Success!",
                success: "Successfully authenticated!",
                error: "Failed to authenticate! Please try again.",
            },
            delete: {
                content: "Removing an account is an irreversible process.",
                success: "Successfully deleted!",
                error: "Failed to delete! Please try again.",
            },
        },
        subscription: {
            title: "Subscription",
        },
        general: {
            title: "General",
            lang: "System Language",
            theme: "System Theme and Color",
        },
        themes: {
            lightBule: "Light(Blue)",
            lightViolet: "Light(Violet)",
            dark: "Dark",
        },
        github: {
            title: "GitHub",
            token: "GitHub Classic Token",
            tokenPlaceholder: "Please enter your classic token",
            username: "GitHub User Name",
            delete: {
                title: "Remove GitHub Account",
            },
        },
        gitlab: {
            title: "GitLab",
            token: "GitLab Personal Access Token",
            tokenPlaceholder: "Please enter your personal access token",
            username: "GitLab User Name",
            delete: {
                title: "Remove GitLab Account",
            },
        },
        api: {
            title: "API Keys",
            openai: "OpenAI Key",
            openaiPlaceholder: "Please enter your OpenAI Key",
            deepseek: "DeepSeek Key",
            deepseekPlaceholder: "Please enter your DeepSeek Key",
            success: "Settings updated!",
            error: "Settings update failed!",
        },
    },
};
const zh = {
    settings: {
        title: "设置",
        shared: {
            save: "保存",
            cancel: "取消",
            account: "账户 {{index}}",
            oauth: "OAuth",
            linking: "已链接：",
            tokenTooltip: "点击查看教程",
            usernamePlaceholder: "请输入您的用户名",
            auth: {
                button: "认证",
                successShort: "成功！",
                success: "成功认证！",
                error: "认证失败！请重试。",
            },
            delete: {
                content: "删除账户是一个不可逆的过程。",
                success: "删除成功！",
                error: "删除失败！请重试。",
            },
        },
        subscription: {
            title: "订阅",
        },
        general: {
            title: "通用",
            lang: "语言",
            theme: "主题和颜色",
        },
        themes: {
            lightBule: "浅蓝色",
            lightViolet: "浅紫色",
            dark: "夜间模式",
        },
        github: {
            title: "GitHub",
            token: "GitHub Classic Token",
            tokenPlaceholder: "请输入您的 classic token",
            username: "GitHub 用户名",
            delete: {
                title: "删除 GitHub 账户",
            },
        },
        gitlab: {
            title: "GitLab",
            token: "GitLab 个人访问令牌",
            tokenPlaceholder: "请输入您的个人访问令牌",
            username: "GitLab 用户名",
            delete: {
                title: "删除 GitLab 账户",
            },
        },
        api: {
            title: "API 密钥",
            openai: "OpenAI 密钥",
            openaiPlaceholder: "请输入您的 OpenAI 密钥",
            deepseek: "DeepSeek 密钥",
            deepseekPlaceholder: "请输入您的 DeepSeek 密钥",
            success: "设置已更新！",
            error: "设置更新失败！",
        },
    },
};
exports.default = { en, zh };
