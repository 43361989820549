// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editeor {
  overscroll-behavior: auto;
}
.editeor .arco-tabs-header-scroll {
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
}
.editeor .arco-tabs-card > .arco-tabs-content {
  margin-left: -1px;
  margin-right: -1px;
  margin-bottom: -1px;
  border-style: none;
  padding-top: 6px;
  border: none !important;
}
.editeor .myHighlightClass {
  background-color: var(--color-fill-3);
}
.editeor .myHighlightClassClose {
  background-color: var(--color-bg-1);
}
.editeor .decorationsOverviewRuler {
  width: 0 !important;
}
.editeor .mtk1 {
  font-family: Menlo, "SF Mono", "Courier New", Courier, monospace;
}`, "",{"version":3,"sources":["webpack://./src/components/Editor/index.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ;AACI;EAAA,iBAAA;EAAA,kBAAA;EAAA;AAAA;AAGA;EAAA,iBAAA;EAAA,kBAAA;EAAA,mBAAA;EAAA,kBAAA;EAAA,gBAAA;EACA;AADA;AAIA;EAAA;AAAA;AAGA;EAAA;AAAA;AAEF;EACE,mBAAA;AAEJ;AACI;EAAA;AAAA","sourcesContent":[".editeor {\n    overscroll-behavior: auto;\n  .arco-tabs-header-scroll {\n    @apply mx-[-1px] mt-[-1px];\n  }\n  .arco-tabs-card > .arco-tabs-content {\n    @apply mx-[-1px] mb-[-1px] border-none pt-[6px];\n    border: none !important;\n  }\n  .myHighlightClass {\n    @apply bg-fill-3;\n  }\n  .myHighlightClassClose {\n    @apply bg-bg-1;\n  }\n  .decorationsOverviewRuler {\n    width: 0 !important;\n  }\n  .mtk1 {\n    @apply font-[Menlo,_'SF_Mono',_'Courier_New',_Courier,_monospace];\n  }\n\n \n\n}\n// .react-monaco-editor-container {\n//   width: 100% !important;\n// }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
