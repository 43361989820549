"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const api_1 = require("@src/api");
const { postRenameProjectV2 } = api_1.api;
const useRenameProject = () => {
    const [renameProjectModalVisible, setRenameProjectModalVisible] = (0, react_1.useState)(false);
    const [isRenameProjectLoading, setIsRenameProjectLoading] = (0, react_1.useState)(false);
    const [renameProjectValue, setRenameProjectValue] = (0, react_1.useState)("");
    const [activeProjectId, setActiveProjectId] = (0, react_1.useState)("");
    const handleClickRename = (projectId, name) => {
        setRenameProjectModalVisible(true);
        setRenameProjectValue(name);
        setActiveProjectId(projectId);
    };
    const handleRenameProject = async ({ currentName, callback, }) => {
        try {
            setIsRenameProjectLoading(true);
            const res = await postRenameProjectV2({
                uuid: activeProjectId,
                name: currentName,
            });
            if (res.status === 0) {
                if (callback)
                    callback();
                setRenameProjectModalVisible(false);
                return true;
            }
            return false;
        }
        finally {
            setIsRenameProjectLoading(false);
        }
    };
    return {
        renameProjectModalVisible,
        isRenameProjectLoading,
        renameProjectValue,
        handleClickRename,
        handleRenameProject,
        setRenameProjectModalVisible,
    };
};
exports.default = useRenameProject;
