"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resultLineBgColorList = exports.ResultLinebgColorClasses = exports.ColorType = void 0;
var ColorType;
(function (ColorType) {
    ColorType["Primary3"] = "primary-3";
    ColorType["Danger2"] = "danger-2";
    ColorType["Danger5"] = "danger-5";
    ColorType["Link1"] = "link-1";
    ColorType["Warning6"] = "warning-6";
    ColorType["Warning5"] = "warning-5";
    ColorType["Warning4"] = "warning-4";
    ColorType["Primary5"] = "primary-5";
})(ColorType || (exports.ColorType = ColorType = {}));
exports.ResultLinebgColorClasses = {
    [ColorType.Primary3]: "bg-primary-3",
    [ColorType.Danger2]: "bg-danger-2",
    [ColorType.Danger5]: "bg-danger-5",
    [ColorType.Link1]: "bg-link-1",
    [ColorType.Warning6]: "bg-warning-6",
    [ColorType.Warning5]: "bg-warning-5",
    [ColorType.Warning4]: "bg-warning-4",
    [ColorType.Primary5]: "bg-primary-5",
};
//Display in sequence
exports.resultLineBgColorList = [
    ColorType.Primary3,
    ColorType.Warning4,
    ColorType.Danger5,
];
