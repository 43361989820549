// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-menu .arco-menu-light,
.sidebar-menu .arco-menu-item,
.sidebar-menu .arco-menu-light .arco-menu-inline-header {
    background-color: transparent
}
.sidebar-menu .arco-menu-light .arco-menu-item .arco-icon {
    color: var(--color-text-2)
}
.sidebar-menu .arco-menu-light .arco-menu-item.arco-menu-selected .arco-icon {
    color: rgb(var(--primary-6))
}
.sidebar-menu .arco-btn-secondary:not(.arco-btn-disabled) {
    background-color: var(--color-fill-3)
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Home/HomeSidebarMenu/index.scss"],"names":[],"mappings":"AAII;;;IAAA;AAAA;AAGA;IAAA;AAAA;AAGA;IAAA;AAAA;AAGA;IAAA;AAAA","sourcesContent":[".sidebar-menu {\n.arco-menu-light,\n.arco-menu-item,\n.arco-menu-light .arco-menu-inline-header {\n    @apply bg-transparent;\n}\n.arco-menu-light .arco-menu-item .arco-icon {\n    @apply text-text-2;\n}\n.arco-menu-light .arco-menu-item.arco-menu-selected .arco-icon {\n    @apply text-primary-6;\n}\n.arco-btn-secondary:not(.arco-btn-disabled) {\n    @apply bg-fill-3;\n}\n\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
