"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_router_dom_1 = require("react-router-dom");
const useIsShowSidebar = () => {
    const { pathname } = (0, react_router_dom_1.useLocation)();
    const isShowSidebar = pathname === "/codebase/research" ||
        pathname.includes("home") ||
        pathname.includes("workspaceList");
    return isShowSidebar;
};
exports.default = useIsShowSidebar;
