"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchSubscriptionData = void 0;
const paymentApi_1 = require("@src/api/paymentApi/paymentApi");
const { getUserSubscription } = paymentApi_1.paymentApi;
const fetchSubscriptionData = async () => {
    var _a, _b, _c, _d, _e, _f;
    const response = (await getUserSubscription());
    const data = response === null || response === void 0 ? void 0 : response.data;
    // 存储到 localStorage
    localStorage.setItem("current_atlas_request_limit", (_a = data === null || data === void 0 ? void 0 : data.current_atlas_request_limit) === null || _a === void 0 ? void 0 : _a.toString());
    localStorage.setItem("current_api_key_request_limit", (_b = data === null || data === void 0 ? void 0 : data.current_api_key_request_limit) === null || _b === void 0 ? void 0 : _b.toString());
    localStorage.setItem("current_research_limit", (_c = data === null || data === void 0 ? void 0 : data.current_research_limit) === null || _c === void 0 ? void 0 : _c.toString());
    localStorage.setItem("total_atlas_request_limit", (_d = data === null || data === void 0 ? void 0 : data.total_atlas_request_limit) === null || _d === void 0 ? void 0 : _d.toString());
    localStorage.setItem("total_api_key_request_limit", (_e = data === null || data === void 0 ? void 0 : data.total_api_key_request_limit) === null || _e === void 0 ? void 0 : _e.toString());
    localStorage.setItem("total_research_limit", (_f = data === null || data === void 0 ? void 0 : data.total_research_limit) === null || _f === void 0 ? void 0 : _f.toString());
    return {
        currentAtlasRequestLimit: data.current_atlas_request_limit,
        currentApiKeyRequestLimit: data.current_api_key_request_limit,
    };
};
exports.fetchSubscriptionData = fetchSubscriptionData;
