"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const web_react_1 = require("@arco-design/web-react");
const icon_1 = require("@arco-design/web-react/icon");
const react_1 = __importStar(require("react"));
require("./index.scss");
const DropdownMenu = ({ items, defaultSelectedKey, onSelect, }) => {
    const getInitialSelectedItem = () => {
        var _a;
        return (localStorage.getItem("selectedItem") ||
            "AtlasKey" ||
            ((_a = items[0].children) === null || _a === void 0 ? void 0 : _a[0].key) ||
            items[0].key);
    };
    const getInitialSelectedParentItem = () => {
        return (localStorage.getItem("selectedParentItem") ||
            defaultSelectedKey ||
            items[0].key);
    };
    const [selectedItem, setSelectedItem] = (0, react_1.useState)(getInitialSelectedItem());
    const [selectedParentItem, setSelectedParentItem] = (0, react_1.useState)(getInitialSelectedParentItem());
    (0, react_1.useEffect)(() => {
        localStorage.setItem("selectedItem", selectedItem);
    }, [selectedItem]);
    (0, react_1.useEffect)(() => {
        localStorage.setItem("selectedParentItem", selectedParentItem);
    }, [selectedParentItem]);
    const handleMenuClick = (key, parentKey) => {
        setSelectedItem(key);
        setSelectedParentItem(parentKey);
        if (onSelect) {
            onSelect(key, parentKey);
        }
    };
    const renderMenuItems = (menuItems) => {
        return menuItems.map((item) => {
            if (item.children) {
                return (react_1.default.createElement(web_react_1.Menu.SubMenu, { key: item.key, title: react_1.default.createElement("div", { className: `flex items-center justify-center  ${selectedParentItem === item.key ? "arco-dropdown-menu-item-selected" : ""}` }, item.label) }, item.children.map((child) => (react_1.default.createElement(web_react_1.Menu.Item, { className: `flex items-center justify-between ${selectedItem === child.key ? "bg-fill-2" : ""}`, key: child.key, onClick: () => handleMenuClick(child.key, item.key), disabled: child.disabled }, child.label)))));
            }
            return (react_1.default.createElement(web_react_1.Menu.Item, { className: `flex items-center justify-between ${selectedItem === item.key ? "bg-fill-1" : ""}`, key: item.key, onClick: () => handleMenuClick(item.key, item.key) }, item.label));
        });
    };
    const droplist = (react_1.default.createElement(web_react_1.Menu, { className: "chatModelDrop translate-x-[40px] translate-y-[5px] transform" }, renderMenuItems(items)));
    return (react_1.default.createElement(web_react_1.Dropdown, { position: "br", trigger: "click", droplist: droplist },
        react_1.default.createElement("div", { className: "ml-[-8px] flex cursor-pointer items-center justify-center gap-1 text-[14px] text-text-2" },
            selectedParentItem,
            react_1.default.createElement(icon_1.IconDown, { className: "ml-[2px] h-[12px] w-[12px] " }))));
};
exports.default = DropdownMenu;
