// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.workspace_nav {
  margin-right: 0.75rem;
}
.workspace_nav .arco-btn-size-default {
  border-radius: 6px !important;
}
.workspace_nav .arco-btn-shape-square {
  width: 97px;
  border-radius: 6px 0px 0px 6px !important;
  border-right: none !important;
}
.workspace_nav .arco-btn-icon-only {
  width: 32px;
  border-radius: 0px 6px 6px 0px !important;
}

.workspace_save_button .arco-btn-primary {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header_btn .arco-btn-group .arco-btn-size-default:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  padding-right: 2px;
}
.header_btn .arco-btn-group .arco-btn-size-default:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.header_btn .arco-btn-group .arco-btn-primary:not(:last-child) {
  border-right-width: 1px;
  border-style: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/index.scss"],"names":[],"mappings":"AACE;EAAA;AAAA;AACA;EACE,6BAAA;AAEJ;AACE;EACE,WAAA;EACA,yCAAA;EACA,6BAAA;AACJ;AAEE;EACE,WAAA;EACA,yCAAA;AAAJ;;AAKI;EAAA,aAAA;EAAA,mBAAA;EAAA;AAAA;;AAKA;EAAA,2BAAA;EAAA,8BAAA;EAAA;AAAA;AAGA;EAAA,4BAAA;EAAA;AAAA;AAGA;EAAA,uBAAA;EAAA;AAAA","sourcesContent":[".workspace_nav {\n  @apply mr-3;\n  .arco-btn-size-default {\n    border-radius: 6px !important;\n  }\n\n  .arco-btn-shape-square {\n    width: 97px;\n    border-radius: 6px 0px 0px 6px !important;\n    border-right: none !important;\n  }\n\n  .arco-btn-icon-only {\n    width: 32px;\n    border-radius: 0px 6px 6px 0px !important;\n  }\n}\n.workspace_save_button {\n  .arco-btn-primary {\n    @apply flex items-center justify-center;\n  }\n}\n.header_btn {\n  .arco-btn-group .arco-btn-size-default:first-child {\n    @apply rounded-l-[6px] pr-[2px]\n  }\n  .arco-btn-group .arco-btn-size-default:last-child  {\n    @apply rounded-r-[6px]\n  }\n  .arco-btn-group .arco-btn-primary:not(:last-child) {\n    @apply border-r border-none\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
