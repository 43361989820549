"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const en = {
    nav: {
        codebase: "Codebase",
        projects: "Projects",
        research: "Research",
        settings: "Settings",
        repoInfo: "Overview",
        search: "Search",
        explorer: "Explorer",
        wpExplorer: "Explorer",
        workSpace: "WorkSpace",
        docs: "Docs",
        chatResults: "Chat Results",
    },
};
const zh = {
    nav: {
        codebase: "代码库",
        projects: "项目",
        research: "研究",
        settings: "设置",
        repoInfo: "仓库信息",
        search: "搜索",
        explorer: "浏览",
        wpExplorer: "浏览",
        workSpace: "工作区",
        docs: "文档",
        chatResults: "聊天结果",
    },
};
exports.default = { en, zh };
