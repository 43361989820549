"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const web_react_1 = require("@arco-design/web-react");
const jotai_1 = require("jotai");
const react_1 = __importStar(require("react"));
const react_syntax_highlighter_1 = require("react-syntax-highlighter");
const prism_1 = require("react-syntax-highlighter/dist/esm/styles/prism");
const api_1 = require("@src/api");
const hooks_1 = require("@src/hooks");
const codebaseChatModeAtom_1 = require("@src/store/codebaseChatModeAtom");
const workspaceAtom_1 = require("@src/store/workspaceAtom");
const rengerBreadCrumb_1 = require("@src/util/share/rengerBreadCrumb");
require("./index.scss");
const { postFileContentByProId } = api_1.api;
const WorkingViewer = ({ status }) => {
    var _a, _b, _c, _d;
    const containerRef = (0, react_1.useRef)(null);
    const containerWidth = (0, hooks_1.useElementWidth)(containerRef);
    const [activeKey] = (0, react_1.useState)("1");
    const [fileContent, setFileContent] = (0, react_1.useState)("");
    const [lastDisplayedLog] = (0, jotai_1.useAtom)(workspaceAtom_1.lastDisplayedLogAtom);
    const [newDraftList] = (0, jotai_1.useAtom)(workspaceAtom_1.newDraftListAtom);
    const [isWaiting] = (0, jotai_1.useAtom)(workspaceAtom_1.isWaitingAtom);
    const [waitingStatus] = (0, jotai_1.useAtom)(workspaceAtom_1.waitingStatusAtom);
    const [verifyThought] = (0, jotai_1.useAtom)(workspaceAtom_1.verifyThoughtAtom);
    const [curProId] = (0, jotai_1.useAtom)(workspaceAtom_1.curProIdAtom);
    const [curShowQueryCodeWorklog] = (0, jotai_1.useAtom)(codebaseChatModeAtom_1.curShowQueryCodeWorklogAtom);
    (0, react_1.useEffect)(() => {
        const fetchFileContent = async () => {
            var _a, _b;
            if (lastDisplayedLog && lastDisplayedLog.tool_call_path !== "") {
                const fileRes = (await postFileContentByProId({
                    project_uuid: (_a = localStorage.getItem("curProId")) !== null && _a !== void 0 ? _a : curProId,
                    paths: [lastDisplayedLog === null || lastDisplayedLog === void 0 ? void 0 : lastDisplayedLog.tool_call_path],
                }));
                setFileContent((_b = fileRes === null || fileRes === void 0 ? void 0 : fileRes.data.file_contents[0].content) !== null && _b !== void 0 ? _b : "");
            }
        };
        fetchFileContent();
    }, [lastDisplayedLog]);
    const codeViewerRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        const scrollElement = codeViewerRef.current;
        if (scrollElement) {
            const maxScroll = scrollElement.scrollHeight - scrollElement.clientHeight;
            const duration = 500;
            let start = null;
            const step = (timestamp) => {
                if (!start)
                    start = timestamp;
                const progress = timestamp - start;
                const position = Math.min((progress / duration) * maxScroll, maxScroll);
                scrollElement.scrollTop = position;
                if (progress < duration) {
                    window.requestAnimationFrame(step);
                }
            };
            window.requestAnimationFrame(step);
        }
    }, [curShowQueryCodeWorklog]);
    return (react_1.default.createElement("div", { className: "WorkingViewer h-full w-full rounded-[12px] bg-bg-1 ", ref: containerRef },
        react_1.default.createElement(web_react_1.Collapse, { accordion: false, activeKey: activeKey, className: "h-[234px] bg-[#1e1e1e]" },
            react_1.default.createElement(web_react_1.Collapse.Item, { header: react_1.default.createElement("div", { className: "ml-[8px] flex flex-1  justify-between gap-[8px] overflow-hidden text-ellipsis whitespace-nowrap" },
                    react_1.default.createElement("div", { className: "flex items-center " }, status === "displaying" && !isWaiting && (react_1.default.createElement("div", null,
                        react_1.default.createElement(web_react_1.Breadcrumb, null, (0, rengerBreadCrumb_1.renderBreadcrumb)((lastDisplayedLog === null || lastDisplayedLog === void 0 ? void 0 : lastDisplayedLog.tool_call_path) ||
                            ((_a = newDraftList[newDraftList.length - 1]) === null || _a === void 0 ? void 0 : _a.path))))))), name: "1" },
                react_1.default.createElement("div", { style: { width: `${containerWidth}px` }, className: `${status === "thinking" || status === "navigating" || (isWaiting && waitingStatus === "end") || (isWaiting && waitingStatus === "waitThinking") || (isWaiting && (waitingStatus === "waitReviewing" || waitingStatus === "initThinking" || waitingStatus === "initNavigating" || waitingStatus === "waitGenerating")) ? "flex items-center justify-center bg-[#1e1e1e] pt-[80px]" : ""}` },
                    !isWaiting && (react_1.default.createElement(react_1.default.Fragment, null,
                        status === "thinking" && (react_1.default.createElement("div", { className: "text-[#fff]" }, "Atlas is not using any tool at this moment")),
                        status === "navigating" && (react_1.default.createElement("div", { className: "text-[#fff]" }, "Atlas is navigating to the file")),
                        status === "displaying" && (react_1.default.createElement(react_syntax_highlighter_1.Prism, { language: ((_b = newDraftList[newDraftList.length - 1]) === null || _b === void 0 ? void 0 : _b.language) ||
                                "javascript", style: prism_1.a11yDark, showLineNumbers: true, customStyle: {
                                backgroundColor: "#1e1e1e",
                                background: "#1e1e1e",
                                border: "none",
                                borderRadius: "0px",
                                fontSize: "14px",
                                marginTop: 0,
                                paddingTop: "4px",
                                overflow: "auto",
                                maxHeight: "234px",
                            }, startingLineNumber: 1, wrapLines: true }, fileContent ||
                            ((_c = newDraftList[newDraftList.length - 1]) === null || _c === void 0 ? void 0 : _c.content) ||
                            ((_d = curShowQueryCodeWorklog[curShowQueryCodeWorklog.length - 1]) === null || _d === void 0 ? void 0 : _d.content) ||
                            "Atlas is thinking...")))),
                    isWaiting && (react_1.default.createElement(react_1.default.Fragment, null, (() => {
                        var _a, _b, _c, _d, _e;
                        switch (waitingStatus) {
                            case "end":
                                return (react_1.default.createElement("div", { className: "text-[#fff]" }, "Atlas is not using any tool at this moment"));
                            case "waitThinking":
                                return (react_1.default.createElement("div", { className: "text-[#fff]" }, "Atlas is thinking..."));
                            case "waitNavigating":
                                return (react_1.default.createElement("div", { className: "text-[#fff]" }, "Atlas is navigating to the file"));
                            case "waitGenerating":
                                return (react_1.default.createElement("div", { className: "text-[#fff]" }, "Atlas is composing an answer for you"));
                            case "waitDisplaying":
                                return (react_1.default.createElement("div", { ref: codeViewerRef, style: { overflowY: "auto", maxHeight: "234px" } },
                                    react_1.default.createElement(react_syntax_highlighter_1.Prism, { language: ((_a = newDraftList[newDraftList.length - 1]) === null || _a === void 0 ? void 0 : _a.language) ||
                                            "javascript", style: prism_1.a11yDark, showLineNumbers: true, customStyle: {
                                            backgroundColor: "#1e1e1e",
                                            background: "#1e1e1e",
                                            border: "none",
                                            borderRadius: "0px",
                                            fontSize: "14px",
                                            marginTop: 0,
                                            paddingTop: "4px",
                                            // overflowY: "auto",
                                            // maxHeight: "234px",
                                        }, startingLineNumber: 1, wrapLines: true }, (_b = curShowQueryCodeWorklog[curShowQueryCodeWorklog.length - 1]) === null || _b === void 0 ? void 0 : _b.content)));
                            case "waitEditing" || "waitReviewing":
                                return (react_1.default.createElement(react_syntax_highlighter_1.Prism, { language: ((_c = newDraftList[newDraftList.length - 1]) === null || _c === void 0 ? void 0 : _c.language) ||
                                        "javascript", style: prism_1.a11yDark, showLineNumbers: true, customStyle: {
                                        backgroundColor: "#1e1e1e",
                                        background: "#1e1e1e",
                                        border: "none",
                                        borderRadius: "0px",
                                        fontSize: "14px",
                                        marginTop: 0,
                                        paddingTop: "4px",
                                        overflow: "auto",
                                        maxHeight: "234px",
                                    }, startingLineNumber: 1, wrapLines: true }, fileContent ||
                                    ((_d = newDraftList[newDraftList.length - 1]) === null || _d === void 0 ? void 0 : _d.content) ||
                                    verifyThought));
                            case "waitReviewing":
                                return (react_1.default.createElement("div", { className: "text-[#fff]" }, "Atlas is reviewing and polishing the change"));
                            case "isNoWaitingThinking":
                                return (react_1.default.createElement(react_syntax_highlighter_1.Prism, { language: ((_e = newDraftList[newDraftList.length - 1]) === null || _e === void 0 ? void 0 : _e.language) ||
                                        "javascript", style: prism_1.a11yDark, showLineNumbers: true, customStyle: {
                                        backgroundColor: "#1e1e1e",
                                        background: "#1e1e1e",
                                        border: "none",
                                        borderRadius: "0px",
                                        fontSize: "14px",
                                        marginTop: 0,
                                        paddingTop: "4px",
                                        overflowY: "auto",
                                        maxHeight: "234px",
                                    }, startingLineNumber: 1, wrapLines: true }, verifyThought));
                            case "initThinking":
                                return (react_1.default.createElement("div", { className: "text-[#fff]" }, "Atlas is thinking..."));
                            case "initNavigating":
                                return (react_1.default.createElement("div", { className: "text-[#fff]" }, "Atlas is navigating..."));
                            default:
                                return (react_1.default.createElement("div", { className: "text-[#fff]" }, "Atlas is not using any tool at this moment")); // 当没有匹配的case时，不渲染任何内容
                        }
                    })())))))));
};
exports.default = WorkingViewer;
