"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const en = {
    files: {
        header: {
            repoFiles: "Repo Files",
            edit: "Edit",
            chat: "Chat",
            workspace: "Workspace",
            existingProjects: "Existing Projects",
            newProject: "New Project",
            aboutThisRepo: "About this Repo",
            generalInquiry: "General Inquiry",
            askAtlas: "Ask Atlas",
            createProject: "Create a project",
            viewProjectList: "View project list",
            openInIDE: "Open in IDE",
        },
        left: {
            codeDiff: {
                title: "Code Diff",
                changes: "Changes",
                selectAll: "Select All",
            },
            allFiles: {
                title: "All Files",
            },
            dashboard: "Launch Workspace",
            manageKey: "Manage / Add Key",
        },
        right: {
            topBar: {
                openSecondViewer: "Open second file viewer",
                original: "Original",
                codeDiff: "Code Diff",
                search: "Search this file",
            },
            fileList: {
                name: "Name",
                lastCommitMessage: "Last Commit Message",
                lastCommitTime: "Last Commit Time",
                fileSize: "File Size",
            },
            search: {
                notFoundsubTitle: "Please try again with other keyword(s)",
                notFoundTitle: "No Result Found",
                globalSearch: "Search",
                exitSearch: "Exit search",
            },
            copy: "Copy",
        },
    },
};
const zh = {
    files: {
        header: {
            repoFiles: "仓库文件",
            edit: "编辑",
            chat: "聊天",
            workspace: "工作区",
            existingProjects: "现有项目",
            newProject: "新项目",
            aboutThisRepo: "关于这个仓库",
            generalInquiry: "一般询问",
            askAtlas: "询问Atlas",
            createProject: "创建项目",
            viewProjectList: "查看项目列表",
            openInIDE: "在IDE中打开",
        },
        left: {
            codeDiff: {
                title: "代码差异",
                changes: "更改",
                selectAll: "全选",
            },
            allFiles: {
                title: "所有文件",
            },
            dashboard: "控制台",
            manageKey: "管理 / 添加密钥",
        },
        right: {
            topBar: {
                openSecondViewer: "打开第二个文件查看器",
                original: "原始",
                codeDiff: "代码差异",
                search: "搜索",
            },
            fileList: {
                name: "名称",
                lastCommitMessage: "最后提交信息",
                lastCommitTime: "最后提交时间",
                fileSize: "文件大小",
            },
            search: {
                notFoundsubTitle: "请重新输入其他关键词",
                notFoundTitle: "没有找到结果",
                globalSearch: "全局搜索",
                exitSearch: "退出搜索",
            },
            copy: "复制",
        },
    },
};
exports.default = { en, zh };
