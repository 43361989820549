"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const web_react_1 = require("@arco-design/web-react");
const icon_1 = require("@arco-design/web-react/icon");
const i18next_1 = require("i18next");
const jotai_1 = require("jotai");
const react_1 = __importStar(require("react"));
const react_i18next_1 = require("react-i18next");
const Button_1 = require("@src/components/Button");
const allAtom_1 = require("@src/store/allAtom");
const SubProjectList = ({ projectList, baseBranch, projectListLoading, handleProjectClick, handleClickRenameReport, handleClickDeleteReport, isListCollapsed, }) => {
    const [isLocallyCollapsed, setIsLocallyCollapsed] = (0, react_1.useState)(isListCollapsed);
    const [currentCodebase] = (0, jotai_1.useAtom)(allAtom_1.currentCodebaseAtom);
    const toggleLocalCollapse = () => {
        setIsLocallyCollapsed(!isLocallyCollapsed);
    };
    (0, react_1.useEffect)(() => {
        setIsLocallyCollapsed(isListCollapsed);
    }, [isListCollapsed]);
    return (react_1.default.createElement(web_react_1.Spin, { loading: projectListLoading },
        react_1.default.createElement("div", { className: "mt-[6px] flex items-center  justify-between self-stretch pr-[6px]" },
            react_1.default.createElement("div", { className: "flex items-center gap-[8px]" },
                react_1.default.createElement("div", { className: "cursor-pointer", onClick: toggleLocalCollapse }, isLocallyCollapsed ? (react_1.default.createElement(icon_1.IconRight, { className: "text-text-2" })) : (react_1.default.createElement(icon_1.IconDown, { className: "text-text-2" }))),
                react_1.default.createElement(react_i18next_1.Trans, { i18nKey: "detail.project.branch", values: {
                        branch: baseBranch,
                        current: baseBranch === currentCodebase.currentBranch ? "(Current)" : "",
                    }, components: [
                        react_1.default.createElement("div", { className: "text-text-1" }),
                        react_1.default.createElement("div", { className: "font-semibold text-primary-6" }),
                        react_1.default.createElement("div", { className: "text-text-2" }),
                    ] })),
            react_1.default.createElement("div", { className: "text-[12px] text-text-1" }, (0, i18next_1.t)("detail.project.count", { count: projectList.length }))),
        !isLocallyCollapsed && (react_1.default.createElement(web_react_1.List, { virtualListProps: {
                height: 650,
            }, className: "mt-[12px] rounded-[8px] border-l border-r border-t border-solid border-border-2", size: "large", dataSource: projectList, render: (item, index) => (react_1.default.createElement("div", { className: `group flex h-[54px] max-h-[54px] cursor-pointer items-center justify-between px-[24px] py-[12px] text-[14px] ${index === projectList.length - 1
                    ? ""
                    : "border-b border-solid border-border-2"}`, key: item.project_uuid, onClick: () => {
                    handleProjectClick(item.project_uuid, item.name);
                } },
                react_1.default.createElement("div", { className: "flex flex-grow items-center gap-3 font-semibold text-text-1" },
                    react_1.default.createElement("span", { className: "line-clamp-1" }, item.name || `Project ${index + 1}`),
                    react_1.default.createElement(icon_1.IconPen, { className: "h-4 w-4", onClick: (e) => {
                            e.stopPropagation();
                            handleClickRenameReport(item.project_uuid, item.name);
                        } })),
                react_1.default.createElement("div", { className: "flex flex-shrink-0 items-center justify-between" },
                    react_1.default.createElement("div", { className: "-mr-1 flex items-center text-[12px] group-hover:hidden" },
                        react_1.default.createElement("div", { className: "mt-[1px]" }, item.task_count),
                        react_1.default.createElement("div", { className: "ml-2" }, item.task_count === 1
                            ? (0, i18next_1.t)("detail.project.session")
                            : (0, i18next_1.t)("detail.project.sessions"))),
                    react_1.default.createElement("div", { className: "-mr-[12px] hidden gap-3 group-hover:flex" },
                        react_1.default.createElement(Button_1.Button, { size: "small", onClick: () => {
                                handleProjectClick(item.project_uuid, item.name);
                            } }, (0, i18next_1.t)("detail.project.view")),
                        react_1.default.createElement(Button_1.Button, { type: "gray", icon: react_1.default.createElement(icon_1.IconDelete, null), size: "small", onClick: (e) => {
                                e.stopPropagation();
                                handleClickDeleteReport(item.project_uuid);
                            } }))))) }))));
};
exports.default = SubProjectList;
