// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-search .arco-input-group .arco-input-inner-wrapper {
    border-radius: 0.5rem;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-border-2)
}`, "",{"version":3,"sources":["webpack://./src/page/Database/Home/Content/TopBar/index.scss"],"names":[],"mappings":"AACE;IAAA,qBAAA;IAAA,iBAAA;IAAA,mBAAA;IAAA;AAAA","sourcesContent":[".input-search .arco-input-group .arco-input-inner-wrapper {\n  @apply rounded-lg border border-solid border-border-2;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
