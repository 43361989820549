"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const en = {
    research: {
        topBar: {
            category: {
                title: "Search Mode",
                generalInquiry: "General Inquiry",
                repoSpecific: "Repo-Specific",
                myRepos: "My Repos",
                myFavorites: "My Favorites",
                myCollections: "My Collections",
                newSearch: "New Search",
            },
            group: {
                title: "Search Location",
                global: "Global (Auto)",
                local: "Local",
                USA: "USA",
                EU: "EU",
            },
        },
        content: {
            title: "With Atlas, Get Reliable Answers Faster",
            askAFollowUpQuestion: "Ask a follow-up question",
            sources: "Sources",
            answer: "Answer",
            followUpQuestions: "Follow-up Questions",
        },
        suggestions: {
            title: "Suggestions",
            suggestions: [
                "How to build a VSCode extension?",
                "I cannot setup brew on my mac.",
                "What is the best way to learn React?",
                "How to use Tailwind CSS?",
                "What are the new features in JavaScript ES2021?",
                "How to deploy a Node.js application?",
            ],
        },
    },
};
const zh = {
    research: {
        topBar: {
            category: {
                title: "搜索模式",
                generalInquiry: "联网搜索",
                repoSpecific: "针对仓库",
                myRepos: "我的仓库",
                myFavorites: "我的收藏",
                myCollections: "我的收藏夹",
                newSearch: "创建新搜索",
            },
            group: {
                title: "搜索范围",
                local: "本地",
                global: "全球（自动）",
                USA: "美国",
                EU: "欧盟",
            },
        },
        content: {
            title: "使用 Atlas，更快地获得可靠的答案",
            askAFollowUpQuestion: "提出跟进问题",
            sources: "来源",
            answer: "答案",
            followUpQuestions: "跟进问题",
        },
        suggestions: {
            title: "建议",
            suggestions: [
                "如何构建 VSCode 扩展？",
                "我无法在我的 Mac 上设置 brew。",
                "学习 React 的最佳方法是什么？",
                "如何使用 Tailwind CSS？",
                "JavaScript ES2021 的新特性有哪些？",
                "如何部署 Node.js 应用程序？",
            ],
        },
    },
};
exports.default = {
    en,
    zh,
};
