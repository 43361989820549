// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trending::-webkit-scrollbar {
  display: none;
}
.trending .trending__loadMore .arco-btn-secondary:not(.arco-btn-disabled) {
  border-style: none;
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Home/Trending/index.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ;AAIM;EAAA;AAAA","sourcesContent":[".trending {\n  &::-webkit-scrollbar {\n    display: none;\n  }\n  .trending__loadMore {\n    .arco-btn-secondary:not(.arco-btn-disabled) {\n      @apply border-none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
