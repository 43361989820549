"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateChatItem = void 0;
// src/hooks/useCreateChatItem.ts
const jotai_1 = require("jotai");
const codebaseChatModeAtom_1 = require("@src/store/codebaseChatModeAtom");
const useCreateChatItem = () => {
    const [citeFileName] = (0, jotai_1.useAtom)(codebaseChatModeAtom_1.curSelectFileNameAtom);
    const [selectedCiteRange] = (0, jotai_1.useAtom)(codebaseChatModeAtom_1.selectedCiteRangeAtom);
    const createChatItem = (inputText, isUser, isResultBox, isMd, searchBox, isPrologue, isSearchResultBox, isClearContext) => {
        return {
            content: inputText,
            isUser: isUser,
            isMd: isMd,
            citeFileName: citeFileName,
            selectedCiteRange: selectedCiteRange,
            isResultBox: isResultBox,
            searchBox: searchBox,
            isPrologue: isPrologue,
            isSearchResultBox: isSearchResultBox,
            isClearContext: isClearContext,
        };
    };
    return createChatItem;
};
exports.useCreateChatItem = useCreateChatItem;
