"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jotai_1 = require("jotai");
const react_1 = __importStar(require("react"));
const codebaseChatModeAtom_1 = require("@src/store/codebaseChatModeAtom");
const codebaseResearchAtom_1 = require("@src/store/codebaseResearchAtom");
const ResearchContent_1 = require("./Cpns/ResearchContent");
const ResearchResultMain = () => {
    const [isChatExpend] = (0, jotai_1.useAtom)(codebaseChatModeAtom_1.isChatModelExpandAtom);
    const containerRef = (0, react_1.useRef)(null);
    const [researchResultHistory, setResearchResultHistory] = (0, jotai_1.useAtom)(codebaseResearchAtom_1.researchResultHistoryAtom);
    const ParentRef = (0, react_1.useRef)(null);
    const handleScrollInParent = (scrollTop) => {
        if (ParentRef.current) {
            ParentRef.current.scrollTop += scrollTop;
        }
    };
    const scrollToBottom = () => {
        if (containerRef.current) {
            requestAnimationFrame(() => {
                if (containerRef.current) {
                    containerRef.current.scrollTop = containerRef.current.scrollHeight;
                }
            });
        }
    };
    (0, react_1.useEffect)(() => {
        scrollToBottom();
    }, [researchResultHistory]);
    (0, react_1.useEffect)(() => {
        return () => {
            setResearchResultHistory([]);
        };
    }, []);
    return (react_1.default.createElement("div", { className: `CBChatResultCenterItem b-1 flex flex-1 flex-col  border-x border-solid border-border-2 bg-bg-1  ${isChatExpend ? "w-[125px]" : ""}` },
        react_1.default.createElement("div", { className: `${researchResultHistory.length <= 2 ? "ml-[250px] text-start" : "ml-[250px]"} max-w-[calc(100vw-521px)] space-y-6 overflow-y-auto p-6 text-[14px] text-text-1 scrollbar-hide`, ref: containerRef },
            react_1.default.createElement(ResearchContent_1.ResearchContent, null))));
};
exports.default = ResearchResultMain;
