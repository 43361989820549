"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.paymentApi = void 0;
const apiConfigV2_1 = require("../apiConfigV2");
const originalUrlV2 = "/api/v2/payment";
const getPrices = () => {
    return (0, apiConfigV2_1.getApi)(originalUrlV2 + "/services", {}, true);
};
const postSubscribe = (params) => {
    return (0, apiConfigV2_1.postApi)(originalUrlV2 + "/create-subscription", params, true);
};
const postCheckOut = (params) => {
    return (0, apiConfigV2_1.postApi)(originalUrlV2 + "/create-checkout-session", params, true);
};
const getPaymentSessionInfo = (params) => {
    return (0, apiConfigV2_1.getApi)(originalUrlV2 + "/get-payment-session-info", params, true);
};
const getUserSubscription = () => {
    return (0, apiConfigV2_1.getApi)(originalUrlV2 + "/get_user_subscription_status", {}, true);
};
const postCancelSubscription = () => {
    return (0, apiConfigV2_1.postApi)(originalUrlV2 + "/cancel-subscription", {}, true);
};
exports.paymentApi = {
    getPrices,
    postSubscribe,
    postCheckOut,
    getPaymentSessionInfo,
    getUserSubscription,
    postCancelSubscription,
};
