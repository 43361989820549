"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jotai_1 = require("jotai");
const react_1 = __importStar(require("react"));
require("react-data-grid/lib/styles.css");
const react_monaco_editor_1 = __importStar(require("react-monaco-editor"));
const useHighlight_1 = require("@src/hooks/useHighlight");
const allAtom_1 = require("@src/store/allAtom");
const codebaseChatModeAtom_1 = require("@src/store/codebaseChatModeAtom");
const codebaseFileAtom_1 = require("@src/store/codebaseFileAtom");
const workspaceAtom_1 = require("@src/store/workspaceAtom");
const groupAndMergeHighlights_1 = require("@src/util/groupAndMergeHighlights");
const CiteCodePopup_1 = require("./CiteCodePopup");
const HighlightOverlay_1 = require("./HighlightOverlay");
const ResultLineBgColorClasses_1 = require("./ResultLineBgColorClasses");
const UnSelectPopup_1 = require("./UnSelectPopup");
require("./index.scss");
const syntaxRules_1 = require("./syntaxRules");
const keywords = [
    "class",
    "new",
    "string",
    "function",
    "boolean",
    "private",
    "public",
    "head",
    "import",
    "from",
    "as",
    "export",
    "default",
    "const",
    "let",
    "var",
    "if",
    "else",
    "for",
    "while",
    "return",
    "switch",
    "case",
    "break",
    "continue",
    "function",
    "throw",
    "try",
    "catch",
    "finally",
    "this",
    "super",
    "extends",
    "static",
    "typeof",
    "instanceof",
    "void",
    "interface",
    "enum",
    "namespace",
    "abstract",
    "protected",
    "implements",
    "package",
    "yield",
    "await",
    "async",
    "do",
    "delete",
    "in",
    "with",
    "debugger",
    "eval",
    "arguments",
    "typeof",
    "instanceof",
    "void",
    "null",
    "undefined",
    "NaN",
    "Infinity",
    "true",
    "false",
    "Object",
    "Function",
    "Boolean",
    "Symbol",
    "Error",
    "EvalError",
    "InternalError",
    "RangeError",
    "ReferenceError",
    "SyntaxError",
    "TypeError",
    "URIError",
    "Number",
    "Math",
    "Date",
    "String",
    "RegExp",
    "Array",
    "Int8Array",
    "Uint8Array",
    "Uint8ClampedArray",
    "Int16Array",
    "Uint16Array",
    "Int32Array",
    "Uint32Array",
    "Float32Array",
    "Float64Array",
    "Map",
    "Set",
    "WeakMap",
    "WeakSet",
    "ArrayBuffer",
    "SharedArrayBuffer",
    "Atomics",
    "DataView",
    "JSON",
    "Promise",
    "Generator",
    "GeneratorFunction",
    "AsyncFunction",
    "Reflect",
    "Proxy",
    "Intl",
    "WebAssembly",
    "elif",
    "def",
    "except",
];
const lineHeight = 18; // 与编辑器的行高一致 Consistent with the line height of the editor
const paddingTop = 12; // 顶部内边距 Top padding
const paddingBottom = 12; // 底部内边距 Bottom padding
let highlightIdCounter = 0;
const OmsViewMarkdown = (0, react_1.memo)(({ textContent, width, outHeight, outterHeight, resultStartLineNumber, resultEndLineNumber, resultLineBgColor = "danger-2", showEntireFile = true, chatResultMode = false, workSpaceMode = false, handleContentChange, onEditorScroll, queryCodeMdMode = false, isWorkingLogMode = false, commonMode = false, searchStartLineNumber, searchMode = false, outerfilepath, }) => {
    const targetDivRef = (0, react_1.useRef)(null);
    const [showCitePopup, setShowCitePopup] = (0, react_1.useState)(false);
    const [selectedCiteRange, setSelectedCiteRange] = (0, react_1.useState)(null);
    const [selectedCiteText, setSelectedCiteText] = (0, react_1.useState)("");
    const [editorHeight, setEditorHeight] = (0, react_1.useState)(undefined);
    const [showCitePopupStyle, setShowCitePopupStyle] = (0, react_1.useState)({});
    const [highlightTop, setHighlightTop] = (0, react_1.useState)(0);
    const [highlightHeight, setHighlightHeight] = (0, react_1.useState)(0);
    const { editorRef } = (0, useHighlight_1.useHighlight)({
        lineHeight,
        resultStartLineNumber,
        resultEndLineNumber,
    }); //reultHighLight
    const [searchKey] = (0, jotai_1.useAtom)(codebaseFileAtom_1.searchKeyAtom);
    const [themes] = (0, jotai_1.useAtom)(allAtom_1.themesAtom);
    const [isbtnExplaining] = (0, jotai_1.useAtom)(codebaseChatModeAtom_1.isbtnExplainingAtom);
    //reultHighLight bgColor
    const bgColorClass = ResultLineBgColorClasses_1.ResultLinebgColorClasses[resultLineBgColor];
    const [decorationsCollection, setDecorationsCollection] = (0, react_1.useState)(undefined);
    const [showUnselectPopup, setIsShowUnselectPopup] = (0, react_1.useState)(false);
    const displayedContent = showEntireFile
        ? String(textContent)
        : String(textContent)
            .split("\n")
            .slice(resultStartLineNumber ? resultStartLineNumber - 1 : 0, resultEndLineNumber)
            .join("\n");
    const [highlights, setHighlights] = (0, jotai_1.useAtom)(workspaceAtom_1.highlightsAtom);
    const [selectHighlights, setSelectHighlights] = (0, jotai_1.useAtom)(workspaceAtom_1.selectHighlightsAtom);
    const selectHighlightsRef = (0, react_1.useRef)(selectHighlights);
    const handleUnselect = () => {
        if (selectedCiteRange) {
            setHighlights((prevHighlights) => {
                // 去重：基于 start 和 end 值创建一个包含唯一项的数组
                const uniqueHighlights = Array.from(new Map(prevHighlights.map((item) => [`${item.start}-${item.end}`, item])).values());
                const newHighlights = uniqueHighlights.reduce((acc, highlight) => {
                    // 检查当前高亮是否与选中范围有重叠
                    const isOverlapping = highlight.end >= selectedCiteRange.start &&
                        highlight.start <= selectedCiteRange.end;
                    if (!isOverlapping) {
                        // 如果没有重叠，保留当前高亮
                        acc.push(highlight);
                    }
                    else {
                        // 如果有重叠，根据重叠情况拆分为新的高亮对象
                        // 如果选中范围的开始大于当前高亮的开始，添加一个新的高亮对象
                        if (selectedCiteRange.start > highlight.start) {
                            acc.push({
                                ...highlight,
                                id: `${highlight.id}-part1`,
                                end: selectedCiteRange.start - 1,
                                top: highlight.top,
                                height: (selectedCiteRange.start - 1 - highlight.start + 1) *
                                    lineHeight, // 需要根据实际情况调整
                            });
                        }
                        // 如果选中范围的结束小于当前高亮的结束，添加一个新的高亮对象
                        if (selectedCiteRange.end < highlight.end) {
                            acc.push({
                                ...highlight,
                                id: `${highlight.id}-part2`,
                                start: selectedCiteRange.end + 1,
                                top: (selectedCiteRange.end + 1 - highlight.start) *
                                    lineHeight +
                                    highlight.top, // 需要根据实际情况调整
                                height: (highlight.end - (selectedCiteRange.end + 1)) *
                                    lineHeight, // 需要根据实际情况调整
                            });
                        }
                    }
                    return acc;
                }, []);
                const selectHighlights = newHighlights.map(({ start, end }) => ({
                    start,
                    end,
                }));
                setSelectHighlights((0, groupAndMergeHighlights_1.mergeHighlightsBasedOnStartOrEnd)([...selectHighlights]));
                setIsShowUnselectPopup(false);
                return newHighlights;
            });
        }
    };
    // 每次 selectHighlights 更新时，更新 ref 的值
    (0, react_1.useEffect)(() => {
        selectHighlightsRef.current = selectHighlights;
    }, [selectHighlights]);
    const selectedCiteRangeRef = (0, react_1.useRef)(selectedCiteRange);
    // Update the ref whenever the state changes
    (0, react_1.useEffect)(() => {
        selectedCiteRangeRef.current = selectedCiteRange;
    }, [selectedCiteRange]);
    (0, react_1.useEffect)(() => {
        const editor = editorRef.current;
        const container = targetDivRef.current;
        if (editor && container) {
            const handleScroll = (e) => {
                const editorHeight = editor.getScrollHeight();
                const visibleHeight = editor.getLayoutInfo().height;
                container.scrollTop =
                    (e.scrollTop * (container.scrollHeight - container.clientHeight)) /
                        (editorHeight - visibleHeight);
            };
            editor.onDidScrollChange(handleScroll);
        }
    }, []);
    const handleEditorDidMount = (editor) => {
        // 注册新语言
        react_monaco_editor_1.monaco.languages.register({ id: "yicyTest" });
        // 定义语法规则
        react_monaco_editor_1.monaco.languages.setMonarchTokensProvider("yicyTest", syntaxRules_1.pythonSyntax);
        react_monaco_editor_1.monaco.editor.defineTheme("yicyTest-theme", {
            base: "vs",
            inherit: true,
            rules: [
                { token: "keyword", foreground: "#FF6600", fontStyle: "bold" },
                { token: "comment", foreground: "#999999" },
                { token: "string", foreground: "#009966" },
                { token: "variable", foreground: "#006699" },
                { token: "number", foreground: "B5CEA8" },
                { token: "identifier", foreground: "6A9955" },
                { token: "comment", foreground: "888888" },
                { token: "keyword.class", foreground: "#FF0000" },
                { token: "keyword.function", foreground: "#00FF00" },
                { token: "keyword.const", foreground: "#0000FF" },
                { token: "keyword.import", foreground: "#B5CEA8" },
                { token: "keyword.from", foreground: "#0000FF" },
                { token: "keyword.as", foreground: "#0000FF" },
            ],
            colors: {
                "editor.foreground": "#000000",
                "editor.background": "#edf9fa",
                "editorCursor.foreground": "#8b0000",
                "editor.lineHighlightBackground": "#0000ff20",
                "editorLineNumber.foreground": "#008800",
                "editor.selectionBackground": "#88000030",
                "editor.inactiveSelectionBackground": "#88000015",
            },
        });
        react_monaco_editor_1.monaco.editor.setTheme("yicyTest-theme");
        editorRef.current = editor;
        const targetDiv = targetDivRef.current;
        editor.onMouseDown((e) => {
            const position = e.target.position;
            setIsShowUnselectPopup(false);
            if (position) {
                // Check if the position is within any of the highlight ranges
                const inHighlightRange = selectHighlightsRef.current.some((highlight) => {
                    return (position.lineNumber >= highlight.start &&
                        position.lineNumber <= highlight.end);
                });
                const incitecodeRange = selectedCiteRangeRef.current &&
                    selectHighlightsRef.current.some((highlight) => {
                        var _a, _b, _c;
                        return (((_a = selectedCiteRangeRef.current) === null || _a === void 0 ? void 0 : _a.start) !== undefined &&
                            ((_b = selectedCiteRangeRef.current) === null || _b === void 0 ? void 0 : _b.start) >= highlight.start &&
                            ((_c = selectedCiteRangeRef.current) === null || _c === void 0 ? void 0 : _c.end) !== undefined &&
                            selectedCiteRangeRef.current.end <= highlight.end);
                    });
                if (inHighlightRange && incitecodeRange) {
                    // If in highlight range, perform the desired action
                    // //console.log(
                    //   `Mouse is over highlighted line: ${position.lineNumber}`
                    // );
                    setIsShowUnselectPopup(true);
                }
            }
        });
    };
    (0, react_1.useEffect)(() => {
        if (editorRef.current) {
            editorRef.current.updateOptions({
                lineNumbers: (lineNumber) => {
                    // 如果不显示整个文件，并且 resultStartLineNumber 有定义，则从该行号开始计数,否则从1开始计数
                    // If the entire file is not displayed and resultStartLineNumber is defined, start counting from that line number
                    // Otherwise, start counting from 1
                    return !showEntireFile && resultStartLineNumber
                        ? (lineNumber + resultStartLineNumber - 1).toString()
                        : searchStartLineNumber
                            ? (lineNumber + searchStartLineNumber - 1).toString()
                            : lineNumber.toString();
                },
            });
        }
    }, [showEntireFile, resultStartLineNumber, editorRef]);
    (0, react_1.useEffect)(() => {
        const numberOfLines = showEntireFile
            ? textContent.split("\n").length
            : (resultEndLineNumber || textContent.split("\n").length) -
                (resultStartLineNumber ? resultStartLineNumber - 1 : 0);
        // 根据行数计算编辑器的理想高度
        // Calculate the ideal height of the editor based on the number of lines
        let newEditorHeight = numberOfLines * lineHeight + paddingTop + paddingBottom;
        // 如果计算出的高度超过了外部容器的高度，则使用外部容器的高度
        // If the calculated height exceeds the height of the external container, use the height of the external container
        if (outHeight && newEditorHeight > outHeight) {
            newEditorHeight = outHeight;
        }
        setEditorHeight(newEditorHeight);
    }, [
        showEntireFile,
        textContent,
        resultStartLineNumber,
        resultEndLineNumber,
        lineHeight,
        outHeight,
        paddingTop,
        paddingBottom,
    ]);
    (0, react_1.useEffect)(() => {
        // 当 showEntireFile 为 true 时，从第一行到最后一行进行高亮, 否则，我们使用传入的 resultStartLineNumber 和 resultEndLineNumber
        // When showEntireFile is true, we want to highlight from the first line to the last line
        // Otherwise, we use the passed resultStartLineNumber and resultEndLineNumber
        const startLineNumber = resultStartLineNumber || 1;
        const endLineNumber = resultEndLineNumber || textContent.split("\n").length;
        // 计算高亮区域的顶部和高度， Calculate the top and height of the highlight area
        const newHighlightTop = showEntireFile
            ? (startLineNumber - 1) * lineHeight + paddingTop
            : paddingTop;
        const newHighlightHeight = (endLineNumber - startLineNumber + 1) * lineHeight;
        // Update the state to reflect the new highlight area
        setHighlightTop(newHighlightTop);
        setHighlightHeight(newHighlightHeight);
    }, [
        showEntireFile,
        resultStartLineNumber,
        resultEndLineNumber,
        textContent,
        lineHeight,
        paddingTop,
    ]);
    (0, react_1.useEffect)(() => {
        if (editorRef.current && (chatResultMode || searchMode)) {
            // 如果 chatResultMode 为 true，则禁止滚动，If chatResultMode is true, disable scrolling
            editorRef.current.onDidScrollChange(() => {
                var _a;
                (_a = editorRef.current) === null || _a === void 0 ? void 0 : _a.setScrollTop(0); // 禁止滚动 Disable scrolling
            });
        }
    }, [chatResultMode]);
    (0, react_1.useEffect)(() => {
        if (editorRef.current) {
            const editor = editorRef.current;
            const handleWheel = (e) => {
                if (chatResultMode || searchMode) {
                    e.preventDefault(); // 禁止编辑器内部滚动
                    if (onEditorScroll) {
                        onEditorScroll(editor.getScrollTop() + e.deltaY); // 将滚动位置传递给外部容器
                    }
                }
            };
            const domNode = editor.getDomNode();
            if (domNode) {
                domNode.addEventListener("wheel", handleWheel);
            }
            return () => {
                if (domNode) {
                    domNode.removeEventListener("wheel", handleWheel);
                }
            };
        }
    }, [chatResultMode, onEditorScroll]);
    (0, react_1.useEffect)(() => {
        var _a;
        if (chatResultMode) {
            return; // 如果没有开启该功能，直接返回 If this feature is not enabled, return directly
        }
        const handleMouseUp = () => {
            var _a;
            if (editorRef.current) {
                const selection = editorRef.current.getSelection();
                if (selection && !selection.isEmpty()) {
                    // 获取选中的文本 Get the selected text
                    const selectedText = (_a = editorRef.current
                        .getModel()) === null || _a === void 0 ? void 0 : _a.getValueInRange(selection);
                    if (selectedText) {
                        setSelectedCiteText(selectedText);
                    }
                    // 获取光标位置对应的DOM元素 Get the DOM element corresponding to the cursor position
                    const editorContent = editorRef.current.getDomNode();
                    if (editorContent) {
                        // 获取光标的客户端坐标 Get the client coordinates of the cursor
                        const cursorCoords = editorRef.current.getScrolledVisiblePosition(selection.getEndPosition());
                        if (cursorCoords) {
                            const cursorWidth = 1;
                            const editorScrollTop = editorContent.scrollTop || 0;
                            const editorScrollLeft = editorContent.scrollLeft || 0;
                            // 计算弹出窗口的样式（位置） Calculate the style (position) of the popup window
                            let top = cursorCoords.top +
                                lineHeight -
                                editorContent.offsetTop -
                                editorScrollTop +
                                60;
                            let left = cursorCoords.left +
                                cursorWidth -
                                editorContent.offsetLeft -
                                editorScrollLeft;
                            const numberOfLinesSelected = selection.endLineNumber - selection.startLineNumber + 1;
                            if (numberOfLinesSelected > 1 && numberOfLinesSelected <= 3) {
                                top += numberOfLinesSelected * lineHeight;
                            }
                            if (numberOfLinesSelected === 1) {
                                top += 4 * lineHeight - 15;
                                left -= 60;
                            }
                            if (searchMode) {
                                // if (numberOfLinesSelected <= 2) {
                                //   top -= 200;
                                //   left -= 30;
                                //   return;
                                // }
                                top -= 180;
                                left -= 20;
                                if (numberOfLinesSelected <= 2) {
                                    top += 35;
                                    left += 15;
                                }
                            }
                            setShowCitePopupStyle({ top: `${top}px`, left: `${left}px` });
                        }
                        if (searchMode && searchStartLineNumber) {
                            setSelectedCiteRange({
                                start: selection.startLineNumber + searchStartLineNumber - 1,
                                end: selection.endLineNumber + searchStartLineNumber - 1,
                            });
                            // //console.log(
                            //   "searchMode",
                            //   selection.startLineNumber + searchStartLineNumber - 1
                            // );
                            setShowCitePopup(true);
                            return;
                        }
                        setSelectedCiteRange({
                            start: selection.startLineNumber,
                            end: selection.endLineNumber,
                        });
                        setShowCitePopup(true);
                    }
                }
                else {
                    setShowCitePopup(false);
                }
            }
        };
        const editorContent = (_a = editorRef.current) === null || _a === void 0 ? void 0 : _a.getDomNode();
        editorContent === null || editorContent === void 0 ? void 0 : editorContent.addEventListener("mouseup", handleMouseUp);
        return () => {
            // 清理事件监听器 Clean up the event listener
            editorContent === null || editorContent === void 0 ? void 0 : editorContent.removeEventListener("mouseup", handleMouseUp);
        };
    }, [editorRef]);
    // Utility function to escape special characters in the search key
    const escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
    };
    // ... existing code ...
    // 监听searchKey变化并高亮文本
    (0, react_1.useEffect)(() => {
        if (editorRef.current && searchKey) {
            decorationsCollection === null || decorationsCollection === void 0 ? void 0 : decorationsCollection.clear(); // clear original decoration
            const model = editorRef.current.getModel();
            if (!model)
                return;
            const fullText = model.getValue();
            const newDecorations = [];
            let match;
            const escapedSearchKey = escapeRegExp(searchKey);
            const regex = new RegExp(`\\b${escapedSearchKey}\\b`, "gi"); // Use word boundaries to avoid matching HTML tags and entities
            while ((match = regex.exec(fullText)) !== null) {
                let startIndex = match.index;
                let endIndex = startIndex + match[0].length;
                // Skip matches that are within HTML entities
                if (fullText.substring(startIndex - 1, endIndex + 1).includes("&")) {
                    continue;
                }
                const startPos = model.getPositionAt(startIndex);
                const endPos = model.getPositionAt(endIndex);
                newDecorations.push({
                    range: new react_monaco_editor_1.monaco.Range(startPos.lineNumber, startPos.column, endPos.lineNumber, endPos.column),
                    options: { className: "myHighlightClass" },
                });
            }
            const newDecorationsCollection = editorRef.current.createDecorationsCollection(newDecorations);
            setDecorationsCollection(newDecorationsCollection);
        }
        else {
            decorationsCollection === null || decorationsCollection === void 0 ? void 0 : decorationsCollection.clear();
            setDecorationsCollection(undefined);
        }
    }, [searchKey, textContent, decorationsCollection]);
    // ... existing code ...
    // ... existing code ...
    (0, react_1.useEffect)(() => {
        if (editorRef.current) {
            const editor = editorRef.current;
            const updateHighlights = () => {
                setHighlights((currentHighlights) => {
                    const editorScrollTop = editor.getScrollTop();
                    const updatedHighlights = currentHighlights.map((highlight) => {
                        const newTop = (highlight.start - 1) * lineHeight +
                            paddingTop -
                            editorScrollTop -
                            10;
                        // 只有当新的 top 值与当前的 top 值不同时才更新
                        if (newTop !== highlight.top) {
                            return { ...highlight, top: newTop };
                        }
                        return highlight;
                    });
                    // 检查是否有任何高亮实际上被更新了
                    const hasUpdates = updatedHighlights.some((highlight, index) => highlight.top !== currentHighlights[index].top);
                    if (hasUpdates) {
                        return updatedHighlights;
                    }
                    // 如果没有更新，则返回原始高亮数组以避免不必要的渲染
                    return currentHighlights;
                });
            };
            editor.onDidChangeCursorSelection(({ selection }) => {
                if (workSpaceMode) {
                    const model = editor.getModel();
                    // 定用于在编辑器滚动时更新高亮区域的位置
                    if (model) {
                        const startLineNumber = selection.startLineNumber;
                        const endLineNumber = selection.endLineNumber;
                        const id = `${Date.now()}-${highlightIdCounter++}`;
                        const newHighlight = {
                            id,
                            top: (startLineNumber - 1) * lineHeight + paddingTop - 10,
                            height: (endLineNumber - startLineNumber + 1) * lineHeight,
                            start: startLineNumber,
                            end: endLineNumber,
                        };
                        setSelectHighlights((prevRanges) => {
                            const newRange = {
                                id,
                                start: startLineNumber,
                                end: endLineNumber,
                            }; // 使用相同的 ID
                            return (0, groupAndMergeHighlights_1.mergeHighlightsBasedOnStartOrEnd)([
                                ...prevRanges,
                                newRange,
                            ]);
                        });
                        setHighlights((prevHighlights) => [
                            ...prevHighlights,
                            newHighlight,
                        ]);
                    }
                    updateHighlights();
                }
            });
            editor.onDidScrollChange(updateHighlights);
            // 返回一个清理函数，用于在组件卸载时移除滚动事件监听器
            return () => {
                editor.onDidScrollChange(updateHighlights, true); // Use the second parameter as `true` to remove the event listener
            };
        }
    }, [workSpaceMode, editorRef, lineHeight, paddingTop, highlights]);
    // hide UnSelectPopup while Scroll
    (0, react_1.useEffect)(() => {
        if (editorRef.current) {
            const editor = editorRef.current;
            const handleScroll = () => {
                setIsShowUnselectPopup(false);
            };
            editor.onDidScrollChange(handleScroll);
            return () => {
                editor.onDidScrollChange(handleScroll, true);
            };
        }
    }, [editorRef]);
    // //console.log("searchMode", showCitePopup, selectedCiteRange);
    return (react_1.default.createElement("div", { className: "editeor h-full", ref: targetDivRef },
        chatResultMode && !queryCodeMdMode && (react_1.default.createElement(HighlightOverlay_1.HighlightOverlay, { top: highlightTop, height: highlightHeight, bgColorClass: bgColorClass })),
        workSpaceMode &&
            highlights.map((highlight) => (react_1.default.createElement(HighlightOverlay_1.HighlightOverlay, { key: highlight.id, top: highlight.top, height: highlight.height, bgColorClass: bgColorClass }))),
        react_1.default.createElement(react_monaco_editor_1.default, { width: width, height: outterHeight || editorHeight, language: "yicyTest", value: displayedContent, className: " relative w-full overflow-hidden font-mono text-primary-5", theme: themes.includes("dark") || isWorkingLogMode ? "vs-dark" : "vs", options: {
                selectOnLineNumbers: true,
                roundedSelection: false,
                readOnly: true,
                cursorStyle: "line",
                automaticLayout: true,
                autoClosingBrackets: "languageDefined",
                // wordWrap: "on",
                minimap: {
                    enabled: false,
                },
                renderLineHighlight: "none",
                padding: chatResultMode || searchMode
                    ? {
                        top: paddingTop,
                        bottom: paddingBottom,
                    }
                    : {
                        top: 0,
                        bottom: 0,
                    },
                fontSize: 12,
                fontFamily: "font-mono",
            }, onChange: handleContentChange, editorDidMount: handleEditorDidMount }),
        showCitePopup &&
            selectedCiteRange &&
            !workSpaceMode &&
            !isWorkingLogMode &&
            !commonMode &&
            !isbtnExplaining && (react_1.default.createElement(CiteCodePopup_1.CiteCodePopup, { searchMode: searchMode, outerfilepath: outerfilepath, style: showCitePopupStyle, selectedText: selectedCiteText, selectedLines: selectedCiteRange })),
        workSpaceMode && showUnselectPopup && (react_1.default.createElement(UnSelectPopup_1.UnSelectPopup, { style: showCitePopupStyle, selectedText: selectedCiteText, selectedLines: selectedCiteRange, onUnselect: handleUnselect }))));
});
exports.default = OmsViewMarkdown;
