// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wp__res__import__container .arco-tabs {
    height: 100%
}
.wp__res__import__container .arco-tabs .arco-tabs-content {
    min-height: 450px
}
.wp__res__import__container .arco-tabs .arco-tabs-content-inner {
    height: 100%
}
.wp__res__import__container .arco-tabs .arco-tabs-content-inner .arco-tabs-pane {
    height: 100%;
    padding: 1rem
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Workspace/CBWorkspaceCenter/Center/CenterContent/CenterResImport/index.scss"],"names":[],"mappings":"AAEI;IAAA;AAAA;AAEE;IAAA;AAAA;AAGA;IAAA;AAAA;AAEE;IAAA,YAAA;IAAA;AAAA","sourcesContent":[".wp__res__import__container {\n  .arco-tabs {\n    @apply h-full;\n    .arco-tabs-content {\n      @apply min-h-[450px];\n    }\n    .arco-tabs-content-inner {\n      @apply h-full;\n      .arco-tabs-pane {\n        @apply h-full p-4;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
