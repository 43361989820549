"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const web_react_1 = require("@arco-design/web-react");
const i18next_1 = require("i18next");
const jotai_1 = require("jotai");
const react_1 = require("react");
const softwareEngineer_1 = require("@src/api/codebaseApi/softwareEngineer");
const workspaceAtom_1 = require("@src/store/workspaceAtom");
const CBWorkSpaceContext_1 = require("../../CBWorkSpaceContext");
const { postApplyActions } = softwareEngineer_1.softwareEngineerApi;
const useWpApplyAction = () => {
    const { setWpTaskStatus, setWorkStatusList } = (0, react_1.useContext)(CBWorkSpaceContext_1.WorkSpaceContext);
    const [curTaskId] = (0, jotai_1.useAtom)(workspaceAtom_1.curTaskIdAtom);
    //6.用户接受所有改动，apply action
    const applyAction = async () => {
        try {
            const res = await postApplyActions({
                task_uuid: curTaskId == ""
                    ? localStorage.getItem("curTaskId")
                    : curTaskId,
            });
            if ((res === null || res === void 0 ? void 0 : res.status) === 0) {
                setWpTaskStatus("apply_actions");
                setWorkStatusList((prev) => prev.map((v) => {
                    return v.sessionId === curTaskId && v.title.includes("Draft")
                        ? { ...v, finish: true }
                        : v;
                }));
                web_react_1.Message.success("All Appied!");
            }
        }
        catch (e) {
            web_react_1.Message.error((0, i18next_1.t)("workspace.center.codeDiff.acceptAllError"));
        }
    };
    return { applyAction };
};
exports.default = useWpApplyAction;
