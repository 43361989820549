// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatModelDrop .arco-dropdown-menu-inner .arco-dropdown-menu-item-selected {
  color: rgb(var(--primary-5));
}
.chatModelDrop .arco-dropdown-menu {
  background: red !important;
}`, "",{"version":3,"sources":["webpack://./src/components/chatBox/CBChatModeBox.tsx/commonCpns/index.scss"],"names":[],"mappings":"AAIE;EAAA;AAAA;AAQA;EACE,0BAAA;AARJ","sourcesContent":["\n  .chatModelDrop {\n    .arco-dropdown-menu-inner {\n     .arco-dropdown-menu-item-selected {\n  @apply text-primary-5;\n\n//       .arco-dropdown-menu-item{\n//    background: red !important;\n\n    // }\n}\n  }\n  .arco-dropdown-menu {\n    background: red !important;\n  }\n}\n\n\n//     .arco-dropdown-menu-item{\n//    background: red !important;\n\n//     }\n//     .arco-dropdown-menu-pop-header {\n//          background: red !important;\n//     }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
