"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkSpaceContext = exports.WorkSpaceProvider = void 0;
const react_1 = __importStar(require("react"));
// 创建一个 Context，并提供默认值
const WorkSpaceContext = (0, react_1.createContext)(undefined);
exports.WorkSpaceContext = WorkSpaceContext;
// 创建一个 Provider 组件
const WorkSpaceProvider = ({ children }) => {
    const [languageList, setLanguageList] = (0, react_1.useState)({});
    const [commitHistoryList, setCommitHistoryList] = (0, react_1.useState)([]);
    const [sessionShow, setSessionShow] = (0, react_1.useState)(false);
    const [generatePlan, setGeneratePlan] = (0, react_1.useState)({});
    const [curProjectName, setCurProjectName] = (0, react_1.useState)("Project");
    const [diffList, setDiffList] = (0, react_1.useState)([]);
    const [diffListCache, setDiffListCache] = (0, react_1.useState)({});
    const [resultList, setResultList] = (0, react_1.useState)([]);
    const [sessionDoing, setSessionDoing] = (0, react_1.useState)(false);
    const [curCheckStatus, setCurCheckStatus] = (0, react_1.useState)("res_local"); //res_config,res_refer,res_local,config,alignment,add_req,decide_dev,focus_file,generate_plan,generate_actions,apply_actions,done, work_log
    const [wpTaskStatus, setWpTaskStatus] = (0, react_1.useState)("none"); //alignment,add_req,decide_dev,focus_file,generate_plan,generate_actions,apply_actions,done
    const [curTaskName, setCurTaskName] = (0, react_1.useState)("");
    const [diffShow, setDiffShow] = (0, react_1.useState)(false);
    const [feedBack, setFeedBack] = (0, react_1.useState)(false);
    const [chatHistory, setChatHistory] = (0, react_1.useState)([]);
    const [showTextArea, setShowTextArea] = (0, react_1.useState)(true);
    const [showInteractArea, setShowInteractArea] = (0, react_1.useState)(true);
    const [addFilesNum, setAddFilesNum] = (0, react_1.useState)(0);
    const [workStatusList, setWorkStatusList] = (0, react_1.useState)([]);
    const [workLog, setWorkLog] = (0, react_1.useState)([]);
    const [focusFileSnippet, setFocusFileSnippet] = (0, react_1.useState)({});
    const [contextExamFiles, setContextExamFiles] = (0, react_1.useState)([]);
    const [focusFileContent, setFocusFileContent] = (0, react_1.useState)([]);
    const [createFocusFileList, setCreateFocusFileList] = (0, react_1.useState)([]);
    const [localContextList, setLocalContextList] = (0, react_1.useState)([]);
    const [sessionRoundList, setSessionRoundList] = (0, react_1.useState)([]);
    const [sessRoundActiveKeys, setSessRoundActiveKeys] = (0, react_1.useState)([
        "1",
    ]);
    const [finalActions, setFinalActions] = (0, react_1.useState)({});
    const [curCheckTaskId, setCurCheckTaskId] = (0, react_1.useState)("");
    const [summaryList, setSummaryList] = (0, react_1.useState)([]);
    const [focusFilePageLoading, setFocusFilePageLoading] = (0, react_1.useState)(false);
    const [planPageLoading, setPlanPageLoading] = (0, react_1.useState)(false);
    const [diffPageLoading, setDiffPageLoading] = (0, react_1.useState)(false);
    const [showFinalMessage, setShowFinalMessage] = (0, react_1.useState)(false);
    const [shouldExecuteFeature, setShouldExecuteFeature] = (0, react_1.useState)(false);
    const [isExploering, setIsExploering] = (0, react_1.useState)(false);
    const [regeList, setRegeList] = (0, react_1.useState)({});
    const [regePrepare, setRegePrepare] = (0, react_1.useState)(false);
    return (react_1.default.createElement(WorkSpaceContext.Provider, { value: {
            regePrepare,
            setRegePrepare,
            regeList,
            setRegeList,
            languageList,
            setLanguageList,
            commitHistoryList,
            setCommitHistoryList,
            summaryList,
            setSummaryList,
            planPageLoading,
            setPlanPageLoading,
            diffPageLoading,
            setDiffPageLoading,
            focusFilePageLoading,
            setFocusFilePageLoading,
            sessRoundActiveKeys,
            setSessRoundActiveKeys,
            sessionShow,
            setSessionShow,
            generatePlan,
            setGeneratePlan,
            curProjectName,
            setCurProjectName,
            diffList,
            setDiffList,
            diffListCache,
            setDiffListCache,
            sessionDoing,
            setSessionDoing,
            resultList,
            setResultList,
            curCheckStatus,
            setCurCheckStatus,
            wpTaskStatus,
            setWpTaskStatus,
            curTaskName,
            setCurTaskName,
            diffShow,
            setDiffShow,
            feedBack,
            setFeedBack,
            chatHistory,
            setChatHistory,
            showTextArea,
            setShowTextArea,
            showInteractArea,
            setShowInteractArea,
            addFilesNum,
            setAddFilesNum,
            workStatusList,
            setWorkStatusList,
            workLog,
            setWorkLog,
            focusFileSnippet,
            setFocusFileSnippet,
            contextExamFiles,
            setContextExamFiles,
            focusFileContent,
            setFocusFileContent,
            createFocusFileList,
            setCreateFocusFileList,
            finalActions,
            setFinalActions,
            localContextList,
            setLocalContextList,
            sessionRoundList,
            setSessionRoundList,
            curCheckTaskId,
            setCurCheckTaskId,
            showFinalMessage,
            setShowFinalMessage,
            shouldExecuteFeature,
            setShouldExecuteFeature,
            isExploering,
            setIsExploering,
        } }, children));
};
exports.WorkSpaceProvider = WorkSpaceProvider;
