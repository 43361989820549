"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const i18next_1 = require("i18next");
const jotai_1 = require("jotai");
const react_1 = __importStar(require("react"));
const softwareEngineer_1 = require("@src/api/codebaseApi/softwareEngineer");
const Button_1 = require("@src/components/Button");
const workspaceAtom_1 = require("@src/store/workspaceAtom");
const CBWorkSpaceContext_1 = require("../../CBWorkSpaceContext");
require("./index.scss");
const { postApplyActions } = softwareEngineer_1.softwareEngineerApi;
const ChangeSummary = ({ fileChanges, additions, deletions, setExpandAll, expandAll, isDiffLoading, }) => {
    const { setWpTaskStatus, wpTaskStatus, setWorkStatusList } = (0, react_1.useContext)(CBWorkSpaceContext_1.WorkSpaceContext);
    const [curTaskId] = (0, jotai_1.useAtom)(workspaceAtom_1.curTaskIdAtom);
    const [isWpGeneWorking] = (0, jotai_1.useAtom)(workspaceAtom_1.isWpGeneWorkingAtom);
    return (react_1.default.createElement("div", { className: "diff_top flex h-[46px] items-center justify-between  border-b border-solid border-border-2 py-3 pl-4 pr-3" },
        react_1.default.createElement("div", { className: "flex whitespace-pre text-sm text-text-1" },
            react_1.default.createElement("span", null, (0, i18next_1.t)("workspace.center.codeDiff.summary.start", { count: fileChanges })),
            additions && additions > 0 && (react_1.default.createElement("span", { className: "font-semibold" }, (0, i18next_1.t)("workspace.center.codeDiff.summary.addition", {
                count: additions,
            }))),
            react_1.default.createElement("span", null, (0, i18next_1.t)("workspace.center.codeDiff.summary.end"))),
        react_1.default.createElement("div", { className: "expand__button flex gap-2" },
            react_1.default.createElement(Button_1.Button, { type: "gray", shape: "round", className: "text-[14px]", onClick: () => {
                    setExpandAll((prev) => !prev);
                } }, expandAll
                ? (0, i18next_1.t)("workspace.shared.collapseAll")
                : (0, i18next_1.t)("workspace.shared.expandAll")))));
};
exports.default = ChangeSummary;
