"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const en = {
    shared: {
        remove: "Remove",
        cancel: "Cancel",
        comingSoon: "Feature Coming soon",
    },
};
const zh = {
    shared: {
        remove: "删除",
        cancel: "取消",
        comingSoon: "功能即将上线",
    },
};
exports.default = { en, zh };
