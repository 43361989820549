"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const getNewTreeData = (node) => {
    var _a;
    // 给当前节点添加name字段
    const reverseArr = (_a = node.path) === null || _a === void 0 ? void 0 : _a.split("/").reverse();
    node.name = reverseArr && reverseArr[0];
    // 如果当前节点有子节点，递归地对每个子节点调用此函数
    if (node.children && node.children.length > 0) {
        node.children.forEach((child) => getNewTreeData(child));
    }
    return node;
};
// 假设tree是你的树形结构的根节点
exports.default = getNewTreeData;
