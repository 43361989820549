"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const icon_1 = require("@arco-design/web-react/icon");
const jotai_1 = require("jotai");
const react_1 = __importStar(require("react"));
const codebaseChatModeAtom_1 = require("@src/store/codebaseChatModeAtom");
const workspaceAtom_1 = require("@src/store/workspaceAtom");
const dot_1 = require("./dot");
const WorkingState = ({ isExpand, status, setStatus, setIsExpand, isBanExpand = false, }) => {
    var _a;
    const [lastDisplayedLog] = (0, jotai_1.useAtom)(workspaceAtom_1.lastDisplayedLogAtom);
    const [newDraftList] = (0, jotai_1.useAtom)(workspaceAtom_1.newDraftListAtom);
    const [isWaiting] = (0, jotai_1.useAtom)(workspaceAtom_1.isWaitingAtom);
    const [waitingStatus] = (0, jotai_1.useAtom)(workspaceAtom_1.waitingStatusAtom);
    const [curShowQueryCodeWorklog] = (0, jotai_1.useAtom)(codebaseChatModeAtom_1.curShowQueryCodeWorklogAtom);
    (0, react_1.useEffect)(() => {
        // Start with "Atlas is thinking..."
        setStatus("thinking");
        // First timeout to switch to "Atlas is navigating..."
        const navigatingTimer = setTimeout(() => {
            setStatus("navigating");
        }, 500); // Delay for "thinking"
        // Second timeout to switch to displaying the tool usage message
        const displayingTimer = setTimeout(() => {
            setStatus("displaying");
        }, 1500); // Additional delay for "navigating"
        return () => {
            clearTimeout(navigatingTimer);
            clearTimeout(displayingTimer);
        };
    }, [lastDisplayedLog]);
    (0, react_1.useEffect)(() => {
        if ((isWaiting &&
            (waitingStatus === "waitThinking" ||
                waitingStatus === "end" ||
                waitingStatus === "waitReviewing")) ||
            waitingStatus === "waitResearching" ||
            waitingStatus === "waitGenerating") {
            setIsExpand(false);
        }
        else if (isWaiting &&
            (waitingStatus === "initThinking" ||
                waitingStatus === "initNavigating" ||
                waitingStatus === "waitDisplaying")) {
            setIsExpand(true);
        }
        else {
            setIsExpand(true);
        }
    }, [isWaiting, waitingStatus]);
    return (react_1.default.createElement("div", { className: `relative mb-3 mt-1 box-border flex ${isExpand ? "h-[22px]" : "h-[20px]"} w-full items-center justify-center self-stretch px-1 py-0` },
        react_1.default.createElement("div", { className: "absolute left-1" },
            react_1.default.createElement(dot_1.Dot, null)),
        react_1.default.createElement("div", { className: "flex items-center text-[14px] text-primary-6" },
            react_1.default.createElement("div", { className: "mr-2 flex items-center" },
                !isWaiting && (react_1.default.createElement(react_1.default.Fragment, null,
                    status === "thinking" && (react_1.default.createElement(icon_1.IconBulb, { className: "h-[16px] w-[16px]" })),
                    status === "navigating" && (react_1.default.createElement(icon_1.IconCodeSquare, { className: "h-[16px] w-[16px]" })),
                    status === "displaying" && (react_1.default.createElement(icon_1.IconCodeSquare, { className: "h-[16px] w-[16px]" })))),
                isWaiting && (react_1.default.createElement(react_1.default.Fragment, null, (() => {
                    switch (waitingStatus) {
                        case "waitThinking":
                            return react_1.default.createElement(icon_1.IconBulb, { className: "h-[16px] w-[16px]" });
                        case "waitNavigating":
                            return react_1.default.createElement(icon_1.IconCodeSquare, { className: "h-[16px] w-[16px]" });
                        case "waitDisplaying":
                            return react_1.default.createElement(icon_1.IconCodeSquare, { className: "h-[16px] w-[16px]" });
                        case "waitEditing":
                            return react_1.default.createElement(icon_1.IconCodeSquare, { className: "h-[16px] w-[16px]" });
                        case "waitReviewing":
                            return react_1.default.createElement(icon_1.IconCodeSquare, { className: "h-[16px] w-[16px]" });
                        case "waitGenerating":
                            return react_1.default.createElement(icon_1.IconCodeSquare, { className: "h-[16px] w-[16px]" });
                        case "initThinking":
                            return react_1.default.createElement(icon_1.IconBulb, { className: "h-[16px] w-[16px]" });
                        case "initNavigating":
                            return react_1.default.createElement(icon_1.IconCodeSquare, { className: "h-[16px] w-[16px]" });
                        case "end":
                            return (react_1.default.createElement(icon_1.IconClockCircle, { className: "h-[16px] w-[16px] text-text-2" }));
                        case "isNoWaitingThinking":
                            return react_1.default.createElement(icon_1.IconBulb, { className: "h-[16px] w-[16px]" });
                        case "waitResearching":
                            return react_1.default.createElement(icon_1.IconBulb, { className: "h-[16px] w-[16px]" });
                        default:
                            return (react_1.default.createElement(icon_1.IconClockCircle, { className: "h-[16px] w-[16px] text-text-2" })); // 当没有匹配的状态时，不渲染任何内容
                    }
                })()))),
            !isWaiting && (react_1.default.createElement(react_1.default.Fragment, null,
                status === "thinking" && react_1.default.createElement("div", null, "Atlas is thinking..."),
                status === "navigating" && react_1.default.createElement("div", null, "Atlas is navigating..."),
                status === "displaying" && (react_1.default.createElement("div", null,
                    "Atlas is ",
                    newDraftList.length > 0 ? "editing" : "reading",
                    " ",
                    (lastDisplayedLog === null || lastDisplayedLog === void 0 ? void 0 : lastDisplayedLog.tool_call_path) ||
                        ((_a = newDraftList[newDraftList.length - 1]) === null || _a === void 0 ? void 0 : _a.path))))),
            isWaiting && (react_1.default.createElement(react_1.default.Fragment, null, (() => {
                var _a;
                switch (waitingStatus) {
                    case "waitThinking":
                        return react_1.default.createElement("div", null, "Atlas is thinking...");
                    case "waitNavigating":
                        return react_1.default.createElement("div", null, "Atlas is navigating");
                    case "waitDisplaying":
                        return (react_1.default.createElement("div", null,
                            "Atlas is reading",
                            " ", (_a = curShowQueryCodeWorklog[curShowQueryCodeWorklog.length - 1]) === null || _a === void 0 ? void 0 :
                            _a.tool_call_path));
                    case "waitEditing":
                        return react_1.default.createElement("div", null, "Atlas is editing...");
                    case "waitReviewing":
                        return react_1.default.createElement("div", null, "Atlas is reviewing and polishing the change");
                    case "waitGenerating":
                        return react_1.default.createElement("div", null, "Atlas is composing an answer for you");
                    case "isNoWaitingThinking":
                        return react_1.default.createElement("div", null, "Atlas is thinking...");
                    case "initThinking":
                        return react_1.default.createElement("div", null, "Atlas is thinking...");
                    case "initNavigating":
                        return react_1.default.createElement("div", null, "Atlas is navigating...");
                    case "waitResearching":
                        return react_1.default.createElement("div", null, "Searching the internet...");
                    case "end":
                        return (react_1.default.createElement("div", { className: "text-text-2" }, "Atlas is waiting for you"));
                    default:
                        return (react_1.default.createElement("div", { className: "text-text-2" }, "Atlas is waiting for you")); // 当没有匹配的状态时，不渲染任何内容
                }
            })()))),
        !isBanExpand && (react_1.default.createElement("div", { className: "absolute right-1", onClick: () => setIsExpand(!isExpand) }, isExpand ? (react_1.default.createElement(icon_1.IconShrink, { className: "h-[16px] w-[16px] text-text-2" })) : (react_1.default.createElement(icon_1.IconExpand, { className: "h-[16px] w-[16px] text-text-2" }))))));
};
exports.default = WorkingState;
