"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const web_react_1 = require("@arco-design/web-react");
const icon_1 = require("@arco-design/web-react/icon");
const react_1 = __importStar(require("react"));
const rengerBreadCrumb_1 = require("@src/util/share/rengerBreadCrumb");
require("./index.scss");
const WorkLogCollapse = ({ breadcrumbData, fileContentObj, wpTaskStatus, expandAll }) => {
    const [activeKey, setActiveKey] = (0, react_1.useState)("");
    const handleCopy = (0, react_1.useCallback)(() => {
        navigator.clipboard
            .writeText(breadcrumbData.tool_call_path)
            .then(() => web_react_1.Message.success("Content copied successfully!"))
            .catch((err) => web_react_1.Message.error("Failed to copy content: " + err));
    }, [breadcrumbData.tool_call_path]);
    (0, react_1.useEffect)(() => {
        expandAll ? setActiveKey("1") : setActiveKey("");
    }, [expandAll]);
    return (react_1.default.createElement("div", { className: "worklog-collapse" },
        react_1.default.createElement(web_react_1.Collapse, { className: "border-border-2", expandIcon: react_1.default.createElement(icon_1.IconCaretRight, { className: " text-text-2" }), activeKey: activeKey, onChange: (key) => {
                activeKey === "1" ? setActiveKey("") : setActiveKey("1");
            } },
            react_1.default.createElement(web_react_1.Collapse.Item, { header: react_1.default.createElement("div", { className: "ml-2 flex items-center justify-center" },
                    react_1.default.createElement("div", { className: "mr-[10px] text-[14px] font-semibold  text-text-1" }, breadcrumbData.tool_name + ":"),
                    react_1.default.createElement(web_react_1.Breadcrumb, { className: "mb-[1px] mr-2" }, (0, rengerBreadCrumb_1.renderBreadcrumb)(breadcrumbData.tool_call_path)),
                    react_1.default.createElement(icon_1.IconCopy, { className: "cursor-pointer", onClick: handleCopy })), name: "1" },
                react_1.default.createElement("div", { className: "h-full bg-black" },
                    react_1.default.createElement("p", { className: "px-4 py-2 text-[14px] text-[white]" },
                        "",
                        fileContentObj))))));
};
exports.default = WorkLogCollapse;
