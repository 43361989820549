// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-modal-body .arco-menu {
    width: 180px
}
.settings-modal-body .arco-menu-item:focus-visible {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.settings-modal-body .arco-menu-item.arco-menu-selected {
    background-color: inherit
}
.settings-modal-body .button-white {
    border-radius: 0.5rem;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-border-2);
    background-color: var(--color-bg-8)
}
.settings-modal-body .radio-item {
    line-height: 22px
}
.settings-modal-body .setting__subscription .arco-btn-secondary {
    border-style: none
}`, "",{"version":3,"sources":["webpack://./src/components/SettingsModal/index.scss"],"names":[],"mappings":"AAEI;IAAA;AAAA;AAGA;IAAA,sBAAA;IAAA,8BAAA;IAAA;AAAA;AAGE;IAAA;AAAA;AAKF;IAAA,qBAAA;IAAA,iBAAA;IAAA,mBAAA;IAAA,mCAAA;IAAA;AAAA;AAGA;IAAA;AAAA;AAUE;IAAA;AAAA","sourcesContent":[".settings-modal-body {\n  .arco-menu {\n    @apply w-[180px];\n  }\n  .arco-menu-item {\n    @apply focus-visible:shadow-none;\n\n    &.arco-menu-selected {\n      @apply bg-inherit;\n    }\n  }\n\n  .button-white {\n    @apply rounded-lg border border-solid border-border-2 bg-bg-8;\n  }\n  .radio-item {\n    @apply leading-[22px];\n  }\n  .setting__subscription {\n    .pay__info {\n      // &::-webkit-scrollbar {\n      //   display: none;\n      // }\n    }\n\n    .arco-btn-secondary {\n      @apply border-none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
