"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.totalSizeAtom = exports.currentRepoLanguageDataAtom = exports.communityRepoTabAtom = exports.filterDatabaseAtom = exports.databaseViewTypeAtom = void 0;
const jotai_1 = require("jotai");
// database view type
exports.databaseViewTypeAtom = (0, jotai_1.atom)("Card");
// filter database params
exports.filterDatabaseAtom = (0, jotai_1.atom)({
    page_idx: 1,
    page_volume: 12,
    keyword: "",
    state: 1,
    type: "1",
});
exports.communityRepoTabAtom = (0, jotai_1.atom)("About");
exports.currentRepoLanguageDataAtom = (0, jotai_1.atom)([]);
exports.totalSizeAtom = (0, jotai_1.atom)(0);
