"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isWorkingLogExpandAtom = exports.defaultwhiteListExpandedKeysAtom = exports.defaultwhiteListSelectedKeysAtom = exports.whiteListTreeAtom = exports.showVerifyModalAtom = exports.sessionCountAtom = exports.verifyThoughtAtom = exports.showFinalMessageAtom = exports.waitingStatusAtom = exports.isWaitingAtom = exports.newDraftListAtom = exports.curDraftpathAtom = exports.isThinkingAtom = exports.lastDisplayedLogAtom = exports.chatIconShowAtom = exports.isWpGeneWorkingAtom = exports.wpIsDoingRegeAtom = exports.selectHighlightsAtom = exports.highlightsAtom = exports.wpCurFilePathAtom = exports.wpTaskStatusAtom = exports.wpSessionShowAtom = exports.wpShowChatAtom = exports.wpCentertabActiveAtom = exports.clearChatAtom = exports.createNewProjectAtom = exports.codeBaseSideBarAtom = exports.curTaskIdAtom = exports.curProIdAtom = exports.wpIsLoadingAtom = exports.workSpaceTabAtom = void 0;
const jotai_1 = require("jotai");
// 创建一个原子
exports.workSpaceTabAtom = (0, jotai_1.atom)(true);
exports.wpIsLoadingAtom = (0, jotai_1.atom)(false);
exports.curProIdAtom = (0, jotai_1.atom)("");
exports.curTaskIdAtom = (0, jotai_1.atom)("");
exports.codeBaseSideBarAtom = (0, jotai_1.atom)("1");
exports.createNewProjectAtom = (0, jotai_1.atom)(false);
exports.clearChatAtom = (0, jotai_1.atom)(false);
exports.wpCentertabActiveAtom = (0, jotai_1.atom)("1");
exports.wpShowChatAtom = (0, jotai_1.atom)(false);
exports.wpSessionShowAtom = (0, jotai_1.atom)(false); //work for header states.
exports.wpTaskStatusAtom = (0, jotai_1.atom)("none"); //alignment,add_req,decide_dev,focus_file,generate_plan,generate_actions,apply_actions,done
exports.wpCurFilePathAtom = (0, jotai_1.atom)("");
//highlight line
exports.highlightsAtom = (0, jotai_1.atom)([]);
//highlight range
exports.selectHighlightsAtom = (0, jotai_1.atom)([]);
exports.wpIsDoingRegeAtom = (0, jotai_1.atom)(false);
exports.isWpGeneWorkingAtom = (0, jotai_1.atom)(false);
exports.chatIconShowAtom = (0, jotai_1.atom)(false);
exports.lastDisplayedLogAtom = (0, jotai_1.atom)(null);
exports.isThinkingAtom = (0, jotai_1.atom)(false);
exports.curDraftpathAtom = (0, jotai_1.atom)("");
exports.newDraftListAtom = (0, jotai_1.atom)([]);
exports.isWaitingAtom = (0, jotai_1.atom)(true);
exports.waitingStatusAtom = (0, jotai_1.atom)("end");
exports.showFinalMessageAtom = (0, jotai_1.atom)(false);
exports.verifyThoughtAtom = (0, jotai_1.atom)("");
exports.sessionCountAtom = (0, jotai_1.atom)(0);
exports.showVerifyModalAtom = (0, jotai_1.atom)(false);
exports.whiteListTreeAtom = (0, jotai_1.atom)([]);
exports.defaultwhiteListSelectedKeysAtom = (0, jotai_1.atom)([]);
exports.defaultwhiteListExpandedKeysAtom = (0, jotai_1.atom)([]);
exports.isWorkingLogExpandAtom = (0, jotai_1.atom)(false);
