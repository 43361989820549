"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Subscription = void 0;
const web_react_1 = require("@arco-design/web-react");
const icon_1 = require("@arco-design/web-react/icon");
const i18next_1 = require("i18next");
const jotai_1 = require("jotai");
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const paymentApi_1 = require("@src/api/paymentApi/paymentApi");
const codebaseHomeAtom_1 = require("@src/store/codebaseHomeAtom");
const Button_1 = require("../Button");
const Subscription = ({ setInvisible, setCurrentKey, }) => {
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const navigate = (0, react_router_dom_1.useNavigate)();
    const [userPaymentInfo, setUserPaymentInfo] = (0, jotai_1.useAtom)(codebaseHomeAtom_1.userPaymentInfoAtom);
    const { getUserSubscription, postCancelSubscription } = paymentApi_1.paymentApi;
    const [cancelModalVis, setCancelModalVis] = (0, react_1.useState)(false);
    const { subscription_tier, subscription_type, next_payment_date, current_atlas_request_limit, current_api_key_request_limit, current_research_limit, active, refill_day, current_copilot_chat_atlas_limit, current_copilot_chat_on_prem_limit, current_copilot_auto_complete_atlas_limit, current_copilot_auto_complete_on_prem_limit, } = userPaymentInfo;
    const fetchUserInfo = async () => {
        try {
            setIsLoading(true);
            const payInfo = (await getUserSubscription());
            if (payInfo.status === 0) {
                setIsLoading(false);
                setUserPaymentInfo(payInfo.data);
            }
        }
        catch (e) {
            setIsLoading(false);
            web_react_1.Message.error("Fetch Payment Info Error");
            //console.log(e);
        }
    };
    const handleCancel = async () => {
        try {
            const res = await postCancelSubscription();
            if ((res === null || res === void 0 ? void 0 : res.status) === 0) {
                web_react_1.Message.success("Cancel Success!");
                fetchUserInfo();
                setCancelModalVis(false);
            }
            else {
                web_react_1.Message.warning("Cancel Error");
            }
        }
        catch (e) {
            //console.log(e);
        }
    };
    const CancelModal = () => {
        return (react_1.default.createElement(web_react_1.Modal, { title: react_1.default.createElement("div", { style: { textAlign: "left", fontSize: "14px" } },
                (0, i18next_1.t)("payment.setting.cancelSub"),
                "?"), visible: cancelModalVis, onOk: () => {
                setCancelModalVis(false);
            }, className: "copilot__update__modal", onCancel: () => {
                handleCancel();
            }, closeIcon: react_1.default.createElement(icon_1.IconClose, { onClick: (event) => {
                    event.stopPropagation();
                    setCancelModalVis(false);
                }, className: "relative top-[-4px]" }), okText: "No", cancelButtonProps: {
                status: "danger",
            }, cancelText: (0, i18next_1.t)("payment.setting.cancelSub") },
            react_1.default.createElement("p", null, (0, i18next_1.t)("payment.setting.cancelSubInfo"))));
    };
    (0, react_1.useEffect)(() => {
        fetchUserInfo();
    }, []);
    return (react_1.default.createElement("div", { className: "setting__subscription flex h-full flex-1 flex-col" },
        react_1.default.createElement("div", { className: "pay__info flex flex-grow flex-col gap-3 overflow-y-scroll px-3 py-3" },
            react_1.default.createElement(web_react_1.Spin, { loading: isLoading },
                react_1.default.createElement("div", { className: "member__info flex flex-col gap-3 border-b border-solid border-border-2 pb-3" },
                    react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                        react_1.default.createElement("p", { className: "text-[14px] text-text-2" }, (0, i18next_1.t)("payment.setting.subscribeTire")),
                        react_1.default.createElement("p", { className: "text-[14px] text-primary-6" }, subscription_tier === "basic"
                            ? (0, i18next_1.t)("payment.setting.free")
                            : (subscription_tier === null || subscription_tier === void 0 ? void 0 : subscription_tier.charAt(0).toUpperCase()) +
                                (subscription_tier === null || subscription_tier === void 0 ? void 0 : subscription_tier.slice(1))),
                        !active && (react_1.default.createElement(Button_1.Button, { type: "primary", className: "h8 w-[97px]", onClick: () => {
                                navigate("/payment/prices");
                                setInvisible();
                            } }, (0, i18next_1.t)("payment.prices.subscribe"))),
                        react_1.default.createElement("div", { className: "flex flex-col" },
                            react_1.default.createElement("p", { className: "mb-2 text-text-2" },
                                (0, i18next_1.t)("payment.setting.refillDate"),
                                ":"),
                            react_1.default.createElement("p", { className: "text-[14px] font-[500] text-primary-6" }, subscription_tier && subscription_tier === "basic"
                                ? refill_day
                                : next_payment_date))),
                    active && (react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                            react_1.default.createElement("p", { className: "text-[14px] text-text-2" }, "Subscription Type:"),
                            react_1.default.createElement("p", { className: "text-[14px] text-primary-6" }, (0, i18next_1.t)("payment.setting.payMonth"))),
                        react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                            react_1.default.createElement("p", { className: "text-[14px] text-text-2" },
                                " ",
                                (0, i18next_1.t)("payment.setting.nextPay"),
                                ":"),
                            react_1.default.createElement("p", { className: "text-[14px] text-primary-6" }, next_payment_date)),
                        react_1.default.createElement(Button_1.Button, { type: "gray", className: "h8 w-[163px] border-none", onClick: () => {
                                setCancelModalVis(true);
                            } }, (0, i18next_1.t)("payment.setting.cancelSub"))))),
                react_1.default.createElement("div", { className: "mt-3 flex flex-col " },
                    react_1.default.createElement("p", { className: "mb-2 text-text-2" },
                        " ",
                        (0, i18next_1.t)("payment.setting.exploreUsage")),
                    react_1.default.createElement("div", null,
                        react_1.default.createElement("ul", { style: { listStyleType: "disc" }, className: "pl-[25px]" },
                            react_1.default.createElement("li", null,
                                react_1.default.createElement("span", { className: "font-[500]" }, (0, i18next_1.t)("payment.setting.useAtlasKey")),
                                ": ",
                                current_atlas_request_limit,
                                " ",
                                (0, i18next_1.t)("payment.setting.remains")),
                            react_1.default.createElement("li", null,
                                react_1.default.createElement("span", { className: "font-[500]" }, (0, i18next_1.t)("payment.setting.useYourKey")),
                                ": ",
                                current_api_key_request_limit,
                                " ",
                                (0, i18next_1.t)("payment.setting.remains"))))),
                react_1.default.createElement("div", { className: "mt-3 flex flex-col border-b border-solid border-border-2 pb-3" },
                    react_1.default.createElement("p", { className: "mb-2 text-text-2" },
                        (0, i18next_1.t)("payment.setting.researchUsage"),
                        ":"),
                    react_1.default.createElement("div", null,
                        react_1.default.createElement("ul", { style: { listStyleType: "disc" }, className: "pl-[25px]" },
                            react_1.default.createElement("li", null,
                                react_1.default.createElement("span", { className: "font-[500]" }, (0, i18next_1.t)("payment.setting.useAtlasKey")),
                                ": ",
                                current_research_limit,
                                " ",
                                (0, i18next_1.t)("payment.setting.remains")))),
                    react_1.default.createElement(Button_1.Button, { type: "gray", className: "mt-3 h-8 w-[94px]", onClick: () => {
                            setCurrentKey("API Keys");
                        } }, (0, i18next_1.t)("payment.setting.addKey"))),
                react_1.default.createElement("div", { className: "mt-3 flex flex-col " },
                    react_1.default.createElement("p", { className: "mb-2 text-text-2" }, " Copilot - Chat"),
                    react_1.default.createElement("div", null,
                        react_1.default.createElement("ul", { style: { listStyleType: "disc" }, className: "pl-[25px]" },
                            react_1.default.createElement("li", null,
                                react_1.default.createElement("span", { className: "font-[500]" }, (0, i18next_1.t)("payment.setting.useAtlasKey")),
                                ": ",
                                current_copilot_chat_atlas_limit,
                                " ",
                                (0, i18next_1.t)("payment.setting.remains")),
                            react_1.default.createElement("li", null,
                                (0, i18next_1.t)("payment.setting.useYourKey"),
                                ":",
                                " ",
                                react_1.default.createElement("span", { className: "font-[500]" }, current_copilot_chat_on_prem_limit),
                                " ",
                                (0, i18next_1.t)("payment.setting.remains"))))),
                react_1.default.createElement("div", { className: "mt-3 flex flex-col pb-3" },
                    react_1.default.createElement("p", { className: "mb-2 text-text-2" }, "Copilot - Autocompletion:"),
                    react_1.default.createElement("div", null,
                        react_1.default.createElement("ul", { style: { listStyleType: "disc" }, className: "pl-[25px] " },
                            react_1.default.createElement("li", null,
                                react_1.default.createElement("span", { className: "font-[500]" }, (0, i18next_1.t)("payment.setting.useAtlasKey")),
                                ": ",
                                current_copilot_auto_complete_atlas_limit,
                                " ",
                                (0, i18next_1.t)("payment.setting.remains")),
                            react_1.default.createElement("li", null,
                                react_1.default.createElement("span", { className: "font-[500]" }, (0, i18next_1.t)("payment.setting.useYourKey")),
                                ": ",
                                current_copilot_auto_complete_on_prem_limit,
                                (0, i18next_1.t)("payment.setting.remains")))),
                    react_1.default.createElement(Button_1.Button, { type: "gray", className: "mt-3 h-8 w-[94px]", onClick: () => {
                            navigate("/codebase/copilot");
                            setInvisible();
                        } }, (0, i18next_1.t)("payment.setting.config"))))),
        react_1.default.createElement("div", { className: "flex justify-end gap-2 border-t border-solid border-border-2 p-3" },
            react_1.default.createElement(Button_1.Button, { type: "gray", onClick: fetchUserInfo, disabled: isLoading }, (0, i18next_1.t)("payment.setting.refresh")),
            react_1.default.createElement(Button_1.Button, { loading: isLoading, onClick: () => {
                    navigate("/payment/prices");
                } }, (0, i18next_1.t)("payment.setting.manage"))),
        react_1.default.createElement(CancelModal, null)));
};
exports.Subscription = Subscription;
