"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateResearchResItem = void 0;
const useCreateResearchResItem = () => {
    const createResearchResItem = (inputText, isUser, ref_links, suggestions) => {
        return {
            content: inputText,
            isUser: isUser,
            ref_links: ref_links,
            suggestions: suggestions,
        };
    };
    return createResearchResItem;
};
exports.useCreateResearchResItem = useCreateResearchResItem;
