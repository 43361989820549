"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGitlabRepoList = exports.getGithubRepoList = exports.deleteRepo = exports.getCodebase = exports.getCodebaseList = exports.cloneLocalRepo = exports.cloneRemoteRepo = void 0;
const apiConfigV2_1 = require("../apiConfigV2");
const url = "/api/v2/codebase";
const cloneRemoteRepo = (params) => {
    const defaultParams = {
        ...params,
        user_id: params.user_id || "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    };
    return (0, apiConfigV2_1.postApi)(url + "/create_remote_codebase", defaultParams, true);
};
exports.cloneRemoteRepo = cloneRemoteRepo;
const cloneLocalRepo = ({ source_path }) => {
    return (0, apiConfigV2_1.postApi)(url + "/create_local_codebase", {
        source_path,
        use_symlinks: true,
    }, true);
};
exports.cloneLocalRepo = cloneLocalRepo;
const getCodebaseList = (params) => {
    return (0, apiConfigV2_1.postApi)(url + "/get_codebase_info_list", params, true);
};
exports.getCodebaseList = getCodebaseList;
const getCodebase = (params) => {
    return (0, apiConfigV2_1.getApi)(url + "/get_codebase", params, true);
};
exports.getCodebase = getCodebase;
const deleteRepo = (params) => {
    return (0, apiConfigV2_1.postApi)(url + "/delete_codebase", params, true);
};
exports.deleteRepo = deleteRepo;
const getGithubRepoList = (params) => {
    return (0, apiConfigV2_1.getApi)(url + "/get_github_repo", params, true);
};
exports.getGithubRepoList = getGithubRepoList;
const getGitlabRepoList = (params) => {
    return (0, apiConfigV2_1.getApi)(url + "/get_gitlab_repo", params, true);
};
exports.getGitlabRepoList = getGitlabRepoList;
