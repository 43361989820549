"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useElementWidth = (ref, isShowChat) => {
    const [width, setWidth] = (0, react_1.useState)(0);
    (0, react_1.useEffect)(() => {
        const handleResize = () => {
            var _a;
            if (ref.current instanceof HTMLElement) {
                setWidth(ref.current.clientWidth);
            }
            else if (((_a = ref.current) === null || _a === void 0 ? void 0 : _a.current) instanceof HTMLElement) {
                setWidth(ref.current.current.clientWidth);
            }
        };
        // 监听窗口大小变化事件以更新高度
        window.addEventListener("resize", handleResize);
        // 初始计算高度
        handleResize();
        // 清理函数
        return () => window.removeEventListener("resize", handleResize);
    }, [ref, isShowChat]); // 依赖于 ref，当 ref 变化时重新执行
    return width;
};
exports.default = useElementWidth;
