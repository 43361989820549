"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const CBWorkSpaceContext_1 = require("../CBWorkSpaceContext");
const useWpChatHistory = () => {
    const { setChatHistory } = (0, react_1.useContext)(CBWorkSpaceContext_1.WorkSpaceContext);
    const clearErrorBox = () => {
        setChatHistory((prevChatHistory) => {
            const newArr = prevChatHistory.filter((v) => v.title !== "error_msg");
            return newArr;
        });
    };
    const showErrorBox = (handleError) => {
        const newChatItem = {
            text: "An error occured. Please try again.",
            isUser: false, // 代理发送的消息
            type: "error",
            expand: true,
            finish: false,
            title: "error_msg",
            handleYes: handleError,
            ask: true,
        };
        setChatHistory((prevChatHistory) => [...prevChatHistory, newChatItem]);
    };
    return {
        clearErrorBox,
        showErrorBox,
    };
};
exports.default = useWpChatHistory;
