// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.private-repo .arco-menu-inner {
    display: flex;
    width: 412px;
    flex-direction: column;
    gap: 0.75rem;
    overflow-x: hidden;
    padding: 0px;
    padding-top: 0.5rem
}
.private-repo .arco-menu-item {
    margin: 0px;
    display: flex;
    align-items: center;
    padding: 0px;
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: 30px;
    color: var(--color-text-1)
}
.private-repo .arco-menu-item .arco-icon {
    margin-right: 0.5rem;
    color: inherit
}
.private-repo .arco-menu-item:hover {
    color: inherit
}
.private-repo .arco-menu-item:hover .arco-icon {
    color: inherit
}
.private-repo .arco-menu-item.arco-menu-selected {
    background-color: rgb(var(--primary-1));
    font-weight: 400;
    color: inherit
}
.private-repo .arco-menu-item.arco-menu-selected .arco-icon {
    color: inherit
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Home/TopBar/AddRepoModal/MyRepo/index.scss"],"names":[],"mappings":"AAEI;IAAA,aAAA;IAAA,YAAA;IAAA,sBAAA;IAAA,YAAA;IAAA,kBAAA;IAAA,YAAA;IAAA;AAAA;AAIA;IAAA,WAAA;IAAA,aAAA;IAAA,mBAAA;IAAA,YAAA;IAAA,kBAAA;IAAA,mBAAA;IAAA,iBAAA;IAAA;AAAA;AAGE;IAAA,oBAAA;IAAA;AAAA;AAIA;IAAA;AAAA;AAGE;IAAA;AAAA;AAKF;IAAA,uCAAA;IAAA,gBAAA;IAAA;AAAA;AAGE;IAAA;AAAA","sourcesContent":[".private-repo {\n  .arco-menu-inner {\n    @apply flex w-[412px] flex-col gap-3 overflow-x-hidden p-0 pt-2;\n  }\n\n  .arco-menu-item {\n    @apply m-0 flex items-center p-0 px-4 leading-[30px] text-text-1;\n\n    .arco-icon {\n      @apply mr-2 text-inherit;\n    }\n\n    &:hover {\n      @apply text-inherit;\n\n      .arco-icon {\n        @apply text-inherit;\n      }\n    }\n\n    &.arco-menu-selected {\n      @apply bg-primary-1 font-normal text-inherit;\n\n      .arco-icon {\n        @apply text-inherit;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
