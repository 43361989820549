"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const web_react_1 = require("@arco-design/web-react");
const icon_1 = require("@arco-design/web-react/icon");
const i18next_1 = require("i18next");
const react_1 = __importStar(require("react"));
const codebaseCopilot_1 = require("@src/api/codebaseApi/codebaseCopilot");
const OnPerm = () => {
    const [ollaConifg, setOllaConfig] = (0, react_1.useState)({
        use_ollama: false,
        ollama_api_base: "",
    });
    const [originConfig, setOriginConfig] = (0, react_1.useState)({
        use_ollama: false,
        ollama_api_base: "",
    });
    const handleUpdate = async () => {
        try {
            if (!ollaConifg.ollama_api_base) {
                web_react_1.Message.warning("API Base required");
                return;
            }
            const res = await (0, codebaseCopilot_1.postUpdateOllamaConfigs)(ollaConifg);
            if ((res === null || res === void 0 ? void 0 : res.status) === 0) {
                web_react_1.Message.success("Ollama config updated successfully");
            }
            else {
                web_react_1.Message.error("Ollama config updated failed");
            }
        }
        catch (e) {
            web_react_1.Message.error("Ollama config updated failed");
            console.log(e);
        }
    };
    (0, react_1.useEffect)(() => {
        const fetchDate = async () => {
            try {
                const res = (await (0, codebaseCopilot_1.getOllamaConfigs)());
                if (res.status === 0) {
                    setOllaConfig(res.data);
                    setOriginConfig(res.data);
                }
            }
            catch (e) {
                console.log(e);
            }
        };
        fetchDate();
    }, []);
    return (react_1.default.createElement("div", { className: "my__model flex w-full flex-1 flex-col items-center justify-center" },
        react_1.default.createElement("div", { className: "flex w-[550px] flex-col gap-6" },
            react_1.default.createElement("div", { className: "flex h-[120px] w-full flex-col gap-[2px] rounded-lg border border-solid border-border-2 p-3" },
                react_1.default.createElement("div", { className: "flex h-[22px] items-center " },
                    react_1.default.createElement(icon_1.IconBook, { className: "text-[18px] text-text-2" }),
                    react_1.default.createElement("p", { className: "ml-3 text-[14px] leading-[2px] text-text-2" }, (0, i18next_1.t)("home.dashboard.copilot.instruction"))),
                react_1.default.createElement("div", { className: "h-[66px]" },
                    react_1.default.createElement("ul", { style: { listStyleType: "disc", textAlign: "left" }, className: "flex flex-col pl-[25px] text-text-1" },
                        react_1.default.createElement("li", null,
                            react_1.default.createElement("span", { className: "text-[14px]" }, (0, i18next_1.t)("home.dashboard.copilot.permDownLoad"))),
                        react_1.default.createElement("li", null,
                            react_1.default.createElement("span", { className: "text-[14px]" },
                                (0, i18next_1.t)("home.dashboard.copilot.permRun1"),
                                react_1.default.createElement("span", { className: "text-primary-6" }, "ollama run <MODEL_NAME>"),
                                ". ",
                                (0, i18next_1.t)("home.dashboard.copilot.permRun2")))))),
            react_1.default.createElement("div", { className: "flex items-center justify-between gap-[114px]" },
                react_1.default.createElement("span", { className: "text-[14px] text-text-1" }, (0, i18next_1.t)("home.dashboard.copilot.useOll")),
                react_1.default.createElement(web_react_1.Switch, { size: "small", onChange: (e) => setOllaConfig((prev) => ({ ...prev, use_ollama: e })), checked: ollaConifg.use_ollama })),
            react_1.default.createElement("div", { className: "flex items-center justify-between gap-[114px]" },
                react_1.default.createElement("span", { className: "text-[14px] text-text-1" }, (0, i18next_1.t)("home.dashboard.copilot.apiBase")),
                react_1.default.createElement(web_react_1.Input, { placeholder: (0, i18next_1.t)("home.dashboard.copilot.enterAPIBase"), style: { width: 330, background: "var(--color-fill-2)" }, value: ollaConifg.ollama_api_base, onChange: (e) => setOllaConfig((prev) => ({ ...prev, ollama_api_base: e })) })),
            react_1.default.createElement("div", { className: "flex justify-end" },
                react_1.default.createElement(web_react_1.Button, { type: "secondary", className: "mr-2 h-8 w-[77px]", onClick: () => {
                        setOllaConfig(originConfig);
                    } }, (0, i18next_1.t)("home.dashboard.copilot.cancel")),
                react_1.default.createElement(web_react_1.Button, { type: "primary", className: "h-8 w-[79px]", onClick: handleUpdate }, (0, i18next_1.t)("home.dashboard.copilot.update"))))));
};
exports.default = OnPerm;
