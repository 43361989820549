// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arco-btn.button-primary {

    border-radius: 0.5rem;

    border-width: 1px;

    border-style: solid;

    border-color: rgb(var(--primary-1));

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.arco-btn.button-gray {

    border-radius: 0.5rem;

    border-width: 1px;

    border-style: solid;

    border-color: var(--color-border-2)
}

.arco-btn.button-white {

    border-radius: 0.5rem;

    border-width: 1px;

    border-style: solid;

    border-color: var(--color-border-2);

    background-color: var(--color-bg-1)
}

.arco-btn.arco-btn-status-danger.button-danger {

    border-radius: 0.5rem;

    border-width: 1px;

    border-style: solid;

    border-color: rgb(var(--danger-3));

    background-color: rgb(var(--danger-1));

    color: rgb(var(--danger-6))
}

.arco-btn.button-text {

    border-radius: 0.5rem;

    color: var(--color-text-2)
}
.arco-btn.button-text.arco-btn-text:hover {

    color: var(--color-text-2)
}
.arco-btn.button-text.arco-btn-text:not(.arco-btn-disabled):not(.arco-btn-loading):hover {

    color: var(--color-text-2)
}

.arco-btn.button-outline {

    border-radius: 0.5rem
}

.arco-btn.arco-btn-shape-round {

    border-radius: 9999px
}

.arco-btn.arco-btn-shape-circle {

    border-radius: 9999px
}`, "",{"version":3,"sources":["webpack://./src/components/Button/index.scss"],"names":[],"mappings":"AACE;;IAAA,qBAAA;;IAAA,iBAAA;;IAAA,mBAAA;;IAAA,mCAAA;;IAAA,oBAAA;;IAAA;AAAA;;AAIA;;IAAA,qBAAA;;IAAA,iBAAA;;IAAA,mBAAA;;IAAA;AAAA;;AAIA;;IAAA,qBAAA;;IAAA,iBAAA;;IAAA,mBAAA;;IAAA,mCAAA;;IAAA;AAAA;;AAIA;;IAAA,qBAAA;;IAAA,iBAAA;;IAAA,mBAAA;;IAAA,kCAAA;;IAAA,sCAAA;;IAAA;AAAA;;AAIA;;IAAA,qBAAA;;IAAA;AAAA;AAEE;;IAAA;AAAA;AAGA;;IAAA;AAAA;;AAKF;;IAAA;AAAA;;AAIA;;IAAA;AAAA;;AAIA;;IAAA;AAAA","sourcesContent":[".arco-btn.button-primary {\n  @apply rounded-lg border border-solid border-primary-1 text-white-100;\n}\n\n.arco-btn.button-gray {\n  @apply rounded-lg border border-solid border-border-2;\n}\n\n.arco-btn.button-white {\n  @apply rounded-lg border border-solid border-border-2 bg-bg-1;\n}\n\n.arco-btn.arco-btn-status-danger.button-danger {\n  @apply rounded-lg border border-solid border-danger-3 bg-danger-1 text-danger-6;\n}\n\n.arco-btn.button-text {\n  @apply rounded-lg text-text-2;\n  &.arco-btn-text:hover {\n    @apply text-text-2;\n  }\n  &.arco-btn-text:not(.arco-btn-disabled):not(.arco-btn-loading):hover {\n    @apply text-text-2;\n  }\n}\n\n.arco-btn.button-outline {\n  @apply rounded-lg;\n}\n\n.arco-btn.arco-btn-shape-round {\n  @apply rounded-full;\n}\n\n.arco-btn.arco-btn-shape-circle {\n  @apply rounded-full;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
