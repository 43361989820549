"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userPaymentInfoAtom = exports.codebaseTrendingCategoryAtom = exports.codebaseTrendingCategoryListAtom = exports.workSpaceListAtom = exports.workSpaceListGroupAtom = exports.workSpaceListCategoryAtom = exports.freshDataAtom = exports.selectedNodeKeyAtom = exports.bookMarkPointerAtom = exports.bookMarkAtom = exports.codebaseListAtom = exports.codebaseViewTypeAtom = exports.codebaseMaskVisibleAtom = exports.codebaseGroupAtom = exports.codebaseCategoryAtom = exports.filterCodebaseAtom = void 0;
const jotai_1 = require("jotai");
const codebaseHomeTypes_1 = require("@src/types/codebaseHomeTypes");
exports.filterCodebaseAtom = (0, jotai_1.atom)({
    page: 0,
    page_size: 9,
    source_type: [],
    keywords: "",
    accumulate: false,
    showLoading: true,
});
exports.codebaseCategoryAtom = (0, jotai_1.atom)("trending"); //myRepos,trending
exports.codebaseGroupAtom = (0, jotai_1.atom)("repoLocation");
exports.codebaseMaskVisibleAtom = (0, jotai_1.atom)(false);
exports.codebaseViewTypeAtom = (0, jotai_1.atom)("card");
exports.codebaseListAtom = (0, jotai_1.atom)([]);
// current file obj
exports.bookMarkAtom = (0, jotai_1.atom)([]);
exports.bookMarkPointerAtom = (0, jotai_1.atom)(0);
// record per module select key
exports.selectedNodeKeyAtom = (0, jotai_1.atom)(["0-0"]);
exports.freshDataAtom = (0, jotai_1.atom)(false);
exports.workSpaceListCategoryAtom = (0, jotai_1.atom)("myRepositories");
exports.workSpaceListGroupAtom = (0, jotai_1.atom)("repoLocation");
exports.workSpaceListAtom = (0, jotai_1.atom)({
    type: [
        codebaseHomeTypes_1.CodebaseQuerySourceType.PUBLIC,
        codebaseHomeTypes_1.CodebaseQuerySourceType.PRIVATE,
        codebaseHomeTypes_1.CodebaseQuerySourceType.LOCAL,
    ],
    page: 0,
    page_size: 10,
});
exports.codebaseTrendingCategoryListAtom = (0, jotai_1.atom)([]);
exports.codebaseTrendingCategoryAtom = (0, jotai_1.atom)("All");
const paymentInfo = {
    subscription_tier: "basic",
    subscription_type: "",
    next_payment_date: "",
    current_atlas_request_limit: 0,
    current_api_key_request_limit: 0,
    current_research_limit: 0,
    total_atlas_request_limit: 0,
    total_api_key_request_limit: 0,
    total_research_limit: 0,
    active: false,
    refill_day: "",
    current_copilot_chat_atlas_limit: 0,
    current_copilot_chat_on_prem_limit: 0,
    current_copilot_auto_complete_atlas_limit: 0,
    current_copilot_auto_complete_on_prem_limit: 0,
    total_copilot_chat_atlas_limit: 0,
    total_copilot_chat_on_prem_limit: 0,
    total_copilot_auto_complete_atlas_limit: 0,
    total_copilot_auto_complete_on_prem_limit: 0,
    openai_key_provided: false,
    deepseek_key_provided: false,
};
exports.userPaymentInfoAtom = (0, jotai_1.atom)(paymentInfo);
