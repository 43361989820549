// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-home .button-gray {
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-border-2)
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Detail/DetailBottom/DetailHome/index.scss"],"names":[],"mappings":"AACE;IAAA,iBAAA;IAAA,mBAAA;IAAA;AAAA","sourcesContent":[".detail-home .button-gray {\n  @apply border border-solid border-border-2;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
