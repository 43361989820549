// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.code_diff_tab_container .select_all .arco-btn-secondary {
    display: flex;
    height: 1.5rem;
    width: 77px;
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-color: var(--color-border-2);
    background-color: var(--color-bg-1);
    font-size: 0.75rem;
    line-height: 1.25rem
}
.code_diff_tab_container .button_wrpper {
    display: flex
}
.code_diff_tab_container .button_wrpper .arco-btn-primary:first-child {
    width: 103px
}
.code_diff_tab_container .arco-tabs-header-nav {
    height: 46px;
    padding-left: 0.75rem;
    padding-right: 0.75rem
}
.code_diff_tab_container .arco-tabs-header-nav::before {
    width: 100%
}
.code_diff_tab_container .arco-tabs {
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    overflow-y: auto
}
.code_diff_tab_container .arco-tabs-header-title-active,
.code_diff_tab_container .arco-tabs-header-title-active:hover {
    color: rgb(var(--primary-6))
}
.code_diff_tab_container .arco-tabs-header-scroll {
    height: 46px;
    width: 100%
}
.code_diff_tab_container .arco-tabs-header-scroll > .arco-tabs-header-wrapper {
    height: 46px;
    width: 100%
}
.code_diff_tab_container .arco-tabs-header-scroll > .arco-tabs-header-wrapper > .arco-tabs-header {
    display: flex;
    height: 46px;
    width: 100%;
    justify-content: space-around
}
.code_diff_tab_container .arco-input-group-wrapper {
    background-color: var(--color-fill-1)
}
.code_diff_tab_container .arco-tabs-content {
    flex: 1 1 0%;
    border-bottom-left-radius: 0.75rem;
    padding: 0px
}
.code_diff_tab_container .arco-tabs-content .arco-tabs-content-inner {
    height: 100%
}
.code_diff_tab_container .arco-tabs-content .arco-tabs-content-inner .arco-tabs-pane {
    height: 100%
}
.code_diff_tab_container .arco-tree {
    background-color: var(--color-bg-1)
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Workspace/CBCodebaseLeft/index.scss"],"names":[],"mappings":"AAGM;IAAA,aAAA;IAAA,cAAA;IAAA,WAAA;IAAA,mBAAA;IAAA,uBAAA;IAAA,mBAAA;IAAA,mCAAA;IAAA,mCAAA;IAAA,kBAAA;IAAA;AAAA;AAIF;IAAA;AAAA;AAGI;IAAA;AAAA;AAKJ;IAAA,YAAA;IAAA,qBAAA;IAAA;AAAA;AAGA;IAAA;AAAA;AAGA;IAAA,aAAA;IAAA,YAAA;IAAA,sBAAA;IAAA;AAAA;AAIA;;IAAA;AAAA;AAIA;IAAA,YAAA;IAAA;AAAA;AAEE;IAAA,YAAA;IAAA;AAAA;AAEE;IAAA,aAAA;IAAA,YAAA;IAAA,WAAA;IAAA;AAAA;AAMJ;IAAA;AAAA;AAGA;IAAA,YAAA;IAAA,kCAAA;IAAA;AAAA;AAEE;IAAA;AAAA;AAEE;IAAA;AAAA;AAKJ;IAAA;AAAA","sourcesContent":[".code_diff_tab_container {\r\n  .select_all {\r\n    .arco-btn-secondary {\r\n      @apply flex h-6 w-[77px] items-center justify-center border-solid border-border-2 bg-bg-1 text-xs;\r\n    }\r\n  }\r\n  .button_wrpper {\r\n    @apply flex;\r\n    .arco-btn-primary {\r\n      &:first-child {\r\n        @apply w-[103px];\r\n      }\r\n    }\r\n  }\r\n  .arco-tabs-header-nav {\r\n    @apply h-[46px] px-3;\r\n  }\r\n  .arco-tabs-header-nav::before {\r\n    @apply w-full;\r\n  }\r\n  .arco-tabs {\r\n    @apply flex flex-1 flex-col overflow-y-auto;\r\n  }\r\n  .arco-tabs-header-title-active,\r\n  .arco-tabs-header-title-active:hover {\r\n    @apply text-primary-6;\r\n  }\r\n\r\n  .arco-tabs-header-scroll {\r\n    @apply h-[46px] w-full;\r\n    > .arco-tabs-header-wrapper {\r\n      @apply h-[46px] w-full;\r\n      > .arco-tabs-header {\r\n        @apply flex h-[46px] w-full justify-around;\r\n      }\r\n    }\r\n  }\r\n\r\n  .arco-input-group-wrapper {\r\n    @apply bg-fill-1;\r\n  }\r\n  .arco-tabs-content {\r\n    @apply flex-1 rounded-bl-xl  p-0;\r\n    .arco-tabs-content-inner {\r\n      @apply h-full;\r\n      .arco-tabs-pane {\r\n        @apply h-full;\r\n      }\r\n    }\r\n  }\r\n  .arco-tree {\r\n    @apply bg-bg-1;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
