// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.research-result .arco-input {
  border-width: 0px;
}
.research-result .arco-input:focus, .research-result .arco-input.arco-input-focus {
  background-color: transparent;
}
.research-result .line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.research-result .arco-input-disabled {
  background-color: transparent;
}
.research-result .arco-typography {
  -webkit-hyphens: auto !important;
          hyphens: auto !important;
  word-break: break-word !important;
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Research/ResearchResult/index.scss"],"names":[],"mappings":"AAEI;EAAA;AAAA;AAGA;EAAA;AAAA;AAEH;EACC,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;EACA,uBAAA;AAAF;AAGG;EAAA;AAAA;AAIA;EACI,gCAAA;UAAA,wBAAA;EACL,iCAAA;AAFF","sourcesContent":[".research-result {\n .arco-input {\n    @apply border-0\n }\n .arco-input:focus, .arco-input.arco-input-focus {\n    @apply bg-transparent\n }\n .line-clamp-2 {\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.arco-input-disabled {\n   @apply bg-transparent\n}\n\n\n   .arco-typography {\n       hyphens: auto !important;\n  word-break: break-word !important;\n   }\n   \n\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
