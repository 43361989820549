"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHeaderList = void 0;
const jotai_1 = require("jotai");
const allAtom_1 = require("@src/store/allAtom");
const useHeaderList = () => {
    const [headerList, setHeaderList] = (0, jotai_1.useAtom)(allAtom_1.headerListAtom);
    const setHeaderListStatic = (shouldHighlight, fullList) => {
        setHeaderList(fullList.map((item) => ({
            ...item,
            isHightLight: item === shouldHighlight,
        })));
    };
    return { headerList, setHeaderList, setHeaderListStatic };
};
exports.useHeaderList = useHeaderList;
