// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.workspace_frame .arco-spin-children {
    height: 100%
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Workspace/index.scss"],"names":[],"mappings":"AAEI;IAAA;AAAA","sourcesContent":[".workspace_frame {\r\n  .arco-spin-children {\r\n    @apply h-full;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
