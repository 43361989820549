// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.price__list .prices__tooltip {
  max-width: 378px !important;
}
.price__list .gradient-background {
  background: linear-gradient(to bottom, #fae5f1, #c5e6f7);
}`, "",{"version":3,"sources":["webpack://./src/page/Payment/Prices/PricesList/index.scss"],"names":[],"mappings":"AACE;EACE,2BAAA;AAAJ;AAEE;EACE,wDAAA;AAAJ","sourcesContent":[".price__list {\n  .prices__tooltip {\n    max-width: 378px !important;\n  }\n  .gradient-background {\n    background: linear-gradient(to bottom, #fae5f1, #c5e6f7);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
