"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const web_react_1 = require("@arco-design/web-react");
const icon_1 = require("@arco-design/web-react/icon");
const react_1 = __importStar(require("react"));
const Button_1 = require("@src/components/Button");
// import { softwareEngineerApi } from "@api/softwareEngineer";
require("./index.scss");
const FolderCollapse = ({ itemPath, handleDelete, breadcrumbData }) => {
    const [activeKey, setActiveKey] = (0, react_1.useState)("");
    const handleClickDelete = () => {
        handleDelete && handleDelete(itemPath);
    };
    // 创建一个隐藏的文本输入框
    const copyInputRef = (0, react_1.useRef)(null);
    const handleCopyPath = (event) => {
        event.stopPropagation();
        if (copyInputRef.current) {
            web_react_1.Message.success("Path Copied!");
            copyInputRef.current.value = breadcrumbData.join("/");
            copyInputRef.current.select();
            document.execCommand("copy");
            copyInputRef.current.setSelectionRange(0, 0); // 取消选中文本
        }
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(web_react_1.Collapse, { className: "docs__collapse rounded-xl border-border-2", expandIcon: react_1.default.createElement(icon_1.IconFolder, { className: "text-[16px] text-text-2" }), activeKey: activeKey, onChange: () => { } },
            react_1.default.createElement(web_react_1.Collapse.Item, { header: react_1.default.createElement("div", { className: "ml-6 flex items-center justify-center" },
                    react_1.default.createElement(web_react_1.Breadcrumb, { className: "mr-[6px]" }, breadcrumbData.map((item) => (react_1.default.createElement(web_react_1.Breadcrumb.Item, { className: "p-0", key: item }, item)))),
                    react_1.default.createElement(Button_1.Button, { type: "text", shape: "circle", size: "small", icon: react_1.default.createElement(icon_1.IconCopy, { className: "text-text-2" }), onClick: handleCopyPath })), name: "1", extra: react_1.default.createElement("div", { className: "edit__button flex items-center" },
                    react_1.default.createElement(Button_1.Button, { className: "ml-3 flex flex-shrink-0 items-center justify-center", type: "text", size: "mini", shape: "round", onClick: handleClickDelete, icon: react_1.default.createElement(icon_1.IconDelete, { className: "text-[16px] text-text-2" }) })) })),
        react_1.default.createElement("input", { type: "text", ref: copyInputRef, className: "absolute left-[-9999px] top-[-9999px]", readOnly: true })));
};
exports.default = FolderCollapse;
