"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.projectApi = void 0;
const apiConfigV2_1 = require("../apiConfigV2");
const apiConfigV2_2 = require("../apiConfigV2");
const urlV2 = "/api/v2/project";
const getAllProjectV2 = (params) => (0, apiConfigV2_1.getApi)(urlV2 + "/get_project_list_by_workspace_id", params, true);
const createNewProjectV2 = (params) => {
    return (0, apiConfigV2_2.postApi)(urlV2 + "/create_project", {
        ...params,
        enable_index: true,
    }, true);
};
const postUpdateProjectWhitelist = (params) => {
    return (0, apiConfigV2_2.postApi)(urlV2 + "/update_project_whitelist", params, true);
};
const postRenameProjectV2 = (params) => (0, apiConfigV2_2.postApi)(urlV2 + "/rename_project", params, true);
const delProjectByIdV2 = (params) => {
    return (0, apiConfigV2_2.postApi)(urlV2 + "/destroy_project", params, true);
};
const postFileContentByProId = (data) => {
    const { project_uuid, paths, encoding = "utf-8" } = data;
    return (0, apiConfigV2_2.postApi)(urlV2 + "/get_file_content", {
        project_uuid,
        paths,
        encoding,
    }, true);
};
const getAllProjectList = (params) => {
    return (0, apiConfigV2_1.getApi)(urlV2 + "/get_all_project_list", params, true);
};
const getSearchFile = (params) => {
    return (0, apiConfigV2_1.getApi)(urlV2 + "/search_project_file", params, true);
};
const getSearchFolder = (params) => {
    return (0, apiConfigV2_1.getApi)(urlV2 + "/search_project_dir", params, true);
};
const updateProjectWhitelist = (params) => {
    return (0, apiConfigV2_2.postApi)(urlV2 + "/update_project_whitelist", params, true);
};
const postCodeSearch = (params) => {
    return (0, apiConfigV2_2.postApi)(urlV2 + "/code_search", params, true);
};
exports.projectApi = {
    getAllProjectV2,
    createNewProjectV2,
    delProjectByIdV2,
    postRenameProjectV2,
    postFileContentByProId,
    getAllProjectList,
    getSearchFile,
    getSearchFolder,
    updateProjectWhitelist,
    postUpdateProjectWhitelist,
    postCodeSearch,
};
