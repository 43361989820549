"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.softwareEngineerApi = void 0;
const apiConfigV2_1 = require("../apiConfigV2");
const originalUrlV2 = "/api/v2/swe";
/**
 * 添加期望feature
 * @param data
 * @returns
 */
const postAddFeature = (data) => {
    return (0, apiConfigV2_1.postApi)(originalUrlV2 + "/add_requirement", data, true);
};
const postInitAlignment = (data) => {
    return (0, apiConfigV2_1.postApi)(originalUrlV2 + "/initial_alignment_chat", data, true);
};
const postFinalAlignment = (data) => {
    return (0, apiConfigV2_1.postApi)(originalUrlV2 + "/final_alignment_chat", data, true);
};
const postDecideDevType = (data) => {
    return (0, apiConfigV2_1.postApi)(originalUrlV2 + "/decide_dev_type", data, true);
};
const postUpdateProjectDefaultReference = (data) => {
    return (0, apiConfigV2_1.postApi)(originalUrlV2 + "/update_project_default_reference_list", data, true);
};
const postjudgeStates = (data) => {
    //0 继续，1 回滚，2结束
    return (0, apiConfigV2_1.postApi)(originalUrlV2 + "/judge_current_states", data, true);
};
const postChooseFile = (data) => {
    return (0, apiConfigV2_1.postApi)(originalUrlV2 + "/choose_file", data, true);
};
const postGeneratePlan = (data) => {
    const { task_uuid, files_dict, encoding = "utf-8" } = data;
    return (0, apiConfigV2_1.postApi)(originalUrlV2 + "/generate_plan", {
        task_uuid,
        files_dict,
        encoding,
    }, true);
};
const getGeneratePlanSSE = (params) => {
    return (0, apiConfigV2_1.getApi)(originalUrlV2 + "/generate_plan_sse", params, true);
};
const getGenerateActionsSSE = (params) => {
    return (0, apiConfigV2_1.getApi)(originalUrlV2 + "/generate_actions_sse", params, true);
};
const postGenerateActions = (data) => {
    return (0, apiConfigV2_1.postApi)(originalUrlV2 + "/generate_actions", data, true);
};
const postApplyActions = (data) => {
    return (0, apiConfigV2_1.postApi)(originalUrlV2 + "/apply_actions", data, true);
};
const postModifyPlan = (data) => {
    return (0, apiConfigV2_1.postApi)(originalUrlV2 + "/modify_plan", data, true);
};
const postRegeDraft = (data) => {
    return (0, apiConfigV2_1.postApi)(originalUrlV2 + "/modify_draft", data, true);
};
const getTaskInfo = (params) => {
    return (0, apiConfigV2_1.getApi)(originalUrlV2 + "/get_task_info", params, true);
};
const getReferenceList = (params) => {
    return (0, apiConfigV2_1.getApi)(originalUrlV2 + "/get_reference_list", params, true);
};
const postRegenerateActions = (params) => {
    return (0, apiConfigV2_1.postApi)(originalUrlV2 + "/regenerate_actions", params, true);
};
const postModifyActions = (params) => {
    return (0, apiConfigV2_1.postApi)("/api/v2/project/write_file", params, true); //modify_action 现在直接改文件
};
const postRegenerateChooseFile = (params) => {
    return (0, apiConfigV2_1.postApi)(originalUrlV2 + "/regenerate_choose_file", params, true);
};
const postRegeneratePlan = (params) => {
    return (0, apiConfigV2_1.postApi)(originalUrlV2 + "/regenerate_plan", params, true);
};
const getRegeneratePlanSSE = (params) => {
    return (0, apiConfigV2_1.getApi)(originalUrlV2 + "/regenerate_plan_sse", params, true);
};
const postAcceptAllActions = (params) => {
    return (0, apiConfigV2_1.postApi)("/api/v2/project/write_file_list", params, true);
};
exports.softwareEngineerApi = {
    postAddFeature,
    postDecideDevType,
    postjudgeStates,
    postChooseFile,
    postGeneratePlan,
    postGenerateActions,
    postApplyActions,
    postModifyPlan,
    getTaskInfo,
    postRegenerateActions,
    postRegeneratePlan,
    postRegenerateChooseFile,
    postModifyActions,
    postAcceptAllActions,
    getGeneratePlanSSE,
    getRegeneratePlanSSE,
    getGenerateActionsSSE,
    postInitAlignment,
    postFinalAlignment,
    postUpdateProjectDefaultReference,
    getReferenceList,
    postRegeDraft,
};
