"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCbChatModeApi = exports.getRetryGenerateExplanationSSEUrl = exports.getAcceptDetailSSEUrl = exports.getAcceptFocusFilesSSEUrl = exports.understandExplainSSE = void 0;
const apiConfigV2_1 = require("../apiConfigV2");
const apiConfigV2_2 = require("../apiConfigV2");
const url = "/api/v2/query_code";
const urltask = "/api/v2/task";
const sseBaseUrl = "https://atlas-backend-f9ghdhgubpcdd6e8.z01.azurefd.net/api/v2";
const understandExplain = (params, onError) => {
    return (0, apiConfigV2_2.postApi)(`${url}/explain_code`, params, true, onError);
};
const understandExplainSSE = (params) => {
    return (0, apiConfigV2_2.postApi)(url + "/explain_code_sse", params, true);
};
exports.understandExplainSSE = understandExplainSSE;
const understandAskQuestion = (params, onError, signal) => {
    return (0, apiConfigV2_2.postApi)(url + "/answer_question", params, true, onError, signal);
};
const acceptFocusFiles = (params) => {
    return (0, apiConfigV2_1.getApi)(url + "/accept_focus_files", params);
};
const modifyFocusFiles = (params) => {
    return (0, apiConfigV2_2.postApi)(url + "/modify_focus_files", params, true);
};
const updateFocusFiles = (params) => {
    return (0, apiConfigV2_2.postApi)(urltask + "/update_focus_files", params, true);
};
const createTask = ({ project_uuid, task_type, task_name, openai_api_key = "", }) => {
    const params = openai_api_key
        ? {
            project_uuid,
            task_type,
            task_name: "", // if openai_api_key exist，task_name named ""
            openai_api_key,
        }
        : {
            project_uuid,
            task_type,
            task_name,
        };
    return (0, apiConfigV2_2.postApi)(urltask + "/create_task", params, true);
};
const getChatResult = (params) => {
    return (0, apiConfigV2_1.getApi)(url + "/get_result", params, true);
};
const getChatHistory = (params) => {
    return (0, apiConfigV2_1.getApi)(url + "/get_history", params, true);
};
const getChatAllTaskHistory = (params) => {
    return (0, apiConfigV2_2.postApi)(urltask + "/get_task_list_by_project_id", params, true);
};
const deleteChatTaskById = (params) => {
    return (0, apiConfigV2_2.postApi)(urltask + "/delete_task", params, true);
};
const renameTask = (params) => {
    return (0, apiConfigV2_2.postApi)(urltask + "/rename_task", params, true);
};
const retryRequest = (params) => {
    return (0, apiConfigV2_2.postApi)(url + "/retry_generate_explaination", params, true);
};
const getAcceptFocusFilesSSEUrl = (taskUuid) => {
    return `${sseBaseUrl}/query_code/accept_focus_files_sse?${new URLSearchParams({ task_uuid: taskUuid }).toString()}`;
};
exports.getAcceptFocusFilesSSEUrl = getAcceptFocusFilesSSEUrl;
const getAcceptDetailSSEUrl = (taskUuid) => {
    return `${sseBaseUrl}/query_code/answer_question_detailedly_sse?${new URLSearchParams({ task_uuid: taskUuid }).toString()}`;
};
exports.getAcceptDetailSSEUrl = getAcceptDetailSSEUrl;
const getRetryGenerateExplanationSSEUrl = (taskUuid, resultUuid) => {
    return `${sseBaseUrl}/query_code/retry_generate_explanation_sse?${new URLSearchParams({ task_uuid: taskUuid, result_uuid: resultUuid }).toString()}`;
};
exports.getRetryGenerateExplanationSSEUrl = getRetryGenerateExplanationSSEUrl;
const getChatTaskContext = (params, onError, signal) => {
    return (0, apiConfigV2_1.getApi)(urltask + "/retrieve_tools_usage_context", params, true, onError, signal);
};
const initialAlignmentChat = (params, onError, signal) => {
    return (0, apiConfigV2_2.postApi)(url + "/initial_alignment_chat", params, true, onError, signal);
};
const finalAlignmentChat = (params, onError, signal) => {
    return (0, apiConfigV2_2.postApi)(url + "/final_alignment_chat", params, true, onError, signal);
};
const getQueryCodeChatSSEUrl = (taskUuid, filePath, beginLineNo, endLineNo) => {
    return `${sseBaseUrl}/query_code/chat_code_sse?${new URLSearchParams({
        task_uuid: taskUuid,
        file_path: filePath,
        begin_lineno: beginLineNo.toString(),
        end_lineno: endLineNo.toString(),
    }).toString()}`;
};
const getContinueChatCodeSSEUrl = (taskUuid, message) => {
    return `${sseBaseUrl}/query_code/continue_chat_code_sse?${new URLSearchParams({
        task_uuid: taskUuid,
        message: message,
    }).toString()}`;
};
const stopAnswerQuestion = (params) => {
    return (0, apiConfigV2_2.postApi)(url + "/stop_answer_question", params, true);
};
const refuseAnswerQuestionDetailedly = (params) => {
    return (0, apiConfigV2_1.getApi)(url + "/refuse_answer_question_detailedly", params, true);
};
const summarizePreviousChat = (params) => {
    return (0, apiConfigV2_1.getApi)(url + "/summarize_previous_chat", params, true);
};
exports.useCbChatModeApi = {
    understandExplain,
    understandAskQuestion,
    acceptFocusFiles,
    modifyFocusFiles,
    createTask,
    getChatResult,
    getChatHistory,
    getChatAllTaskHistory,
    deleteChatTaskById,
    renameTask,
    retryRequest,
    updateFocusFiles,
    getChatTaskContext,
    initialAlignmentChat,
    finalAlignmentChat,
    stopAnswerQuestion,
    refuseAnswerQuestionDetailedly,
    summarizePreviousChat,
};
