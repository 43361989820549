"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSegments = void 0;
const jotai_1 = require("jotai");
const codebaseChatModeAtom_1 = require("@src/store/codebaseChatModeAtom");
// useSegments hook
const useSegments = () => {
    const [segmentsArray, setSegmentsArray] = (0, jotai_1.useAtom)(codebaseChatModeAtom_1.segmentsArrayAtom);
    const addSegments = (newSegments) => {
        setSegmentsArray((oldSegments) => [...oldSegments, ...newSegments]);
    };
    const replaceSegments = (newSegments) => {
        setSegmentsArray(newSegments);
    };
    // if need, add elete Segment
    return { segmentsArray, addSegments, replaceSegments };
};
exports.useSegments = useSegments;
