"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchRepo = exports.switchBranch = exports.getBranchList = void 0;
const apiConfigV2_1 = require("../apiConfigV2");
const apiConfigV2_2 = require("../apiConfigV2");
const url = "/api/v2/codebase";
const urlb = "/api/v2/workspace";
const getBranchList = (id) => {
    return (0, apiConfigV2_1.getApi)(url + "/get_branch_list", { id }, true);
};
exports.getBranchList = getBranchList;
const switchBranch = (params) => {
    return (0, apiConfigV2_2.postApi)(urlb + "/change_branch", params, true);
};
exports.switchBranch = switchBranch;
const fetchRepo = (params) => {
    return (0, apiConfigV2_2.postApi)(urlb + "/workspace_uuid_fetch?" + new URLSearchParams(params).toString(), {}, true);
};
exports.fetchRepo = fetchRepo;
