// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.workspace_left_main .arco-spin-children {

    height: 100%
}

.workspace_sidebar .arco-collapse-item .arco-collapse-item-icon-hover-right > .arco-collapse-item-header-icon-down {

    --tw-rotate: 180deg;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.workspace_sidebar .arco-collapse-item-content {

    background-color: transparent
}
.workspace_sidebar .arco-collapse-item-header {

    border-style: none;

    background-color: transparent
}
.workspace_sidebar .arco-collapse {

    border-style: none
}
.workspace_sidebar .arco-collapse-item {

    padding-top: 0.75rem;

    padding-bottom: 0.75rem
}
.workspace_sidebar .arco-collapse-item-content-box {

    padding-left: 0.5rem;

    padding-right: 0.5rem
}
.workspace_sidebar .add-button {

    border-radius: 0.5rem
}
.workspace_sidebar .edit_buttons .arco-btn-text:hover {

    background-color: rgb(var(--reverse-3))
}
.workspace_sidebar .edit_buttons .arco-icon-delete {

    font-size: 14px
}
.workspace_sidebar .edit_buttons .arco-icon-delete:hover {

    background-color: rgb(var(--primary-1));

    color: rgb(var(--primary-6))
}
.workspace_sidebar .edit_buttons .arco-icon-pen {

    font-size: 14px
}
.workspace_sidebar .edit_buttons .arco-icon-pen:hover {

    background-color: rgb(var(--primary-1));

    color: rgb(var(--primary-6))
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Workspace/CBWorkspaceLeft/index.scss"],"names":[],"mappings":"AAEI;;IAAA;AAAA;;AAOA;;IAAA,mBAAA;;IAAA;AAAA;AAGA;;IAAA;AAAA;AAGA;;IAAA,kBAAA;;IAAA;AAAA;AAGA;;IAAA;AAAA;AAGA;;IAAA,oBAAA;;IAAA;AAAA;AAGA;;IAAA,oBAAA;;IAAA;AAAA;AAGA;;IAAA;AAAA;AAIE;;IAAA;AAAA;AAGA;;IAAA;AAAA;AAAA;;IAAA,uCAAA;;IAAA;AAAA;AAGA;;IAAA;AAAA;AAAA;;IAAA,uCAAA;;IAAA;AAAA","sourcesContent":[".workspace_left_main {\r\n  .arco-spin-children {\r\n    @apply h-full;\r\n  }\r\n}\r\n.workspace_sidebar {\r\n  .arco-collapse-item\r\n    .arco-collapse-item-icon-hover-right\r\n    > .arco-collapse-item-header-icon-down {\r\n    @apply rotate-180 transform;\r\n  }\r\n  .arco-collapse-item-content {\r\n    @apply bg-transparent;\r\n  }\r\n  .arco-collapse-item-header {\r\n    @apply border-none bg-transparent;\r\n  }\r\n  .arco-collapse {\r\n    @apply border-none;\r\n  }\r\n  .arco-collapse-item {\r\n    @apply py-3;\r\n  }\r\n  .arco-collapse-item-content-box {\r\n    @apply px-2;\r\n  }\r\n  .add-button {\r\n    @apply rounded-lg;\r\n  }\r\n  .edit_buttons {\r\n    .arco-btn-text {\r\n      @apply hover:bg-reverse-3;\r\n    }\r\n    .arco-icon-delete {\r\n      @apply text-[14px] hover:bg-primary-1 hover:text-primary-6;\r\n    }\r\n    .arco-icon-pen {\r\n      @apply text-[14px] hover:bg-primary-1 hover:text-primary-6;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
