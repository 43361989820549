// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.selectfilebox .input{
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.selectfilebox .arco-select-multiple .arco-select-view{
  background-color: transparent;
}
.selectfilebox .arco-tag-checked{
  border-radius: 0.5rem;
}
.selectfilebox .hide-scrollbar {
  scrollbar-width: none; /* 对于 Firefox */
  -ms-overflow-style: none; /* 对于 IE 10+ 和 Edge */
  /* 对于 Chrome, Safari, 和 Opera */
}
.selectfilebox .hide-scrollbar::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/chatBox/selectFilebox/index.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAEZ;EAAA,aAAA;EAAA,uBAAA;EAAA,SAAA;EAAA;AAAA;AAGA;EAAA;AAAA;AAGA;EAAA;AAAA;AAEF;EACA,qBAAA,EAAA,eAAA;EACA,wBAAA,EAAA,qBAAA;EAEA,+BAAA;AAAF;AACE;EACE,aAAA;AACJ","sourcesContent":[".selectfilebox {\n  .input {\n    @apply flex items-start gap-[16px] self-stretch; \n  }\n  .arco-select-multiple .arco-select-view {\n    @apply bg-transparent;\n  }\n  .arco-tag-checked {\n    @apply rounded-lg; \n  }\n  .hide-scrollbar {\n  scrollbar-width: none; /* 对于 Firefox */\n  -ms-overflow-style: none;  /* 对于 IE 10+ 和 Edge */\n\n  /* 对于 Chrome, Safari, 和 Opera */\n  &::-webkit-scrollbar {\n    display: none;\n  }\n}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
