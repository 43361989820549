"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNewProject = useNewProject;
const web_react_1 = require("@arco-design/web-react");
const i18next_1 = require("i18next");
const jotai_1 = require("jotai");
const react_1 = require("react");
const api_1 = require("@src/api");
const allAtom_1 = require("@src/store/allAtom");
const cbDetailAtom_1 = require("@src/store/cbDetailAtom");
const handleProjectClick_1 = require("@src/util/LinkDifferentSections/handleProjectClick");
const { createNewProjectV2, getAllProjectV2 } = api_1.api;
function useNewProject() {
    const [newProjectModalVisible, setNewProjectModalVisible] = (0, react_1.useState)(false);
    const [isNewProjectLoading, setIsNewProjectLoading] = (0, react_1.useState)(false);
    const [currentCodebase] = (0, jotai_1.useAtom)(allAtom_1.currentCodebaseAtom);
    const [, setProjectList] = (0, jotai_1.useAtom)(cbDetailAtom_1.projectListAtom);
    const [, setProjectListLoading] = (0, jotai_1.useAtom)(cbDetailAtom_1.projectListLoadingAtom);
    const handleProjectClick = (0, handleProjectClick_1.useHandleProjectClick)();
    const getProjectList = async () => {
        setProjectListLoading(true);
        const res = await getAllProjectV2({
            workspace_uuid: currentCodebase.id,
        });
        if (res.status === 0) {
            const filteredProjects = res.data.project_infos.filter((project) => project.name !== "default_project");
            setProjectList(filteredProjects);
        }
        setProjectListLoading(false);
    };
    const handleNewProject = async (projectName, settingBranch, callBack) => {
        try {
            setIsNewProjectLoading(true);
            const res = await createNewProjectV2({
                workspace_uuid: currentCodebase.id,
                name: projectName,
                branch: settingBranch ? settingBranch : currentCodebase.currentBranch,
                enable_index: true,
            });
            if (res.status === 0) {
                await getProjectList();
                if (callBack) {
                    callBack(res.data.project_uuid, currentCodebase.name); //如果还有后续操作，不需要及时跳转的情况
                }
                else {
                    handleProjectClick(res.data.project_uuid, currentCodebase.name);
                }
            }
        }
        catch (error) {
            web_react_1.Message.error((0, i18next_1.t)("detail.project.create.error"));
        }
        finally {
            setNewProjectModalVisible(false);
            setIsNewProjectLoading(false);
        }
    };
    return {
        newProjectModalVisible,
        setNewProjectModalVisible,
        handleNewProject,
        isNewProjectLoading,
    };
}
