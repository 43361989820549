// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wp_input_text .arco-input-inner-wrapper {
  background-color: var(--color-fill-1);
}
.wp_input_text .arco-btn-secondary:not(.arco-btn-disabled) {
  background-color: var(--color-fill-3);
}

.wp_text_area .file_upload_button .arco-btn-secondary:not(.arco-btn-disabled) {
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-border-2);
  background-color: var(--color-fill-2);
}
.wp_text_area .arco-textarea {
  resize: none;
  border-radius: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-style: none;
  border-color: var(--color-fill-3);
  background-color: transparent;
  font-size: 14px;
}
.wp_text_area .arco-textarea:hover {
  background-color: transparent;
}
.wp_text_area .arco-textarea:focus {
  background-color: transparent;
}
.wp_text_area .arco-btn-secondary:not(.arco-btn-disabled) {
  background-color: var(--color-fill-3);
}
.wp_text_area .editable-content:empty::before {
  content: "Enter your message here. You can use @ to show files to Atlas.";
  color: gray;
  pointer-events: none;
}
.wp_text_area .textareaBtn .arco-btn-text:not(.arco-btn-disabled) {
  font-size: 12px;
  color: var(--color-text-2);
}
.wp_text_area .textareaBtn .rightBtn.arco-btn-text:not(.arco-btn-disabled) {
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-border-2);
}
.wp_text_area .textareaBtn .arco-btn-secondary:not(.arco-btn-disabled) {
  background-color: var(--color-fill-3);
}
.wp_text_area .typebtn .arco-btn-secondary:not(.arco-btn-disabled) {
  background-color: rgb(var(--primary-6));
}
.wp_text_area .typebtn .arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  background-color: rgb(var(--primary-6));
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Workspace/CBWorkspaceRight/InputArea/index.scss"],"names":[],"mappings":"AAEI;EAAA;AAAA;AAGA;EAAA;AAAA;;AAME;EAAA,iBAAA;EAAA,mBAAA;EAAA,mCAAA;EAAA;AAAA;AAIF;EAAA,YAAA;EAAA,kBAAA;EAAA,sBAAA;EAAA,uBAAA;EAAA,wBAAA;EAAA,kBAAA;EAAA,iCAAA;EAAA,6BAAA;EAAA;AAAA;AAEE;EAAA;AAAA;AAGA;EAAA;AAAA;AAIF;EAAA;AAAA;AAEF;EACE,yEAAA;EACA,WAAA;EACA,oBAAA;AAHJ;AAOI;EAAA,eAAA;EAAA;AAAA;AAIA;EAAA,kBAAA;EAAA,iBAAA;EAAA,mBAAA;EAAA;AAAA;AAIA;EAAA;AAAA;AAMA;EAAA;AAAA;AAGD;EAAA;AAAA","sourcesContent":[".wp_input_text {\n  .arco-input-inner-wrapper {\n    @apply bg-fill-1;\n  }\n  .arco-btn-secondary:not(.arco-btn-disabled) {\n    @apply bg-fill-3;\n  }\n}\n.wp_text_area {\n  .file_upload_button {\n    .arco-btn-secondary:not(.arco-btn-disabled) {\n      @apply border border-solid border-border-2 bg-fill-2;\n    }\n  }\n  .arco-textarea {\n    @apply resize-none rounded-none border-x-0 border-b-0 border-none border-fill-3 bg-transparent text-[14px];\n    &:hover {\n      @apply bg-transparent;\n    }\n    &:focus {\n      @apply bg-transparent;\n    }\n  }\n  .arco-btn-secondary:not(.arco-btn-disabled) {\n    @apply bg-fill-3;\n  }\n  .editable-content:empty::before {\n    content: \"Enter your message here. You can use @ to show files to Atlas.\";\n    color: gray;\n    pointer-events: none;\n  }\n  .textareaBtn {\n  .arco-btn-text:not(.arco-btn-disabled) {\n    @apply text-[12px] text-text-2;\n  }\n\n  .rightBtn.arco-btn-text:not(.arco-btn-disabled) {\n    @apply rounded-[4px] border border-solid border-border-2;\n  }\n\n  .arco-btn-secondary:not(.arco-btn-disabled) {\n    @apply bg-fill-3;\n  }\n}\n\n.typebtn {\n  .arco-btn-secondary:not(.arco-btn-disabled) {\n    @apply bg-primary-6;\n  }\n  .arco-btn-secondary:not(.arco-btn-disabled):not(.arco-btn-loading):hover {\n   @apply bg-primary-6;\n}\n}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
