// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center_main_container .center_main .arco-tabs-content {
  height: 100%;
  overflow-y: scroll;
  border-style: none;
  padding-bottom: 33px;
  padding-top: 0px;
}
.center_main_container .center_main .arco-tabs-content::-webkit-scrollbar {
  display: none;
}
.center_main_container .center_main .arco-tabs-content .arco-tabs-content-item {
  overflow: scroll;
}
.center_main_container .center_main .nothing .arco-result-icon-custom .arco-icon {
  margin-bottom: 0px;
  fill: rgb(var(--primary-6));
  color: var(--color-bg-1);
}
.center_main_container .center_main .nothing .arco-result-icon {
  margin-bottom: 0.5rem;
}
.center_main_container .center_main .nothing .arco-result-title {
  font-size: 14px;
}
.center_main_container .center_main::-webkit-scrollbar {
  display: none;
}
.center_main_container .arco-collapse {
  border-radius: 0.75rem;
  --tw-shadow: 0px 1px 3px 0px rgba(25, 33, 61, 0.10);
  --tw-shadow-colored: 0px 1px 3px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.center_main_container::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Workspace/CBWorkspaceCenter/index.scss"],"names":[],"mappings":"AAGM;EAAA,YAAA;EAAA,kBAAA;EAAA,kBAAA;EAAA,oBAAA;EAAA;AAAA;AACA;EACE,aAAA;AAAR;AAGQ;EAAA;AAAA;AAOE;EAAA,kBAAA;EAAA,2BAAA;EAAA;AAAA;AAIF;EAAA;AAAA;AAGA;EAAA;AAAA;AAGJ;EACE,aAAA;AANN;AAUI;EAAA,sBAAA;EAAA,mDAAA;EAAA,2DAAA;EAAA;AAAA;AAEF;EACE,aAAA;AAPJ","sourcesContent":[".center_main_container {\r\n  .center_main {\r\n    .arco-tabs-content {\r\n      @apply h-full overflow-y-scroll border-none pb-[33px] pt-0;\r\n      &::-webkit-scrollbar {\r\n        display: none;\r\n      }\r\n      .arco-tabs-content-item {\r\n        @apply overflow-scroll;\r\n      }\r\n    }\r\n\r\n    .nothing {\r\n      .arco-result-icon-custom {\r\n        .arco-icon {\r\n          @apply mb-0 fill-primary-6 text-bg-1;\r\n        }\r\n      }\r\n      .arco-result-icon {\r\n        @apply mb-2;\r\n      }\r\n      .arco-result-title {\r\n        @apply text-[14px];\r\n      }\r\n    }\r\n    &::-webkit-scrollbar {\r\n      display: none;\r\n    }\r\n  }\r\n  .arco-collapse {\r\n    @apply rounded-xl shadow;\r\n  }\r\n  &::-webkit-scrollbar {\r\n    display: none;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
