"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.taskApi = void 0;
const apiConfigV2_1 = require("../apiConfigV2");
const originalUrlV2 = "/api/v2/task";
const postTasksByProjectId = (params) => (0, apiConfigV2_1.postApi)(originalUrlV2 + "/get_task_list_by_project_id", params, true);
const postCreateTaskFast = (params) => {
    return (0, apiConfigV2_1.postApi)("/api/v2/swe/create_task", params, true);
};
const postVerifyActions = (params) => {
    return (0, apiConfigV2_1.postApi)("/api/v2/swe/verify_actions", params, true);
};
const postCreateTaskByRound = (params) => {
    return (0, apiConfigV2_1.postApi)("/api/v2/task/create_task", params, true);
};
const postRenameTask = (params) => (0, apiConfigV2_1.postApi)(originalUrlV2 + "/rename_task", params, true);
const postDeleteTask = (params) => (0, apiConfigV2_1.postApi)(originalUrlV2 + "/delete_task", params, true);
exports.taskApi = {
    postTasksByProjectId,
    postCreateTaskFast,
    postRenameTask,
    postDeleteTask,
    postCreateTaskByRound,
    postVerifyActions,
};
