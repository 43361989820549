"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserTimeZoneOffset = void 0;
exports.timeAgo = timeAgo;
const i18next_1 = require("i18next");
function timeAgo(timestamp) {
    const now = new Date(); // current local time in milliseconds
    const utcNow = now.getTime() / 1000 + now.getTimezoneOffset() * 60; // current UTC time in seconds
    const timeDifference = utcNow - timestamp; // timestamp is UTC time in seconds
    const seconds = Math.floor(timeDifference);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);
    if (seconds === 0) {
        return (0, i18next_1.t)("detail.topBar.lastFetchedNow");
    }
    else if (seconds < 60) {
        return (0, i18next_1.t)("detail.topBar.lastFetchedSeconds", { seconds });
    }
    else if (minutes < 60) {
        return (0, i18next_1.t)("detail.topBar.lastFetchedMinutes", { minutes });
    }
    else if (hours < 24) {
        return (0, i18next_1.t)("detail.topBar.lastFetchedHours", { hours });
    }
    else if (days < 7) {
        return (0, i18next_1.t)("detail.topBar.lastFetchedDays", { days });
    }
    else if (weeks < 4) {
        return (0, i18next_1.t)("detail.topBar.lastFetchedWeeks", { weeks });
    }
    else if (months < 12) {
        return (0, i18next_1.t)("detail.topBar.lastFetchedMonths", { months });
    }
    else {
        return (0, i18next_1.t)("detail.topBar.lastFetchedYears", { years });
    }
}
const getUserTimeZoneOffset = () => {
    const now = new Date();
    const timeZoneOffsetInMinutes = -now.getTimezoneOffset();
    return timeZoneOffsetInMinutes;
};
exports.getUserTimeZoneOffset = getUserTimeZoneOffset;
