// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes expandWidth {
  0% {
    width: 0;
  }
  100% {
    width: 30%;
  }
}
.payment__success .expand__width {
  animation: expandWidth 1s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/page/Payment/Success/index.scss"],"names":[],"mappings":"AACE;EACE;IACE,QAAA;EAAJ;EAEE;IACE,UAAA;EAAJ;AACF;AAGE;EACE,qCAAA;AADJ","sourcesContent":[".payment__success {\n  @keyframes expandWidth {\n    0% {\n      width: 0;\n    }\n    100% {\n      width: 30%;\n    }\n  }\n\n  .expand__width {\n    animation: expandWidth 1s ease-in-out;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
