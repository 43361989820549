// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard .arco-card-body {
  background-color: var(--color-fill-1);
  padding: 0px;
}
.dashboard .discription.arco-typography {
  margin-top: -4px;
  overflow-wrap: normal;
  word-break: normal;
}
.dashboard div.arco-typography {
  margin-top: -2px;
  overflow-wrap: normal;
  word-break: normal;
}
.dashboard h5.arco-typography {
  font-size: 18px;
  font-weight: 400;
}
.dashboard .arco-card-hoverable:hover {
  box-shadow: 0 3px 7px rgba(var(--gray-2), 0.3);
}`, "",{"version":3,"sources":["webpack://./src/page/Codebase/Home/DashBoard/index.scss"],"names":[],"mappings":"AAEI;EAAA,qCAAA;EAAA;AAAA;AAGA;EAAA,gBAAA;EAAA,qBAAA;EAAA;AAAA;AAGA;EAAA,gBAAA;EAAA,qBAAA;EAAA;AAAA;AAGA;EAAA,eAAA;EAAA;AAAA;AAEF;EACE,8CAAA;AAAJ","sourcesContent":[".dashboard {\n  .arco-card-body {\n    @apply p-0 bg-fill-1 \n  }\n  .discription.arco-typography {\n    @apply mt-[-4px] break-normal\n  }\n  div.arco-typography {\n    @apply mt-[-2px] break-normal\n  }\n  h5.arco-typography{\n    @apply text-[18px] font-normal\n  }\n  .arco-card-hoverable:hover {\n    box-shadow: 0 3px 7px rgba(var(--gray-2), 0.3);\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
