"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteCommentLike = exports.addCommentLike = exports.getCommentList = exports.deleteComment = exports.addComment = exports.getAllCategory = exports.postAllCommunityRepo = void 0;
const apiConfigV2_1 = require("../apiConfigV2");
const url = "/api/v2/community_repo";
const postAllCommunityRepo = () => {
    return (0, apiConfigV2_1.postApi)(url + "/list_all_repos", {});
};
exports.postAllCommunityRepo = postAllCommunityRepo;
const getAllCategory = () => {
    return (0, apiConfigV2_1.getApi)(url + "/get_category_list", {});
};
exports.getAllCategory = getAllCategory;
const urlb = "/api/v2/comment";
const addComment = (params) => {
    return (0, apiConfigV2_1.postApi)(urlb, params, true);
};
exports.addComment = addComment;
const deleteComment = (params) => {
    return (0, apiConfigV2_1.deleteApi)(urlb, params);
};
exports.deleteComment = deleteComment;
const getCommentList = (params) => {
    return (0, apiConfigV2_1.getApi)(urlb, params);
};
exports.getCommentList = getCommentList;
const addCommentLike = (params) => {
    return (0, apiConfigV2_1.postApi)(urlb + "/like", params, true);
};
exports.addCommentLike = addCommentLike;
const deleteCommentLike = (params) => {
    return (0, apiConfigV2_1.deleteApi)(urlb + "/like", params, true);
};
exports.deleteCommentLike = deleteCommentLike;
