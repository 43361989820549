"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const en = {
    workspace: {
        topBar: {
            workspace: "Workspace",
            repoFiles: "Repo Files",
            inProgress: "In Progress",
            chat: "Chat",
            save: "Save",
            saveProgress: "Save Progress",
            saveExit: "Save and Exit",
            rename: "Rename",
            back: "Back",
            baseOn: "Based on ",
        },
        left: {
            dashboard: "Dashboard",
            lastDate: "MMM DD, YYYY HH:mm",
            newSession: {
                title: "New Session",
                label: "Name of the new session:",
                placeholder: "Please enter session name",
                save: "Save",
                cancel: "Cancel",
                error: "Create new session error!",
            },
            renameSession: {
                title: "Rename Session",
                label: "Name of the session:",
                placeholder: "Please enter session name",
                save: "Save",
                cancel: "Cancel",
            },
            delete: {
                title: "Delete Session",
                content: "Removing a session is an irreversible process. All your existing files will also be deleted.",
                success: "Successfully deleted!",
                error: "Failed to delete: server error",
            },
            session: {
                new: "Session",
                plan: {
                    title: "Plan",
                },
                pending: "Pending",
                log: {
                    title: "Log",
                    workLog: "Work Log",
                },
                result: {
                    title: "Result",
                    view: "View",
                    fileChanged_one: "1 Changed Files",
                    fileChanged_other: "{{count}} Changed Files",
                },
                git: {
                    summary: "Summary (Required)",
                    description: "Description",
                    commitDev: "Commit to <0>Dev</0>",
                    commitContinue: "Commit & Continue working",
                },
            },
            sessionError: "This session has error occurred",
            config: "Configuration",
            priorRepo: "Prioritized Repo Modules",
            context: "Context",
        },
        center: {
            priorModuleView: "Prioritized Modules (View Only)",
            focusFiles: {
                agentRead: "Agent will read and analyze these files before drafting a plan:",
                unselectAll: "Unselect All",
                selectingLine: "Selecting Line ",
                unselect: "Unselect",
                entireFile: "Entire File",
                range_one: "1 Range",
                range_other: "{{count}} Ranges",
                addFile: "Add",
                existingFile: "Existing Repo File",
                createFile: "Create a new file / folder",
                createFilePlaceholder: "Please enter the path of the file you want to create",
                createFileButton: "Create",
                new: "New",
                editFile: "Edit Path",
                save: "Save",
                cancel: "Cancel",
            },
            addRefer: "Add Reference",
            align: "Let's align",
            upload: {
                error: "This file format is wrong, please check and do again",
                tip: "Upload files only, No folders",
            },
            codeDiff: {
                summary: {
                    start: "Showing {{count}} changed files ",
                    addition_one: "with 1 addition",
                    addition_other: " {{count}} additions",
                    and: " and",
                    deletion_one: " 1 deletion",
                    deletion_other: " {{count}} deletions",
                    end: ".",
                },
                applyAll: "Apply All",
                acceptAllError: "Accept All Actions Error!",
                regenerateError: "Regenerate Error!",
                rejectError: "Reject Error!",
                updateError: "Update action Error!",
            },
            selectSession: "Select a session first",
            enterGoal: "Enter your goal to start",
        },
        right: {
            history: "history",
            noSession: "No Session Created",
            chat: "Chat",
            regenerate: "Regenerate",
            youSaid: "You:",
            sdeAgentResponded: "Atlas:",
            no: "No",
            yes: "Yes",
            result: "View Result",
            generateModule: "Generate a new module",
            modifyModule: "Modify existing modules",
            submit: "Submit",
            inputPlaceholder: "Enter your message here",
            giveFeedback: "Give Feedback",
            startExecution: "Start Execution",
            finish: "Finish",
            continue: "Yes, Continue",
        },
        shared: {
            focusFiles: "Focus Files",
            implement: "Impl Plan",
            log: "Exploration",
            reference: "Configuration",
            expandAll: "Expand All",
            collapseAll: "Collapse All",
            update: "Update",
            cancel: "Cancel",
            edit: "Edit",
            save: "Save",
        },
    },
};
const zh = {
    workspace: {
        topBar: {
            workspace: "工作区",
            repoFiles: "仓库文件",
            inProgress: "进行中",
            chat: "聊天",
            save: "保存",
            saveProgress: "保存进度",
            saveExit: "保存并退出",
            rename: "重命名",
            back: "回退",
            baseOn: "基于",
        },
        left: {
            dashboard: "控制台",
            lastDate: "YYYY年MM月DD日 HH:mm",
            newSession: {
                title: "新会话",
                label: "新会话的名称：",
                placeholder: "请输入会话名称",
                save: "保存",
                cancel: "取消",
                error: "创建新会话错误！",
            },
            renameSession: {
                title: "重命名会话",
                label: "会话的名称：",
                placeholder: "请输入会话名称",
                save: "保存",
                cancel: "取消",
            },
            delete: {
                title: "删除会话",
                content: "删除会话是一个不可逆的过程。所有现有文件也将被删除。",
                success: "删除成功！",
                error: "删除失败：服务器错误",
            },
            session: {
                new: "会话",
                plan: {
                    title: "计划",
                },
                pending: "待定",
                log: {
                    title: "日志",
                    workLog: "工作日志",
                },
                result: {
                    title: "结果",
                    view: "查看",
                    fileChanged_one: "更改了 1 个文件",
                    fileChanged_other: "更改了 {{count}} 个文件",
                },
                git: {
                    summary: "摘要（必填）",
                    description: "描述",
                    commitDev: "提交到 <0>Dev</0>",
                    commitContinue: "提交并继续工作",
                },
            },
            sessionError: "此会话发生错误",
            config: "配置",
            priorRepo: "优先仓库模块",
            context: "外部依赖",
        },
        center: {
            priorModuleView: "优先模块（仅查看）",
            focusFiles: {
                agentRead: "AI 将在起草计划之前阅读和分析这些文件：",
                unselectAll: "取消所有选择",
                selectingLine: "已选择行 ",
                unselect: "取消选择",
                entireFile: "整个文件",
                range_one: "1 个范围",
                range_other: "{{count}} 个范围",
                addFile: "添加",
                existingFile: "现有仓库文件",
                createFile: "创建新文件 / 文件夹",
                createFilePlaceholder: "请输入要创建的文件的路径",
                createFileButton: "创建",
                new: "新",
                editFile: "编辑路径",
                save: "保存",
                cancel: "取消",
            },
            addRefer: "添加依赖",
            align: "让我们开始",
            upload: {
                error: "此文件格式错误，请检查并重新上传",
                tip: "仅上传文件，不支持文件夹",
            },
            codeDiff: {
                summary: {
                    start: "显示了 {{count}} 个已更改的文件",
                    addition_one: " ，包括 1 处新增",
                    addition_other: " {{count}} 处新增",
                    and: "和",
                    deletion_one: " 1 处删除",
                    deletion_other: " {{count}} 处删除",
                    end: "。",
                },
                applyAll: "全部应用",
                acceptAllError: "全部接受执行出错！",
                regenerateError: "重新生成出错！",
                rejectError: "拒绝出错！",
                updateError: "更新操作出错！",
            },
            selectSession: "选择一个会话",
            enterGoal: "输入您的目标以开始",
        },
        right: {
            chat: "聊天",
            regenerate: "重新生成",
            youSaid: "您说：",
            sdeAgentResponded: "Atlas:",
            no: "否",
            yes: "是",
            result: "结果",
            generateModule: "生成一个新模块",
            modifyModule: "修改现有模块",
            submit: "提交",
            inputPlaceholder: "在此输入您的消息",
            giveFeedback: "提供反馈",
            startExecution: "开始执行",
            finish: "完成",
            continue: "是的，继续",
            history: "历史记录",
            noSession: "暂无会话",
        },
        shared: {
            focusFiles: "重点文件",
            implement: "实施计划",
            log: "工作日志",
            reference: "项目配置",
            expandAll: "展开全部",
            collapseAll: "折叠全部",
            update: "更新",
            cancel: "取消",
            edit: "编辑",
            save: "保存",
        },
    },
};
exports.default = { en, zh };
