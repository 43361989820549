"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GitlabAccount = void 0;
const web_react_1 = require("@arco-design/web-react");
const icon_1 = require("@arco-design/web-react/icon");
const i18next_1 = require("i18next");
const jotai_1 = require("jotai");
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const userApi_1 = require("@src/api/userApi");
const Button_1 = require("@src/components/Button");
const userAtom_1 = require("@src/store/userAtom");
const RadioGroup = web_react_1.Radio.Group;
const GitlabAccount = ({ count, account, isNewAccount = false, setNewAccount, setIsLoading, handleRemove, }) => {
    const [user, setUser] = (0, jotai_1.useAtom)(userAtom_1.userAtom);
    return (react_1.default.createElement("div", { className: "flex flex-grow flex-col" },
        react_1.default.createElement("div", { className: "flex h-8 items-center justify-between" },
            react_1.default.createElement("span", { className: "flex items-center gap-1" },
                react_1.default.createElement("span", { className: "-mb-1 font-medium text-danger-6" }, "*"),
                react_1.default.createElement("span", null, (0, i18next_1.t)("settings.shared.account", { index: count + 1 }))),
            react_1.default.createElement("div", { className: "flex gap-[6px]" },
                (!isNewAccount || count !== 0) && (react_1.default.createElement(Button_1.Button, { type: "white", icon: react_1.default.createElement(icon_1.IconDelete, null), onClick: () => {
                        if (isNewAccount) {
                            setNewAccount(undefined);
                        }
                        else {
                            handleRemove && handleRemove(account.uuid);
                        }
                    } })),
                !isNewAccount && (react_1.default.createElement(Button_1.Button, { type: "white", icon: react_1.default.createElement(icon_1.IconPlus, null), onClick: () => {
                        setNewAccount({
                            uuid: "",
                            user_name: "",
                            oauth_token: "",
                            token_type: "GitLabApp",
                        });
                    } })))),
        react_1.default.createElement(RadioGroup, { className: "[&_.arco-radio]:pl-0", direction: "vertical", defaultValue: "1", value: account.token_type, disabled: !isNewAccount, onChange: (token_type) => {
                setNewAccount({ ...account, token_type });
            } },
            react_1.default.createElement(web_react_1.Radio, { className: "radio-item mt-2", value: "GitLabApp" }, (0, i18next_1.t)("settings.shared.oauth")),
            account.token_type === "GitLabApp" && (react_1.default.createElement(react_1.Fragment, null, isNewAccount ? (react_1.default.createElement(Button_1.Button, { className: "ml-6 mt-4", onClick: () => {
                    window.location.assign("https://gitlab.com/oauth/authorize?client_id=" +
                        process.env.GITLAB_CLIENT_ID +
                        "&response_type=code&redirect_uri=https://atlasxp.com/api/v2/gitlab/callback&scope=api");
                } }, (0, i18next_1.t)("settings.shared.auth.button"))) : (react_1.default.createElement("div", { className: "my-4 ml-6" },
                react_1.default.createElement("div", { className: "flex items-center gap-1" },
                    react_1.default.createElement("span", null, (0, i18next_1.t)("settings.shared.linking")),
                    react_1.default.createElement("span", { className: "font-medium text-primary-6" }, account.user_name)))))),
            react_1.default.createElement(web_react_1.Radio, { className: "radio-item mt-4", value: "Classic" }, (0, i18next_1.t)("settings.gitlab.token")),
            account.token_type === "Classic" && (react_1.default.createElement("div", { className: "mx-6 mt-4 flex flex-col gap-3" },
                react_1.default.createElement("label", { className: "flex flex-col gap-2 text-text-2" },
                    react_1.default.createElement("span", { className: "flex items-center gap-1" },
                        react_1.default.createElement("span", { className: "-mb-1 font-medium text-danger-6" }, "*"),
                        react_1.default.createElement("span", null, (0, i18next_1.t)("settings.gitlab.token")),
                        react_1.default.createElement(web_react_1.Tooltip, { content: react_1.default.createElement(react_router_dom_1.Link, { className: "text-primary-3", to: "https://docs.gitlab.com/ee/user/profile/personal_access_tokens.html#create-a-personal-access-token", target: "_blank" }, (0, i18next_1.t)("settings.shared.tokenTooltip")) },
                            react_1.default.createElement(icon_1.IconQuestionCircle, { className: "text-fill-4" }))),
                    react_1.default.createElement(web_react_1.Input, { className: "rounded-lg border border-solid border-border-2 bg-fill-2", value: account.oauth_token, allowClear: true, placeholder: (0, i18next_1.t)("settings.gitlab.tokenPlaceholder"), disabled: !isNewAccount, onChange: (oauth_token) => setNewAccount({ ...account, oauth_token }) })),
                react_1.default.createElement("label", { className: "flex flex-col gap-2 text-text-2" },
                    react_1.default.createElement("span", { className: "flex items-center gap-1" },
                        react_1.default.createElement("span", { className: "-mb-1 font-medium text-danger-6" }, "*"),
                        react_1.default.createElement("span", null, (0, i18next_1.t)("settings.gitlab.username"))),
                    react_1.default.createElement(web_react_1.Input, { className: "rounded-lg border border-solid border-border-2 bg-fill-2", value: account.user_name, allowClear: true, placeholder: (0, i18next_1.t)("settings.shared.usernamePlaceholder"), disabled: !isNewAccount, onChange: (user_name) => setNewAccount({ ...account, user_name }) })),
                isNewAccount && (react_1.default.createElement("div", { className: "mt-1 flex items-center gap-4" },
                    react_1.default.createElement(Button_1.Button, { onClick: () => {
                            setIsLoading(true);
                            (0, userApi_1.addGitLabClassicToken)({
                                user_id: user.uuid,
                                user_name: account.user_name,
                                token: account.oauth_token,
                            })
                                .then((res) => {
                                if ((res === null || res === void 0 ? void 0 : res.status) === 0) {
                                    web_react_1.Message.success("Successfully authenticated!");
                                    (0, userApi_1.getUserInfoByAuthingId)({
                                        authing_id: user.authing_id,
                                    }).then((res) => {
                                        setUser(res.data);
                                    });
                                }
                                else {
                                    throw new Error();
                                }
                            })
                                .catch(() => {
                                web_react_1.Message.error("Failed to authenticate! Please try again.");
                            })
                                .finally(() => {
                                setIsLoading(false);
                            });
                        } }, (0, i18next_1.t)("settings.shared.auth.button")))))))));
};
exports.GitlabAccount = GitlabAccount;
